import React from 'react';

export const PoweredLogo = () => (
  <svg
    id="Component_4_1"
    data-name="Component 4 – 1"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 18.003 18"
  >
    <path
      id="Path_529"
      data-name="Path 529"
      d="M109.62,58.995a.769.769,0,0,1-.77-.769V57.179a.769.769,0,0,1,1.539,0v1.047a.767.767,0,0,1-.769.769"
      transform="translate(-100.62 -49.266)"
      fill="#fff"
    />
    <path
      id="Path_530"
      data-name="Path 530"
      d="M130.45,50.972a.769.769,0,0,1-.77-.769V44.93a.769.769,0,1,1,1.539,0V50.2a.769.769,0,0,1-.769.77"
      transform="translate(-118.812 -38.568)"
      fill="#fff"
    />
    <path
      id="Path_531"
      data-name="Path 531"
      d="M88.8,50.972a.769.769,0,0,1-.77-.769V44.93a.769.769,0,1,1,1.539,0V50.2a.769.769,0,0,1-.769.77"
      transform="translate(-82.436 -38.568)"
      fill="#fff"
    />
    <path
      id="Path_532"
      data-name="Path 532"
      d="M52.85,18a9,9,0,1,1,8.27-5.433l.705,2.144a.769.769,0,0,1-.49.971l-5.662,1.864A9.076,9.076,0,0,1,52.85,18m0-16.462A7.459,7.459,0,0,0,49.5,15.658a7.411,7.411,0,0,0,5.694.427l4.931-1.624-.555-1.684a.874.874,0,0,1-.038-.161.755.755,0,0,1,.076-.436A7.46,7.46,0,0,0,52.854,1.54"
      transform="translate(-43.861 -0.001)"
      fill="#fff"
    />
  </svg>
);
