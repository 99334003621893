import React, { useContext, useState } from 'react';

export const AlertContext = React.createContext(null);

const defaultColor = '#ffa116';
const closeTime = 3000;
let alertCloseTimeout = null;

const defaultState = {
  title: '',
  description: '',
  color: defaultColor,
  open: false,
};

export const AlertProvider = props => {
  const [state, setState] = useState(defaultState);
  return (
    <AlertContext.Provider value={[state, setState]}>
      {props.children}
    </AlertContext.Provider>
  );
};

export const useAlert = () => {
  const [state, setState] = useContext(AlertContext);

  const openAlert = (title, description = '', color = defaultColor) => {
    clearTimeout(alertCloseTimeout);
    setState({
      title,
      description,
      color,
      open: true,
    });
    alertCloseTimeout = setTimeout(closeAlert, closeTime);
  };

  const closeAlert = () => {
    clearTimeout(alertCloseTimeout);
    setState(defaultState);
  };

  return {
    // global state
    ...state,
    // methods
    openAlert,
    closeAlert,
  };
};
