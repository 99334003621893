import React from 'react';
import { Flex } from 'reflexbox';
import styled, { createGlobalStyle, css } from 'styled-components';
import {
  WIDGET_INFORMATION_MAP,
  WIDGET_SCREENS,
  WithWidget,
} from '../../models/widget/withWidget';
import { Text } from '../../ui/text/Text';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { RecordWrapper } from '../../ui/widget/icons/RecordWrapper';
import { Microphone } from '../../ui/widget/icons/Microphone';
import { RedDot } from '../../ui/redDot/RedDot';
import { Box } from 'reflexbox';
import { PauseRecordingIcon } from '../../ui/icons/PauseRecordingIcon';
import { PlayRecordingIcon } from '../../ui/icons/PlayRecordingIcon';
import { RemakeRecordingIcon } from '../../ui/icons/RemakeRecordingIcon';
import { Progress, ProgressWrapper } from '../../ui/progress/Progress';
import { Send } from '../../ui/widget/icons/Send';
import { LoginRegisterScreen } from './LoginRegisterScreen';
import { Thanks } from '../../ui/widget/icons/Thanks';
import { Pointer } from '../../ui/box/Pointer';
import { PoweredBy } from '../../ui/widget/PoweredBy';
import { APP_USER_SCREENS } from '../../models/widget/withAppUser';
import { Avatar } from '../../ui/widget/Avatar';
import { MySpaceScreen } from './MySpaceScreen';
import { Gradient } from '../../ui/gradient/Gradient';
import { LevelsScreen } from './LevelsScreen';
import { ProfileScreen } from './ProfileScreen';
import { Close } from '../../ui/widget/icons/Close';
import { RankingScreen } from './RankingScreen';
import {
  COLOR,
  INTERACTION_API_TYPE_MAP,
  isMobileRegex,
  MP4_BACKGROUND_STR,
  STATIC_BUCKET,
} from '../../constants/common';
import { Other } from '../../ui/widget/icons/Other';
import { RecordButton } from '../../ui/widget/RecordButton';
import { Vote } from '../../ui/widget/icons/Vote';
import { Dots } from '../../ui/widget/Dots';
import { FbBlocked } from '../../ui/widget/FbBlocked';
import { Share } from '../../ui/widget/icons/Share';
import { Record as RecordIcon } from '../../ui/widget/icons/Record';
import { Relative } from '../../ui/box/Relative';
import { Absolute } from '../../ui/box/Absolute';
import { useUser } from '../../models/users/withUser';
import { Video as VideoIcon } from '../../ui/widget/icons/Video';
import { parse } from 'query-string';
import { useLocation } from 'react-router-dom';
import { get } from 'lodash';
import { Input } from '../../ui/input/Input';
import VizSensor from 'react-visibility-sensor';
import { WithVoteInteractionResults } from '../../models/interactions/withVoteInteractionResults';
import { Spinner } from '../../ui/spinner/Spinner';
import { Separator } from '../../ui/separator/Separator';
import { useTranslation } from 'react-i18next';

const GlobalStyle = createGlobalStyle`
   body {
    background:  ${({ backgroundColor }) =>
      backgroundColor || 'transparent'} !important;
    font-family: 'Ubuntu', sans-serif !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #root {
    flex: 1;
  }
  html, body, #root {
    width: 100%;
    height: 100%;
  }
  * {
    box-sizing: border-box;
  }

`;

const WidgetChannel = styled(Flex)`
  width: 100%;
  height: 100%;
  background-color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(${({ background }) => background || ''});
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  overflow: hidden;
  transition: background-image 150ms linear;
  position: relative;
  margin: auto !important;
`;

const WidgetInteraction = styled.div`
  color: white;
  font-family: 'Ubuntu', sans-serif;
  font-size: 20px;
  line-height: 32px;
  border-radius: 5px;
  position: relative;
`;

const Record = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  position: relative;
  background: ${({ showBg }) =>
    showBg ? 'rgba(0, 0, 0, 0.5)' : 'transparent'};
  box-shadow: ${({ showBg }) =>
    showBg ? '0 3px 6px 0 rgba(0, 0, 0, 0.16);' : 'unset'};
  border-radius: 12px;
  height: auto;
  padding: 18px 12px 55px;
  min-height: 137px;
`;

const InteractionContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  position: relative;
  z-index: 1005;
  max-width: 381px;
  margin: auto;

  .slick-track {
    display: flex;
    align-items: flex-end;
  }

  .slick-slider {
    max-width: 100vw;
  }

  .slick-slide div {
    outline: none !important;
  }
`;

const TextCenter = styled.div`
  font-size: 15px;
  line-height: 22px;
  font-weight: bold;
  margin: 6px 2px 0;
  width: 87%;
  text-align: center;
  white-space: normal;
`;

const TextUnavailable = styled(Text)`
  font-size: 15px !important;
  line-height: 22px !important;
  font-weight: bold;
  margin: ${({ margin }) => margin || '6px auto 0'} !important;
  width: 87%;
  text-align: center;
  white-space: normal;
  color: ${COLOR.white} !important;
`;

const Li = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  backface-visibility: hidden;
  overflow: visible;
  vertical-align: top;
  left: 0;
  right: 0;
  padding: 0 15px 70px;
  margin: 0;
  height: 100%;
`;

class Mounted extends React.PureComponent {
  componentDidMount() {
    const { onMounted } = this.props;
    if (onMounted) {
      onMounted();
    }
  }

  render() {
    return this.props.children;
  }
}

const TopPanel = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 13px 15.8px 0 18px;
  z-index: 2500;
`;

const RecorderInfo = styled(Box)`
  font-size: 26px;
  color: white;
  background: rgba(28, 28, 28, 0.65);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  height: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 28px;
  margin-top: 7px !important;
  width: calc(100% - 28px);
  padding: 0 25px 0 15px;
`;

const StopButton = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background: ${({ mainColor }) => mainColor || '#a37578'};
`;

const CloseWrapper = styled(Pointer)`
  position: absolute;
  right: 8px;
  top: 2px;
  line {
    stroke: ${COLOR.grey4};
  }
`;

const SmsLink = styled.a`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10000;
`;

const Choice = styled.div`
  width: 100%;
  height: 37px;
  border-radius: 27px;
  border: 1px solid ${COLOR.grey5};
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background: ${({ active }) =>
    active ? 'rgb(28,28,28, 0.7)' : 'transparent'};
  cursor: pointer;
  padding: 0 28px;
  font-size: 16px;
  margin-bottom: 10px;
`;

const ParticipateTooButton = styled.div`
  height: 37px;
  background: ${COLOR.blackish};
  border-radius: 27px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  cursor: pointer;
  position: absolute;
  bottom: -50px;
  user-select: none;
  width: 78.5%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PostedOnWrapper = styled.div`
  width: 100%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background: ${COLOR.blackish};
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  position: absolute;
  z-index: 3000;
`;

const PostedOnChannelLogo = styled.img`
  width: 44px;
  height: 44px;
  object-fit: cover;
`;

const Video = styled.video`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '100%'};
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  background: rgba(28, 28, 28, 0.7);
  object-position: 50% 20%;
  z-index: 1;

  ${isMobileRegex
    ? css`
        object-fit: cover;
        height: 100%;
        width: 100%;
        object-position: center;
      `
    : ''}
`;

const VideoBackground = styled.video`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
`;

const NotAvailable = styled.div`
  background-color: rgba(0, 0, 0, 0.78);
  position: absolute;
  padding: 80px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  z-index: 9000;
`;

const UploadInsideButton = styled.input`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
`;

const ProgressText = styled.div`
  font-weight: bold;
  font-size: 23px;
  line-height: 26px;
  text-align: center;
  color: ${COLOR.grey4};
`;

const TextCityWrapper = styled.div`
  margin-bottom: 17px;
  & > div {
    margin: 0 !important;
    width: 100%;
  }
`;

const CityFormWrapper = styled.div`
  input {
    color: white;
    font-size: 18px;
  }
  .input-label {
    margin-bottom: 0 !important;
  }
`;

const ResultLine = styled(Flex)`
  padding: 12px 0;
  border-bottom: 1px solid ${COLOR.grey1};
  &:last-of-type {
    border-bottom: none;
  }
`;

const sliderSettings = {
  dots: false,
  infinite: true,
  arrows: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplaySpeed: 5000,
};

export const WidgetScreen = () => {
  const {
    alreadyReceived,
    width,
    height,
    background,
    backgroundColor,
    channelPermanents,
    handleSlideChange,
    mainColor,
    startRecording,
    confirmRecord,
    activeScreen,
    recordingState,
    playing,
    playPause,
    send,
    remake,
    stopRecording,
    logout,
    getRecordingTime,
    getLeftProgress,
    activeSegment,
    setActiveScreen,
    handleAppUser,
    setInitialLoginRegisterScreen,
    initialLoginRegisterScreen,
    appUser,
    channel,
    handleClose,
    setHideAnonymous,
    hideAnonymous,
    managePreviousScreen,
    setPreviousScreen,
    getSmsLink,
    handleRegisterSms,
    setVoteScreen,
    manageChoice,
    sendVotes,
    selectedChoices,
    setAppUser,
    isFacebook,
    shareInteraction,
    isSocialMedia,
    sharedInteraction,
    clearShared,
    recordingStateVideo,
    playingVideo,
    playPauseVideo,
    sendVideo,
    stopRecordingVideo,
    getRecordingTimeVideo,
    getLeftProgressVideo,
    channelPermanentsLoaded,
    backgroundReady,
    handleUploadFromFile,
    uploadInitVideo,
    uploadProgress,
    extraUserInformation,
    setField,
    showValidation,
  } = WithWidget();
  const { t } = useTranslation();
  const { language } = useUser();
  const { search } = useLocation();
  const queryParams = parse(search);

  const anyChoiceSelected = Object.keys(selectedChoices).length !== 0;

  const isMainScreen =
    activeScreen === WIDGET_SCREENS.recording ||
    activeScreen === WIDGET_SCREENS.voting ||
    activeScreen === WIDGET_SCREENS.sending ||
    activeScreen === WIDGET_SCREENS.thanks ||
    activeScreen === WIDGET_SCREENS.voteResults ||
    activeScreen === WIDGET_SCREENS.record;

  const showVideo =
    confirmRecord &&
    activeSegment &&
    activeSegment.type === INTERACTION_API_TYPE_MAP.video &&
    (activeScreen === WIDGET_SCREENS.recording ||
      activeScreen === WIDGET_SCREENS.record);

  const isSharedVideo =
    sharedInteraction &&
    get(sharedInteraction, 'segment.type') === INTERACTION_API_TYPE_MAP.video;

  const isVideoBackground =
    background &&
    background.includes(MP4_BACKGROUND_STR) &&
    !background.includes(STATIC_BUCKET);

  setTimeout(() => {
    if (channel && channel.label) {
      window.document.title = channel.label;
    }
  }, 0);

  const isVoteInteraction =
    activeSegment &&
    (activeSegment.type === INTERACTION_API_TYPE_MAP.choice ||
      activeSegment.type === INTERACTION_API_TYPE_MAP.vote);

  const { results: voteResults } = WithVoteInteractionResults({
    interactionId:
      isVoteInteraction && activeScreen === WIDGET_SCREENS.voteResults
        ? activeSegment.id
        : null,
  });

  return (
    <>
      {(showVideo || isSharedVideo) && (
        <Video
          id={'video_recording'}
          autoPlay={!isSharedVideo && !uploadInitVideo}
          width={width}
          height={height}
          muted={recordingStateVideo !== 'finished'}
        />
      )}
      <WidgetChannel
        width={width}
        height={height}
        background={isVideoBackground ? null : background}
      >
        {isVideoBackground && (
          <VideoBackground src={background} muted autoPlay loop />
        )}
        {isMainScreen && <Gradient top />}
        <GlobalStyle backgroundColor={backgroundColor} />
        {activeScreen !== WIDGET_SCREENS.login &&
          activeScreen !== WIDGET_SCREENS.profileEdit &&
          activeScreen !== WIDGET_SCREENS.levels &&
          activeScreen !== WIDGET_SCREENS.ranking &&
          activeScreen !== WIDGET_SCREENS.mySpace && (
            <TopPanel>
              {channel && channel.my_space_enabled && (
                <Avatar
                  user={appUser}
                  showPoints={channel.gamification}
                  onClick={() =>
                    !appUser
                      ? setInitialLoginRegisterScreen() ||
                        setActiveScreen(WIDGET_SCREENS.login) ||
                        setHideAnonymous('home') ||
                        setPreviousScreen(activeScreen)
                      : setActiveScreen(WIDGET_SCREENS.mySpace)
                  }
                />
              )}
            </TopPanel>
          )}
        {sharedInteraction && channel && (
          <PostedOnWrapper>
            <Text mr={'10px'} upper theme={'white'} size={'h5'}>
              Message posted on
            </Text>
            {channel.logo_url ? (
              <PostedOnChannelLogo src={channel.logo_url} alt={''} />
            ) : (
              <Text mr={'10px'} upper theme={'white'} size={'h5'}>
                {channel.label}
              </Text>
            )}
          </PostedOnWrapper>
        )}
        {isMainScreen && (
          <InteractionContainer>
            {channelPermanents && channel && channel.status === 'active' ? (
              <Slider
                {...sliderSettings}
                autoplay={!activeSegment}
                swipe={!activeSegment}
                afterChange={handleSlideChange}
                initialSlide={
                  activeSegment ? channelPermanents.indexOf(activeSegment) : 0
                }
              >
                {sharedInteraction ? (
                  <Li>
                    <VizSensor
                      onChange={(isVisible) => {
                        if (!isVisible || !channelPermanents[0]) {
                          return;
                        }
                        setTimeout(() => {
                          window.ga
                            .getAll()[0]
                            .send(
                              'event',
                              'Interaction ' + channelPermanents[0].type,
                              'seen',
                              channelPermanents[0].id + '',
                              1
                            );
                        }, 1000);
                      }}
                    >
                      <WidgetInteraction>
                        <Record showBg>
                          <TextCenter>
                            <span>
                              {channelPermanents[0]
                                ? channelPermanents[0].text
                                : ''}
                            </span>
                          </TextCenter>
                          <RecorderInfo>
                            <Pointer
                              onClick={() =>
                                isSharedVideo ? playPauseVideo() : playPause()
                              }
                            >
                              {playing || playingVideo ? (
                                <Box>
                                  <PauseRecordingIcon />
                                </Box>
                              ) : (
                                <Box>
                                  <PlayRecordingIcon />
                                </Box>
                              )}
                            </Pointer>
                            <ProgressWrapper>
                              <Progress
                                width={
                                  isSharedVideo
                                    ? getLeftProgressVideo()
                                    : getLeftProgress()
                                }
                              />
                            </ProgressWrapper>
                            <Text theme={'white'} size={'h4'} semiBold>
                              {isSharedVideo
                                ? getRecordingTimeVideo(false)
                                : getRecordingTime(false)}
                            </Text>
                          </RecorderInfo>
                          <ParticipateTooButton onClick={clearShared}>
                            <Text size={'md'} upper theme={'white'} center>
                              I want to participate too
                            </Text>
                          </ParticipateTooButton>
                        </Record>
                      </WidgetInteraction>
                    </VizSensor>
                  </Li>
                ) : (
                  channelPermanents.map((segment, segmentIndex) => (
                    <Li key={segment.id}>
                      <VizSensor
                        onChange={(isVisible) => {
                          if (!isVisible) {
                            return;
                          }
                          setTimeout(() => {
                            try {
                              window.ga
                                .getAll()[0]
                                .send(
                                  'event',
                                  'Interaction ' + segment.type,
                                  'seen',
                                  segment.id + '',
                                  1
                                );
                            } catch (e) {
                              console.error(e);
                            }
                          }, 1000);
                        }}
                      >
                        <WidgetInteraction>
                          <Record
                            showBg={
                              activeScreen !== WIDGET_SCREENS.record ||
                              segment.text
                            }
                          >
                            {activeScreen === WIDGET_SCREENS.record ? (
                              <>
                                {(segment.type ===
                                  INTERACTION_API_TYPE_MAP.voice ||
                                  segment.type ===
                                    INTERACTION_API_TYPE_MAP.video ||
                                  (segment.type ===
                                    INTERACTION_API_TYPE_MAP.vote &&
                                    confirmRecord)) && (
                                  <>
                                    {confirmRecord &&
                                      segment.informationToGather?.length &&
                                      (segment.type ===
                                        INTERACTION_API_TYPE_MAP.voice ||
                                        segment.type ===
                                          INTERACTION_API_TYPE_MAP.vote) && (
                                        <CityFormWrapper>
                                          <TextCityWrapper>
                                            <TextUnavailable>
                                              {t(
                                                'So that your message can be quoted or broadcast on the air, tell us about yourself :'
                                              )}
                                            </TextUnavailable>
                                          </TextCityWrapper>
                                          {segment.informationToGather.map(
                                            (field) => (
                                              <Box key={field}>
                                                <Input
                                                  label={t(
                                                    WIDGET_INFORMATION_MAP[
                                                      field
                                                    ]
                                                  )}
                                                  labelSize={'md'}
                                                  labelColor={COLOR.grey4}
                                                  widgetInputSmall
                                                  value={
                                                    extraUserInformation[field]
                                                  }
                                                  onChange={setField}
                                                  name={field}
                                                  valid={
                                                    extraUserInformation[field]
                                                  }
                                                  showValidation={
                                                    showValidation
                                                  }
                                                />
                                              </Box>
                                            )
                                          )}
                                          <Text
                                            size={'xxxs'}
                                            theme={'white'}
                                            center
                                          >
                                            {t(
                                              'By validating this step, you agree that your data and your voice participation will be used by VoxM and by the recipient media in accordance with the conditions of use of the service.'
                                            )}
                                          </Text>
                                        </CityFormWrapper>
                                      )}
                                    <TextCenter>
                                      {segment && !confirmRecord && (
                                        <span>{segment.text}</span>
                                      )}
                                      {backgroundReady && !segment && (
                                        <TextUnavailable>
                                          Interactions with this radio are not
                                          currently available
                                        </TextUnavailable>
                                      )}
                                      {confirmRecord && (
                                        <TextUnavailable>
                                          Start recording when you're ready
                                        </TextUnavailable>
                                      )}
                                    </TextCenter>
                                    <RecordButton
                                      mainColor={mainColor}
                                      onClick={() => startRecording(segment)}
                                      reversed={confirmRecord}
                                    >
                                      <RecordWrapper />
                                      {!confirmRecord ? (
                                        segment.type ===
                                        INTERACTION_API_TYPE_MAP.video ? (
                                          <VideoIcon />
                                        ) : (
                                          <Microphone />
                                        )
                                      ) : (
                                        <RecordIcon />
                                      )}
                                      <Text
                                        theme={'white'}
                                        upper
                                        center
                                        size={'xms'}
                                      >
                                        {confirmRecord
                                          ? 'Record'
                                          : 'Participate'}
                                      </Text>
                                      {segment.type ===
                                        INTERACTION_API_TYPE_MAP.video && (
                                        <UploadInsideButton
                                          className={`gallery-upload_${segment.id}`}
                                          type={'file'}
                                          accept={'video/*'}
                                          onChange={handleUploadFromFile}
                                        />
                                      )}
                                    </RecordButton>
                                  </>
                                )}
                                {(segment.type ===
                                  INTERACTION_API_TYPE_MAP.vote ||
                                  segment.type ===
                                    INTERACTION_API_TYPE_MAP.choice) &&
                                  !confirmRecord && (
                                    <>
                                      <TextCenter>
                                        {segment && <span>{segment.text}</span>}
                                        {backgroundReady && !segment && (
                                          <TextUnavailable>
                                            Interactions with this radio are not
                                            currently available
                                          </TextUnavailable>
                                        )}
                                      </TextCenter>
                                      <RecordButton
                                        mainColor={mainColor}
                                        onClick={() => setVoteScreen(segment)}
                                      >
                                        <RecordWrapper />
                                        {segment.type ===
                                        INTERACTION_API_TYPE_MAP.vote ? (
                                          <Vote />
                                        ) : (
                                          <Other />
                                        )}
                                        <Text
                                          theme={'white'}
                                          upper
                                          center
                                          size={'xms'}
                                        >
                                          Participate
                                        </Text>
                                      </RecordButton>
                                    </>
                                  )}
                                {(segment.type ===
                                  INTERACTION_API_TYPE_MAP.sms ||
                                  segment.type ===
                                    INTERACTION_API_TYPE_MAP.registration) && (
                                  <>
                                    <TextCenter>
                                      {activeSegment ? (
                                        <>
                                          {activeSegment.type ===
                                            INTERACTION_API_TYPE_MAP.sms && (
                                            <TextUnavailable
                                              margin={'auto'}
                                              mb={'13px'}
                                            >
                                              We prepare the SMS…
                                            </TextUnavailable>
                                          )}
                                          <TextUnavailable>
                                            Confirm your participation please
                                          </TextUnavailable>
                                        </>
                                      ) : (
                                        <>
                                          {segment && (
                                            <span>{segment.text}</span>
                                          )}
                                          {segment &&
                                            segment.legal_notice &&
                                            segment.text && (
                                              <Box mt={'13px'}>
                                                {segment.legal_notice}
                                              </Box>
                                            )}
                                          {backgroundReady && !segment && (
                                            <Text theme={'white'}>
                                              Interactions with this radio are
                                              not currently available
                                            </Text>
                                          )}
                                        </>
                                      )}
                                    </TextCenter>
                                    <RecordButton
                                      mainColor={mainColor}
                                      reversed={activeSegment}
                                      onClick={() => handleRegisterSms(segment)}
                                    >
                                      <RecordWrapper />
                                      {activeSegment ? <Send /> : <Other />}
                                      <Text
                                        theme={'white'}
                                        upper
                                        center
                                        size={'xms'}
                                      >
                                        {activeSegment ? 'Send' : 'Participate'}
                                      </Text>
                                      {segment.type ===
                                        INTERACTION_API_TYPE_MAP.sms &&
                                      appUser &&
                                      activeSegment &&
                                      (!segment.registration_required ||
                                        !appUser.is_anonymous) ? (
                                        <SmsLink
                                          href={getSmsLink(segment)}
                                          target="_parent"
                                        />
                                      ) : null}
                                    </RecordButton>
                                  </>
                                )}
                              </>
                            ) : null}
                            {activeScreen === WIDGET_SCREENS.voting && (
                              <>
                                <Text
                                  theme={'white'}
                                  size={'h5'}
                                  mb={'22px'}
                                  mt={'-4px'}
                                >
                                  {anyChoiceSelected
                                    ? 'Confirm your choice'
                                    : 'What is your choice?'}
                                </Text>
                                {segment.choices.map((choice, index) => (
                                  <Choice
                                    active={selectedChoices[choice.id]}
                                    key={`choice-${choice.id}`}
                                    onClick={() => manageChoice(choice)}
                                  >
                                    {index + 1}.&nbsp;&nbsp;{choice.label || ''}
                                  </Choice>
                                ))}
                                <RecordButton
                                  mainColor={
                                    anyChoiceSelected ? mainColor : '#c9c9c9'
                                  }
                                  onClick={sendVotes}
                                  reversed
                                >
                                  <RecordWrapper />
                                  <Send />
                                  {anyChoiceSelected && (
                                    <Text
                                      theme={'white'}
                                      upper
                                      center
                                      size={'xms'}
                                    >
                                      Send
                                    </Text>
                                  )}
                                </RecordButton>
                              </>
                            )}
                            {activeScreen === WIDGET_SCREENS.recording && (
                              <>
                                {segment.type ===
                                INTERACTION_API_TYPE_MAP.video ? (
                                  <>
                                    {recordingStateVideo === 'finished' ? (
                                      <RecorderInfo>
                                        <Pointer
                                          onClick={() => playPauseVideo()}
                                        >
                                          {playingVideo ? (
                                            <Relative>
                                              <PauseRecordingIcon />
                                              <Absolute
                                                b={'-29px'}
                                                l={
                                                  language === 'fr'
                                                    ? '-12px'
                                                    : '-4px'
                                                }
                                              >
                                                <Text
                                                  size={'xms'}
                                                  theme={'white'}
                                                  upper
                                                >
                                                  Stop
                                                </Text>
                                              </Absolute>
                                            </Relative>
                                          ) : (
                                            <Relative>
                                              <PlayRecordingIcon />
                                              <Absolute
                                                b={'-29px'}
                                                l={
                                                  language === 'fr'
                                                    ? '-3px'
                                                    : language === 'pt'
                                                    ? '-23px'
                                                    : '-5px'
                                                }
                                              >
                                                <Text
                                                  size={'xms'}
                                                  theme={'white'}
                                                  upper
                                                >
                                                  Play
                                                </Text>
                                              </Absolute>
                                            </Relative>
                                          )}
                                        </Pointer>
                                        <ProgressWrapper>
                                          <Progress
                                            width={getLeftProgressVideo()}
                                          />
                                        </ProgressWrapper>
                                        <Text
                                          theme={'white'}
                                          size={'h4'}
                                          semiBold
                                        >
                                          {getRecordingTimeVideo(false)}
                                        </Text>
                                        <Relative ml={'15px'}>
                                          <Pointer>
                                            <RemakeRecordingIcon />
                                          </Pointer>
                                          <Absolute
                                            b={'-29px'}
                                            l={
                                              language === 'fr'
                                                ? '-30px'
                                                : language === 'pt'
                                                ? '-42px'
                                                : '-12px'
                                            }
                                          >
                                            <Text
                                              size={'xms'}
                                              theme={'white'}
                                              upper
                                              width={'115px'}
                                            >
                                              Restart
                                            </Text>
                                          </Absolute>
                                          <UploadInsideButton
                                            id={`gallery-upload_${segment.id}`}
                                            type={'file'}
                                            accept={'video/*'}
                                            onChange={handleUploadFromFile}
                                          />
                                        </Relative>
                                      </RecorderInfo>
                                    ) : (
                                      <RecorderInfo>
                                        <RedDot />
                                        <Text
                                          size={'h4'}
                                          theme={'white'}
                                          ml={'18px'}
                                          flex={1}
                                        >
                                          REC
                                        </Text>
                                        <Text
                                          size={'h4'}
                                          theme={'white'}
                                          ml={'18px'}
                                        >
                                          {getRecordingTimeVideo()}
                                        </Text>
                                      </RecorderInfo>
                                    )}
                                    <RecordButton
                                      mainColor={mainColor}
                                      onClick={() =>
                                        recordingStateVideo === 'finished'
                                          ? sendVideo()
                                          : stopRecordingVideo()
                                      }
                                      reversed
                                    >
                                      <RecordWrapper />
                                      {recordingStateVideo === 'finished' ? (
                                        <Send />
                                      ) : (
                                        <StopButton mainColor={mainColor} />
                                      )}
                                      <Text
                                        theme={'white'}
                                        upper
                                        center
                                        size={'xms'}
                                      >
                                        {recordingStateVideo === 'finished'
                                          ? 'Send'
                                          : 'Stop'}
                                      </Text>
                                    </RecordButton>
                                  </>
                                ) : (
                                  <>
                                    {recordingState === 'finished' ? (
                                      <RecorderInfo>
                                        <Pointer onClick={() => playPause()}>
                                          {playing ? (
                                            <Relative>
                                              <PauseRecordingIcon />
                                              <Absolute
                                                b={'-29px'}
                                                l={
                                                  language === 'fr'
                                                    ? '-12px'
                                                    : '-4px'
                                                }
                                              >
                                                <Text
                                                  size={'xms'}
                                                  theme={'white'}
                                                  upper
                                                >
                                                  Stop
                                                </Text>
                                              </Absolute>
                                            </Relative>
                                          ) : (
                                            <Relative>
                                              <PlayRecordingIcon />
                                              <Absolute
                                                b={'-29px'}
                                                l={
                                                  language === 'fr'
                                                    ? '-3px'
                                                    : language === 'pt'
                                                    ? '-23px'
                                                    : '-5px'
                                                }
                                              >
                                                <Text
                                                  size={'xms'}
                                                  theme={'white'}
                                                  upper
                                                >
                                                  Play
                                                </Text>
                                              </Absolute>
                                            </Relative>
                                          )}
                                        </Pointer>
                                        <ProgressWrapper>
                                          <Progress width={getLeftProgress()} />
                                        </ProgressWrapper>
                                        <Text
                                          theme={'white'}
                                          size={'h4'}
                                          semiBold
                                        >
                                          {getRecordingTime(false)}
                                        </Text>
                                        <Relative ml={'15px'}>
                                          <Pointer onClick={remake}>
                                            <RemakeRecordingIcon />
                                          </Pointer>
                                          <Absolute
                                            b={'-29px'}
                                            l={
                                              language === 'fr'
                                                ? '-30px'
                                                : language === 'pt'
                                                ? '-42px'
                                                : '-12px'
                                            }
                                          >
                                            <Text
                                              size={'xms'}
                                              theme={'white'}
                                              upper
                                              width={'115px'}
                                            >
                                              Restart
                                            </Text>
                                          </Absolute>
                                        </Relative>
                                      </RecorderInfo>
                                    ) : (
                                      <>
                                        <RecorderInfo>
                                          <RedDot />
                                          <Text
                                            size={'h4'}
                                            theme={'white'}
                                            ml={'18px'}
                                            flex={1}
                                          >
                                            REC
                                          </Text>
                                          <Text
                                            size={'h4'}
                                            theme={'white'}
                                            ml={'18px'}
                                          >
                                            {getRecordingTime()}
                                          </Text>
                                        </RecorderInfo>
                                        {activeSegment.max_recording_time && (
                                          <Flex mt={'10px'}>
                                            <Text
                                              size={'xms'}
                                              color={COLOR.grey5}
                                              upper
                                            >
                                              Maximum recording time
                                            </Text>
                                            <Text
                                              size={'xms'}
                                              color={COLOR.grey5}
                                            >
                                              &nbsp;:{' '}
                                              {activeSegment.max_recording_time}
                                              &nbsp;
                                            </Text>
                                            <Text
                                              size={'xms'}
                                              color={COLOR.grey5}
                                              upper
                                            >
                                              seconds
                                            </Text>
                                          </Flex>
                                        )}
                                      </>
                                    )}
                                    <RecordButton
                                      mainColor={mainColor}
                                      onClick={() =>
                                        recordingState === 'finished'
                                          ? send()
                                          : stopRecording()
                                      }
                                      reversed
                                    >
                                      <RecordWrapper />
                                      {recordingState === 'finished' ? (
                                        <Send />
                                      ) : (
                                        <StopButton mainColor={mainColor} />
                                      )}
                                      <Text
                                        theme={'white'}
                                        upper
                                        center
                                        size={'xms'}
                                      >
                                        {recordingState === 'finished'
                                          ? 'Send'
                                          : 'Stop'}
                                      </Text>
                                    </RecordButton>
                                  </>
                                )}
                              </>
                            )}
                            {activeScreen === WIDGET_SCREENS.sending && (
                              <>
                                <Text
                                  size={'h5'}
                                  theme={'white'}
                                  semiBold
                                  center
                                  blink={0.75}
                                  mt={'12px'}
                                >
                                  Sending...
                                </Text>
                                <RecordButton mainColor={COLOR.grey4} reversed>
                                  <RecordWrapper />
                                  {uploadProgress ? (
                                    <ProgressText>
                                      {uploadProgress}%
                                    </ProgressText>
                                  ) : (
                                    <Send />
                                  )}
                                </RecordButton>
                              </>
                            )}
                            {activeScreen === WIDGET_SCREENS.thanks && (
                              <>
                                {(segment.type ===
                                  INTERACTION_API_TYPE_MAP.voice ||
                                  segment.type ===
                                    INTERACTION_API_TYPE_MAP.video ||
                                  (segment.type ===
                                    INTERACTION_API_TYPE_MAP.vote &&
                                    confirmRecord)) && (
                                  <>
                                    <Box>
                                      <Text
                                        size={'h5'}
                                        theme={'white'}
                                        semiBold
                                        center
                                      >
                                        Message sent
                                      </Text>
                                      <Text
                                        size={'h5'}
                                        theme={'white'}
                                        semiBold
                                        center
                                      >
                                        Thank you for your participation
                                      </Text>
                                      <Text
                                        size={'h5'}
                                        theme={'white'}
                                        semiBold
                                        center
                                      >
                                        See you soon !
                                      </Text>
                                      {segment.type ===
                                        INTERACTION_API_TYPE_MAP.vote && (
                                        <Absolute r={'10px'} b={'8px'}>
                                          <Text
                                            underline
                                            theme={'white'}
                                            pointer
                                            onClick={() =>
                                              setActiveScreen(
                                                WIDGET_SCREENS.voteResults
                                              )
                                            }
                                          >
                                            See the results
                                          </Text>
                                        </Absolute>
                                      )}
                                    </Box>
                                    {channel.sharing_enabled ? (
                                      <RecordButton
                                        mainColor={mainColor}
                                        onClick={shareInteraction}
                                        reversed
                                      >
                                        <RecordWrapper />
                                        <Share />
                                        <Text
                                          theme={'white'}
                                          upper
                                          center
                                          size={'xms'}
                                        >
                                          Share
                                        </Text>
                                      </RecordButton>
                                    ) : (
                                      <RecordButton
                                        mainColor={mainColor}
                                        onClick={() =>
                                          !isSocialMedia && handleClose()
                                        }
                                        reversed
                                      >
                                        <RecordWrapper />
                                        <Thanks />
                                      </RecordButton>
                                    )}
                                  </>
                                )}
                                {((segment.type ===
                                  INTERACTION_API_TYPE_MAP.vote &&
                                  !confirmRecord) ||
                                  segment.type ===
                                    INTERACTION_API_TYPE_MAP.choice) && (
                                  <>
                                    {alreadyReceived ? (
                                      <Box>
                                        <Text
                                          size={'h5'}
                                          theme={'white'}
                                          semiBold
                                          center
                                        >
                                          Your participation has already been
                                          registered for that vote
                                        </Text>
                                      </Box>
                                    ) : (
                                      <Box>
                                        <Text
                                          size={'h5'}
                                          theme={'white'}
                                          semiBold
                                          center
                                        >
                                          Participation sent
                                        </Text>
                                        <Text
                                          size={'h5'}
                                          theme={'white'}
                                          semiBold
                                          center
                                        >
                                          Thank you for your participation
                                        </Text>
                                        <Text
                                          size={'h5'}
                                          theme={'white'}
                                          semiBold
                                          center
                                        >
                                          See you soon !
                                        </Text>
                                      </Box>
                                    )}
                                    {!alreadyReceived &&
                                    segment.testimonial_demanded ? (
                                      <>
                                        <Box>
                                          <Separator my={'10px'} />
                                          <Text
                                            size={'h5'}
                                            theme={'white'}
                                            semiBold
                                            center
                                          >
                                            {segment.testimonial ||
                                              'Tell us why ?'}
                                          </Text>
                                          <Absolute r={'10px'} b={'8px'}>
                                            <Text
                                              underline
                                              theme={'white'}
                                              pointer
                                              onClick={() =>
                                                setActiveScreen(
                                                  WIDGET_SCREENS.voteResults
                                                )
                                              }
                                            >
                                              See the results
                                            </Text>
                                          </Absolute>
                                        </Box>
                                        <RecordButton
                                          mainColor={mainColor}
                                          onClick={() =>
                                            startRecording(segment, true)
                                          }
                                          reversed
                                        >
                                          <RecordWrapper />
                                          <Microphone />
                                        </RecordButton>
                                      </>
                                    ) : (
                                      <RecordButton
                                        mainColor={mainColor}
                                        onClick={() =>
                                          !isSocialMedia && handleClose()
                                        }
                                        reversed
                                      >
                                        <RecordWrapper />
                                        <Thanks />
                                      </RecordButton>
                                    )}
                                  </>
                                )}
                                {(segment.type ===
                                  INTERACTION_API_TYPE_MAP.registration ||
                                  segment.type ===
                                    INTERACTION_API_TYPE_MAP.sms) && (
                                  <>
                                    <Box>
                                      <Text
                                        size={'h5'}
                                        theme={'white'}
                                        semiBold
                                        center
                                      >
                                        Registration sent
                                      </Text>
                                      <Text
                                        size={'h5'}
                                        theme={'white'}
                                        semiBold
                                        center
                                      >
                                        Thank you for your participation
                                      </Text>
                                      <Text
                                        size={'h5'}
                                        theme={'white'}
                                        semiBold
                                        center
                                      >
                                        See you soon !
                                      </Text>
                                    </Box>
                                    <RecordButton
                                      mainColor={mainColor}
                                      onClick={() =>
                                        !isSocialMedia && handleClose()
                                      }
                                      reversed
                                    >
                                      <RecordWrapper />
                                      <Thanks />
                                    </RecordButton>
                                  </>
                                )}
                              </>
                            )}
                            {activeScreen === WIDGET_SCREENS.voteResults && (
                              <>
                                <Box width={'100%'} px={'20px'}>
                                  <Text
                                    size={'h5'}
                                    theme={'white'}
                                    semiBold
                                    center
                                    mb={'20px'}
                                  >
                                    For now, the results are :
                                  </Text>
                                  {!voteResults.length ? (
                                    <Box pl={3}>
                                      <Spinner mh={'60px'} />
                                    </Box>
                                  ) : (
                                    voteResults.map((choice) => (
                                      <ResultLine
                                        justifyContent={'space-between'}
                                        key={choice.id}
                                      >
                                        <Text
                                          size={'h5'}
                                          theme={'white'}
                                          semiBold
                                          center
                                        >
                                          {choice.index}. {choice.label}
                                        </Text>
                                        <Text
                                          left
                                          size={'h5'}
                                          theme={'white'}
                                          semiBold
                                        >
                                          {choice.percentage}%
                                        </Text>
                                      </ResultLine>
                                    ))
                                  )}
                                </Box>
                                <RecordButton
                                  mainColor={mainColor}
                                  onClick={() =>
                                    !isSocialMedia && handleClose()
                                  }
                                  reversed
                                >
                                  <RecordWrapper />
                                  <Thanks />
                                </RecordButton>
                              </>
                            )}
                            {((activeScreen === WIDGET_SCREENS.thanks &&
                              !isSocialMedia) ||
                              (activeScreen === WIDGET_SCREENS.record &&
                                confirmRecord) ||
                              activeScreen === WIDGET_SCREENS.recording ||
                              activeScreen === WIDGET_SCREENS.voteResults ||
                              activeScreen === WIDGET_SCREENS.voting ||
                              (activeSegment &&
                                (activeSegment.type ===
                                  INTERACTION_API_TYPE_MAP.sms ||
                                  (activeScreen !== WIDGET_SCREENS.sending &&
                                    activeSegment.type ===
                                      INTERACTION_API_TYPE_MAP.video) ||
                                  activeSegment.type ===
                                    INTERACTION_API_TYPE_MAP.registration))) && (
                              <CloseWrapper onClick={handleClose}>
                                <Close />
                              </CloseWrapper>
                            )}
                            {channelPermanents.length > 1 &&
                            (activeScreen !== WIDGET_SCREENS.record ||
                              segment.text) &&
                            (activeScreen !== WIDGET_SCREENS.thanks ||
                              !segment.testimonial_demanded ||
                              alreadyReceived) ? (
                              <Dots
                                total={channelPermanents.length}
                                active={segmentIndex}
                                activeColor={mainColor}
                              />
                            ) : null}
                          </Record>
                        </WidgetInteraction>
                      </VizSensor>
                    </Li>
                  ))
                )}
              </Slider>
            ) : (
              <Li>
                <WidgetInteraction>
                  <Record>
                    <TextCenter>
                      {backgroundReady && (
                        <TextUnavailable>
                          Interactions with this radio are not currently
                          available
                        </TextUnavailable>
                      )}
                    </TextCenter>
                  </Record>
                </WidgetInteraction>
              </Li>
            )}
          </InteractionContainer>
        )}
        {activeScreen === WIDGET_SCREENS.ranking && (
          <RankingScreen
            appUser={appUser}
            onClose={() => setActiveScreen(WIDGET_SCREENS.mySpace)}
            channel={channel}
          />
        )}
        {activeScreen === WIDGET_SCREENS.login && (
          <LoginRegisterScreen
            onAppUser={handleAppUser}
            onClose={() =>
              managePreviousScreen()
                ? null
                : setActiveScreen(WIDGET_SCREENS.record)
            }
            initialScreen={initialLoginRegisterScreen}
            channelId={channel && channel.id}
            hideAnonymous={hideAnonymous}
          />
        )}
        {activeScreen === WIDGET_SCREENS.mySpace && (
          <MySpaceScreen
            channel={channel}
            goToLogin={() =>
              setInitialLoginRegisterScreen(APP_USER_SCREENS.loginEmail) ||
              setActiveScreen(WIDGET_SCREENS.login) ||
              setHideAnonymous(true) ||
              setPreviousScreen(WIDGET_SCREENS.mySpace)
            }
            goToRegister={() =>
              setInitialLoginRegisterScreen(APP_USER_SCREENS.register) ||
              setActiveScreen(WIDGET_SCREENS.login) ||
              setHideAnonymous(true) ||
              setPreviousScreen(WIDGET_SCREENS.mySpace)
            }
            onClose={() => setActiveScreen(WIDGET_SCREENS.record)}
            appUser={appUser}
            goToProfile={() => setActiveScreen(WIDGET_SCREENS.profileEdit)}
            goToLevels={() => setActiveScreen(WIDGET_SCREENS.levels)}
            goToRanking={() => setActiveScreen(WIDGET_SCREENS.ranking)}
          />
        )}
        {activeScreen === WIDGET_SCREENS.profileEdit && (
          <ProfileScreen
            onClose={() => setActiveScreen(WIDGET_SCREENS.mySpace)}
            onLogout={() => {
              logout();
              setActiveScreen(WIDGET_SCREENS.record);
            }}
            appUser={appUser}
            channelId={channel && channel.id}
            updateAppUser={setAppUser}
          />
        )}
        {activeScreen === WIDGET_SCREENS.levels && (
          <LevelsScreen
            channel={channel}
            onClose={() => setActiveScreen(WIDGET_SCREENS.mySpace)}
            appUser={appUser}
          />
        )}
        {isMainScreen && <PoweredBy />}
        {isMainScreen && <Gradient bottom />}
        {isFacebook &&
          activeSegment &&
          (activeSegment.type === INTERACTION_API_TYPE_MAP.voice ||
            activeSegment.type === INTERACTION_API_TYPE_MAP.video) && (
            <FbBlocked />
          )}
        {queryParams.from_perm == 2 &&
        channelPermanentsLoaded &&
        (!channelPermanents || !channelPermanents.length) ? (
          <NotAvailable>
            <Text theme={'white'} center size={'h3'} lh={'24px'}>
              Sorry this interaction is no longer available
            </Text>
          </NotAvailable>
        ) : null}
      </WidgetChannel>
    </>
  );
};
