import React from 'react';
import styled, { css } from 'styled-components';
import { AvatarUser } from './icons/AvatarUser';
import { Pointer } from '../box/Pointer';
import { getRankData } from '../../screens/widget/components/UserRankingAvatar';

const Wrapper = styled(Pointer)`
  width: 27px;
  height: 27px;
  position: relative;
  z-index: 6000;
  ${({ big }) =>
    big
      ? css`
          height: 93px;
          width: 93px;
        `
      : ''};
  svg {
    width: 100%;
    height: 100%;
  }
`;

const UserAvatar = styled.img`
  width: 27px;
  height: 27px;
  object-fit: cover;
  border: 1px solid white;
  border-radius: 50%;
  ${({ big }) =>
    big
      ? css`
          height: 93px;
          width: 93px;
          border-width: 2.5px;
        `
      : ''};
`;

const RankIconWrapper = styled.div`
  position: absolute;
  right: -10px;
  bottom: -5px;
  width: 19px;
  height: 19px;
  svg {
    width: 100%;
    height: 100%;
  }
  ${({ big }) =>
    big
      ? css`
          height: 52px;
          width: 52px;
          right: -13px;
          bottom: -7px;
        `
      : ''};
`;

export const Avatar = ({ user, big, showPoints, ...rest }) => {
  let icon = null;
  if (user && showPoints && user.points && !user.is_anonymous) {
    icon = getRankData(user.points).icon;
  }
  return (
    <Wrapper big={big} {...rest}>
      {user && (user.avatar_url || user.image_url) ? (
        <UserAvatar
          big={big}
          alt={''}
          src={user.avatar_url || user.image_url}
        />
      ) : (
        <AvatarUser />
      )}
      {icon ? <RankIconWrapper big={big}>{icon}</RankIconWrapper> : null}
    </Wrapper>
  );
};
