import React from 'react';

export const PaperAirplaneIcon = () => (
  <svg height="17" viewBox="0 0 17 17" width="17" xmlns="http://www.w3.org/2000/svg">
    <path d="m119.919 14.747v5.238a.5.5 0 0 0 .9.3l2.559-3.411.78-.4 8.442-11.941.157-.929-1.372.758-11.468 9.956z"
          fill="#fe9923" transform="translate(-115.934 -3.485)" />
    <path
      d="m16.31.04c-.022.009-16.01 8.037-16.035 8.048a.5.5 0 0 0 -.038.87l3.747 2.306 12.838-11.143a.482.482 0 0 0 -.512-.081z"
      fill="#fedb41" />
    <path
      d="m233.462 3.628c-.007-.007-.01-.018-.017-.024l-.1.138-9.285 13.131 5.746 3.536a.5.5 0 0 0 .75-.332l3.055-16a.5.5 0 0 0 -.149-.449z"
      fill="#fc3" transform="translate(-216.623 -3.485)" />
  </svg>
);
