import React from 'react';
import { Label } from '../input/Label';
import Datetime from 'react-datetime';
import { WithPicker } from '../../models/picker/withPicker';
import styled, { css } from 'styled-components';
import { Text } from '../text/Text';
import { COLOR } from '../../constants/common';
import moment from 'moment';
import { Absolute } from '../box/Absolute';
import { Relative } from '../box/Relative';
import ClickOutside from '../../features/utils/ClickOutside';
import { CalendarIcon } from '../icons/CalendarIcon';
import { Box } from 'reflexbox';

const TextBorder = styled(Text)`
  border-bottom: 1px solid ${COLOR.stone2_12};
`;

const PickerWrapper = styled(Absolute)`
  background: transparent;
  z-index: 1;
  width: 100%;
  table {
    width: 100%;
  }
`;

const CalendarIconWrapper = styled.div`
  svg {
    path {
      fill: ${COLOR.link};
    }
  }
`;

const Wrapper = styled(Box)`
  min-width: 161px !important;
  ${({ widgetInput }) =>
    widgetInput
      ? css`
          .rdtPicker {
            background: ${COLOR.blackish};
            color: white;
            width: 100%;
          }
        `
      : ''};
`;

export const DatePicker = ({
  label,
  name,
  value,
  onChange,
  widgetInput,
  widgetInputSmall,
}) => {
  const { open, setOpen } = WithPicker();
  const displayDate = value ? moment(value) : '';
  const dateFormat =
    widgetInput || widgetInputSmall ? 'DD/MM/YYYY' : 'dddd DD/MM/YYYY';
  const dateString = displayDate ? displayDate.format(dateFormat) : '';

  return (
    <Wrapper
      mb={'11px'}
      width={'100%'}
      widgetInput={widgetInput || widgetInputSmall}
    >
      {widgetInput ? (
        <Text color={'#a29e9e'} mb={'16px'} size={'h5'}>
          {label}
        </Text>
      ) : widgetInputSmall ? (
        <Text color={'#a29e9e'} size={'xs'} mb={'14px'}>
          {label}
        </Text>
      ) : (
        label && <Label>{label}</Label>
      )}
      <Relative width={'100%'} onClick={() => setOpen(true)}>
        <TextBorder
          pointer
          size={widgetInputSmall ? 'lg' : 'h5'}
          width={'100%'}
          theme={widgetInput || widgetInputSmall ? 'white' : null}
        >
          {dateString}&nbsp;
        </TextBorder>
        {open && (
          <PickerWrapper r={'0'}>
            <ClickOutside callback={() => setOpen(false)}>
              <Datetime
                open
                input={false}
                value={displayDate}
                onChange={value => onChange(name, value)}
                timeFormat={false}
              />
            </ClickOutside>
          </PickerWrapper>
        )}
        <Absolute r={'0'} t={'0'} display={'flex'}>
          <CalendarIconWrapper>
            <CalendarIcon />
          </CalendarIconWrapper>
        </Absolute>
      </Relative>
    </Wrapper>
  );
};
