import { useEffect, useState } from 'react';

import { request } from '../utils/request';
import { API_ENDPOINTS } from '../../constants/common';
import { emailRe } from '../../utils/utils';
import { useUser } from '../users/withUser';
import moment from 'moment';

const REQUEST_TIMEOUT = 350;

export const WithProfile = ({ initialAppUser, channelId, updateAppUser }) => {
  const { setLanguage } = useUser();
  let checkNicknameTimeout = null;
  let checkEmailTimeout = null;
  const [profile, setProfile] = useState({});
  const [appUser, setAppUser] = useState({});
  const [emailValid, setEmailValid] = useState(false);
  const [previousEmail, setPreviousEmail] = useState('');
  const [nicknameValid, setNicknameValid] = useState(false);
  const [passwordChangeRequested, setPasswordChangeRequested] = useState(false);

  const handleChange = (field, value) =>
    setProfile({ ...profile, [field]: value });

  const checkNicknameValid = () => {
    if (checkNicknameTimeout) {
      clearTimeout(checkNicknameTimeout);
    }
    setNicknameValid(false);
    checkNicknameTimeout = null;
    const { nickname } = profile;
    if (nickname) {
      checkNicknameTimeout = setTimeout(async () => {
        const { data } = await request({
          url: API_ENDPOINTS.checkNickname(nickname),
          method: 'GET',
        });
        setNicknameValid(data === 'OK');
        checkNicknameTimeout = null;
      }, REQUEST_TIMEOUT);
    }
  };

  const checkEmailValid = () => {
    if (checkEmailTimeout) {
      clearTimeout(checkEmailTimeout);
    }
    setEmailValid(false);
    checkEmailTimeout = null;
    const { email } = profile;
    if (email && emailRe.test(email)) {
      checkEmailTimeout = setTimeout(async () => {
        const { data } = await request({
          url: API_ENDPOINTS.checkEmail(email),
          method: 'GET',
        });
        setEmailValid(data === 'OK');
        checkEmailTimeout = null;
      }, REQUEST_TIMEOUT);
    }
  };

  const avatarSave = async () => {
    if (profile.avatar) {
      const body = {
        image: profile.avatar.preview,
        file_name: profile.avatar.name,
      };
      await request({
        url: API_ENDPOINTS.appUserAvatar(appUser.id),
        method: 'POST',
        body,
      });
    }
  };

  const save = async () => {
    await avatarSave();
    const body = {
      first_name: profile.first_name,
      last_name: profile.last_name,
      nickname: profile.nickname,
      phone: profile.phone,
      city: profile.city,
      language: profile.language,
      receive_emails: profile.receive_emails,
      birth_date: moment(profile.birth_date).format('DD/MM/YYYY'),
    };
    if (previousEmail !== profile.email && emailValid) {
      body.email = profile.email;
      body.login = profile.email;
    }
    const { data } = await request({
      url: API_ENDPOINTS.widgetAppUser(appUser.id),
      method: 'PUT',
      body,
    });
    if (data && !data.message) {
      setProfile(data);
      setAppUser(data);
      setPreviousEmail(data.email);
      setLanguage(data.language);
      updateAppUser &&
        updateAppUser({ ...data, points: initialAppUser.points });
      try {
        localStorage.setItem('voxm_app_user', JSON.stringify(data));
      } catch (e) {}
    }
  };

  const setNicknameField = (field, value) => {
    handleChange(field, value);
    profile.nickname = value;
    checkNicknameValid();
  };

  const setEmailField = (field, value) => {
    handleChange(field, value);
    profile.email = value;
    checkEmailValid();
  };

  const forgotPassword = () => {
    const body = { email: appUser.email, channel_id: channelId };
    request({
      url: API_ENDPOINTS.forgotEmail,
      method: 'POST',
      body,
    });
    setPasswordChangeRequested(true);
  };

  useEffect(() => {
    if (initialAppUser) {
      setProfile({ ...initialAppUser });
      setAppUser({ ...initialAppUser });
      setPreviousEmail(initialAppUser.email);
    }
  }, [initialAppUser]);

  return {
    // variables
    appUser,
    profile,
    emailValid,
    nicknameValid,
    passwordChangeRequested,
    //setters
    handleChange,
    checkEmailValid,
    checkNicknameValid,
    setNicknameField,
    setEmailField,
    save,
    forgotPassword,
  };
};
