import React from 'react';
import { Box, Flex } from 'reflexbox';
import styled from 'styled-components';

import { Text } from '../../ui/text/Text';
import { TopNav } from '../../ui/widget/TopNav';
import { ArrowBackIcon } from '../../ui/icons/ArrowBackIcon';
import { ScreenWrapper } from '../../ui/widget/ScreenWrapper';
import { AvatarUser } from '../../ui/widget/icons/AvatarUser';
import { getRankData } from './components/UserRankingAvatar';
import { WithRanking } from '../../models/widget/withRanking';
import { Spinner } from '../../ui/spinner/Spinner';
import { Absolute } from '../../ui/box/Absolute';
import { ScrollY } from '../../ui/box/ScrollY';
import { Translate } from '../../features/translate/Translate';

export const SectionHeader = styled(Translate)`
  font-size: 28px;
  font-weight: bold;
  line-height: 1.14;
  text-align: center;
  color: #d2d2d2;
  margin-top: 56px !important;
  text-transform: uppercase;
  margin-bottom: 15px !important;
`;

const Avatar = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1.5px solid white;
`;

const Order = styled.span`
  color: #fefffe;
  font-size: 13px;
  line-height: 14px;
  width: 24px;
  margin-left: 23px;
`;

const RankWrapper = styled(Flex)`
  border-bottom: 1px solid #a29e9e;
  width: calc(100% - 24px);
  margin: auto !important;
  align-items: center;
  padding: 8px;
  z-index: 3000;
  ${({ isMe }) => (isMe ? 'background: rgba(36, 36, 36, 0.85);' : '')}
  &:last-child {
    border-bottom: none;
  }
`;

const DefaultAvatarWrapper = styled.div`
  svg {
    width: 48px;
    height: 48px;
  }
`;

const AbsolutePointer = styled(Absolute)`
  cursor: pointer;
`;

const RankingPlace = ({ avatar_url, points, name, user_id, isMe }, index) => {
  const { icon } = getRankData(points);
  return (
    <RankWrapper key={`rank-${index}`} isMe={isMe}>
      <Order>{index + 1}</Order>
      {avatar_url ? (
        <Avatar src={avatar_url} alt={''} />
      ) : (
        <DefaultAvatarWrapper>
          <AvatarUser />
        </DefaultAvatarWrapper>
      )}
      <Box ml={'24px'} flex={1} pr={'10px'}>
        <Text size={'h4'} color={'#fefffe'} lh={'21px'} mb={'1px'}>
          {name || 'Pseudo'}
        </Text>
        <Flex>
          <Text color={'#d2d2d2'}>{points || 0}&nbsp;</Text>
          <Text color={'#d2d2d2'}>points</Text>
        </Flex>
      </Box>
      {icon}
    </RankWrapper>
  );
};

export const RankingScreen = ({ appUser, channel, onClose }) => {
  const { ranking, isLoading } = WithRanking({ appUser, channel });
  return (
    <ScreenWrapper>
      <TopNav>
        <AbsolutePointer onClick={onClose} left={'19px'} top={'15px'}>
          <ArrowBackIcon />
        </AbsolutePointer>
      </TopNav>

      <SectionHeader>Ranking</SectionHeader>

      <ScrollY py={'18px'} width={'100%'} flex={1}>
        {isLoading ? <Spinner color={'white'} /> : ranking.map(RankingPlace)}
      </ScrollY>
    </ScreenWrapper>
  );
};
