import { Box } from 'reflexbox';
import styled, { css } from 'styled-components';

export const Gradient = styled(Box)`
  position: absolute;
  z-index: 1000;
  left: 0;
  right: 0;
  height: 248px;
  ${({ top }) =>
    top
      ? css`
          top: 0;
          background: linear-gradient(
            to bottom,
            rgba(28, 28, 28, 0.3),
            transparent
          );
        `
      : ''};
  ${({ bottom }) =>
    bottom
      ? css`
          bottom: 0;
          background: linear-gradient(
            to top,
            rgba(28, 28, 28, 0.3),
            transparent
          );
        `
      : ''};
`;
