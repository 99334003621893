import i18n from 'i18next';

export const WEBVIEW_VERSIONS= [
  { value: '01', label: i18n.t('version 1') },
  { value: '02', label: i18n.t('version 2') },
];

export const YES_NO_OPTIONS = [
  { value: true, label: i18n.t('Yes') },
  { value: false, label: i18n.t('No') },
];

export const SUPPORTING_PARTICIPATION_FORMAT = [
  { value: 'voice', label: i18n.t('Voice') },
  { value: 'text', label: i18n.t('Text') },
];

