import styled, { css } from 'styled-components';
import { Flex } from 'reflexbox';
import { COLOR } from '../../constants/common';

export const IconButton = styled(Flex)`
  cursor: pointer;
  border-radius: 3px;
  border: solid 0.5px ${COLOR.brownishGrey};
  padding: 6px 11px 7px 10px;
  align-items: center;
  justify-content: center;
  margin-right: 20px !important;

  div {
    margin-left: 10px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
  }

  ${({ theme }) =>
    theme === 'white' &&
    css`
      background: ${COLOR.white};
      border: none;
      
    `}
`;
