import React from 'react';

export const UploadIcon = () => (
  <svg
    height="15"
    viewBox="0 0 15 15"
    width="15"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="#1592e6">
      <path
        d="m515.408 287.25 1.6-1.6v8.471a.776.776 0 0 0 1.552 0v-8.471l1.6 1.6a.776.776 0 1 0 1.1-1.1l-2.926-2.926a.776.776 0 0 0 -1.1 0l-2.926 2.926a.776.776 0 1 0 1.1 1.1zm0 0"
        transform="translate(-510.286 -283)"
      />
      <path
        d="m440.224 439a.776.776 0 0 0 -.776.776v5.948h-11.9v-5.948a.776.776 0 1 0 -1.552 0v6.207a1.293 1.293 0 0 0 1.293 1.293h12.414a1.293 1.293 0 0 0 1.297-1.293v-6.207a.776.776 0 0 0 -.776-.776zm0 0"
        transform="translate(-426 -432.276)"
      />
    </g>
  </svg>
);
