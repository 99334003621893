import React from 'react';

export const UsersIcon = () => (
  <svg
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m591 439a10 10 0 1 0 10 10 10 10 0 0 0 -10-10zm0 2.99a3.307 3.307 0 1 1 -3.305 3.308 3.31 3.31 0 0 1 3.305-3.308zm0 14.4a7.343 7.343 0 0 1 -4.778-1.763 1.41 1.41 0 0 1 -.494-1.072 3.335 3.335 0 0 1 3.35-3.333h3.85a3.329 3.329 0 0 1 3.345 3.333 1.409 1.409 0 0 1 -.493 1.072 7.344 7.344 0 0 1 -4.78 1.758zm0 0"
      fill="#707070"
      className="active-change"
      transform="translate(-581 -439)"
    />
  </svg>
);
