import React from 'react';
import {Flex} from 'reflexbox';
import styled from 'styled-components';
import {Text} from '../text/Text';
import {VoxMIcon} from '../icons/VoxMIcon';
import {COLOR} from '../../constants/common';
import {useTranslation} from "react-i18next";


const Wrapper = styled(Flex)`
  margin: ${({margin}) => margin};
  color: white;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  
  svg {
    width: 30px;
    height: 10px;
    fill: white;
  }
`;

export const PoweredBy = ({...rest}) => {
  const { t } = useTranslation();
  return (
    <Wrapper {...rest}>
      <Text color={COLOR.grey8} theme={'white'} size={'xxxs'} mr={'3px'}>
        {t('Powered by')}
      </Text>
      <VoxMIcon color={COLOR.grey8}/>
    </Wrapper>
  )
};
