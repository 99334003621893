import React from 'react';

export const HeroUserIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    data-name="Groupe 949"
    viewBox="0 0 40 40"
  >
    <g data-name="Groupe 410">
      <circle cx="20" cy="20" r="20" fill="#1c1c1c" data-name="Ellipse 58" />
    </g>
    <g data-name="Groupe 412" transform="translate(10.28 9.389)">
      <path
        d="M21.65 10.16l-3.03 4.14-4.72-1.95 1.69 10.54H27.7l1.7-10.54-4.71 1.95z"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="3px"
        data-name="Tracé 472"
        transform="translate(-12.08 -10.16)"
      />
      <g data-name="Groupe 411" transform="translate(0 16.7)">
        <path
          d="M31.52 28.86a12.6 12.6 0 0 1-19.44 0"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="3.5px"
          data-name="Tracé 473"
          transform="translate(-12.08 -28.86)"
        />
      </g>
    </g>
  </svg>
);
