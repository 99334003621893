import React, { useContext, useState } from 'react';

export const GlobalContext = React.createContext(null);

export const GlobalProvider = (props) => {
  const [state, setState] = useState({ refresh: 0 });
  return (
    <GlobalContext.Provider value={[state, setState]}>
      {props.children}
    </GlobalContext.Provider>
  );
};

export const GLOBAL_NAMES = {
  message: 'message',
  messages: 'messages',
};

export const useGlobal = () => {
  const [state, setState] = useContext(GlobalContext);

  const setVariable = (name, value) => {
    setState((previousState) => ({
      ...previousState,
      [name]: value,
    }));
  };

  // update object with values. Only if id is the same (or missing in both)
  const updateVariable = (name, values) => {
    setState((previousState) => ({
      ...previousState,
      [name]: previousState[name]
        ? values.id === previousState[name].id
          ? { ...previousState[name], ...values }
          : previousState[name]
        : { ...values },
    }));
  };

  // replace all values: {...newValue}
  const replaceListVariableById = (name, value) => {
    if (!value || !value.id) {
      return;
    }
    setState((previousState) => {
      if (!previousState[name] || !previousState[name].length) {
        return previousState;
      }
      return {
        ...previousState,
        [name]: previousState[name].map((item) =>
          item.id === value.id ? value : item
        ),
      };
    });
  };

  // update only specific values: {...oldValue, ...newValue}
  const updateListVariableById = (name, values) => {
    if (!values || !values.id) {
      return;
    }
    setState((previousState) => {
      if (!previousState[name] || !previousState[name].length) {
        return previousState;
      }
      return {
        ...previousState,
        [name]: previousState[name].map((item) =>
          item.id === values.id ? { ...item, ...values } : item
        ),
      };
    });
  };

  const refreshData = () =>
    setState((previousState) => ({
      ...previousState,
      refresh: previousState.refresh + 1,
    }));

  return {
    // global state
    ...state,
    // methods
    setVariable,
    refreshData,
    updateVariable,
    replaceListVariableById,
    updateListVariableById,
  };
};
