import React from 'react';

export const CalendarPickerIcon = () => (
  <svg
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <clipPath id="calendar-icon-clip-path">
      <path d="m0 0h20v20h-20z" />
    </clipPath>
    <g clipPath="url(#calendar-icon-clip-path)">
      <path
        d="m16.988 1.573h-1.023v-.787a.786.786 0 1 0 -1.573 0v.786h-3.578v-.786a.786.786 0 0 0 -1.573 0v.786h-3.541v-.786a.786.786 0 1 0 -1.573 0v.786h-.981a3.149 3.149 0 0 0 -3.146 3.147v12.269a3.149 3.149 0 0 0 3.146 3.146h6.016a.786.786 0 0 0 0-1.573h-6.016a1.575 1.575 0 0 1 -1.573-1.573v-12.269a1.575 1.575 0 0 1 1.573-1.573h.983v.786a.786.786 0 1 0 1.573 0v-.786h3.539v.786a.786.786 0 0 0 1.573 0v-.786h3.578v.786a.786.786 0 1 0 1.573 0v-.786h1.022a1.575 1.575 0 0 1 1.573 1.573v4.481a.786.786 0 0 0 1.573 0v-4.481a3.149 3.149 0 0 0 -3.146-3.146m-.472 13.016h-.354v-.826a.786.786 0 1 0 -1.573 0v1.612a.786.786 0 0 0 .786.786h1.14a.786.786 0 0 0 0-1.573m2.045.786a3.185 3.185 0 1 0 -3.185 3.185 3.189 3.189 0 0 0 3.185-3.185m1.573 0a4.758 4.758 0 1 1 -4.758-4.758 4.763 4.763 0 0 1 4.758 4.758m-11.8-1.062a.786.786 0 1 0 .786.786.786.786 0 0 0 -.786-.786m.786-2.635a.786.786 0 1 0 -.786.786.786.786 0 0 0 .786-.786m0-3.421a.786.786 0 1 0 -.786.786.786.786 0 0 0 .786-.786m-4.203 6.058a.786.786 0 1 0 .785.786.786.786 0 0 0 -.786-.786m.786-2.635a.786.786 0 1 0 -.786.786.786.786 0 0 0 .786-.786m10.265-3.421a.786.786 0 1 0 -.786.786.786.786 0 0 0 .786-.786m-3.421 0a.786.786 0 1 0 -.787.786.786.786 0 0 0 .787-.786m-6.842 0a.786.786 0 1 1 -.786-.786.786.786 0 0 1 .786.786"
        fill="#1592e6"
        transform="translate(-.294 -.428)"
      />
    </g>
  </svg>
);
