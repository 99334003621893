import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  ${({ open }) =>
    open &&
    css`
      svg {
        transform: rotate(${open ? 180 : 0}deg);
      }
    `}
`;

export const ArrowDropDownIcon = ({ open }) => (
  <Wrapper open={open}>
    <svg
      id="ic_arrow_drop_down_24px"
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="5"
      viewBox="0 0 10 5"
    >
      <path
        id="ic_arrow_drop_down_24px-2"
        data-name="ic_arrow_drop_down_24px"
        d="M7,10l5,5,5-5Z"
        transform="translate(-7 -10)"
      />
    </svg>
  </Wrapper>
);
