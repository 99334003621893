import { useEffect, useState } from 'react';
import { API_ENDPOINTS } from '../../constants/common';
import { request } from '../utils/request';
import moment from 'moment';
import { get } from 'lodash';
import { dateToLocal, padTime } from '../../utils/utils';

export const WithMySpace = ({ appUser, channel }) => {
  const [messages, setMessages] = useState();
  const [isLoading, setIsLoading] = useState();
  const [openMessages, setOpenMessages] = useState({});
  const [userPoints, setUserPoints] = useState(0);

  const fetchUserPoints = async () => {
    if (!channel || !appUser) {
      return;
    }
    const query = {
      channel_id: channel.id,
      user_id: appUser.id,
    };
    const { data } = await request({
      url: API_ENDPOINTS.rankingUser,
      method: 'GET',
      query,
    });
    setUserPoints(data);
  };

  const convert2Interaction = interaction => {
    const { content } = interaction;
    interaction.duration = '00:00';
    if (interaction.segment) {
      interaction.segmentType = interaction.segment.type;
    }
    interaction.created_at = dateToLocal(interaction.created_at);
    if (content && content.recording_id) {
      interaction.type = 'recording';
      interaction.recordingUrl = content.recording_url;
      if (content.duration) {
        const duration = moment.duration(get(interaction, 'content.duration'));
        interaction.duration = `${padTime(duration.minutes())}:${padTime(
          duration.seconds()
        )}`;
      }
      const { transcription } = content;
      let parsedTranscription = '';
      if (transcription && transcription.data && transcription.data.length) {
        parsedTranscription = transcription.data
          .map(value => value.transcript)
          .join(' ');
      }
      interaction.content = parsedTranscription;
    }
    interaction.date = moment(interaction.created_at).format(
      'DD/MM/YYYY - HH:mm'
    );
    if (interaction.replies) {
      interaction.replies = interaction.replies.map(convert2Interaction);
    }
    return interaction;
  };

  const fetchMessages = async () => {
    if (!appUser || !appUser.id) {
      return;
    }
    setIsLoading(true);
    const query = {};
    if (channel) {
      query.channel_id = channel.id;
    }
    const { data } = await request({
      url: API_ENDPOINTS.appUserMessages(appUser.id),
      method: 'GET',
      query,
    });
    const groupedMessages = data.reduce((groupedMessages, message) => {
      let date = moment(message.created_at).format('YYYY-MM-DD');
      if (!message.date) {
        message = convert2Interaction(message);
      }
      let messages;
      if (groupedMessages[date]) {
        messages = [...groupedMessages[date], message];
      } else {
        messages = [message];
      }
      let returnMessages = { ...groupedMessages };
      returnMessages[date] = messages;
      return returnMessages;
    }, {});

    setMessages(groupedMessages);
    setIsLoading(false);
  };

  const manageOpenMessages = id => {
    setOpenMessages({ ...openMessages, [id]: !openMessages[id] });
  };

  useEffect(() => {
    fetchUserPoints();
    fetchMessages();
  }, [appUser]);

  return {
    //variables
    userPoints,
    isLoading,
    openMessages,
    messages,
    channel,
    manageOpenMessages,
  };
};
