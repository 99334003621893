import React from 'react';

export const AmateurIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    data-name="Groupe 947"
    viewBox="0 0 40 40"
  >
    <g data-name="Groupe 421">
      <circle cx="20" cy="20" r="20" fill="#d2d2d2" data-name="Ellipse 61" />
    </g>
    <g data-name="Groupe 423" transform="translate(10.28 9.075)">
      <g data-name="Groupe 422" transform="translate(0 18.09)">
        <path
          d="M31.52 28.05a12.6 12.6 0 0 1-19.44 0"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="3px"
          data-name="Tracé 478"
          transform="translate(-12.08 -28.05)"
        />
      </g>
      <circle
        cx="6.96"
        cy="6.96"
        r="6.96"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="3px"
        data-name="Ellipse 62"
        transform="translate(2.76)"
      />
      <circle
        cx="2.02"
        cy="2.02"
        r="2.02"
        fill="#d2d2d2"
        data-name="Ellipse 63"
        transform="translate(7.7 4.94)"
      />
    </g>
  </svg>
);
