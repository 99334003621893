import React from 'react';
import { COLOR } from '../../constants/common';

export const CameraInteractionIcon = ({ color = COLOR.grey1 }) => (
  <svg
    enableBackground="new 0 0 24 24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <clipPath id="clip-path">
          <path d="M0 0h21v22H0z"/>
      </clipPath>
      <style>
    
      </style>
    </defs>
    <g id="Groupe_1448" data-name="Groupe 1448" transform="translate(-110 -520)">
      <g id="Groupe_1449" data-name="Groupe 1449" transform="translate(-7331 -17738)">
          <g id="Ikon" transform="translate(7444 18263)">
              <path d="M15.932 7.229h-2.293a.558.558 0 0 1-.524-.368L12.84 6.1A1.677 1.677 0 0 0 11.268 5H8.335a1.677 1.677 0 0 0-1.572 1.1l-.275.759a.559.559 0 0 1-.524.368H3.672A1.674 1.674 0 0 0 2 8.9v6.687a1.674 1.674 0 0 0 1.672 1.673h12.26a1.674 1.674 0 0 0 1.668-1.672V8.9a1.674 1.674 0 0 0-1.672-1.672zm.557 8.359a.558.558 0 0 1-.557.557H3.672a.558.558 0 0 1-.557-.557V8.9a.558.558 0 0 1 .557-.557h2.293a1.677 1.677 0 0 0 1.572-1.1l.275-.759a.559.559 0 0 1 .524-.368h2.933a.558.558 0 0 1 .524.368l.275.758a1.677 1.677 0 0 0 1.572 1.1h2.293a.558.558 0 0 1 .557.557z" transform="translate(-2 -5)" fill={color} />
              <path d="M26.115 12.557a.557.557 0 1 1-.558-.557.557.557 0 0 1 .558.557z" transform="translate(-12.183 -8.099)" fill={color} />
              <path d="M7.229 12.557a.557.557 0 0 1-.557.557H5.557a.557.557 0 0 1 0-1.115h1.115a.557.557 0 0 1 .557.558z" transform="translate(-3.328 -8.099)" fill={color} />
              <path d="M13.344 12a3.344 3.344 0 1 0 3.344 3.344A3.344 3.344 0 0 0 13.344 12zm0 5.573a2.229 2.229 0 1 1 2.229-2.229 2.229 2.229 0 0 1-2.229 2.229z" transform="translate(-5.542 -8.099)" fill={color} />
          </g>
      </g>
    </g>
  </svg>
);