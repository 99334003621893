import React from 'react';
import { Flex } from 'reflexbox';
import { PoweredLogo } from './icons/PoweredLogo';
import styled from 'styled-components';
import { Text } from '../text/Text';

const Wrapper = styled(Flex)`
  margin-right: 12px !important;
  margin-bottom: 12px !important;
  align-items: center;
  color: white;
  z-index: 2000;
`;

export const PoweredBy = ({ ...rest }) => (
  <Wrapper {...rest}>
    <PoweredLogo />
    <Text theme={'white'} size={'xs'} ml={'7.4px'}>
      with VoxM
    </Text>
  </Wrapper>
);
