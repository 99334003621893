import React from 'react';

export const DenyIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
  >
    <g>
      <path
        fill="#ff5858"
        d="M512.4 367.4l-4.5-4.5 4.5-4.5-3-3-4.5 4.5-4.5-4.5-3 3 4.5 4.5-4.5 4.5 3 3 4.5-4.5 4.5 4.5zm0 0"
        transform="translate(-497.398 -355.398)"
      />
    </g>
  </svg>
);
