import { useEffect, useState, useRef } from 'react';
import { API_ENDPOINTS } from '../../constants/common';
import { request } from '../utils/request';

export const WithVoteInteractionResults = ({ interactionId }) => {
  const [results, setResults] = useState([]);
  const interactionIdRef = useRef(interactionId);
  interactionIdRef.current = interactionId;
  const [totalCount, setTotalCount] = useState(0);

  const fetchResults = async () => {
    if (!interactionIdRef.current) {
      setResults([]);
      return;
    }
    const { data } = await request({
      url: API_ENDPOINTS.segmentVoteResults(interactionIdRef.current),
      method: 'GET',
    });
    if (data && data.length) {
      setTotalCount(data.reduce((n, { count }) => n + count, 0));
      setResults(data);
    } else {
      setResults([]);
    }
  };

  useEffect(() => {
    setResults([]);
    fetchResults();
  }, [interactionId]);

  return {
    fetchResults,
    totalCount,
    results,
  };
};
