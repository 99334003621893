import styled from 'styled-components';
import { Relative } from '../box/Relative';
import { Absolute } from '../box/Absolute';

export const ProgressWrapper = styled(Relative)`
  flex: 1;
  background: rgba(228, 228, 228, 0.7);
  border-radius: 8px;
  height: 5px;
  margin-left: 16px !important;
  margin-right: 16px !important;
`;

export const Progress = styled(Absolute)`
  height: 100%;
  border-radius: 8px;
  background: white;
  left: 0;
`;
