import React, { useEffect } from 'react';
import { Box } from 'reflexbox';
import styled, { css } from 'styled-components';
import { COLOR, INTERACTION_API_TYPE_MAP } from '../../constants/common';
import { WithVoteInteractionResults } from '../../models/interactions/withVoteInteractionResults';
import { FlexCenter } from '../../ui/box/FlexCenter';
import { useTranslation } from 'react-i18next';
import { Text } from '../../ui/text/Text';
import { VictoryBadgeIcon } from '../../ui/icons/VictoryBadgeIcon';

const Wrapper = styled(Box)`
  width: 100%;
  border-radius: 5px;
  max-width: 626px;
  padding: 9px 12px;
  background: ${COLOR.white};
  min-width: 279px !important;

  ${({ tooltip }) =>
    tooltip
      ? css`
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.25);
        `
      : css`
          border: 1px solid ${COLOR.veryLightPink};
        `}
`;

const ChoiceWrapper = styled.div`
  background: ${({ background }) => background};
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 6px;
  span,
  b {
    font-size: 12px;
    line-height: 16px;
    color: ${COLOR.brownishGrey};
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

const ChoiceLabel = styled.span`
  flex: 1;
  align-items: center;
  display: flex;
  svg {
    margin-left: 5px;
  }
  ${({ pointer }) =>
    pointer && css`
      cursor: pointer;
    `};
`;

const GameChoice = styled.span`
  color: #1592e6 !important;
  text-decoration: underline;
  cursor: pointer;
`

const ChoiceCount = styled.span`
  width: 46px;
  text-align: right;
`;

const ChoiceNumber = styled(FlexCenter)`
  background: ${COLOR.yellowishOrange};
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 13px !important;
  color: ${COLOR.white};
  font-size: 10px;
`;

const ChoicePercentage = styled(ChoiceCount)`
  font-weight: 600;
  margin-right: ${({ tooltip }) => (tooltip ? 0 : 27)}px;
`;

export const InteractionResults = ({ interactionId, tooltip, ...rest }) => {
  const { results, totalCount, fetchResults } = WithVoteInteractionResults({
    interactionId,
  });
  const { t } = useTranslation();

  useEffect(() => {
    const interval = setInterval(fetchResults, 2500);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Wrapper tooltip={tooltip} {...rest}>
      {results.length ? (
        <>
          {tooltip && (
            <Text semiBold mb={'14px'}>
              {t('Results')}
            </Text>
          )}
          {results.map((choice, index) => (
            <ChoiceWrapper key={`choice-${index}`}>
              {choice.id && <ChoiceNumber>{choice.index}</ChoiceNumber>}
              {rest.interactionType === INTERACTION_API_TYPE_MAP.game ? (
                <ChoiceLabel>
                  <GameChoice
                    onClick={() => rest.setChoice && rest.setChoice(choice)}
                  >{t(choice.label)}&nbsp;</GameChoice>
                  {choice.id === rest.correctAnswer && (
                    <VictoryBadgeIcon width={'10'}/>
                  )}
                </ChoiceLabel>
              ) : (
                <ChoiceLabel>{t(choice.label)}</ChoiceLabel>
              )}
              {choice.id && (
                <ChoicePercentage tooltip={tooltip}>
                  {choice.percentage} %
                </ChoicePercentage>
              )}
              <ChoiceCount>{choice.count}</ChoiceCount>
            </ChoiceWrapper>
          ))}
          {!tooltip && (
            <ChoiceWrapper>
              <ChoiceLabel
                pointer={INTERACTION_API_TYPE_MAP.game === rest.interactionType}
                onClick={() => rest.setChoice && rest.setChoice(null)}
              >{t('Total participations')}</ChoiceLabel>
              <ChoiceCount>{totalCount}</ChoiceCount>
            </ChoiceWrapper>
          )}
        </>
      ) : (
        <Text>{t('No results')}</Text>
      )}
    </Wrapper>
  );
};
