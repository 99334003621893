import styled, { css } from 'styled-components';
import { Flex } from 'reflexbox';
import { COLOR } from '../../constants/common';

export const Action = styled.withCustomProps(Flex, ['stroke'])`
  cursor: pointer;
  color: ${({ color }) => color || COLOR.link};
  display: inline;

  &:hover {
    color: ${COLOR.orange};
  }

  path {
    ${({ stroke, color }) =>
      stroke
        ? css`
            stroke: ${color || COLOR.link};
          `
        : css`
            fill: ${color || COLOR.link};
          `};
  }
`;
