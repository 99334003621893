import React from 'react';
import styled from 'styled-components';
import { Flex } from 'reflexbox';
import { Text } from '../text/Text';
import { COLOR } from '../../constants/common';
import { Relative } from '../box/Relative';
import { Absolute } from '../box/Absolute';
import { useTranslation } from 'react-i18next';

const CircleOuter = styled(Relative)`
  width: 14px;
  height: 14px;
  border: 1px solid ${COLOR.brownishGrey};
  border-radius: 50%;
`;

const CircleInner = styled(Absolute)`
  width: 10px;
  height: 10px;
  background: ${({ color }) => color || COLOR.yellowishOrange};
  top: 1px;
  left: 1px;
  border-radius: 50%;
`;

const OptionWrapper = styled(Flex)`
  cursor: pointer;
  align-items: center;
  flex-basis: 50%;
`;

export const RadioPicker = ({ options, value, onChange, name, colorInner, px='38px', inline=true }) => {
  const { t } = useTranslation();
  return (
    <Flex px={px} flexDirection={inline ? 'row' : 'column'}>
      {options.map((option) => (
        <OptionWrapper
          mb={inline ? '0' : '15px'}
          key={option.value}
          onClick={() => onChange(name, option.value)}
        >
          <CircleOuter>{value === option.value && <CircleInner color={colorInner}/>}</CircleOuter>
          <Text ml={'12px'}>
            {t(option.label) || ''}
          </Text>
        </OptionWrapper>
      ))}
    </Flex>
  );
};
