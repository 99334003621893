import React from 'react';

export const PauseRecordingIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="23" viewBox="0 0 20 23">
    <g id="Groupe_1110" data-name="Groupe 1110" transform="translate(-46 -441)">
      <g id="Rectangle_1937" data-name="Rectangle 1937" transform="translate(46 441)" fill="none" stroke="#fff"
         strokeWidth="1">
        <rect width="8" height="23" stroke="none"/>
        <rect x="0.5" y="0.5" width="7" height="22" fill="none"/>
      </g>
      <g id="Rectangle_1938" data-name="Rectangle 1938" transform="translate(58 441)" fill="none" stroke="#fff"
         strokeWidth="1">
        <rect width="8" height="23" stroke="none"/>
        <rect x="0.5" y="0.5" width="7" height="22" fill="none"/>
      </g>
    </g>
  </svg>
);
