import React from 'react';

export const CheckedIcon = () => (
  <svg id="ic_check_box_24px" xmlns="http://www.w3.org/2000/svg" width="13.662" height="13.662"
       viewBox="0 0 13.662 13.662">
    <path id="ic_check_box_24px-2" data-name="ic_check_box_24px"
          d="M15.144,3H4.518A1.518,1.518,0,0,0,3,4.518V15.144a1.518,1.518,0,0,0,1.518,1.518H15.144a1.518,1.518,0,0,0,1.518-1.518V4.518A1.518,1.518,0,0,0,15.144,3ZM8.313,13.626l-3.8-3.8,1.07-1.07,2.725,2.717,5.761-5.761L15.144,6.8Z"
          transform="translate(-3 -3)" fill="#1592e6"/>
  </svg>
);
