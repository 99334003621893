import { Text } from '../text/Text';
import React from 'react';

export const Label = ({ children, actionColor, ...rest }) => (
  <Text
    semiBold
    upper
    theme={actionColor ? 'orange' : 'lighter'}
    mb={'3px'}
    {...rest}
  >
    {children}
  </Text>
);
