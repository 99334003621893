import React from 'react';

export const ExpertIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    data-name="Groupe 943"
    viewBox="0 0 40 40"
  >
    <g data-name="Groupe 414">
      <circle cx="20" cy="20" r="20" fill="#fa544d" data-name="Ellipse 59" />
    </g>
    <g data-name="Groupe 416" transform="translate(10.28 25.561)">
      <g data-name="Groupe 415">
        <path
          d="M31.52 27.09a12.6 12.6 0 0 1-19.44 0"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="3px"
          data-name="Tracé 474"
          transform="translate(-12.08 -27.09)"
        />
      </g>
    </g>
    <g data-name="Groupe 417" transform="translate(14.34 10.528)">
      <path
        d="M21.8 19.77a5.655 5.655 0 0 1-5.66-5.66V11h11.32v3.1a5.663 5.663 0 0 1-5.66 5.67z"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="3px"
        data-name="Tracé 476"
        transform="translate(-16.14 -11)"
      />
      <path
        d="M0 0v3.18"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="3px"
        data-name="Ligne 103"
        transform="translate(5.66 9.35)"
      />
      <path
        d="M0 0h5.55"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="3px"
        data-name="Ligne 104"
        transform="translate(2.89 13.45)"
      />
    </g>
  </svg>
);
