import styled from 'styled-components';
import { Sticky } from '../sticky/Sticky';
import { COLOR } from '../../constants/common';
import { Absolute } from '../box/Absolute';

export const ActionsBar = styled(Sticky)`
  height: 48px;
  background: ${({ backgroundColor }) =>
    backgroundColor || COLOR.lightBackground};
  padding: 11px 20px 15px 25px;
  border-bottom: 1px solid #e6e6e6;
  align-items: center;
  z-index: 1;
`;

export const MoreActions = styled(Absolute)`
  display: flex;
  width: 200px;
  position: absolute;
  flex-direction: column;
  background: ${COLOR.lightBackground};
  right: 0;
  top: ${({ t }) => t || '48px'};
  border-bottom: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
  z-index: 2;
  padding: 14px 5px 18.7px 17.8px;
`;
