import React from 'react';

export const ParticipationsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26.863"
    height="20"
    viewBox="0 0 26.863 20"
  >
    <g>
      <g>
        <path
          d="M2.643 16.217h-.03A1.188 1.188 0 0 1 1.5 15l.01-12.118a1.167 1.167 0 0 1 1.103-1.211H17.88A1.188 1.188 0 0 1 19 2.885v6.582c.229-.022.449-.033.678-.033a6.589 6.589 0 0 1 .818.056V2.84A2.8 2.8 0 0 0 17.86 0H2.613A2.755 2.755 0 0 0 .01 2.84L0 15.036v.011a2.77 2.77 0 0 0 2.613 2.84h9.473a9.185 9.185 0 0 1 .15-1.671z"
          fill="#a1a3a3"
          className="active-change"
        />
      </g>
      <path
        d="M20.011 10.11a7.416 7.416 0 0 0-6.881 4.956 7.416 7.416 0 0 0 6.881 4.956 7.416 7.416 0 0 0 6.881-4.956 7.416 7.416 0 0 0-6.881-4.956zm0 8.257a3.21 3.21 0 0 1-3.121-3.3 3.131 3.131 0 1 1 6.252 0 3.218 3.218 0 0 1-3.131 3.3zm0-5.285a1.987 1.987 0 1 0 1.889 1.994v-.01a1.93 1.93 0 0 0-1.885-1.984z"
        fill="#a1a3a3"
        className="active-change"
        transform="translate(-.029 -.022)"
      />
      <path
        d="M17.836 3.488l-.389-.648-7.2 4.308L3.038 2.84 2.27 4.126l7.978 4.767 7.968-4.767-.769-1.286.389.648-.389-.648-7.2 4.308L3.038 2.84 2.27 4.126l7.978 4.767 7.968-4.767-.769-1.286z"
        fill="#a1a3a3"
        className="active-change"
        transform="translate(-.006 .432)"
      />
    </g>
  </svg>
);
