import React from 'react';

export const DeleteIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.606"
    height="21.5"
    viewBox="0 0 15.606 21.5"
  >
    <path
      fill="none"
      stroke="#a1a3a3"
      strokeWidth="1.5px"
      d="M6.008 20.778A2.133 2.133 0 0 0 8.023 23h8.061a2.133 2.133 0 0 0 2.016-2.222V7.444H6.008zm13.1-16.667H15.58L14.572 3H9.534L8.527 4.111H5v2.222h14.106z"
      transform="translate(-4.25 -2.25)"
      className="active-change-stroke"
    />
  </svg>
);
