import React from 'react';

export const DuplicateIcon = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="20" 
    height="20" 
    viewBox="0 0 20 20">

    <g data-name="Groupe 1446">
        <g data-name="Layer 2">
            <path data-name="Tracé 708" 
            d="M3.386 21.364h8.864a2.39 2.39 0 0 0 2.386-2.386V7.386A2.39 2.39 0 0 0 12.25 5H3.386A2.39 2.39 0 0 0 1 7.386v11.591a2.39 2.39 0 0 0 2.386 2.387zM2.818 7.386a.568.568 0 0 1 .568-.568h8.864a.568.568 0 0 1 .568.568v11.591a.568.568 0 0 1-.568.568H3.386a.568.568 0 0 1-.568-.568z" 
            transform="translate(-1 -1.364)" 
            fill="#a1a3a3"
            />
            <path data-name="Tracé 709" 
            d="M19.257 1H10.4a2.39 2.39 0 0 0-2.2 1.464.909.909 0 0 0 1.675.709.573.573 0 0 1 .526-.355h8.855a.574.574 0 0 1 .573.573v11.582a.574.574 0 0 1-.573.573H17.1a.909.909 0 0 0 0 1.818h2.155a2.394 2.394 0 0 0 2.391-2.391V3.391A2.394 2.394 0 0 0 19.257 1z" 
            transform="translate(-1.648 -1)" 
            fill="#a1a3a3"
            />
        </g>
    </g>
  </svg>
);
