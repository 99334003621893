import React from 'react';

export const CalendarIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="ic_today_24px" width="24" height="24"
         viewBox="0 0 22 24">
      <path
        fill='currentColor'
        d="M20.425 2.96h-1.089V1h-2.178v1.96H8.445V1H6.267v1.96H5.178a2.067 2.067 0 0 0-2.167 1.96L3 18.643A2.077 2.077 0 0 0 5.178 20.6h15.247a2.084 2.084 0 0 0 2.178-1.96V4.921a2.084 2.084 0 0 0-2.178-1.961zm0 15.683H5.178V7.861h15.247zM7.356 9.822H12.8v4.9H7.356z"
      />
    </svg>
  )
};
