import { useEffect, useState } from 'react';
import {
  API_ENDPOINTS,
  COLOR,
  isDesktop,
  SAMPLE_BACKGROUNDS,
} from '../../constants/common';
import { request } from '../utils/request';
import { useHistory, useLocation } from 'react-router-dom';
import { WithBackup } from '../backup/withBackup';
import i18n from 'i18next';
import { parse } from 'query-string';
import { useUser } from '../../../models/users/withUser';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const channelStatuses = {
  demo: { value: 'demo', label: i18n.t('Demo') },
  active: { value: 'active', label: i18n.t('Active') },
  closed: { value: 'closed', label: i18n.t('Closed') },
};

export const channelTypes = [
  { value: 'radio', label: i18n.t('Radio') },
  { value: 'tv', label: i18n.t('TV') },
  { value: 'event', label: i18n.t('Event') },
  { value: 'press', label: i18n.t('Press') },
];

const channelDefault = {
  type: channelTypes[0].value,
  status: channelStatuses.demo.value,
  interaction_available_voice: true,
  primary_color: COLOR.defaultPrimaryColor,
  background: SAMPLE_BACKGROUNDS[0],
};

export const WithChannel = ({
  channelId: channelIdDefault,
  onChannelSave,
  onSave,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [channel, setChannel] = useState(channelDefault);
  const { backup, setBackup } = WithBackup(channelDefault);
  let [channelId, setChannelId] = useState();
  const history = useHistory();
  const { search } = useLocation();
  const { isSuperAdmin } = useUser();
  const [parentChannelOptions, setParentChannelOptions] = useState([]);

  const setField = (name, value) => {
    if (name.includes('|')) {
      const [key, index] = name.split('|');
      if (!channel[key]) {
        channel[key] = [];
      }
      channel[key][index] = value;
      return setChannel({ ...channel });
    }
    setChannel({ ...channel, [name]: value });
  };

  const fetchParentChannels = async () => {
    if (!isSuperAdmin) {
      return;
    }
    const { data } = await request({
      url: API_ENDPOINTS.channelList,
      method: 'GET',
    });

    const newOptions = data.map((channel) => ({
      value: channel.id,
      label: channel.label,
    }));
    setParentChannelOptions(newOptions);
  };

  const channelCreate = async () => {
    const { data } = await request({
      url: API_ENDPOINTS.channels,
      method: 'POST',
      body: getChannelToSave(channel),
    });
    if (data.id) {
      channelId = data.id;
      setChannelId(data.id);
      const tasks = [channelLogoSave(), channelBackgroundSave()];
      Promise.all(tasks)
        .then(() => {
          fetchChannelDetails(data.id);
          history.replace(`/channels/${data.id}`);
          setIsEdit(false);
        })
        .catch((e) => {
          fetchChannelDetails(data.id);
          history.replace(`/channels/${data.id}`);
          setIsEdit(false);
        });
    }
  };

  const getChannelToSave = () => {
    const channelToSave = { ...channel };
    delete channelToSave.logo;
    if (typeof channelToSave.background !== 'string') {
      delete channelToSave.background;
    }
    return channelToSave;
  };

  const channelInfoSave = async () => {
    const { data } = await request({
      url: API_ENDPOINTS.channelDetails(channel.id),
      method: 'PUT',
      body: getChannelToSave(channel),
    });
    onChannelSave && onChannelSave(data);
    setChannel(data);
    setBackup(data);
    onSave && onSave();
  };

  const channelLogoSave = async () => {
    if (channel.logo) {
      const formData = new FormData();
      formData.append('image', channel.logo);
      const { data } = await request({
        url: API_ENDPOINTS.channelLogoUpload(channelId),
        method: 'POST',
        formData,
      });
      setChannel(data);
      setBackup(data);
      onChannelSave && onChannelSave(data);
    } else if (channel.logo === false) {
      const { data } = await request({
        url: API_ENDPOINTS.channelLogoUpload(channelId),
        method: 'DELETE',
      });
      setChannel(data);
      setBackup(data);
      onChannelSave && onChannelSave(data);
    }
  };

  const channelBackgroundSave = async () => {
    if (channel.background && channel.background.preview) {
      const formData = new FormData();
      formData.append('image', channel.background);
      const { data } = await request({
        url: API_ENDPOINTS.channelBackgroundUpload(channelId),
        method: 'POST',
        formData,
      });
      setChannel(data);
      setBackup(data);
      onChannelSave && onChannelSave(data);
    } else if (channel.background === false) {
      const { data } = await request({
        url: API_ENDPOINTS.channelBackgroundUpload(channelId),
        method: 'DELETE',
      });
      setChannel(data);
      setBackup(data);
      onChannelSave && onChannelSave(data);
    }
  };

  const fetchChannelDetails = async (channelId) => {
    if (!channelId || channelId === 'new') {
      return;
    }
    const { data } = await request({
      url: API_ENDPOINTS.channelDetails(channelId),
      method: 'GET',
    });
    setChannel(data);
    setBackup(data);
  };

  const saveChannel = async () => {
    if (channelId === 'new') {
      channelCreate();
    } else {
      channelInfoSave();
      channelLogoSave();
      channelBackgroundSave();
    }
    // Display Toast
    toast.success('Saved !', {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    toggleEdit();
  };

  const restoreBackup = () => setChannel(backup);

  const toggleEdit = () => setIsEdit(!isEdit);

  useEffect(() => {
    const queryParams = parse(search);
    if (queryParams.edit) {
      setIsEdit(true);
    }
    setChannelId(channelIdDefault);
    fetchChannelDetails(channelIdDefault);
    if (channelIdDefault === 'new') {
      setIsEdit(true);
      setChannel(channelDefault);
      setBackup(channelDefault);
    }
  }, [channelIdDefault]);

  useEffect(() => {
    fetchParentChannels();
  }, [isSuperAdmin]);

  return {
    parentChannelOptions,
    isEdit,
    channel,
    toggleEdit,
    restoreBackup,
    setField,
    saveChannel,
  };
};
