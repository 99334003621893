import React from 'react';

export const Vote = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="39.543"
    height="43.027"
    viewBox="0 0 39.543 43.027"
  >
    <path
      d="M171.465,800.54a4.1,4.1,0,0,1-4.1-4.1v-12.9a4.1,4.1,0,0,1,8.2,0v12.9A4.1,4.1,0,0,1,171.465,800.54Zm-31.34,0a4.1,4.1,0,0,1-4.1-4.1V772.16a4.1,4.1,0,0,1,8.2,0v24.282A4.1,4.1,0,0,1,140.125,800.54Z"
      transform="translate(-136.025 -758.429)"
      fill="#fff"
    />
    <path
      d="M147.172,806.328a4.1,4.1,0,0,1-4.1-4.1V767.4a4.1,4.1,0,0,1,8.2,0V802.23A4.1,4.1,0,0,1,147.172,806.328Z"
      transform="translate(-127.446 -763.301)"
      fill="#fff"
    />
  </svg>
);
