import React from 'react';
import { Flex } from 'reflexbox';
import styled from 'styled-components';
import { Text } from '../text/Text';
import { AcceptIcon } from '../icons/AcceptIcon';
import { DenyIcon } from '../icons/DenyIcon';

const Wrapper = styled(Flex)`
  margin-left: 4.5px !important;
  margin-top: 26px !important;
  align-items: center;
`;

export const AvailableOption = ({ active, text }) => (
  <Wrapper>
    {active ? <AcceptIcon /> : <DenyIcon />}
    <Text size={'xs'} ml={'11px'} capital>
      {text}
    </Text>
  </Wrapper>
);
