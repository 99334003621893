import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { get } from 'lodash';
import { MainText } from '../widget/MainText';
import { Flex } from 'reflexbox';
import { Video } from '../../features/video/Video';
import { MP4_BACKGROUND_STR, STATIC_BUCKET } from '../../../constants/common';
import { COLOR, INTERACTION_API_TYPE_MAP } from '../../constants/common';
import { PoweredBy } from '../widget/PoweredBy';
import { Box } from 'reflexbox/src';
import { ChevronLeftIcon } from '../icons/ChevronLeftIcon';
import { Text } from '../text/Text';
import { FlexCenter } from '../box/FlexCenter';
import {
  ActionButtonsWrapper,
  ChevronWrapper, TextButton,
  TopBadge,
  TopBar,
  WidgetButton,
} from '../../screens/widget/WidgetScreen';
import { useTranslation } from 'react-i18next';
import { VoteInteractionIcon } from '../icons/VoteInteractionIcon';
import { VoiceInteractionIcon } from '../icons/VoiceInteractionIcon';
import { TextInteractionIcon } from '../icons/TextInteractionIcon';
import { useParams } from 'react-router-dom';
import { WithInteraction } from '../../models/interactions/withInteraction';
import { VideoInteractionIcon } from '../icons/VideoInteractionIcon';
import { GameInteractionIcon } from '../icons/GameInteractionIcon';

const Wrapper = styled(Flex)`
  width: 256px;
  overflow: hidden;
  max-width: 100%;
  background: ${({ background }) => background};
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 8px -3px gray;
  border: 1px solid #eee;
  border-radius: 5px;
`;

const Content = styled.div`
  padding: 14px;
`

const Image = styled.img`
  width: 100%;
  height: 222px;
  object-fit: cover;
  margin-bottom: 10px;
`;

const VideoPreview = styled(Video)`
  border-radius: 0;
  margin-top: 0;
  width: 100%;
  height: 222px;
  object-fit: cover;
  margin-bottom: 10px;
`

export const WidgetPreview = ({
  backgroundMedia,
  channel,
  interactionText,
  customisation,
}) => {
  const { t } = useTranslation();
  const { fetchInteraction } = WithInteraction({});
  const { id: interactionId } = useParams();
  let [interaction, setInteraction] = useState({});
  if (!Object.keys(interaction).length) fetchInteraction(interactionId).then(i => setInteraction(i))
  customisation.btn_1_text = "#fffeff"
  return (
    <Wrapper>
      <Box width={"100%"}>
        <TopBar>
          <TopBadge background={customisation.btn_1_color}>{t('Participate!')}</TopBadge>
          <FlexCenter>
            <ChevronWrapper>
              <ChevronLeftIcon color={COLOR.blackish} />
            </ChevronWrapper>
            <Text
              size="xs"
              color={COLOR.blackish}
            >
              {t('Go back')}
            </Text>
          </FlexCenter>
        </TopBar>
      </Box>
      <Content>
        {interactionText && (
          <MainText margin={"0 0 10px"} color={COLOR.blackish}>{interactionText}</MainText>
        )}
        {backgroundMedia && backgroundMedia.includes(MP4_BACKGROUND_STR) && !backgroundMedia.includes(STATIC_BUCKET) ? (
          <VideoPreview src={backgroundMedia} autoPlay muted loop />
        ) : (
          <Image src={backgroundMedia || get(channel, 'background_url')} alt={''} />
        )}
        <ActionButtonsWrapper>
          <WidgetButton
            background={customisation.btn_1_color}
            margin={'0'}
          >
            {interaction.type === INTERACTION_API_TYPE_MAP.voice && (
              <>
                <VoiceInteractionIcon color={customisation.btn_1_text}/>
                {t('Participate vocally')}
              </>
            )}
            {interaction.type === INTERACTION_API_TYPE_MAP.video && (
              <>
                <VideoInteractionIcon color={customisation.btn_1_text}/>
                {t('Participate in video')}
              </>
            )}
            {interaction.type === INTERACTION_API_TYPE_MAP.vote && (
              <>
                <VoteInteractionIcon color={customisation.btn_1_text}/>
                {t('Participate in the survey')}
              </>
            )}
            {interaction.type === INTERACTION_API_TYPE_MAP.text && (
              <>
                <TextInteractionIcon color={customisation.btn_1_text}/>
                {t('Participate in writing')}
              </>
            )}
            {interaction.type === INTERACTION_API_TYPE_MAP.game && (
              <>
                <GameInteractionIcon color={customisation.btn_1_text}/>
                {t('Participate in game')}
              </>
            )}
          </WidgetButton>
          <FlexCenter>
            {interaction.enable_audio_answer && (
              <TextButton color={COLOR.blackish}>
                <VoiceInteractionIcon color={COLOR.blackish}/>
                {t('In voice')}
              </TextButton>
            )}
            {interaction.enable_written_answer && interaction.enable_audio_answer && (<Text bold>|</Text>)}
            {interaction.enable_written_answer && (
              <TextButton color={COLOR.blackish}>
                <TextInteractionIcon color={COLOR.blackish}/>
                {t('Writing')}
              </TextButton>
            )}
            {!interaction.enable_audio_answer && !interaction.enable_written_answer && (<Box height={"25px"}/>)}
          </FlexCenter>
        </ActionButtonsWrapper>
        <Box width={"100%"}>
          {channel.show_voxm_logo && (<PoweredBy margin={"7px 0 0"} />)}
        </Box>
      </Content>
    </Wrapper>
  )
};
