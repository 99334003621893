import React from 'react';

export const CreateIcon = ({ color = '#f89e14' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="22"
    viewBox="0 0 20 22"
  >
    <g>
      <g>
        <g transform="translate(-1134 -24) translate(1134 23)">
          <circle
            cx="10"
            cy="10"
            r="10"
            fill={color}
            transform="translate(0 2.001)"
          />
          <text
            fill="#fff"
            fontFamily="Montserrat-Bold, Montserrat"
            fontSize="18px"
            fontWeight="700"
            letterSpacing="-0.01em"
            transform="translate(4.999 18)"
          >
            <tspan x="0" y="0">
              +
            </tspan>
          </text>
        </g>
      </g>
    </g>
  </svg>
);
