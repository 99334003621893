import { useState } from 'react';

export const WithSteps = () => {
  const [step, setStep] = useState(0);
  const [isStepValid, setIsStepValid] = useState(false);

  const nextStep = () => {
    if (!isStepValid) {
      return;
    }
    setStep((prevState) => prevState + 1);
    setIsStepValid(false);
  };

  const previousStep = () => {
    setStep((prevState) => prevState - 1);
    setIsStepValid(true);
  };

  return { step, nextStep, setStep, previousStep, isStepValid, setIsStepValid };
};
