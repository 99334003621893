import React from 'react';

export const ProIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    data-name="Groupe 944"
    viewBox="0 0 40 40"
  >
    <circle cx="20" cy="20" r="20" fill="#fcbf2e" data-name="Ellipse 64" />
    <g data-name="Groupe 424" transform="translate(10.28 24.654)">
      <path
        d="M31.52 27.09a12.6 12.6 0 0 1-19.44 0"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="3px"
        data-name="Tracé 479"
        transform="translate(-12.08 -27.09)"
      />
    </g>
    <path
      d="M27.52 20.05l-5.66 4.69-5.66-4.69v-9.68h11.32z"
      fill="none"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="3px"
      data-name="Tracé 480"
      transform="translate(-1.807 -.44)"
    />
  </svg>
);
