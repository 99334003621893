import React from 'react';

export const Other = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42.064"
    height="42.536"
    viewBox="0 0 42.064 42.536"
  >
    <path
      d="M232.75,790.408a3.7,3.7,0,0,1-3.705-3.7v-6.181h-6.183a3.7,3.7,0,1,1,0-7.408h6.183v-6.181a3.705,3.705,0,1,1,7.411,0v6.181h6.183a3.7,3.7,0,1,1,0,7.408h-6.183v6.181A3.7,3.7,0,0,1,232.75,790.408Z"
      transform="translate(-219.157 -763.23)"
      fill="#fff"
    />
    <path
      d="M240.106,784.478a6.127,6.127,0,1,1-6.127-6.125,6.124,6.124,0,0,1,6.127,6.125"
      transform="translate(-210.598 -748.064)"
      fill="#fff"
    />
    <path
      d="M246.464,778.066a6.127,6.127,0,1,1-6.127-6.123,6.126,6.126,0,0,1,6.127,6.123"
      transform="translate(-204.4 -754.492)"
      fill="#fff"
    />
  </svg>
);
