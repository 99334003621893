import { useEffect, useState } from 'react';

let listeningTimerInterval = null;

export const WithVideoRecorder = ({ onSend, onError, autoPlay }) => {
  const [recordingState, setRecordingState] = useState('waiting');
  const [recordingTime, setRecordingTime] = useState(0);
  let [lisetningTime, setListeningTime] = useState(0);
  const [uploading, setUploading] = useState(false);
  let [playing, setPlaying] = useState(false);
  let [mediaRecorder, setMediaRecorder] = useState();
  let [recordingTimerInterval, setRecordingTimerInterval] = useState();
  let [videoBlob, setVideoBlob] = useState();
  let [videoUrl, setVideoUrl] = useState();
  let [mainStream, setMainStream] = useState(null);
  // Handle Safari not working
  const [isSafari, setIsSafari] = useState();
  const [uploadInit, setUploadInit] = useState();
  let [file, setFile] = useState();
  let [imageUrl, setImageUrl] = useState();

  const initializeFromFile = (videoFile, url) => {
    setUploadInit(true);
    setRecordingState('finished');
    setFile(videoFile);
    file = videoFile;
    handleFileRead({ target: { result: url } });
  };

  const handleFileRead = e => {
    const blob = new Blob([e.target.result], { type: file.type });
    const blobUrl = URL.createObjectURL(blob);
    setVideoUrl(blobUrl);
    setTimeout(() => {
      const video = setVideoSource(blobUrl);
      if (!video) {
        return;
      }
      video.currentTime = 0;
      if (autoPlay) {
        video.play();
      } else {
        video.addEventListener('ended', () => {
          stop();
        });
      }
      video.addEventListener('durationchange', () => {
        const { duration } = video;
        // It's Infinity on Safari
        if (duration && duration !== Infinity) {
          setRecordingTime(Math.round(duration * 100));
        }
      });
    }, 1500);
  };

  const reader = new FileReader();
  reader.onload = handleFileRead;

  const uploadFromFileImage = async e => {
    file = e.target.files[0];
    if (!file) {
      return;
    }
    if (file.type.includes('image')) {
      imageUrl = URL.createObjectURL(file);
      setFile(file);
      setImageUrl(imageUrl);
      return { src: imageUrl, file: file };
    }
  }
 
  const uploadFromFile = async e => {
    file = e.target.files[0];
    if (!file) {
      return;
    }
    if (file.type.includes('image')) {
      return { src: URL.createObjectURL(file), file: file };
    }
    setUploadInit(true);
    setRecordingState('finished');
    setFile(file);
    reader.readAsArrayBuffer(file);
  };

  const initFromUrl = (url, initDuration) => {
    setVideoUrl(url);
    const video = setVideoSource(url);
    if (!video) {
      return;
    }
    video.currentTime = 0;
    video.addEventListener('ended', () => {
      stop();
    });
    if (initDuration) {
      setRecordingTime(initDuration);
    }
    video.addEventListener('durationchange', () => {
      const { duration } = video;
      // It's Infinity on Safari
      if (duration && duration !== Infinity) {
        setRecordingTime(Math.round(duration * 100));
      } else if (duration === Infinity) {
        setIsSafari([url, initDuration]);
      }
    });
    setRecordingState('finished');
  };

  const startRecording = (init, constraints) => {
    setRecordingState('started');
    const settings = constraints || { audio: true, video: true };
    navigator.mediaDevices
      .getUserMedia(settings)
      .then(stream => {
        stopStream(mainStream);
        mediaRecorder = new MediaRecorder(stream);
        setMainStream(stream);
        setVideoSource(stream);
        setMediaRecorder(mediaRecorder);
        setRecordingTime(0);
        if (!init) {
          mediaRecorder.start();
          setRecordingState('recording');
          recordingTimerInterval = setInterval(() => {
            setRecordingTime(prevTime => prevTime + 3);
          }, 30);
          setRecordingTimerInterval(recordingTimerInterval);
        }

        const audioChunks = [];
        mediaRecorder.addEventListener('dataavailable', event => {
          audioChunks.push(event.data);
        });

        mediaRecorder.addEventListener('stop', () => {
          videoBlob = new Blob(audioChunks);
          setVideoBlob(videoBlob);
          videoUrl = URL.createObjectURL(videoBlob);
          setVideoUrl(videoUrl);
          const video = setVideoSource(videoUrl);
          if (autoPlay) {
            video.play();
          } else {
            video.pause();
          }
        });
      })
      .catch(e => {
        console.error(e);
        resetVideo();
        onError && onError();
      });
  };

  const getVideoElement = () => document.getElementById('video_recording');
  const getVideoElementBg = () => document.getElementById('video_recording_bg');

  const setVideoSource = source => {
    const element = getVideoElement();
    const elementBg = getVideoElementBg();
    if (!element) {
      return;
    }
    element.removeEventListener('ended', stop);
    if (typeof source === 'object') {
      element.srcObject = source;
      elementBg.srcObject = source
      element.src = '';
      elementBg.src = ''
    } else {
      element.src = source;
      elementBg.src = source
      element.srcObject = null;
      elementBg.srcObject = null;
      element.addEventListener('ended', stop);
    }
    return element;
  };

  const stopStream = stream => {
    if (!stream || !stream.getVideoTracks) {
      return;
    }
    stream.getVideoTracks().forEach(track => {
      track.stop();
    });
  };

  const stopRecording = () => {
    // button click function
    setRecordingState('finished');
    mediaRecorder.stop();
    setTimeout(() => {
      clearInterval(recordingTimerInterval);
    }, 0);
    stopStream(mainStream);
  };

  const stop = () => {
    const video = getVideoElement();
    if (video) {
      video.pause();
      clearInterval(listeningTimerInterval);
      setRecordingState('finished');
      setListeningTime(0);
      setPlaying(false);
    }
    if (isSafari) {
      initFromUrl(...isSafari);
    }
  };

  const remake = () => {
    // button click function
    if (uploading) {
      return;
    }
    stop();
    resetVideo();
    startRecording();
  };

  const send = async extraData => {
    if (uploading || (!videoBlob && !file)) {
      return;
    }
    setUploading(true);
    if (file) {
      onSend(file, extraData);
      setUploading(false);
      return;
    }
    const sendFile = new File([videoBlob], 'recording.webm', {
      type: 'video/webm',
    });
    if (onSend) {
      onSend(sendFile, extraData);
      setUploading(false);
    }
  };

  const getRecordingTime = (long = true) => {
    const recordingMs = recordingTime % 100;
    const seconds = parseInt(recordingTime / 100);
    const recordingSeconds = seconds % 60;
    const recordingMinutes = parseInt(seconds / 60);
    return `${
      recordingMinutes < 10 ? '0' + recordingMinutes : recordingMinutes
    }:${recordingSeconds < 10 ? '0' + recordingSeconds : recordingSeconds}${
      long ? (recordingMs < 10 ? ':0' + recordingMs : ':' + recordingMs) : ''
    }`;
  };

  const getLeftProgress = () => {
    return recordingTime !== 0 && lisetningTime !== 0
      ? lisetningTime < recordingTime
        ? `${(lisetningTime / recordingTime) * 97}%`
        : '100%'
      : 0;
  };

  const playPause = () => {
    // button click function
    if (playing) {
      stop();
    } else {
      listen();
    }
  };

  const playerTick = () => {
    setListeningTime(lisetningTime => lisetningTime + 3);
  };

  const listen = () => {
    if (!uploading && videoUrl) {
      const video = getVideoElement();
      playing = true;
      setPlaying(true);
      video.currentTime = 0;
      listeningTimerInterval = setInterval(playerTick, 30);
      video.play();
    }
  };

  const resetVideo = () => {
    mediaRecorder = null;
    videoBlob = null;
    videoUrl = null;
    setPlaying(false);
    setRecordingTime(0);
    setListeningTime(0);
    clearInterval(recordingTimerInterval);
    clearInterval(listeningTimerInterval);
    stopStream(mainStream);
    setMainStream(null);
    setIsSafari(null);
    setUploadInit(null);
    setFile(null);
  };

  useEffect(() => {
    if (!window.MediaRecorder) {
      window.MediaRecorder = require('audio-recorder-polyfill');
    }
  }, []);

  return {
    videoUrl,
    imageUrl,
    file,
    recordingTime,
    recordingState,
    setRecordingState,
    playing,
    uploading,
    uploadInit,
    // methods
    playPause,
    send,
    remake,
    stopRecording,
    startRecording,
    getRecordingTime,
    getLeftProgress,
    resetVideo,
    initFromUrl,
    uploadFromFile,
    uploadFromFileImage,
    initializeFromFile,
  };
};
