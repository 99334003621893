import React from 'react';

export const Microphone = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26.892"
    height="45.754"
    viewBox="0 0 26.892 45.754"
  >
    <path
      d="M44.109,49.078a5.393,5.393,0,0,1-5.389-5.389V25.779a5.389,5.389,0,0,1,10.778,0V43.7A5.386,5.386,0,0,1,44.109,49.078Z"
      transform="translate(-30.78 -20.39)"
      fill="#fff"
    />
    <g transform="translate(0 20.371)">
      <path
        d="M44.516,70a1.8,1.8,0,0,1-1.8-1.8V60.016a1.8,1.8,0,1,1,3.593,0V68.2A1.8,1.8,0,0,1,44.516,70Z"
        transform="translate(-31.187 -44.612)"
        fill="#fff"
      />
      <path
        d="M43.326,58.761A13.466,13.466,0,0,1,29.88,45.315a2.245,2.245,0,0,1,4.491,0,8.955,8.955,0,1,0,17.91,0,2.245,2.245,0,0,1,4.491,0A13.461,13.461,0,0,1,43.326,58.761Z"
        transform="translate(-29.88 -43.07)"
        fill="#fff"
      />
    </g>
  </svg>
);
