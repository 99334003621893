import moment from 'moment';
import { get } from 'lodash';
import { getValueForLanguage } from '../features/translate/Translate';
import { INTERACTION_API_TYPE_MAP } from '../constants/common';

export const padTime = value => ('00' + value).substr(-2);

export const processInteractiveDates = data => {
  data.startDate = moment
    .utc(get(data, 'start_date'))
    .local()
    .format('DD/MM/YYYY');
  data.endDate = moment.utc(get(data, 'end_date')).local();
  data.endDate = data.endDate.isValid()
    ? data.endDate.format('DD/MM/YYYY')
    : '';
  data.startTime = moment
    .utc(get(data, 'start_time'))
    .local()
    .format('H[h]mm');
  data.endTime = moment
    .utc(get(data, 'end_time'))
    .local()
    .format('H[h]mm');
  return data;
};

export const formatDateInList = date => {
  const dateObj = moment(date);
  return dateObj.format('DD MMM YYYY - HH:mm');
};

export const getDateString = date => {
  const now = moment();
  const current = moment(date);
  if (now.isSame(current, 'day')) {
    return current.format('HH:mm');
  }
  return current.format('d MMM');
};

export const copyToClipboard = (value, language = 'fr') => {
  const el = document.createElement('textarea');
  el.value = value;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  alert(getValueForLanguage(language, 'Copied to clipboard'));
};

export const share = (url, text, language = 'fr') => {
  if (navigator.share) {
    navigator.share({
      title: 'VoxM',
      text,
      url,
    });
  } else {
    copyToClipboard(url, language);
  }
};

export const dateToLocal = date =>
  moment
    .utc(date)
    .local()
    .toDate();

export const localDateToStr = date => moment.utc(date);

export const todayString = moment().format('DD/MM/YYYY');

export const processInteraction = data => {
  data.start_time = dateToLocal(data.start_time);
  data.end_time = dateToLocal(data.end_time);
  data.start_date = dateToLocal(data.start_date);
  data.end_date = data.end_date ? dateToLocal(data.end_date) : '';
  if (data.choices) {
    data.choices = data.choices.map(choice => choice.label);
  }
  data.authentification_mode = data.name_town_required
    ? 'name_town'
    : data.registration_required
    ? 'registration'
    : 'none';
  return data;
};

export const prepareInteractionSave = data => {
  data.start_time = localDateToStr(data.start_time);
  data.end_time = localDateToStr(data.end_time);
  data.start_date = localDateToStr(data.start_date);
  data.end_date = data.end_date ? localDateToStr(data.end_date) : null;
  if (data.choices) {
    data.choices = data.choices.map(choice => ({ label: choice }));
  }
  data.time_offset = moment().utcOffset();
  if (
    data.type !== INTERACTION_API_TYPE_MAP.voice &&
    data.type !== INTERACTION_API_TYPE_MAP.vote
  ) {
    data.name_town_required = false;
  } else if (data.authentification_mode === 'name_town') {
    data.name_town_required = true;
    data.registration_required = false;
  } else if (data.authentification_mode === 'registration') {
    data.name_town_required = false;
    data.registration_required = true;
  } else {
    data.name_town_required = false;
    data.registration_required = false;
  }
  return data;
};

export const processReply = interaction => {
  const duration = moment.duration(get(interaction, 'content.duration'));
  interaction.duration = `${padTime(duration.minutes())}:${padTime(
    duration.seconds()
  )}`;
  interaction.created_at = dateToLocal(interaction.created_at);
  return interaction;
};

const shortenLanguage = language => language.split('-')[0].toLowerCase();

export const getDefaultLanguage = () => {
  const allowedLanguages = ['fr', 'en', 'pt', 'es', 'pl'];
  try {
    const i18Language = localStorage.getItem('i18nextLng');
    if (allowedLanguages.includes(i18Language)) {
      return i18Language;
    }
  } catch (e) {
    //  Third party cookies are disabled in browser settings
  }
  const nav = window.navigator;
  const browserLanguagePropertyKeys = [
    'language',
    'browserLanguage',
    'systemLanguage',
    'userLanguage',
  ];
  let i;
  let language;
  let len;
  let shortLanguage = null;

  if (Array.isArray(nav.languages)) {
    for (i = 0; i < nav.languages.length; i++) {
      language = nav.languages[i];
      len = language.length;
      if (!shortLanguage && len) {
        shortLanguage = language;
      }
      if (language && len > 2) {
        shortLanguage = shortenLanguage(language);
      }
    }
  }

  if (!shortLanguage) {
    for (i = 0; i < browserLanguagePropertyKeys.length; i++) {
      language = nav[browserLanguagePropertyKeys[i]];
      len = language.length;
      if (!shortLanguage && len) {
        shortLanguage = language;
      }
      if (language && len > 2) {
        shortLanguage = shortenLanguage(language);
      }
    }
  }

  if (shortLanguage) {
    shortLanguage = shortenLanguage(shortLanguage);
  }
  return shortLanguage in allowedLanguages ? shortLanguage : 'fr';
};

export const getFullTranscript = interaction => {
  let parsedTranscription = '';
  const transcription = get(interaction, 'content.transcription');
  if (transcription && transcription.data && transcription.data.length) {
    parsedTranscription = transcription.data
      .map(value => value.transcript)
      .join(' ');
  }
  return parsedTranscription;
};

export const emailRe = /\S+@\S+\.\S+/;
export const phoneRe = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,7}$/;

export const uuid4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

export const enterFullscreen = elem => {
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) {
    /* Firefox */
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) {
    /* Chrome, Safari and Opera */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    /* IE/Edge */
    elem.msRequestFullscreen();
  }
};

export const getInteractionAuthor = (interaction, language) => {
  if (interaction.nickname) {
    return `${interaction.nickname} ${getValueForLanguage(language, 'from')} ${
      interaction.city
    }`;
  }
  return (
    get(interaction, 'author_app_user.nickname') ||
    get(interaction, 'author_app_user.nickname_anonymous') ||
    'No pseudo'
  );
};
