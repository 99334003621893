import styled from 'styled-components';
import { Text } from '../../../ui/text/Text';
import { get } from 'lodash';
import { Pointer } from '../../../ui/box/Pointer';
import { ArrowDown } from '../../../ui/widget/icons/ArrowDown';
import { Box, Flex } from 'reflexbox';
import { PauseIcon } from '../../../ui/icons/PauseIcon';
import { PlayIcon } from '../../../ui/icons/PlayIcon';
import { Avatar } from '../../../ui/widget/Avatar';
import React from 'react';
import { COLOR, INTERACTION_API_TYPE_MAP } from '../../../constants/common';

const MessageWrapper = styled.div`
  border-radius: 12px;
  width: 100%;
  margin-bottom: 7px;
  overflow: hidden;
  background: rgba(28, 28, 28, 0.65);
`;

const MessageTop = styled.div`
  display: flex;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  background: rgb(28, 28, 28, 0.65);
  padding: 10px 9px 10px 8px;
`;

const MessageBottom = styled(Box)`
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  background: transparent;
  padding-left: 8px;
  padding-right: 8px;
`;

const Point = styled.div`
  color: ${COLOR.blackish};
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 10px;
  font-weight: bold;
  line-height: 11px;
  background: white;
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PlayPauseWrapper = styled(Pointer)`
  path {
    fill: white;
  }
`;

const ReplySeparator = styled.div`
  height: 1px;
  background: #505050;
  width: 100%;
  margin: 10px 0;
`;

const BroadcastStatus = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: ${({ active }) => (active ? 1 : 0.5)};
  width: 76px;
  & > div:first-of-type {
    color: ${({ active }) => (active ? COLOR.white : '#a29e9e')};
  }
`;

const Rotate = styled.div`
  ${({ rotate }) => (rotate ? `transform: rotate(${rotate}deg);` : '')};
`;

const ChannelAvatar = styled.img`
  width: 24px;
  height: 24px;
  object-fit: cover;
`;

const BroadcastStatusWrapper = styled(Flex)`
  background: rgba(28, 28, 28, 0.65);
  margin-left: -8px !important;
  margin-right: -8px !important;
`;

export const Message = ({
  message,
  open,
  onClick,
  listen,
  listening,
  stop,
  channel,
}) => (
  <MessageWrapper>
    <MessageTop>
      <Text flex={1} theme={'white'} size={'md'}>
        {get(message, 'segment.text')}
      </Text>
      <Pointer
        pr={'6px'}
        pt={'10px'}
        mt={'-12px'}
        onClick={() => onClick(message.id)}
      >
        <Rotate rotate={open ? 180 : 0}>
          <ArrowDown />
        </Rotate>
      </Pointer>
    </MessageTop>

    <MessageBottom pt={open ? '10px' : 0}>
      {open && (
        <>
          <Flex flexDirection={'column'} alignItems={'flex-end'}>
            <Flex justifyContent={'flex-end'} alignItems={'center'}>
              {get(message, 'segment.type') ===
              INTERACTION_API_TYPE_MAP.voice ? (
                <>
                  <PlayPauseWrapper
                    onClick={() =>
                      message.recordingUrl === listening
                        ? stop()
                        : listen(message.recordingUrl)
                    }
                  >
                    {message.recordingUrl === listening ? (
                      <PauseIcon />
                    ) : (
                      <PlayIcon />
                    )}
                  </PlayPauseWrapper>
                  <Text size={'md'} theme={'white'} mx={'14px'}>
                    {message.duration}
                  </Text>
                </>
              ) : (
                <Text size={'xs'} color={'#a29e9e'} lh={'13px'} mr={'8px'}>
                  {get(message, 'content.text') ||
                    get(message, 'content.choice_text') ||
                    ''}
                </Text>
              )}
              <Avatar user={message.author_app_user} />
            </Flex>
            <Flex mt={'7px'}>
              <Text size={'xs'} color={'#a29e9e'} lh={'13px'}>
                Participation sent on
              </Text>
              <Text size={'xs'} color={'#a29e9e'} lh={'13px'}>
                &nbsp;{message.date}
              </Text>
            </Flex>
          </Flex>
          {message.replies &&
            message.replies.map(reply => (
              <Flex flexDirection={'column'} key={`reply-${reply.id}`}>
                <ReplySeparator />
                <Flex alignItems={'center'}>
                  <ChannelAvatar src={get(channel, 'logo_url')} alt={''} />
                  {reply.recordingUrl ? (
                    <>
                      <PlayPauseWrapper
                        mx={'14px'}
                        onClick={() =>
                          reply.recordingUrl === listening
                            ? stop()
                            : listen(reply.recordingUrl)
                        }
                      >
                        {reply.recordingUrl === listening ? (
                          <PauseIcon />
                        ) : (
                          <PlayIcon />
                        )}
                      </PlayPauseWrapper>
                      <Text size={'md'} theme={'white'}>
                        {reply.duration}
                      </Text>
                    </>
                  ) : (
                    <Text
                      flex={1}
                      size={'md'}
                      theme={'white'}
                      mt={'5px'}
                      ml={'12px'}
                    >
                      {get(reply, 'content.text', '')}
                    </Text>
                  )}
                </Flex>
                <Flex>
                  <Text size={'xs'} color={'#a29e9e'} mt={'9px'} lh={'13px'}>
                    Answer received on
                  </Text>
                  <Text size={'xs'} color={'#a29e9e'} mt={'9px'} lh={'13px'}>
                    &nbsp;{message.date}
                  </Text>
                </Flex>
              </Flex>
            ))}
          <ReplySeparator />
        </>
      )}
      {channel && channel.gamification && (
        <Flex
          pt={'4px'}
          pb={'11px'}
          alignItems={'center'}
          justifyContent={'flex-end'}
        >
          <Text size={'md'} color={'#a29e9e'} bold upper>
            You have won
          </Text>
          <Point>{get(message, 'points', 0)}</Point>
          <Text size={'md'} color={'#a29e9e'} bold upper>
            {get(message, 'points', 0) === 1 ? 'point' : 'points'}
          </Text>
        </Flex>
      )}
      {open && (
        <BroadcastStatusWrapper
          alignItems={'center'}
          justifyContent={'space-between'}
          px={'9px'}
          py={'7px'}
        >
          <BroadcastStatus active={message.liked}>
            <Text upper size={'xxs'} bold center mb={'6px'}>
              Message liked
            </Text>
            {channel && channel.gamification && <Point>10</Point>}
          </BroadcastStatus>
          <BroadcastStatus active={message.broadcast_status === 'broadcast_1'}>
            <Text upper size={'xxs'} bold center mb={'6px'}>
              Message quoted
            </Text>
            {channel && channel.gamification && <Point>50</Point>}
          </BroadcastStatus>
          <BroadcastStatus active={message.broadcast_status === 'broadcast_2'}>
            <Text upper size={'xxs'} bold center mb={'6px'}>
              Message broadcasted
            </Text>
            {channel && channel.gamification && <Point>100</Point>}
          </BroadcastStatus>
        </BroadcastStatusWrapper>
      )}
    </MessageBottom>
  </MessageWrapper>
);
