import React from 'react';

import { Pointer } from '../../ui/box/Pointer';
import { TopNav } from '../../ui/widget/TopNav';
import { ScreenWrapper } from '../../ui/widget/ScreenWrapper';
import { Flex } from 'reflexbox';
import { WithProfile } from '../../models/widget/withProfile';
import { Avatar } from '../../ui/widget/Avatar';
import { Text } from '../../ui/text/Text';
import { Input } from '../../ui/input/Input';
import styled, { css } from 'styled-components';
import { SelectPicker } from '../../ui/picker/SelectPicker';
import {
  userLanguages,
  userReceiveEmails,
} from '../../models/users/withBEUser';
import { WidgetButton } from '../../ui/widget/WidgetButton';
import { ArrowBackIcon } from '../../ui/icons/ArrowBackIcon';
import { DatePicker } from '../../ui/picker/DatePicker';
import { WithImageUpload } from '../../models/input/withImageUpload';
import { Absolute } from '../../ui/box/Absolute';
import { ScrollY } from '../../ui/box/ScrollY';
import { getTermsLink } from '../../constants/common';
import { Link } from '../../ui/link/Link';

const ProfileWrapper = styled(Flex)`
  z-index: 3000;
  height: 100%;
  input {
    color: white;
  }
`;

const RowWrapper = styled(Flex)`
  width: 100%;
  align-items: center;
  margin-bottom: 13px;
  & > div {
    flex: 1;
    &:first-of-type {
      margin-right: 24px;
    }
  }
`;

const SelectWrapper = styled.div`
  height: ${({ small }) => (small ? 'auto' : '100%')};
  width: 100%;
  .picker-label {
    color: white;
  }
  & > div {
    flex-direction: column;
    margin-bottom: 21px;
    height: 54px;
    display: flex;
    justify-content: space-between;
    path {
      fill: white;
    }
  }
  ${({ noIcon }) =>
    noIcon
      ? css`
          svg {
            display: none;
          }
        `
      : ''}

  .rdtPicker {
    margin-top: -69px;
    margin-left: -44px;
    width: 185px;
  }
  background: transparent;
`;

const Upload = styled.input`
  width: 1px;
  height: 1px;
  opacity: 0;
`;

const NicknameWrapper = styled(RowWrapper)`
  & > div {
    margin-right: 0 !important;
  }
`;

const TermsLink = styled(Link)`
  font-size: 12px;
  line-height: 13px;
  color: #a29e9e;
  text-decoration: none;
  margin-bottom: 26px;
  display: block;
  text-align: center;
`;

export const ProfileScreen = ({
  appUser: initialAppUser,
  onClose,
  onLogout,
  channelId,
  updateAppUser,
}) => {
  const {
    profile,
    handleChange,
    emailValid,
    nicknameValid,
    save,
    setNicknameField,
    setEmailField,
    appUser,
    forgotPassword,
    passwordChangeRequested,
  } = WithProfile({
    initialAppUser,
    channelId,
    updateAppUser,
  });

  const {
    preview,
    handleFileUpload,
    handleUploadClick,
    uploadRef,
  } = WithImageUpload({
    onChange: handleChange,
    name: 'avatar',
  });


  return (
    <ScreenWrapper>
      <TopNav>
        <Absolute left={'19px'} top={'15px'}>
          <Pointer onClick={onClose}>
            <ArrowBackIcon />
          </Pointer>
        </Absolute>
      </TopNav>
      <ProfileWrapper
        mt={'26px'}
        width={'100%'}
        flexDirection={'column'}
        alignItems={'center'}
      >
        <Flex flexDirection={'column'} alignItems={'center'} width={'60%'}>
          <Pointer onClick={handleUploadClick} mb={'13px'}>
            <Avatar user={{ avatar_url: preview || profile.avatar_url }} big />
          </Pointer>
        </Flex>
        <ScrollY width={'100%'} flex={1} pb={'12px'}>
          <NicknameWrapper width={'44%'} margin={'auto'}>
            <Input
              widgetInputSmall
              value={profile.nickname}
              onChange={setNicknameField}
              name={'nickname'}
              valid={nicknameValid}
              showValidation={profile.nickname !== appUser.nickname}
              center
            />
          </NicknameWrapper>
          <RowWrapper mt={'5px'}>
            <SelectWrapper>
              <SelectPicker
                value={profile.language}
                label={'Language'}
                name={'language'}
                onChange={handleChange}
                options={userLanguages}
                widgetInputSmall
              />
            </SelectWrapper>
            <Input
              widgetInputSmall
              value={profile.city || ''}
              onChange={handleChange}
              label={'City / Region'}
              name={'city'}
            />
          </RowWrapper>
          <RowWrapper>
            <Input
              widgetInputSmall
              value={profile.first_name}
              onChange={handleChange}
              name={'first_name'}
              showValidation={profile.first_name !== appUser.first_name}
              valid={profile.first_name}
              label={'First name'}
            />
            <Input
              widgetInputSmall
              value={profile.last_name}
              onChange={handleChange}
              name={'last_name'}
              showValidation={profile.last_name !== appUser.last_name}
              valid={profile.last_name}
              label={'Name'}
            />
          </RowWrapper>
          <RowWrapper>
            <Input
              widgetInputSmall
              value={profile.phone}
              onChange={handleChange}
              name={'phone'}
              label={'Phone'}
            />
            <SelectWrapper noIcon>
              <DatePicker
                widgetInputSmall
                value={profile.birth_date}
                onChange={handleChange}
                name={'birth_date'}
                label={'Birthday'}
              />
            </SelectWrapper>
          </RowWrapper>
          <Input
            widgetInputSmall
            value={profile.email}
            onChange={setEmailField}
            name={'email'}
            label={'Email'}
            showValidation={profile.email !== appUser.email}
            valid={emailValid}
          />
          <SelectWrapper small>
            <SelectPicker
              value={profile.receive_emails}
              label={'Information Emails'}
              name={'receive_emails'}
              onChange={handleChange}
              options={userReceiveEmails}
              widgetInputSmall
            />
          </SelectWrapper>
          <Text
            upper
            bold
            size={'md'}
            mt={'27px'}
            center
            pointer
            pt={'5px'}
            pb={'6px'}
            theme={'white'}
            onClick={forgotPassword}
          >
            Change password
          </Text>
          {passwordChangeRequested && (
            <Text size={'md'} color={'#d2d2d2'} mb={'20px'} center>
              We sent you an email to change your password, just follow the
              instructions
            </Text>
          )}
          <WidgetButton mt={'17px'} onClick={save} mx={'auto'} mb={'25px'}>
            Save
          </WidgetButton>

          <Text
            upper
            bold
            size={'md'}
            mb={'17px'}
            center
            pointer
            theme={'white'}
            onClick={onLogout}
          >
            Log out
          </Text>
          <TermsLink href={getTermsLink(profile.language)} target={'_blank'}>
            Terms of use
          </TermsLink>
        </ScrollY>
      </ProfileWrapper>
      <Upload
        ref={uploadRef}
        name={'avatar'}
        type={'file'}
        accept={'image/*'}
        onChange={handleFileUpload}
      />
    </ScreenWrapper>
  );
};
