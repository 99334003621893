import styled from 'styled-components';
import { Box } from 'reflexbox';

export const RedDot = styled(Box)`
  @keyframes blink-animation {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #dd1919;

  animation: blink-animation 1s infinite;
  -webkit-animation: blink-animation 1s infinite;
  -moz-animation: blink-animation 1s infinite;
  -o-animation: blink-animation 1s infinite;
`;
