import React from 'react';

export const AvatarUser = () => (
  <svg
    id="Component_1_1"
    data-name="Component 1 – 1"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <g id="Group_716" data-name="Group 716" transform="translate(-16 -40)">
      <g
        id="Ellipse_42"
        data-name="Ellipse 42"
        transform="translate(16 40)"
        fill="none"
        stroke="#fff"
        strokeWidth="2"
      >
        <circle cx="16" cy="16" r="16" stroke="none" />
        <circle cx="16" cy="16" r="15" fill="none" />
      </g>
    </g>
    <g id="Group_722" data-name="Group 722" transform="translate(9.395 5.72)">
      <g id="Group_314" data-name="Group 314" transform="translate(0 11.947)">
        <path
          id="Path_287"
          data-name="Path 287"
          d="M40.642,57.923a1.2,1.2,0,0,1-1.2-1.2,3.719,3.719,0,1,0-7.437,0,1.2,1.2,0,1,1-2.41,0,6.128,6.128,0,0,1,12.256,0A1.206,1.206,0,0,1,40.642,57.923Z"
          transform="translate(-29.59 -50.59)"
          fill="#fff"
        />
      </g>
      <path
        id="Path_288"
        data-name="Path 288"
        d="M37.262,31.024a5.092,5.092,0,1,1,5.092-5.092A5.1,5.1,0,0,1,37.262,31.024Zm0-7.771a2.683,2.683,0,1,0,2.683,2.683A2.687,2.687,0,0,0,37.262,23.254Z"
        transform="translate(-31.134 -20.84)"
        fill="#fff"
      />
    </g>
  </svg>
);
