import styled from 'styled-components';
import { COLOR } from '../../constants/common';
import { Translate } from '../../features/translate/Translate';

export const Text = styled(Translate)`
  &,
  * {
    color: ${COLOR.text};
    font-size: 13px;
    line-height: 16px;

    ${({ size }) => (size === 'h1' ? `font-size: 32px` : '')};
    ${({ size }) =>
      size === 'h1.5' ? `font-size: 28px; line-height: 40px` : ''};
    ${({ size }) => (size === 'h2' ? `font-size: 25px` : '')};
    ${({ size }) => (size === 'h3' ? `font-size: 20px` : '')};
    ${({ size }) =>
      size === 'h4' ? `font-size: 18px; line-height: 30px` : ''};
    ${({ size }) =>
      size === 'h5' ? `font-size: 16px; line-height: 24px;` : ''};
    ${({ size }) =>
      size === 'lg' ? `font-size: 15px; line-height: 26px` : ''};
    ${({ size }) => (size === 'md' ? `font-size: 14px` : '')};
    ${({ size }) =>
      size === 'xs' ? `font-size: 12px; line-height: 11px;` : ''};
    ${({ size }) =>
      size === 'xms' ? `font-size: 11px; line-height: 17px;` : ''};
    ${({ size }) =>
      size === 'xxs' ? `font-size: 10px; line-height: 11px;` : ''};
    ${({ size }) =>
      size === 'xxxs' ? `font-size: 7px; line-height: 8px;` : ''};

    ${({ left }) => (left ? `text-align: left;` : '')};
    ${({ right }) => (right ? `text-align: right;` : '')};
    ${({ center }) => (center ? `text-align: center;` : '')};
    ${({ lh }) => (lh ? `line-height: ${lh};` : '')};

    ${({ theme }) => (theme === 'dark' ? `color: ${COLOR.blackish};` : '')};
    ${({ theme }) => (theme === 'orange' ? `color: ${COLOR.action};` : '')};
    ${({ theme }) => (theme === 'light' ? `color: ${COLOR.grey3};` : '')};
    ${({ theme }) => (theme === 'lighter' ? `color: ${COLOR.grey};` : '')};
    ${({ theme }) => (theme === 'gray' ? `color: ${COLOR.grey1};` : '')};
    ${({ theme }) => (theme === 'gray2' ? `color: ${COLOR.grey2};` : '')};
    ${({ theme }) => (theme === 'link' ? `color: ${COLOR.link};` : '')};
    ${({ theme }) => (theme === 'white' ? `color: ${COLOR.white};` : '')};
    ${({ theme }) => (theme === 'menuDark' ? `color: ${COLOR.menuDark};` : '')};
    ${({ color }) => (color ? `color: ${color};` : '')};

    ${({ bold }) => (bold ? 'font-weight: bold' : '')};
    ${({ center }) => (center ? 'text-align: center' : '')};
    ${({ semiBold }) => (semiBold ? 'font-weight: 600' : '')};
    ${({ upper }) => (upper ? 'text-transform: uppercase' : '')};
    ${({ pointer }) => (pointer ? 'cursor: pointer' : '')};
    ${({ underline }) => (underline ? 'text-decoration: underline' : '')};
    ${({ bordered }) =>
      bordered ? `border-bottom: 1px solid ${COLOR.stone2_12};` : ''};
    ${({ blink }) =>
      blink
        ? `
    animation: blinker ${blink || 1}s linear infinite;
    @keyframes blinker {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  `
        : ''};
  }
`;
