import React from 'react';
import styled, { css } from 'styled-components';
import { Flex } from 'reflexbox';
import { Text } from '../text/Text';
import { COLOR } from '../../constants/common';
import { InputWrapper } from './InputWrapper';
import { ColorPickerIcon } from '../icons/ColorPickerIcon';

const InputField = styled.input`
  padding: 0;
  flex: 1;
  border: none;
  color: ${COLOR.brownishGrey};
  background: ${COLOR.white};
  height: 100%;
  font-size: 13px;
  &::placeholder {
    color: ${COLOR.brownGrey};
  }

  ${({ isColor }) =>
    isColor &&
    css`
      max-width: 0;
      max-height: 0;
      opacity: 0;
    `};

  // Autocomplete color
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${COLOR.white} inset !important;
  }
`;

const ColorIndicator = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${({ color }) => color};
  margin-right: 9px;
`;

const TextArea = styled.textarea`
  height: 105px;
  align-items: center;
  border: 1px solid ${COLOR.veryLightPink};
  border-radius: 5px;
  padding: 8px;
  position: relative;
  line-height: 20px;
  resize: none;
  color: ${COLOR.brownishGrey};
  font-size: 13px;
  &::placeholder {
    color: ${COLOR.brownGrey};
  }
`;

const clickInput = (id) => {
  const inputElement = document.getElementById(id);
  if (inputElement) {
    inputElement.click();
  }
};

export const BaseInput = ({
  name,
  onChange,
  disabled,
  showValidation,
  valid,
  value,
  type = 'text',
  textArea,
  onEnter,
  dash,
  ...rest
}) => {
  const isColor = type === 'color';
  const handleKeyDown = onEnter
    ? (e) => {
        if (e.key === 'Enter') {
          onEnter();
        }
      }
    : undefined;

  return (
    <Flex flexDirection={'column'} w={'100%'}>
      {textArea ? (
        <TextArea
          value={value || ''}
          onChange={(e) => onChange && onChange(name, e.target.value)}
          rows={4}
          disabled={disabled}
          {...rest}
        />
      ) : (
        <InputWrapper
          valid={!showValidation ? undefined : valid}
          disabled={disabled}
          dash={dash}
        >
          <InputField
            {...rest}
            value={value || ''}
            type={type}
            onChange={(e) => onChange && onChange(name, e.target.value)}
            disabled={disabled}
            id={name}
            onKeyDown={handleKeyDown}
            isColor={isColor}
          />
          {isColor && (
            <Flex
              height={'100%'}
              onClick={() => clickInput(name)}
              flex={1}
              alignItems={'center'}
            >
              <ColorIndicator color={value} />
              <Text upper flex={1}>
                {value}
              </Text>
              {!disabled && <ColorPickerIcon />}
            </Flex>
          )}
        </InputWrapper>
      )}
    </Flex>
  );
};
