import React from 'react';

export const Close = () => (
  <svg
    id="Component_2_1"
    data-name="Component 2 – 1"
    xmlns="http://www.w3.org/2000/svg"
    width="21.213"
    height="21.213"
    viewBox="0 0 21.213 21.213"
  >
    <g
      id="Group_780"
      data-name="Group 780"
      transform="translate(10.607 0) rotate(45)"
    >
      <line
        id="Line_66"
        data-name="Line 66"
        y2="15"
        transform="translate(7.496 0)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="3"
      />
      <line
        id="Line_67"
        data-name="Line 67"
        x1="15"
        transform="translate(0 7.496)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="3"
      />
    </g>
  </svg>
);
