import { Action as ActionDefault } from '../actions/Actions';
import React from 'react';
import { StarIcon } from '../icons/StarIcon';
import { StarIconEmpty } from '../icons/StarIconEmpty';
import styled from 'styled-components';

const Action = styled(ActionDefault)`
  svg {
    width: 22px;
    height: 20px;
  }
`;

export const Star = ({ onClick, active }) => (
  <Action
    onClick={onClick}
    color={active ? '#ffa116' : '#95989a'}
    stroke={!active}
  >
    {active ? <StarIcon /> : <StarIconEmpty />}
  </Action>
);
