import { useEffect, useState } from 'react';

import { request } from '../utils/request';
import { API_ENDPOINTS } from '../../constants/common';
import moment from 'moment';

import { get } from 'lodash';
import {
  dateToLocal,
  getDateString,
  getFullTranscript,
  padTime,
} from '../../utils/utils';
import { useUser } from '../users/withUser';
import { GLOBAL_NAMES, useGlobal } from '../global/withGlobal';

export const WithMessage = ({ id, defaultReplyType = '' }) => {
  const {
    message: interaction,
    setVariable,
    updateVariable,
    updateListVariableById,
  } = useGlobal();
  const [replyType, setReplyType] = useState(defaultReplyType);
  const [
    showMessageSentNotification,
    setShowMessageSentNotification,
  ] = useState(false);
  const [saveCommentTimeout, setSaveCommentTimeout] = useState(0);
  const [reply, setReply] = useState('');
  const [comment, setComment] = useState('');
  const [runtime, setRuntime] = useState(0);
  const [ftpUploadLoading, setFtpUploadLoading] = useState(false);
  const reloadMessage = () => {
    setRuntime(runtime + 1);
  };

  const { user, fetchUnreadMessages } = useUser();

  const processReply = data => {
    data.created_at = dateToLocal(data.created_at);
    data.createdAt = getDateString(data.created_at);
    data.text = get(data, 'content.text') || getFullTranscript(data);
    data.recording = get(data, 'content.recording_url');
    data.duration = get(data, 'content.duration');
    return data;
  };

  const processInteraction = data => {
    data.created_at = dateToLocal(data.created_at);
    const duration = moment.duration(get(data, 'content.duration'));
    data.duration = `${padTime(duration.minutes())}:${padTime(
      duration.seconds()
    )}`;
    const createdAt = moment(get(data, 'created_at'));
    data.createdAt = createdAt.format('dddd DD/MM/YYYY -HH:mm');
    data.messageQuoted = data.broadcast_status === 'broadcast_1';
    data.messageBroadcasted = data.broadcast_status === 'broadcast_2';
    data.replies = data.replies || [];
    data.replies = data.replies.map(processReply);
    return data;
  };

  const postInteractionToFtp = async () => {
    if (ftpUploadLoading) {
      return;
    }
    setFtpUploadLoading(true);
    const { data } = await request({
      url: API_ENDPOINTS.interactionFtp(id),
      method: 'POST',
    });
    if (!data || data.message) {
      console.warn(data);
      alert(
        `Something went wrong. ${data.message ||
          'Please check channel cloud transfer configuration.'}`
      );
    } else {
      alert('Success');
    }
    setFtpUploadLoading(false);
  };

  const fetchInteraction = async forceId => {
    if (!id && !forceId) {
      return;
    }
    const { data } = await request({
      url: API_ENDPOINTS.interactionWithReplies(forceId || id),
      method: 'GET',
    });
    setComment(data.comment || '');
    if (!data.read) {
      setMessageRead();
    }
    setVariable(GLOBAL_NAMES.message, processInteraction(data));
    return data;
  };

  const saveComment = async value => {
    const body = {
      comment: value,
      id,
    };
    await request({
      url: API_ENDPOINTS.interaction(id),
      method: 'PUT',
      body,
    });
    setSaveCommentTimeout(null);
    updateListVariableById(GLOBAL_NAMES.messages, body);
  };

  const handleCommentChange = e => {
    if (saveCommentTimeout) {
      clearTimeout(saveCommentTimeout);
    }
    const { value } = e.target;
    setComment(value);
    setSaveCommentTimeout(setTimeout(() => saveComment(value), 3000));
  };

  const setMessageRead = async () => {
    const body = { ids: [id] };
    const { data } = await request({
      url: API_ENDPOINTS.interactionRead,
      method: 'POST',
      body,
    });
    fetchUnreadMessages();
    updateListVariableById(GLOBAL_NAMES.messages, { id, read: true });
    return data;
  };

  const toggleMessageSentNotification = () => {
    setShowMessageSentNotification(true);
    setTimeout(() => setShowMessageSentNotification(false), 2000);
  };

  const submitReply = async () => {
    const body = {
      content: { text: reply },
      interaction_id: interaction.id,
      show_id: interaction.show_id,
      channel_id: interaction.channel_id,
      program_id: interaction.program_id,
      segment: interaction.segment.id,
      author_id: user.id,
    };
    await request({
      url: API_ENDPOINTS.interactionReply,
      method: 'POST',
      body,
    });
    toggleMessageSentNotification();
    fetchInteraction();
    setReply('');
    setReplyType(null);
    updateListVariableById(GLOBAL_NAMES.messages, { id, has_reply: true });
  };

  const submitVoiceReply = async file => {
    let formData = new FormData();
    formData.append('file', file);
    const query = {
      interaction_id: interaction.id,
      show_id: interaction.show_id,
      channel_id: interaction.channel_id,
      program_id: interaction.program_id,
      segment: interaction.segment.id,
      author_id: user.id,
    };
    const headers = {
      'Content-Disposition':
        'form-data; name="file"; filename="recording.webm"',
      'Content-Type': 'video/webm',
    };
    await request({
      url: API_ENDPOINTS.interactions,
      method: 'PUT',
      formData,
      query,
      headers,
    });
    toggleMessageSentNotification();
    fetchInteraction();
    setReply('');
    setReplyType(null);
  };

  const updateMessage = async (interactionId, body) => {
    const { data } = await request({
      url: API_ENDPOINTS.channelInteractions + `?interaction_id=${interactionId}`,
      method: 'PUT',
      body,
    });
    return data;
  };

  const focusReply = () => {
    const replyElement = document.getElementById('reply');
    if (replyElement) {
      replyElement.focus();
      replyElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const changeReplyType = value => {
    setReply('');
    setReplyType(value);
    setTimeout(focusReply, 200);
  };

  const setInteractionAction = async action => {
    const body = {
      key: action,
    };
    const { data } = await request({
      url: API_ENDPOINTS.interactionAction(interaction.id),
      method: 'POST',
      body,
    });

    const { winner, liked, broadcast_status } = data;
    updateVariable(GLOBAL_NAMES.message, {
      id: interaction.id,
      broadcast_status,
      liked,
      winner,
      messageBroadcasted: broadcast_status === 'broadcast_2',
      messageQuoted: broadcast_status === 'broadcast_1',
    });
    updateListVariableById(GLOBAL_NAMES.messages, {
      id: interaction.id,
      broadcast_status,
      liked,
      winner,
    });
  };

  useEffect(() => {
    fetchInteraction();
    return () => {
      setVariable(GLOBAL_NAMES.message, null);
    };
  }, [id, runtime]);

  return {
    ftpUploadLoading,
    comment,
    interaction,
    replyType,
    reply,
    showMessageSentNotification,
    // setters
    setReply,
    // methods
    reloadMessage,
    updateMessage,
    changeReplyType,
    fetchInteraction,
    submitReply,
    setInteractionAction,
    saveComment,
    handleCommentChange,
    submitVoiceReply,
    postInteractionToFtp,
  };
};
