import React from 'react';

export const Record = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="108"
    height="108"
    viewBox="0 0 108 108"
  >
    <g transform="translate(-126 -488)">
      <path
        d="M15 0A15 15 0 1 1 0 15 15 15 0 0 1 15 0z"
        fill="#fd9e00"
        transform="translate(165 524)"
      />
    </g>
  </svg>
);
