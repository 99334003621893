import { useEffect, useState } from 'react';
import { API_ENDPOINTS } from '../../constants/common';
import { request } from '../utils/request';

export const WithVoteInteractionResults = ({ interactionId }) => {
  const [results, setResults] = useState([]);

  const fetchResults = async () => {
    if (!interactionId) {
      setResults([]);
      return;
    }
    const { data } = await request({
      url: API_ENDPOINTS.segmentVoteResults(interactionId),
      method: 'GET',
    });
    if (data && data.length) {
      setResults(data);
    } else {
      setResults([]);
    }
  };

  useEffect(() => {
    fetchResults();
  }, [interactionId]);

  return {
    results,
  };
};
