import React from 'react';
import { COLOR } from '../../constants/common';

export const GoBackIcon = ({ color = COLOR.white }) => (
  <svg
    height="24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <clipPath id="go-back">
      <path d="m0 0h24v24h-24z" />
    </clipPath>
    <g
      clipPath={'url(#go-back)'}
      fill={'none'}
      stroke={color}
      strokeLinecap={'round'}
      strokeLinejoin={'round'}
      strokeWidth={'1.5'}
    >
      <path d="m18.42 15.75v.01a7.114 7.114 0 0 1 -1.5 1.82 7.439 7.439 0 1 1 2.52-5.58" />
      <g transform="translate(14.679 15.751)">
        <path d="m0 0h3.746" />
        <path d="m0 3.746v-3.746" transform="translate(3.746)" />
      </g>
    </g>
  </svg>
);
