import React from 'react';
import styled from 'styled-components';
import { COLOR } from '../../constants/common';

const Dot = styled.div`
  width: 9px;
  height: 9px;
  border-radius: 50%;
  margin-left: 2px;
  background: ${({ active, activeColor }) =>
    active ? activeColor : COLOR.white};
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 10px;
  right: 10px;
`;

export const Dots = ({ total, active, activeColor }) => (
  <Wrapper>
    {new Array(total).fill().map((element, index) => (
      <Dot
        active={index === active}
        key={`dot-${index}`}
        activeColor={activeColor}
      />
    ))}
  </Wrapper>
);
