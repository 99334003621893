import React from 'react';

export const HomeIcon = () => (
  <svg
    height="15.916"
    viewBox="0 0 20 15.916"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="#707070" className="active-change">
      <path
        d="m569.145 431.879-7.133 5.881a.142.142 0 0 1 -.006.037.192.192 0 0 0 -.006.037v5.954a.806.806 0 0 0 .793.794h4.764v-4.764h3.176v4.764h4.767a.806.806 0 0 0 .794-.794v-5.954a.173.173 0 0 0 -.013-.074zm0 0"
        transform="translate(-559.145 -428.666)"
      />
      <path
        d="m566.862 422.754-2.717-2.258v-5.062a.382.382 0 0 0 -.4-.4h-2.381a.382.382 0 0 0 -.4.4v2.419l-3.027-2.531a1.538 1.538 0 0 0 -1.885 0l-8.92 7.431a.359.359 0 0 0 -.136.266.4.4 0 0 0 .087.292l.769.917a.423.423 0 0 0 .26.137.461.461 0 0 0 .3-.087l8.588-7.157 8.585 7.158a.38.38 0 0 0 .26.087h.038a.424.424 0 0 0 .26-.137l.769-.918a.4.4 0 0 0 .086-.291.36.36 0 0 0 -.137-.266zm0 0"
        transform="translate(-547.001 -415)"
      />
    </g>
  </svg>
);
