import styled from 'styled-components';
import { Box } from 'reflexbox';

export const Absolute = styled.withCustomProps(Box, ['left', 'l', 'right', 'r', 'bottom', 'b', 'top', 't'])`
  position: absolute;
  ${({ left, l }) => left || l ? `left: ${left || l}` : ''};
  ${({ right, r }) => right || r ? `right: ${right || r}` : ''};
  ${({ bottom, b }) => bottom || b ? `bottom: ${bottom || b}` : ''};
  ${({ top, t }) => top || t ? `top: ${top || t}` : ''};
`;
