import React, { useState } from 'react';
import styled from 'styled-components';
import { Box, Flex } from 'reflexbox';
import { COLOR, isDesktop } from '../../constants/common';
import { useHistory } from 'react-router-dom';

const Image = styled.img`
  border-radius: 50%;
  width: 100%;
  height: 100%;
  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'unset')};
`;

const NotImage = styled(Flex)`
  border-radius: 50%;
  color: white;
  font-size: 20px;
  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'unset')};
`;

const userColors = [
  COLOR.accentRedLight,
  COLOR.accentTeal,
  COLOR.accentYellowLight,
  COLOR.accentRedDark,
  COLOR.accentBlueDark,
  COLOR.accentYellowDark,
  COLOR.accentGreen,
];

const getUserColor = user => {
  const id = parseInt(user.id);
  return userColors[id % userColors.length];
};

export const Avatar = ({ withLink, user = {}, size = '32px' }) => {
  const { avatar_url, nickname, icon, backgroundColor } = user;
  const [errorLoadingImg, setErrorLoadingImg] = useState(false);
  const history = useHistory();
  const detailsClick = () => {
    if (!withLink || !user || !user.id) {
      return;
    }
    history.push(`/participants/${isDesktop ? '?id=' : ''}${user.id}`);
  };

  return (
    <Box width={size} height={size}>
      {avatar_url && !errorLoadingImg ? (
        <Image
          src={avatar_url}
          alt={''}
          onError={() => setErrorLoadingImg(true)}
          onClick={detailsClick}
          pointer={withLink}
        />
      ) : (
        <NotImage
          backgroundColor={
            icon
              ? backgroundColor || COLOR.stone1_12
              : !nickname
              ? COLOR.accentGrey
              : getUserColor(user)
          }
          alignItems={'center'}
          justifyContent={'center'}
          width={'100%'}
          height={'100%'}
          onClick={detailsClick}
          pointer={withLink}
        >
          {icon ? icon : nickname ? nickname[0].toUpperCase() : '?'}
        </NotImage>
      )}
    </Box>
  );
};
