import styled from 'styled-components';
import { Flex } from 'reflexbox';

export const ScreenWrapper = styled(Flex)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgb(28, 28, 28, 0.7);
  z-index: 1100;
  align-items: center;
  justify-content: ${({ isMain }) => (isMain ? 'center' : 'flex-start')};
  padding: 0 12px;
  flex-direction: column;
`;
