import { useState } from 'react';

export const WithBackup = (defaultBackup) => {
  const [backup, setBackup] = useState(defaultBackup);

  return {
    backup,
    setBackup,
  };
};
