import React from 'react';

export const CloseIcon = () => (
  <svg
    height="30"
    viewBox="0 0 30 30"
    width="30"
    xmlns="http://www.w3.org/2000/svg"
  >
    <clipPath id="clip-path">
      <path d="m0 0h13.986v13.986h-13.986z" />
    </clipPath>
    <circle cx="15" cy="15" fill="#a9a8aa" r="15" />
    <g clipPath="url(#clip-path)" transform="translate(8.253 7.638)">
      <path
        d="m6.993 8.264-5.722 5.722-1.271-1.272 5.721-5.721-5.721-5.722 1.271-1.271 5.722 5.721 5.721-5.721 1.272 1.271-5.722 5.722 5.722 5.721-1.272 1.272z"
        fill="#fff"
      />
    </g>
  </svg>
);
