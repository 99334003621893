import React from 'react';
import { Box, Flex } from 'reflexbox';
import styled, { css } from 'styled-components';

import { ProIcon } from '../../../ui/icons/ProIcon';
import { HeroUserIcon } from '../../../ui/icons/HeroIcon';
import { ExpertIcon } from '../../../ui/icons/ExpertIcon';
import { AmateurIcon } from '../../../ui/icons/AmateurIcon';
import { BeginnerIcon } from '../../../ui/icons/BeginnerIcon';
import { ConfirmedIcon } from '../../../ui/icons/ConfirmedIcon';
import { RegularUserIcon } from '../../../ui/icons/RegularIcon';
import { ArrowRightIcon } from '../../../ui/icons/ArrowRightIcon';
import { useUser } from '../../../models/users/withUser';
import {
  getValueForLanguage,
  Translate,
} from '../../../features/translate/Translate';

const Button = styled(Flex)`
  color: white !important;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 20px;
  min-width: 100px;
  height: 35px;
  padding-left: 30px;
  padding-right: 30px;
  font-family: Ubuntu;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.18;
  text-align: center;

  ${({ color }) =>
    css`
      background: ${color};
    `};
`;

const RankingButton = styled(Flex)`
  justify-content: center;
  cursor: pointer;
  height: 21px;
  border-radius: 11px;
  color: white;
  border: solid 1px #fffdfd;
  padding-left: 14px;
  padding-right: 16px;
  font-size: 12px;
  line-height: 13px;
  align-items: center;
  font-weight: bold;
  width: 104px;
`;

const Points = styled.div`
  color: white;
  margin-right: 1rem;
  margin-top: -2px;
  font-size: 15px;
  line-height: 17px;
  div {
    display: inline;
  }
`;

export const getRankData = points => {
  if (points >= 2000) {
    return { color: '#1c1c1c', name: 'HERO', icon: <HeroUserIcon /> };
  }
  if (points >= 1500) {
    return { color: '#fa544d', name: 'EXPERT', icon: <ExpertIcon /> };
  }
  if (points >= 1000) {
    return { color: '#fcbf2e', name: 'PRO', icon: <ProIcon /> };
  }
  if (points >= 750) {
    return { color: '#31cc41', name: 'CONFIRMED', icon: <ConfirmedIcon /> };
  }
  if (points >= 500) {
    return { color: '#198fe5', name: 'REGULAR', icon: <RegularUserIcon /> };
  }
  if (points >= 250) {
    return { color: '#d2d2d2', name: 'AMATEUR', icon: <AmateurIcon /> };
  }
  return { color: '#d2d2d2', name: 'BEGINNER', icon: <BeginnerIcon /> };
};

const ArrowRightWrapper = styled(Box)`
  ${({ scale }) => (scale ? `transform: scale(${scale})` : '')};
`;

export const UserRankingAvatar = ({
  userPoints,
  onBadgeClick,
  onRankingClick,
}) => {
  const { color, name } = getRankData(userPoints);
  const { language } = useUser();
  return (
    <>
      <Button onClick={onBadgeClick} color={color} mt={'25px'}>
        <Translate>{name}</Translate>
        <ArrowRightWrapper ml={'10px'} scale={1.4} mt={'-5px'}>
          <ArrowRightIcon fill={'white'} />
        </ArrowRightWrapper>
      </Button>
      <Flex mt={'21px'} alignItems={'center'}>
        <Points>
          {userPoints} <Translate>points</Translate>
        </Points>
        <RankingButton onClick={onRankingClick}>
          {getValueForLanguage(language, 'RANKING')}
          <ArrowRightWrapper ml={'3px'} scale={0.7} mt={'2px'}>
            <ArrowRightIcon fill={'white'} />
          </ArrowRightWrapper>
        </RankingButton>
      </Flex>
    </>
  );
};
