import styled, { css } from 'styled-components';
import { COLOR } from '../../constants/common';
import { Translate } from '../../features/translate/Translate';

export const WidgetButton = styled(Translate)`
  display: flex;
  height: 40px;
  width: 75%;
  margin: auto;
  border: 1px solid white;
  border-radius: 22px;
  cursor: pointer;
  text-align: center;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  ${({ reversed }) =>
    reversed
      ? css`
          background: transparent;
          color: white;
        `
      : css`
          background: white;
          color: ${COLOR.blackish};
        `};
`;
