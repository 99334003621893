import React from 'react';

export const ArrowDown = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.584"
    height="8.792"
    viewBox="0 0 15.584 8.792"
  >
    <path
      id="Tracé_597"
      d="M0 0l6.378 6.378L0 12.755"
      data-name="Tracé 597"
      transform="rotate(90 6.378 7.792)"
      fill="none"
      stroke="#fffcfc"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="2px"
    />
  </svg>
);
