import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { Box, Flex } from 'reflexbox';
import Waveform from 'waveform-react';
import styled, { css } from 'styled-components';

import { Action } from '../../ui/actions/Actions';
import { ActionsBar } from '../../ui/actions/ActionsBar';
import { EditIcon } from '../../ui/icons/EditIcon';
import { ReplyVoiceIcon } from '../../ui/icons/ReplyVoiceIcon';
import { BackIcon } from '../../ui/icons/BackIcon';
import { useHistory, useParams } from 'react-router-dom';
import { WithMessage } from '../../models/messages/withMessage';
import { Avatar } from '../../ui/img/Avatar';
import { Text } from '../../ui/text/Text';
import { ClockIcon } from '../../ui/icons/ClockIcon';
import {
  COLOR,
  INTERACTION_API_TYPE_MAP,
  INTERACTION_FRONTEND_TYPE_MAP,
  INTERACTION_ICON_MAP,
} from '../../constants/common';
import { PauseIcon } from '../../ui/icons/PauseIcon';
import { PlayIcon } from '../../ui/icons/PlayIcon';
import { StopIcon } from '../../ui/icons/StopIcon';
import { DownloadIcon } from '../../ui/icons/DownloadIcon';
import { CloudIcon } from '../../ui/icons/CloudIcon';
import { Reply } from '../../ui/input/Reply';
import { Heart } from '../../ui/interaction/Heart';
import { Relative } from '../../ui/box/Relative';
import { Absolute } from '../../ui/box/Absolute';
import { Recorder } from '../../features/recorder/Recorder';
import { WithPlayer } from '../../models/player/withPlayer';
import { Star } from '../../ui/interaction/Star';
import { useUser } from '../../models/users/withUser';
import {
  getValueForLanguage,
  Translate,
} from '../../features/translate/Translate';
import {
  enterFullscreen,
  getFullTranscript,
  getInteractionAuthor,
  padTime,
} from '../../utils/utils';
import * as moment from 'moment';
import { InternalComment } from '../../ui/input/InternalComment';
import { FullScreenIcon } from '../../ui/icons/FullScreenIcon';
import { Spinner } from '../../ui/spinner/Spinner';

const InteractionBox = styled(Box)`
  width: 100%;
  border-radius: 8px;
  border: solid 1px ${COLOR.stone2_12};
  background: white;
  margin-bottom: 16px;
`;

const WaveContainer = styled(Box)`
  height: 72px;
  width: 100%;
  background: #fafafa;
  border-bottom: solid 1px ${COLOR.stone2_12};
`;

const MessageContainer = styled(Translate)`
  height: 56px;
  width: 100%;
  border-bottom: solid 1px ${COLOR.stone2_12};
  padding: 4px 8px;
  font-size: 16px;
  color: #5d5d5d;
  line-height: 16px;
`;

const WinnerContainer = styled.div`
  width: 100%;
  height: 84px;
  box-sizing: border-box;
`;

const NotificationContainer = styled(Box)`
  position: fixed;
  top: 0;
  width: 100%;
  transition: height 0.2s ease-in-out;
  ${({ showNotification }) =>
    showNotification ? 'height: 32px' : 'height: 0px'};
  background-color: #1b6a9f;
  overflow-x: hidden;
  z-index: 20000;
`;

const PlayerBar = styled(Flex)`
  height: 40px;
  background: ${COLOR.stone1_12};
  align-items: center;
  border-bottom: solid 1px ${COLOR.stone2_12};
  border-top: solid 1px ${COLOR.stone2_12};
  justify-content: space-around;
`;

const SeparatorVertical = styled(Box)`
  height: 16px;
  background: ${COLOR.stone2_12};
  width: 2px;
`;

const ButtonBar = styled(Flex)`
  justify-content: space-between;
  height: 40px;
  background: ${COLOR.stone1_12};
  border-top: solid 1px ${COLOR.stone2_12};
`;

const ActionButton = styled(Flex)`
  color: #787878;
  line-height: 16px;
  border-radius: 7px;
  text-align: center;
  width: 86px;
  font-weight: bold;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 12px;

  & > div {
    background: #787878;
    width: 11px;
    height: 3px;
  }
  ${({ active }) =>
    active
      ? css`
          color: #fafafa;
          background: #ffa116;

          & > div {
            background: #fafafa;
          }
        `
      : ''};
`;

const MessageButton = styled(Flex)`
  border: 1px solid ${COLOR.link};
  align-items: center;
  height: 48px;
  min-width: 157px;
  width: 47.8%;
  border-radius: 7px;
  cursor: pointer;
  padding: 10px;
`;

const RecordingWrapper = styled.div`
  border-radius: 8px;
  background: #fafafa;
  display: flex;
  align-items: center;
  height: 37px;
  width: 156px;
  border: 1px solid ${COLOR.stone2_12};
  padding: 0 15px 0 12px;
`;

const ScrollWrapper = styled(Box)`
  height: calc(100vh - 109px);
  overflow-y: auto;
`;

const Video = styled.video`
  width: 100%;
  height: 190px;
  border-radius: 8px;
  background: #707070;
`;

const SpinnerWrapper = styled.div`
  height: 16px;
  width: 22px;
  svg,
  div {
    margin: 0;
    min-height: unset;
    width: 22px;
    height: 100%;
  }
`;

const getAudioComponent = interaction => {
  const selector = `interaction-${interaction.id}`;
  return document.getElementById(selector);
};

const getCurrentAudioTime = (interaction, progress) => {
  let duration = get(interaction, 'duration');
  if (!duration) {
    return '0:00';
  }
  const durationSplit = duration.split(':');
  duration =
    moment
      .duration({
        minutes: durationSplit[0],
        seconds: durationSplit[1],
      })
      .asSeconds() * progress;
  return `${padTime(Math.round(duration / 60))}:${padTime(
    Math.round(duration % 60)
  )}`;
};

export const downloadAudioBuffer = (audioSrc, setAudioBuffer) => {
  const context = new (window.AudioContext || window.webkitAudioContext)();
  fetch(audioSrc, onSuccess);

  function fetch(url, resolve) {
    const request = new XMLHttpRequest();
    request.open('GET', url, true);
    request.responseType = 'arraybuffer';
    request.onload = function() {
      resolve(request);
    };
    request.send();
  }

  function onSuccess(request) {
    const audioData = request.response;
    context.decodeAudioData(audioData, onBuffer, onDecodeBufferError);
  }

  function onBuffer(buffer) {
    const source = context.createBufferSource();
    source.buffer = buffer;
    source.connect(context.destination);
    setAudioBuffer(buffer);
  }

  function onDecodeBufferError(e) {
    console.log('Error decoding buffer: ' + e.message);
    console.error(e);
  }
};

export default function MessageScreen({ id, handleBack }) {
  const history = useHistory();
  const { id: paramsId } = useParams();
  id = id || paramsId;

  const {
    ftpUploadLoading,
    handleCommentChange,
    comment,
    interaction,
    reply,
    replyType,
    setReply,
    showMessageSentNotification,
    submitVoiceReply,
    changeReplyType,
    submitReply,
    setInteractionAction,
    postInteractionToFtp,
  } = WithMessage({ id });
  const { language } = useUser();

  const { listen, listening, stop } = WithPlayer();
  const [audioBuffer, setAudioBuffer] = useState(null);
  const [currentPlayTime, setCurrentPlayTime] = useState(null);
  let interactionType = get(interaction, 'segment.type');
  if (
    get(interaction, 'content.transcription.data[0].transcript') &&
    interactionType !== INTERACTION_API_TYPE_MAP.video
  ) {
    interactionType = INTERACTION_API_TYPE_MAP.voice;
  }

  useEffect(() => {
    if (interactionType !== INTERACTION_API_TYPE_MAP.voice) {
      return;
    }
    downloadAudioBuffer(get(interaction, 'content.recording_url'), setAudioBuffer);

    setInterval(() => {
      if (interaction) {
        const audio = getAudioComponent(interaction);
        if (audio) {
          setCurrentPlayTime(audio.currentTime / audio.duration);
        }
      }
    }, 50);
  }, [get(interaction, 'id')]);

  const isFromRegisteredUser = !get(
    interaction,
    'author_app_user.is_anonymous'
  );
  const messageText =
    get(interaction, 'content.text') || get(interaction, 'content.choice_text');

  return (
    <Box>
      <NotificationContainer showNotification={showMessageSentNotification}>
        <Box ml={'16px'} mt={'7px'} mb={'7px'}>
          <Text semiBold size={'md'} color={'white'}>
            Message sent
          </Text>
        </Box>
      </NotificationContainer>
      <ActionsBar top={'56px'} backgroundColor={COLOR.white}>
        <Action onClick={() => (handleBack ? handleBack() : history.goBack())}>
          <BackIcon />
        </Action>

        {isFromRegisteredUser && (
          <>
            <Action ml={'auto'} onClick={() => changeReplyType('text')}>
              <EditIcon />
            </Action>
            <Action ml={'25px'} onClick={() => changeReplyType('voice')}>
              <ReplyVoiceIcon />
            </Action>
          </>
        )}
      </ActionsBar>

      <ScrollWrapper>
        {interaction && (
          <Box px={3} pt={'12px'} mb={3}>
            <Flex>
              <Box mt={'9px'}>
                {
                  INTERACTION_ICON_MAP[
                    INTERACTION_FRONTEND_TYPE_MAP[interactionType]
                  ]
                }
              </Box>
              <Flex flex={1} ml={'11px'} flexDirection={'column'}>
                <Text semiBold size={'h5'}>
                  {get(interaction, 'segment.title')}
                </Text>
                <Text theme={'gray2'} size={'h5'}>
                  {get(interaction, 'segment.text')}
                </Text>
              </Flex>
            </Flex>
            <Flex mt={'9px'} alignItems={'center'} mb={'15px'}>
              <Avatar user={interaction.author_app_user} withLink />
              <Flex ml={'11px'} flex={1} flexDirection={'column'}>
                <Flex alignItems={'baseline'}>
                  <Text semiBold size={'h5'}>
                    {getInteractionAuthor(interaction, language)}
                  </Text>
                  <Flex alignItems={'baseline'} ml={'10px'}>
                    <ClockIcon />
                    <Text theme={'lighter'} ml={'5px'}>
                      {get(interaction, 'duration')}
                    </Text>
                  </Flex>
                </Flex>
                <Text theme={'gray2'}>{get(interaction, 'createdAt')}</Text>
              </Flex>
              {(interactionType === INTERACTION_API_TYPE_MAP.voice ||
                interactionType === INTERACTION_API_TYPE_MAP.video) && (
                <Heart
                  active={interaction.liked}
                  onClick={() => setInteractionAction('liked')}
                />
              )}
              {(interactionType === INTERACTION_API_TYPE_MAP.sms ||
                interactionType === INTERACTION_API_TYPE_MAP.registration ||
                interactionType === INTERACTION_API_TYPE_MAP.choice) && (
                <Star
                  active={interaction.winner}
                  onClick={() => setInteractionAction('winner')}
                />
              )}
            </Flex>

            <InteractionBox>
              {messageText && (
                <MessageContainer>
                  {get(interaction, 'content.text') ||
                    get(interaction, 'content.choice_text') ||
                    ''}
                </MessageContainer>
              )}
              {interactionType === INTERACTION_API_TYPE_MAP.voice ? (
                <WaveContainer>
                  <Waveform
                    // Audio buffer
                    buffer={audioBuffer}
                    // waveform height
                    height={150}
                    markerStyle={{
                      // Position marker color
                      color: COLOR.link,
                      // Position marker width (in pixels)
                      width: 2,
                    }}
                    // Wave plot type (line or bar)
                    plot="bar"
                    // Marker position on waveform (0 - 1)
                    position={currentPlayTime}
                    // redraw waveform on window size change (default: true)
                    responsive
                    // Show position marker
                    showPosition
                    waveStyle={{
                      // animate waveform on draw (default: true)
                      animate: true,
                      // waveform color
                      color: '#787878',
                      // width of each rendered point (min: 1, max: 10)
                      pointWidth: 1,
                    }}
                    // waveform width
                    width={900}
                  />
                </WaveContainer>
              ) : (
                interactionType === INTERACTION_API_TYPE_MAP.video && (
                  <Video
                    src={get(interaction, 'content.recording_url')}
                    id={`interaction-${interaction.id}`}
                  />
                )
              )}
              {interactionType === INTERACTION_API_TYPE_MAP.voice ||
              interactionType === INTERACTION_API_TYPE_MAP.video ? (
                <>
                  <Text theme={'gray2'} size={'h5'} p={2}>
                    {getFullTranscript(interaction)}
                  </Text>
                  <PlayerBar>
                    <Flex alignItems="center">
                      <Text theme={'gray2'} size={'h5'}>
                        {getCurrentAudioTime(interaction, currentPlayTime)}
                        <b>/&nbsp;</b>
                        {get(interaction, 'duration')}
                      </Text>
                    </Flex>
                    <Action>
                      <SeparatorVertical />
                    </Action>
                    <Action
                      onClick={() => getAudioComponent(interaction).pause()}
                    >
                      <PauseIcon />
                    </Action>
                    <Action
                      key={interaction.id}
                      onClick={() => getAudioComponent(interaction).play()}
                    >
                      {interactionType === INTERACTION_API_TYPE_MAP.voice && (
                        <audio id={`interaction-${interaction.id}`}>
                          <source
                            src={get(interaction, 'content.recording_url')}
                            type="audio/ogg"
                          />
                          <source
                            src={get(interaction, 'content.recording_url')}
                            type="audio/mpeg"
                          />
                          Your browser does not support the audio element.
                        </audio>
                      )}
                      <PlayIcon />
                    </Action>
                    <Action
                      onClick={() => {
                        const audio = getAudioComponent(interaction);
                        audio.pause();
                        audio.currentTime = 0.0;
                      }}
                    >
                      <StopIcon />
                    </Action>
                    <Action>
                      <SeparatorVertical />
                    </Action>
                    <Action
                      onClick={() =>
                        window.open(
                          get(interaction, 'content.recording_url'),
                          '_blank'
                        )
                      }
                    >
                      <DownloadIcon />
                    </Action>
                    {interactionType === INTERACTION_API_TYPE_MAP.video ? (
                      <Action
                        onClick={() =>
                          enterFullscreen(
                            document.getElementById(
                              `interaction-${interaction.id}`
                            )
                          )
                        }
                      >
                        <FullScreenIcon />
                      </Action>
                    ) : ftpUploadLoading ? (
                      <SpinnerWrapper>
                        <Spinner />
                      </SpinnerWrapper>
                    ) : (
                      <Action onClick={postInteractionToFtp}>
                        <CloudIcon />
                      </Action>
                    )}
                  </PlayerBar>
                  <InternalComment
                    rows={3}
                    placeholder={getValueForLanguage(
                      language,
                      'Enter an internal comment here'
                    )}
                    onChange={handleCommentChange}
                    value={comment}
                  />
                  <ButtonBar>
                    <ActionButton
                      active={!interaction.broadcast_status}
                      onClick={() => setInteractionAction('-')}
                    >
                      <div />
                    </ActionButton>
                    <ActionButton
                      onClick={() =>
                        !interaction.messageQuoted &&
                        setInteractionAction('broadcast_1')
                      }
                      active={interaction.messageQuoted}
                    >
                      {getValueForLanguage(language, 'Message quoted')}
                    </ActionButton>
                    <ActionButton
                      onClick={() =>
                        !interaction.messageBroadcasted &&
                        setInteractionAction('broadcast_2')
                      }
                      active={interaction.messageBroadcasted}
                    >
                      {getValueForLanguage(language, 'Broadcast message')}
                    </ActionButton>
                  </ButtonBar>
                </>
              ) : (
                <WinnerContainer>
                  <InternalComment
                    rows={3}
                    placeholder={getValueForLanguage(
                      language,
                      'Enter an internal comment here'
                    )}
                    onChange={handleCommentChange}
                    value={comment}
                  />
                </WinnerContainer>
              )}
            </InteractionBox>

            <Box mt={'2px'}>
              {get(interaction, 'replies', []).map(reply => (
                <Flex mt={'8px'} key={`reply-${reply.id}`}>
                  <Box mt={'6px'}>
                    <Avatar
                      user={{
                        avatar_url: get(reply, 'author.image_url'),
                        nickname: get(reply, 'author.screenname'),
                      }}
                    />
                  </Box>
                  <Relative ml={'11px'} flex={1}>
                    <Flex alignItems={'center'}>
                      <Text size={'h5'} semiBold>
                        {get(reply, 'author.screenname')}
                      </Text>
                      {reply.duration && (
                        <Flex alignItems={'center'} ml={'7px'}>
                          <ClockIcon />
                          <Text theme={'lighter'} ml={'5px'} mr={'8px'}>
                            {reply.duration}
                          </Text>
                        </Flex>
                      )}
                    </Flex>
                    {reply.recording ? (
                      <RecordingWrapper>
                        {listening === get(reply, 'recording') ? (
                          <Action onClick={stop}>
                            <PauseIcon />
                          </Action>
                        ) : (
                          <Action
                            onClick={() => listen(get(reply, 'recording'))}
                          >
                            <PlayIcon />
                          </Action>
                        )}
                      </RecordingWrapper>
                    ) : (
                      <Text size={'h5'}>{get(reply, 'text')}</Text>
                    )}
                    <Absolute r={'0px'} t={'0px'}>
                      <Text theme={'lighter'}>{get(reply, 'createdAt')}</Text>
                    </Absolute>
                  </Relative>
                </Flex>
              ))}
            </Box>

            {isFromRegisteredUser && (
              <>
                <Flex mt={3} justifyContent={'space-between'} mb={'15px'}>
                  <MessageButton onClick={() => changeReplyType('voice')}>
                    <ReplyVoiceIcon />
                    <Text
                      theme={'link'}
                      semiBold
                      size={'md'}
                      flex={1}
                      ml={'18px'}
                      alignItems={'center'}
                      mt={'2px'}
                      upper
                    >
                      Voice message
                    </Text>
                  </MessageButton>
                  <MessageButton onClick={() => changeReplyType('text')}>
                    <EditIcon />
                    <Text
                      theme={'link'}
                      semiBold
                      size={'md'}
                      flex={1}
                      ml={'12.5px'}
                      alignItems={'center'}
                      mt={'2px'}
                      upper
                    >
                      Written message
                    </Text>
                  </MessageButton>
                </Flex>

                {replyType === 'text' && (
                  <Box>
                    <Reply
                      value={reply}
                      onChange={setReply}
                      onSubmit={submitReply}
                      name={'reply'}
                    />
                    <Box height={'60vh'} w={1} />
                  </Box>
                )}

                {replyType === 'voice' && (
                  <Recorder
                    interaction={interaction}
                    onSend={submitVoiceReply}
                  />
                )}
              </>
            )}
          </Box>
        )}
      </ScrollWrapper>
    </Box>
  );
}
