import styled, { css } from 'styled-components';
import { Flex } from 'reflexbox';

export const FlexCenter = styled(Flex)`
  align-items: center;
  justify-content: center;
  ${({ fullHeight }) =>
    fullHeight &&
    css`
      height: 100%;
    `}
`
