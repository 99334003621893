import styled, { css } from 'styled-components';
import { Flex } from 'reflexbox';
import { COLOR } from '../../constants/common';

export const Footer = styled(Flex)`
  z-index: 2;
  background: ${COLOR.white};
  border-top: 1px solid ${COLOR.veryLightPink};
  align-items: center;
  justify-content: space-between;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  ${({ small }) =>
    small
      ? css`
          height: 55px;
          padding: 0 10px;
        `
      : css`
          height: 61px;
          padding: 0 20px;
        `}
`;
