import React from 'react';
import { Box } from 'reflexbox';
import { TRANSLATIONS } from '../../constants/translations';
import { useUser } from '../../models/users/withUser';

export const getValueForLanguage = (language, name) => {
  let value;

  try {
    value = TRANSLATIONS[language][name.toLowerCase()];
  } catch (e) {}

  return value || name;
};

export const Translate = ({ children, ...rest }) => {
  const { language } = useUser();
  return <Box {...rest}>{getValueForLanguage(language, children)}</Box>;
};
