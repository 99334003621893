import React from 'react';
import styled from 'styled-components';
import { COLOR } from '../../constants/common';

export const InternalComment = styled.textarea`
  border: none;
  font-size: 16px;
  line-height: 16px;
  color: ${COLOR.orange2};
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
  font-family: 'Source Sans Pro', sans-serif;
  resize: none;

  &::placeholder {
    color: #929292;
    opacity: 64%;
  }
`;
