import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'reflexbox';
import { COLOR } from '../../constants/common';
import { Button } from './Button';
import { UploadIcon } from '../icons/UploadIcon';
import { Text } from '../text/Text';
import { WithImageUpload } from '../../../models/input/withImageUpload';
import { Upload } from '../../../ui/upload/Upload';

export const UploadButtons = ({
  onUpload,
  text,
  hideDelete,
  name,
  mt = '11px',
  mr,
  width,
  acceptVideo=false,
  ...rest
}) => {
  const { t } = useTranslation();
  const {
    handleFileUpload,
    uploadRef,
    handleUploadClick,
    handleDeleteClick,
  } = WithImageUpload({
    onChange: onUpload,
    name,
    video: acceptVideo
  });
  return (
    <Flex flexDirection={'column'} alignItems={'center'} {...rest}>
      <Button theme={'white'} mt={mt} onClick={handleUploadClick} mr={mr} width={width}>
        <UploadIcon />
        <Text size={'xs'} color={COLOR.waterBlue} ml={'7px'}>
          {text}
        </Text>
      </Button>
      <Upload
        ref={uploadRef}
        name={'background'}
        type={'file'}
        accept={acceptVideo ? 'image/*, video/*' : 'image/*'}
        onChange={handleFileUpload}
      />
      {!hideDelete && (
        <Text
          pointer
          size={'xs'}
          onClick={handleDeleteClick}
          mt={mt}
          color={COLOR.waterBlue}
          bordered
        >
          {t('Delete image')}
        </Text>
      )}
    </Flex>
  );
};
