import React from 'react';
import { COLOR } from '../../constants/common';

export const VoteInteractionIcon = ({ color = COLOR.grey1 }) => (
  <svg
    height="26"
    width="26"
  >
    <clipPath id="vote-interaction">
      <path d="m0 0h26v26h-26z" />
    </clipPath>
    <g
      clipPath={'url(#vote-interaction)'}
      fill={'none'}
      stroke={color}
      strokeLinecap={'round'}
      strokeLinejoin={'round'}
      strokeWidth={'1.5'}
    >
      <path
        d="m4.75 6.931a2.181 2.181 0 0 1 2.181-2.181h11.453a2.181 2.181 0 0 1 2.181 2.181v11.453a2.181 2.181 0 0 1 -2.181 2.181h-11.453a2.181 2.181 0 0 1 -2.181-2.181z"
        transform="translate(.431 .431)"
      />
      <path d="m8.75 15.749v-6" transform="translate(.794 .884)" />
      <path d="m15.25 15.749v-6" transform="translate(1.383 .884)" />
      <path d="m12 15.477v-2.727" transform="translate(1.088 1.156)" />
    </g>
  </svg>
);
