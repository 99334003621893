import React from 'react';
import styled, { css } from 'styled-components';
import { Flex } from 'reflexbox';
import { Text } from '../text/Text';
import { COLOR } from '../../constants/common';
import { ColorPickerIcon } from '../icons/ColorPickerIcon';
import { Label } from './Label';
import { Pointer } from '../box/Pointer';
import { Absolute } from '../../new/ui/box/Absolute';
import { Close } from '../widget/icons/Close';

const InputField = styled.input`
  color: ${({color}) => color} !important;
  font-weight: ${({fontWeight}) => fontWeight};
  padding-bottom: 10px;
  border-radius: 0;
  font-size: 16px;
  border: none;
  ${({borderless}) => borderless || css`
    border-bottom: 1px solid ${({placeholderColor}) => placeholderColor || COLOR.stone2_12};
  `};
  ${({rounded}) => rounded && css`
    border-radius: 5px;
  `};
  flex: 1;
  width: 100%;
  background: ${({ background }) => background || 'transparent'};
  font-family: ${({fontFamily}) => fontFamily || 'Ubuntu'}, sans-serif;

  ${({ center }) => (center ? `text-align: center;` : '')};
  ${({ padding }) => padding && css`padding: ${padding}`}

  ${({ widgetInputSmall }) => widgetInputSmall ? css`font-size: 12px;` : ''}
  ${({ type }) =>
    type === 'color'
      ? css`
          border: none;
          width: 40px;
          background: transparent;
        `
      : ''};
  border-bottom-color: ${({ valid, placeholderColor }) =>
    valid !== undefined ? (valid ? '#3a7c2a' : 'red') : placeholderColor || COLOR.stone2_12};
  
  &::placeholder {
    color: ${({placeholderColor}) => placeholderColor};
    font-weight: ${({fontWeight}) => fontWeight};
    font-family: ${({fontFamily}) => fontFamily || 'Ubuntu'}, sans-serif;
  }
`;

const TextArea = styled.textarea`
  background: ${({ background }) => background};
  padding: 14px 12px;
  font-size: 14px;
  line-height: 24px;
  border: none;
  border-radius: 5px;
  resize: none;
  margin-top: 5px;
  color: ${({ color }) => color};
  
  &::placeholder {
    color: ${({ color }) => color};
  }
`;

const TextBorder = styled(Text)`
  width: 80px;
  border-bottom: 1px solid ${COLOR.stone2_12};
`;

const TextDisabled = styled(Text)`
  word-break: break-all;
  max-height: 25px;
  overflow: hidden;
`;

const ErrorIconWrapper = styled(Absolute)`
  background-color: #db3838;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  
  svg {
    width: 10px;
    height: 10px;
  }
`


const clickInput = id => {
  const inputElement = document.getElementById(id);
  if (inputElement) {
    inputElement.click();
  }
};

export const Input = ({
  label,
  labelSize,
  labelColor,
  labelBottomMargin,
  name,
  onChange,
  disabled,
  description,
  descriptionColor,
  showValidation,
  valid,
  value,
  type = 'text',
  textArea,
  widgetInput,
  widgetInputSmall,
  onEnter,
  center,
  wideDisabledString,
  ...rest
}) => {
  const isColor = type === 'color';
  const handleKeyDown = onEnter
    ? e => {
        if (e.key === 'Enter') {
          onEnter();
        }
      }
    : undefined;

  return (
    <Flex
      flexDirection={'column'}
      mb={widgetInputSmall ? '15px' : 2}
      w={'100%'}
    >
      {widgetInput && label ? (
        <Text color={labelColor || '#d2d2d2'} mb={labelBottomMargin || '12px'} size={labelSize || 'h5'}>
          {label}
        </Text>
      ) : widgetInputSmall && label ? (
        <Text color={labelColor || '#a29e9e'} size={labelSize || 'xs'} mb={labelBottomMargin || '12px'}>
          {label}
        </Text>
      ) : (
        <Label>{label || ''}</Label>
      )}
      {textArea ? (
        <TextArea
          value={value || ''}
          onChange={e => onChange && onChange(name, e.target.value)}
          rows={7}
          disabled={disabled}
          {...rest}
        />
      ) : disabled ? (
        <TextDisabled size={'h5'} width={wideDisabledString ? '500px' : '100%'}>
          {value}
        </TextDisabled>
      ) : (
        <Flex w={'100%'} justifyContent={'space-between'} alignItems={'center'}>
          {isColor && (
            <TextBorder size={'h5'} mr={'22px'}>
              {value}&nbsp;
            </TextBorder>
          )}
            <InputField
              {...rest}
              value={value || ''}
              type={type}
              onChange={e => onChange && onChange(name, e.target.value)}
              valid={!showValidation ? undefined : valid}
              id={name}
              onKeyDown={handleKeyDown}
              widgetInputSmall={widgetInputSmall}
              center={center}
            />
            {showValidation && !valid && (
              <ErrorIconWrapper right={'30px'}>
                <Close/>
              </ErrorIconWrapper>
            )}
          {isColor && (
            <Pointer
              ml={'19px'}
              justifyContent={'space-between'}
              alignItems={'center'}
              display={'flex'}
              onClick={() => clickInput(name)}
            >
              <ColorPickerIcon />
            </Pointer>
          )}
        </Flex>
      )}
      {description && (
        <Text color={descriptionColor || '#a29e9e'} mt={'5px'} size={'xs'}>
          {description}
        </Text>
      )}
    </Flex>
  );
};
