import React from 'react';

export const Video = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="108"
    height="108"
    viewBox="0 0 108 108"
  >
    <g transform="translate(5.709 3.898)">
      <path
        d="M47.383 57.326H24.551a6.88 6.88 0 0 1-6.872-6.872v-22.83a6.882 6.882 0 0 1 6.872-6.874h22.832a6.882 6.882 0 0 1 6.872 6.874v22.83a6.88 6.88 0 0 1-6.872 6.872zM25.035 49.97H46.9V28.106H25.035z"
        fill="#fff"
        transform="translate(6.37 7.876)"
      />
      <path
        d="M43.968 34.682l10.98-4.618-3.348-2.553v19.268l3.352-2.554-10.98-3.452 1.944 2.553v-11.2c0-3.409-5.3-3.415-5.3 0v11.2a2.714 2.714 0 0 0 1.944 2.554l10.98 3.452a2.671 2.671 0 0 0 3.353-2.553V27.511c0-1.539-1.724-3.238-3.353-2.554l-10.98 4.618c-3.094 1.302-1.735 6.425 1.408 5.107z"
        fill="#fff"
        transform="translate(17.121 9.785)"
      />
    </g>
  </svg>
);
