import React from 'react';

export const Share = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="90"
    height="90.002"
    viewBox="0 0 90 90.002"
  >
    <g transform="translate(-135 -493.425)">
      <path
        fill="#fd9e00"
        d="M629.01 630.189v5.749s-16.725-.448-16.725 23.146c0 0 .109.588.521.1a17.043 17.043 0 0 1 16.07-11.62l-.066 5.562s.151.366.612.12 12.778-11.382 12.778-11.382a.179.179 0 0 0 0-.3l-12.812-11.374a.517.517 0 0 0-.378-.001z"
        transform="translate(-445.285 -106.813)"
      />
    </g>
  </svg>
);
