import React from 'react';

export const DistributionIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="15.45"
    viewBox="0 0 20 15.45"
  >
    <defs>
      <clipPath id="distribution-clip-path">
        <path d="M0 0H20V15.45H0z" fill="#707070" />
      </clipPath>
    </defs>
    <g clipPath="url(#distribution-clip-path)">
      <path
        d="M514.252 10.138a8.383 8.383 0 0 1-.07 12.886l1.01 1.016a9.752 9.752 0 0 0 .072-14.909l-1.012 1.007"
        fill="#707070"
        className="active-change"
        transform="translate(-498.885 -8.859)"
      />
      <path
        d="M9.926 8.9a9.758 9.758 0 0 0 .095 14.926l.97-.982a.2.2 0 0 0 .024-.041 8.383 8.383 0 0 1-.078-12.885L9.926 8.9"
        fill="#707070"
        className="active-change"
        transform="translate(-6.309 -8.635)"
      />
      <path
        d="M128.245 93.995a6.237 6.237 0 0 0 .09 9.882l.516-.526.509-.511a4.816 4.816 0 0 1-.082-7.827l-1.034-1.017"
        fill="#707070"
        className="active-change"
        transform="translate(-122.112 -91.199)"
      />
      <path
        d="M429.817 103.857a6.223 6.223 0 0 0 .094-9.881l-1.027 1.016a4.823 4.823 0 0 1-.075 7.825c.022.026.039.05.059.071l.949.969"
        fill="#707070"
        className="active-change"
        transform="translate(-416.053 -91.18)"
      />
      <path
        d="M275.785 201.154a1.848 1.848 0 1 0-1.847-1.777 1.858 1.858 0 0 0 1.847 1.777"
        fill="#707070"
        className="active-change"
        transform="translate(-265.789 -191.584)"
      />
      <path
        d="M514.252 10.138l1.012-1.007a9.752 9.752 0 0 1-.072 14.909l-1.01-1.016a8.383 8.383 0 0 0 .07-12.886"
        fill="#707070"
        className="active-change"
        transform="translate(-498.885 -8.859)"
      />
      <path
        d="M505.933 15.75l-1.28-1.288.157-.136a8.077 8.077 0 0 0 2.988-6.28 8.013 8.013 0 0 0-.739-3.479 8.811 8.811 0 0 0-2.18-2.836l-.154-.136 1.283-1.278.135.114a9.746 9.746 0 0 1 2.643 3.514 10.057 10.057 0 0 1 .854 4.119 9.844 9.844 0 0 1-3.571 7.573zm-.715-1.268l.74.744a9.463 9.463 0 0 0 3.3-7.164 9.666 9.666 0 0 0-.82-3.96 9.35 9.35 0 0 0-2.405-3.26l-.74.737a8.433 8.433 0 0 1 2.9 6.469 8.414 8.414 0 0 1-2.966 6.433"
        fill="#707070"
        className="active-change"
        transform="translate(-489.641 -.308)"
      />
      <path
        d="M9.926 8.9l1.01 1.018a8.383 8.383 0 0 0 .078 12.882.2.2 0 0 1-.024.041l-.97.982A9.758 9.758 0 0 1 9.926 8.9"
        fill="#707070"
        className="active-change"
        transform="translate(-6.309 -8.635)"
      />
      <path
        d="M3.728 15.45l-.136-.109a9.474 9.474 0 0 1-2.611-3.315A10.168 10.168 0 0 1 0 7.893a9.839 9.839 0 0 1 .8-4.12A10.119 10.119 0 0 1 3.491.119L3.628 0 4.91 1.292l-.154.136a8.081 8.081 0 0 0-2.915 6.315 8 8 0 0 0 .78 3.47 8.8 8.8 0 0 0 2.211 2.808l.12.1-.08.143a.275.275 0 0 1-.052.077l-.835.846zM3.609.531a9.705 9.705 0 0 0-2.455 3.4 9.454 9.454 0 0 0-.765 3.954 9.779 9.779 0 0 0 .941 3.975 9.11 9.11 0 0 0 2.37 3.069l.011-.011.724-.733a8.426 8.426 0 0 1-2.979-6.44 8.419 8.419 0 0 1 2.892-6.47z"
        fill="#707070"
        className="active-change"
      />
      <path
        d="M128.245 93.995l1.034 1.017a4.816 4.816 0 0 0 .082 7.827l-.509.511-.516.526a6.237 6.237 0 0 1-.09-9.882"
        fill="#707070"
        className="active-change"
        transform="translate(-122.112 -91.199)"
      />
      <path
        d="M122.021 95.791l-.136-.108A6.41 6.41 0 0 1 121.8 85.5l.133-.1 1.316 1.294-.183.134a4.623 4.623 0 0 0 .079 7.514l.186.132-.316.317-.355.356-.349.355zm-.12-9.884a6.063 6.063 0 0 0-1.389 7.647 6.451 6.451 0 0 0 1.477 1.716l.044-.045.35-.356.352-.354a5.008 5.008 0 0 1-.081-7.867z"
        fill="#707070"
        className="active-change"
        transform="translate(-115.782 -82.854)"
      />
      <path
        d="M429.817 103.857l-.949-.969c-.02-.021-.037-.045-.059-.071a4.823 4.823 0 0 0 .075-7.825l1.027-1.016a6.223 6.223 0 0 1-.094 9.881"
        fill="#707070"
        className="active-change"
        transform="translate(-416.053 -91.18)"
      />
      <path
        d="M420.757 95.782l-.329-.336-.74-.755a.75.75 0 0 1-.053-.062l-.017-.022-.128-.155.161-.122a5.094 5.094 0 0 0 1.449-1.642 4.717 4.717 0 0 0 .035-4.218 5.075 5.075 0 0 0-1.411-1.659l-.172-.135 1.3-1.289.134.1a6.217 6.217 0 0 1 1.836 2.253 6.452 6.452 0 0 1-1.929 7.936zm-.707-1.274l.653.667.088.09a6.064 6.064 0 0 0 1.681-7.356 5.75 5.75 0 0 0-1.589-2.009l-.752.745a5 5 0 0 1-.081 7.863"
        fill="#707070"
        className="active-change"
        transform="translate(-407.011 -82.848)"
      />
      <path
        d="M275.785 201.154a1.848 1.848 0 1 1 1.853-1.772 1.857 1.857 0 0 1-1.853 1.772"
        fill="#707070"
        className="active-change"
        transform="translate(-265.789 -191.584)"
      />
      <path
        d="M269.478 195.042a2.037 2.037 0 0 1-1.423-.583 1.986 1.986 0 0 1-.617-1.384 2.044 2.044 0 1 1 4.086.006 1.979 1.979 0 0 1-.62 1.38 2.049 2.049 0 0 1-1.426.58m0-3.7a1.628 1.628 0 1 0 .022 0h-.022"
        fill="#707070"
        className="active-change"
        transform="translate(-259.482 -185.279)"
      />
    </g>
  </svg>
);
