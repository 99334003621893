import React from 'react';
import { WithRecorder } from '../../models/recorder/withRecorder';
import { Box } from 'reflexbox';
import { Text } from '../../ui/text/Text';
import styled from 'styled-components';
import { Absolute } from '../../ui/box/Absolute';
import { COLOR } from '../../constants/common';
import { Relative } from '../../ui/box/Relative';
import { RedDot } from '../../ui/redDot/RedDot';
import { Spinner } from '../../ui/spinner/Spinner';
import { Flex } from 'reflexbox';
import { StartRecordingIcon } from '../../ui/icons/StartRecordingIcon';
import { SendRecordingIcon } from '../../ui/icons/SendRecordingIcon';
import { StopRecordingIcon } from '../../ui/icons/StopRecordingIcon';
import { RemakeRecordingIcon } from '../../ui/icons/RemakeRecordingIcon';
import { PauseRecordingIcon } from '../../ui/icons/PauseRecordingIcon';
import { PlayRecordingIcon } from '../../ui/icons/PlayRecordingIcon';
import { Progress, ProgressWrapper } from '../../ui/progress/Progress';

const Wrapper = styled(Relative)`
  background: #0d4167;
  width: 100%;
  border-radius: 8px;
  padding: 14px 16px 50px 16px;
`;

const Button = styled(Absolute)`
  width: 76px;
  height: 76px;
  background: ${COLOR.stone2_12};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  bottom: -38px;
  margin: auto !important;
  left: 0;
  right: 0;
  cursor: pointer;
`;

const ListenWrapper = styled.div`
  padding: 10px 16px;
  display: flex;
  align-items: center;
  background: #6e6e6e;
  border: 1px solid ${COLOR.stone2_12};
  border-radius: 8px;
  margin-top: 12px;
`;

const SpinnerWrapper = styled(Flex)`
  height: 100%;
  & > div {
    min-height: unset;
  }
  svg {
    margin: auto;
  }
`;

export const Recorder = ({interaction, onSend}) => {
  const {
    recordingState,
    playing,
    uploading,
    playPause,
    send,
    remake,
    stopRecording,
    startRecording,
    getRecordingTime,
    getLeftProgress,
  } = WithRecorder({interaction, onSend});

  const renderRecorder = () => {
    switch (recordingState) {
      case 'waiting':
        return;
      case 'recording':
        return renderRecorderInRecordingState();
      case 'finished':
        return renderRecorderInListeningState();
      default:
        return;
    }
  };

  const renderRecorderButton = () => {
    if (uploading) {
      return (
        <SpinnerWrapper alignItems={'center'} justifyContent={'center'}>
          <Spinner/>
        </SpinnerWrapper>
      );
    }
    switch (recordingState) {
      case 'waiting':
        return <Box onClick={startRecording}><StartRecordingIcon/></Box>;
      case 'recording':
        return <Box onClick={stopRecording}><StopRecordingIcon/></Box>;
      case 'finished':
        return <Box onClick={send}><SendRecordingIcon/></Box>;
      default:
        return;
    }
  };

  const renderRecorderInRecordingState = () => {
    return (
      <ListenWrapper>
        <RedDot/>
        <Text size={'h4'} theme={'white'} ml={'18px'} flex={1}>
          REC
        </Text>
        <Text size={'h4'} theme={'white'} ml={'18px'}>
          {getRecordingTime()}
        </Text>
      </ListenWrapper>
    );
  };

  const renderRecorderInListeningState = () => {
    const progress = getLeftProgress();
    return (
      <ListenWrapper>
        <Box onClick={() => playPause()}>
          {playing ? <Box><PauseRecordingIcon/></Box> : <Box><PlayRecordingIcon/></Box>}
        </Box>
        <ProgressWrapper>
          <Progress width={progress}/>
        </ProgressWrapper>
        <Text theme={'white'} size={'h4'} semiBold>
          {getRecordingTime(false)}
        </Text>
        <Box onClick={remake} ml={'15px'}>
          <RemakeRecordingIcon/>
        </Box>
      </ListenWrapper>
    );
  };

  return (
    <Wrapper>
      <Text size={'md'} theme={'white'} semiBold center>
        It’s up to you to talk to let
      </Text>
      <Text size={'md'} theme={'white'} semiBold center>
        Your message !
      </Text>
      {renderRecorder()}
      <Button>{renderRecorderButton()}</Button>
    </Wrapper>
  );
};
