import { encode } from 'querystring';

import { parsePaginationHeaders } from './parseHeaders';
import * as UpChunk from '@mux/upchunk';
import { API_ENDPOINTS } from '../../constants/common';

export const request = async ({
  url,
  query,
  headers = {},
  method = 'GET',
  body,
  formData,
  ...rest
}) => {
  const methodLower = method.toLowerCase();
  if (
    body &&
    (methodLower === 'post' ||
      methodLower === 'put' ||
      methodLower === 'patch' ||
      methodLower === 'delete') &&
    !headers['Content-Type']
  ) {
    headers['Content-Type'] = 'application/json';
  }
  if (body && typeof body === 'object') {
    body = JSON.stringify(body);
  }
  if (formData) {
    body = formData;
  }
  try {
    headers.authorization = `JWT ${window.localStorage.getItem(
      'access_token'
    )}`;
  } catch (e) {
    //  Third party cookies are disabled in browser settings
  }
  const res = await fetch(url + (query ? `?${encode(query)}` : ''), {
    method,
    headers,
    body,
    ...rest,
  });

  const data = await res.json();
  const pagination = parsePaginationHeaders(res.headers);

  return {
    res,
    data,
    pagination,
  };
};

export const initPartialUpload = async extension => {
  const { data } = await request({
    url: API_ENDPOINTS.upload,
    method: 'POST',
    body: { extension },
  });
  return data;
};

export const handlePartialUpload = (file, url, progressFunction = null) => {
  return new Promise((resolve, reject) => {
    const headers = new Headers();
    headers.append('Content-Type', file.type);
    const upload = UpChunk.createUpload({
      endpoint: url,
      file,
      headers,
    });
    upload.on('error', err => {
      console.error('Error uploading video', err.detail);
      reject(err);
    });

    upload.on('progress', progress => {
      if (progress.detail < 80) {
        progressFunction && progressFunction(progress.detail);
      }
    });

    upload.on('success', () => {
      progressFunction && progressFunction(85);
      resolve();
    });
  });
};
