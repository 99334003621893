import React from 'react';
import {COLOR} from "../../constants/common";

export const VoxMIcon = ({color = COLOR.grey1}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="94.994"
    height="29.71"
    viewBox="0 0 94.994 29.71"
  >
    <defs>
      <clipPath id="voxm-icon-clip-path">
        <path fill="none" d="M0 0H94.994V29.71H0z" />
      </clipPath>
    </defs>
    <g clipPath="url(#voxm-icon-clip-path)">
      <path
        fill={color}
        d="M43.875 37a1.275 1.275 0 0 0-1.275 1.275V40a1.275 1.275 0 1 0 2.55 0v-1.725A1.275 1.275 0 0 0 43.875 37"
        transform="translate(-29.02 -25.205)"
      />
      <path
        fill={color}
        d="M57.575 29a1.275 1.275 0 0 0-1.275 1.275v8.7a1.275 1.275 0 1 0 2.55 0v-8.7A1.275 1.275 0 0 0 57.575 29"
        transform="translate(-38.353 -19.756)"
      />
      <path
        fill={color}
        d="M30.175 29a1.275 1.275 0 0 0-1.275 1.275v8.7a1.275 1.275 0 1 0 2.55 0v-8.7A1.275 1.275 0 0 0 30.175 29"
        transform="translate(-19.687 -19.756)"
      />
      <path
        fill={color}
        d="M28.5 20.781a14.855 14.855 0 1 0-8.989 8.224l9.34-3.06a1.275 1.275 0 0 0 .8-1.594zm-2.614.1a1.275 1.275 0 0 0 .064.255l.924 2.773-8.161 2.678a12.3 12.3 0 1 1 7.268-6.439 1.275 1.275 0 0 0-.128.733"
        transform="translate(-.007 -.029)"
      />
      <path
        fill={color}
        d="M121.509 19.9L117.4 32.97l-4.147-13.07H109.3l5.865 17.469.032.064h4.367l5.93-17.533z"
        transform="translate(-74.458 -13.556)"
      />
      <path
        fill={color}
        d="M168.78 36.115a6.641 6.641 0 1 0 0 9.436 6.7 6.7 0 0 0 0-9.436m-2.391 7.077a3.156 3.156 0 0 1-2.327.924 3.124 3.124 0 0 1-2.327-.924 3.472 3.472 0 0 1 0-4.718 3.124 3.124 0 0 1 2.327-.924 3.156 3.156 0 0 1 2.327.924 3.472 3.472 0 0 1 0 4.718"
        transform="translate(-107.225 -23.268)"
      />
      <path
        fill={color}
        d="M211.274 35.4h-3.921l-2.453 3.411-2.424-3.411h-3.985l4.431 6.152-4.622 6.44h3.953l2.646-3.666 2.614 3.666h3.953l-4.59-6.439z"
        transform="translate(-135.087 -24.115)"
      />
      <path
        fill={color}
        d="M257.552 19.9l-4.877 8.065-4.909-8.065H244.1v17.532h3.634V26.5l4.686 7.746.51-.032 4.718-7.746v10.964h3.634V19.9z"
        transform="translate(-166.288 -13.556)"
      />
    </g>
  </svg>
);
