import { useState } from 'react';

export const WithDebounce = () => {
  const [callTimeout, setCallTimeout] = useState();

  const debounce = (target) => {
    clearTimeout(callTimeout);
    setCallTimeout(setTimeout(target, 500));
  };

  return { debounce };
};
