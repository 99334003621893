// Allows passing custom props to styled components. This function will prevent them from reaching the
// DOM and thereby avoids React from complaining.
//
// e.g:
//    import styled from 'styled-components'
//    const el = styled.withCustomProps('div', ['withBlueBackground'])`
//       ${({withBlueBackground}) => withBlueBackground && 'background: blue;'}
//    `
//
import React from 'react'
import omit from 'lodash/omit'
import styled from 'styled-components'

styled.withCustomProps = (tag, customProps, config = {}) => {
  return (...args) => {
    const Elem = styled(tag)``;
    const result = styled(props => <Elem {...omit(props, customProps)} />)
      .withConfig(config)
      .call(styled, ...args);
    return result
  }
};

styled.withCustomProps.withConfig = config => {
  return (...args) => styled.withCustomProps(...args, config)
};
