import React from 'react';

export const Send = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="90"
    height="90.002"
    viewBox="0 0 90 90.002"
  >
    <g transform="translate(-140 -491.425)">
      <path
        d="M24.17 35.44l-6.07-14.1L4 15.26 35.44 4z"
        transform="translate(164 518)"
        fill="#fd9e00"
        stroke="#fd9e00"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="5px"
      />
    </g>
  </svg>
);
