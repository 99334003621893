import { useEffect, useState } from 'react';
import { API_ENDPOINTS } from '../../constants/common';
import { request } from '../utils/request';

export const WithRanking = ({ appUser, channel }) => {
  const [ranking, setRanking] = useState([]);
  const [isLoading, setIsLoading] = useState();

  const fetchRanking = async () => {
    if (!appUser || !appUser.id) {
      return;
    }
    setIsLoading(true);
    const query = {};
    if (channel) {
      query.channel_id = channel.id;
    }
    let { data } = await request({
      url: API_ENDPOINTS.ranking,
      method: 'GET',
      query,
    });

    data = data.map(place => ({
      ...place,
      isMe: place.user_id === appUser.id,
    }));

    setRanking(data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchRanking();
  }, [appUser]);

  return {
    //variables
    isLoading,
    ranking,
    channel,
  };
};
