import React from 'react';
import styled from 'styled-components';
import { Pointer } from '../box/Pointer';
import { COLOR } from '../../constants/common';

const Wrapper = styled(Pointer)`
  width: 40px;
  height: 26px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  background: ${({ background }) => background};
`;

const Indicator = styled.div`
  width: 24px;
  height: 24px;
  background: ${({color}) => color};
  box-shadow: 0 3px 1px 0 rgba(0, 0, 0, 0.06), 0 3px 8px 0 rgba(0, 0, 0, 0.15);
  border: solid 0.5px rgba(0, 0, 0, 0.04);
  border-radius: 50%;
  box-sizing: border-box;
  transition: margin 0.5s ease-in-out;

  margin: ${({ active }) => (active ? '0 0 0 auto' : '0 auto 0 0')};
`;

export const Toggle = ({ active, onChange, color = COLOR.action }) => (
  <Wrapper
    onClick={() => onChange(!active)}
    background={active ? (color + '88') : (COLOR.grey2 + '66')}
  >
    <Indicator active={active} color={color}/>
  </Wrapper>
);
