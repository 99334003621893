import React from 'react';

export const CalendarIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <path
      className="active-change"
      d="M20.778 3h-1.111V1h-2.223v2H8.556V1H6.333v2H5.222a2.109 2.109 0 0 0-2.211 2L3 19a2.119 2.119 0 0 0 2.222 2h15.556A2.126 2.126 0 0 0 23 19V5a2.126 2.126 0 0 0-2.222-2zm0 16H5.222V8h15.556zM7.444 10H13v5H7.444z"
      transform="translate(-3 -1)"
      fill="#707070"
    />
  </svg>
);
