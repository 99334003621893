import { useGlobal } from '../../../models/global/withGlobal';

export const WithMenu = () => {
  const { sidebarHidden, setVariable } = useGlobal();

  const toggleSidebar = () => setVariable('sidebarHidden', !sidebarHidden);

  return {
    toggleSidebar,
  };
};
