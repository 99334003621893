import React from 'react';

export const RecordWrapper = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="102"
    height="102"
    viewBox="0 0 102 102"
  >
    <defs>
      <filter
        id="filter34254325"
        x="0"
        y="0"
        width="102"
        height="102"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" result="blur" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g transform="scale(1.2)">
      <g transform="translate(4 4)">
        <path
          id="background"
          d="M71.671,53.776,75.33,64.89,49.423,73.411A37.645,37.645,0,1,1,71.671,53.776"
          transform="translate(-0.001 -0.001)"
          fill="#7f0e7e"
        />
      </g>
      <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#filter34254325)">
        <path
          id="border"
          d="M41.941,83.995A42.008,42.008,0,1,1,80.448,58.818l3.4,10.321a3.107,3.107,0,0,1-1.98,3.922l-26.752,8.81A42.206,42.206,0,0,1,41.941,83.995ZM41.96,6.217a35.41,35.41,0,0,0-11.152,1.8A35.769,35.769,0,0,0,53.17,75.969l23.8-7.834-2.783-8.465a3.525,3.525,0,0,1-.163-.7,3.041,3.041,0,0,1,.316-1.722A35.773,35.773,0,0,0,41.96,6.217Z"
          transform="translate(9 6.01)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);
