import React from 'react';
import { COLOR } from '../../constants/common';

export const ColorPickerIcon = ({color = 'none'}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21"
  >
    <path
      fill={color}
      stroke={color ? 'none' : COLOR.waterBlue}
      d="M74 64a10 10 0 0 0 0 20 1.664 1.664 0 0 0 1.667-1.667 1.629 1.629 0 0 0-.433-1.117 1.669 1.669 0 0 1 1.25-2.772h1.961A5.558 5.558 0 0 0 84 72.889C84 67.978 79.522 64 74 64zm-6.111 10a1.667 1.667 0 1 1 1.667-1.667A1.664 1.664 0 0 1 67.889 74zm3.333-4.444a1.667 1.667 0 1 1 1.667-1.667 1.664 1.664 0 0 1-1.667 1.667zm5.556 0a1.667 1.667 0 1 1 1.667-1.667 1.664 1.664 0 0 1-1.667 1.667zM80.111 74a1.667 1.667 0 1 1 1.667-1.667A1.664 1.664 0 0 1 80.111 74z"
      transform="translate(-63.5 -63.5)"
    />
  </svg>
);
