import React from 'react';
import styled from 'styled-components';
import { Box, Flex } from 'reflexbox';
import { Text } from '../../ui/text/Text';
import { Pointer } from '../../ui/box/Pointer';
import { ScreenWrapper } from '../../ui/widget/ScreenWrapper';
import { TopNav } from '../../ui/widget/TopNav';
import { WithMySpace } from '../../models/widget/withMySpace';
import moment from 'moment';
import { todayString } from '../../utils/utils';
import { WithPlayer } from '../../models/player/withPlayer';
import { Spinner } from '../../ui/spinner/Spinner';
import { UserRankingAvatar } from './components/UserRankingAvatar';
import { Message } from './components/Message';
import { ArrowBackIcon } from '../../ui/icons/ArrowBackIcon';
import { WidgetButton } from '../../ui/widget/WidgetButton';
import { Absolute } from '../../ui/box/Absolute';
import { Avatar } from '../../ui/widget/Avatar';

const MessagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  margin-top: 20px;
  align-items: center;
  padding: 0 0 25px;
  width: 100%;
  z-index: 3000;
`;

const DateLine = styled.div`
  background: #a29e9e;
  height: 1px;
  width: 67px;
`;

const ReservedSpace = styled.div`
  background: rgba(28, 28, 28, 0.65);
  flex: 1;
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 45px 0;
  z-index: 3000;
`;

export const MySpaceScreen = ({
  appUser,
  channel,
  onClose,
  goToProfile,
  goToLevels,
  goToRanking,
  goToLogin,
  goToRegister,
}) => {
  const {
    messages,
    isLoading,
    manageOpenMessages,
    openMessages,
    userPoints,
  } = WithMySpace({ appUser, channel });

  const { listen, listening, stop } = WithPlayer();
  return (
    <ScreenWrapper>
      <TopNav>
        <Absolute left={'19px'} top={'15px'}>
          <Pointer onClick={onClose}>
            <ArrowBackIcon />
          </Pointer>
        </Absolute>
      </TopNav>

      <Flex mt={'26px'} alignItems={'center'} flexDirection={'column'}>
        <Avatar
          user={appUser ? { ...appUser, points: userPoints } : null}
          big
          onClick={() => appUser && !appUser.is_anonymous && goToProfile()}
          showPoints={channel && channel.gamification}
        />
        {channel && channel.gamification && (
          <UserRankingAvatar
            userPoints={userPoints}
            onBadgeClick={goToLevels}
            onRankingClick={goToRanking}
          />
        )}
      </Flex>

      {appUser && !appUser.is_anonymous ? (
        <MessagesWrapper>
          {isLoading ? (
            <Spinner color={'white'} />
          ) : (
            messages &&
            Object.keys(messages)
              .sort((a, b) => (a > b ? -1 : 1))
              .map(date => {
                const messagePerDate = messages[date];
                let dateTitle = moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
                if (dateTitle === todayString) {
                  dateTitle = 'Today';
                }
                let renderedMessages = messagePerDate.sort(
                  (a, b) => b.created_at - a.created_at
                );
                if (!renderedMessages || renderedMessages.length === 0) {
                  return null;
                }
                renderedMessages = renderedMessages.map(message => (
                  <Message
                    key={`message-${message.id}`}
                    message={message}
                    open={openMessages[message.id]}
                    onClick={manageOpenMessages}
                    listen={listen}
                    listening={listening}
                    stop={stop}
                    channel={channel}
                  />
                ));
                return (
                  <Box key={date} width={'100%'}>
                    <Flex
                      alignItems={'center'}
                      justifyContent={'center'}
                      mt={'8px'}
                      mb={'18px'}
                    >
                      <DateLine />
                      <Text
                        size={'md'}
                        color={'#d2d2d2'}
                        center
                        width={'74px'}
                        upper
                      >
                        {dateTitle}
                      </Text>
                      <DateLine />
                    </Flex>
                    {renderedMessages}
                  </Box>
                );
              })
          )}
        </MessagesWrapper>
      ) : (
        <>
          <Flex
            alignItems={'center'}
            justifyContent={'center'}
            mt={'8px'}
            mb={'11px'}
          >
            <DateLine />
            <Text size={'md'} color={'#d2d2d2'} center width={'74px'} upper>
              Today
            </Text>
            <DateLine />
          </Flex>
          <ReservedSpace>
            <Text upper bold size={'h4'} theme={'white'}>
              Reserved space
            </Text>
            <Text color={'#d2d2d2'} size={'md'} center width={'47%'}>
              To find your participations, responses from your media, accumulate
              points…
            </Text>
            <WidgetButton onClick={goToRegister} mt={'100px'}>
              Register
            </WidgetButton>
            <Text
              pointer
              upper
              color={'#a29e9e'}
              size={'md'}
              bold
              onClick={goToLogin}
              mt={'15px'}
            >
              Login
            </Text>
          </ReservedSpace>
        </>
      )}
    </ScreenWrapper>
  );
};
