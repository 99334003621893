import React from 'react';
import { COLOR } from '../../constants/common';

export const VideoInteractionIcon = ({ color = COLOR.grey1 }) => (
  <svg
    enableBackground="new 0 0 24 24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <clipPath id="video-interaction">
      <path d="m0 0h24v24h-24z" />
    </clipPath>
    <path
      fill={color}
      clipPath="url(#video-interaction)"
      d="m19.6 7.1c-.2-.1-.5-.1-.8 0l-2.8 1.7c0-1.6-1.2-2.8-2.8-2.8h-6.4c-1.6 0-2.8 1.2-2.8 2.8v6.5c0 1.5 1.2 2.7 2.8 2.7h6.5c1.5 0 2.8-1.2 2.8-2.8v-.1l2.9 1.7c.2.1.5.1.8 0 .2-.1.4-.4.4-.7v-8.3c-.2-.3-.3-.6-.6-.7zm-6.4 9.4h-6.4c-.7 0-1.2-.6-1.2-1.2v-6.5c0-.7.6-1.2 1.2-1.2h6.5c.7 0 1.2.6 1.2 1.2v6.5c0 .6-.6 1.2-1.3 1.2zm2.8-5.9 2.5-1.5v5.9l-2.5-1.6z"
    />
  </svg>
);
