export const TRANSLATIONS = {
  fr: {
    login: 'Connectez-vous',
    'forgotten password ?': 'Mot de passe oubli\u00e9 ?',
    home: 'ACCUEIL',
    messages: 'Messages',
    participants: 'Participants',
    interactions: 'INTERACTIONS',
    'hello !': 'Bonjour !',
    'broadcast interactions': 'Programme des interactions',
    'see the schedule of the day': 'Voir la programmation du jour',
    'last five messages not read': '5 derniers messages non lus',
    live: 'En ce moment',
    'at the moment': 'En ce moment',
    statistics: 'Statistiques',
    received: 'Re\u00e7us',
    'thanks from the media': 'Remerciement du m\u00e9dia',
    liked: 'Aim\u00e9s',
    'quoted on the air': 'Cit\u00e9s \u00e0 l\u2019antenne',
    'broadcast on the air': 'Diffus\u00e9s \u00e0 l\u2019antenne',
    'points of loyalty given': 'Points de fid\u00e9lisation donn\u00e9s',
    'vote & survey': 'Vote et sondages',
    game: 'Jeux',
    regsitered: 'Inscrits',
    winners: 'Gagnants',
    don: 'Don',
    collected: 'Collect\u00e9s',
    all: 'Tous',
    registered: 'Inscrits',
    'not registered': 'Non inscrit',
    testimonies: 'T\u00e9moignages',
    'tirage au sort': 'Draw',
    mailbox: 'Boite de r\u00e9ception',
    'of the day': 'Du jour',
    'not read': 'Non lus',
    'good answers': 'Bonnes r\u00e9ponses',
    winning: 'Gagnants',
    search: 'Rechercher',
    'put in unread': 'Marquer comme non lu',
    'set to read': 'Marquer comme lu',
    'put back in mailbox': 'D\u00e9sarchiver',
    's\u00e9lectionn\u00e9 par tirage au sort du 12/02/2020 \u00e0 08h00':
      'Selected by lot from 02/12/2020 at 08:00 am',
    'winner by jury': 'Gagnant par jury \u2026',
    'not winning': 'Non gagnant',
    'gain of 3,500 euros': 'Gain de 3500  euros',
    'enter an internal comment here': 'Saisissez ici un commentaire interne',
    'message quoted': 'Message cit\u00e9',
    'broadcast message': 'Message Diffus\u00e9',
    'voice message': 'Message vocal',
    'written message': 'Message \u00e9crit',
    monday: 'Lundi',
    tuesday: 'Mardi',
    wednesday: 'Mercredi',
    thursday: 'Jeudi',
    friday: 'Vendredi',
    saturday: 'Samedi',
    sunday: 'Dimanche',
    'last 5 messages for the participant': '5 derniers messages du participant',
    trash: 'Corbeille',
    cancel: 'Annuler',
    save: 'Enregistrer',
    title: 'Titre',
    status: 'Statut',
    'type of interaction': 'Type d\u2019interaction',
    'choice game': 'Jeux \u00e0 choix muliples',
    'registration game': 'Jeux \u00e0 inscriptions',
    'voice game': 'Jeux vocal',
    'text (limited to 160 characters)': 'Texte (160 caract\u00e8res maximum)',
    'show associated': 'Programme associ\u00e9',
    payment: 'Paiement',
    'payment method': 'Mode de paiement',
    'paid part': 'Participation payante',
    amount: 'Montant',
    'cost of the service': 'Co\u00fbt du service',
    currency: 'Devise',
    'principal widget schedule': 'Programmation du widget principal',
    'date of start': 'Date de d\u00e9marrage',
    'date of end': 'Date de fin',
    'start time': 'Heure de d\u00e9marrage',
    'end time': 'Heure de fin',
    days: 'Jours',
    'text (limit to 160 characters)': 'Texte (160 caract\u00e8res max)',
    'specific background': 'Arri\u00e8re-plan sp\u00e9cifique',
    'dedicated widget': 'Widget d\u00e9di\u00e9',
    delete: 'Supprimer',
    'copy to clipboard': 'Copier dans le presse-papier',
    display: 'AFFICHAGE',
    'see home page': 'Voir page d\u2019accueil',
    'account information': 'Information du compte',
    'principal color': 'Couleur principale',
    'background color': 'Couleur de fond',
    'secret code': 'Code secret',
    stream: 'Stream',
    logo: 'Logo',
    background: 'Arri\u00e8re-plan',
    programs: 'Programmes',
    'scheduled for today': 'Grille du jour',
    published: 'Publi\u00e9',
    draft: 'Brouillon',
    archived: 'Archiv\u00e9',
    'program name': 'Nom du programme',
    upload: 'Importer',
    user: 'Utilisateur',
    active: 'Actif',
    dissbled: 'D\u00e9sactiv\u00e9',
    email: 'E-mail',
    password: 'Mot de passe',
    pseudo: 'Pseudo',
    'first name': 'Pr\u00e9nom',
    'last name': 'Nom',
    language: 'Langue',
    picture: 'Image',
    'notification emails': 'E-mails de notification',
    widgets: 'Widgets',
    'widget name': 'Nom du widget',
    location: 'Localisation',
    'desktop parameters': 'Param\u00e8tres du bureau',
    'right offset': 'D\u00e9calage droit',
    'bottom offset': 'D\u00e9calage inf\u00e9rieur',
    'z-index': 'Index Z (profondeur)',
    'mobile parameters': 'Param\u00e8tres mobiles',
    'code to insert': 'Code \u00e0 ins\u00e9rer',
    link: 'Lien',
    'reset password': 'R\u00e9initialiser le mot de passe',
    'log out': 'Se d\u00e9connecter',
    register: 'Inscrivez-vous',
    'send without registering': 'ENVOYER SANS VOUS INSCRIRE',
    'just choose a pseudonym': 'Choisissez simplement un pseudonyme',
    'in the event that your message is broadcast on the air':
      'Dans le cas o\u00f9 votre message serait diffus\u00e9 \u00e0 l\u2019antenne',
    validate: 'VALIDER',
    'to register': 'Pour vous inscrire',
    'enter your email': 'Entrez votre e-mail',
    'enter your password': 'Saisissez votre mot de passe',
    'password must be at least 8 characters long':
      'Le mot de passe doit comporter au moins 8 caract\u00e8res',
    'choose a pseudonym': 'Choisissez un pseudonyme',
    'by validating, you accept\nthe terms of use of the service\n\u00a0 and the privacy policy':
      'En validant, vous acceptez \nles Conditions  G\u00e9n\u00e9rales d\u2019utilisation\n et la Politique de Confidentialit\u00e9',
    'enter your e-mail': 'Entrez votre e-mail',
    'forgot your password ?': 'Mot de passe oublie ?',
    reconnect: 'Pour vous connecter',
    'enter your new password': 'Entrez votre nouveau mot de passe',
    'we sent you an email to change your password, just follow the instructions':
      'nous vous avons envoy\u00e9 un e-mail pour changer de mot de passe, suivez simplement les instructions',
    'send email again ?': 'RENVOYER l\u2019E-MAIL',
    'change voxm password': 'Changer de mot de passe VoxM',
    'hello pseudo,\n\nyou asked to change your password':
      'Hello Pseudo,\n\nVous avez demand\u00e9 \u00e0 r\u00e9initialiser le mot de passe.',
    'click here': 'Cliquez ici',
    'if you did not ask to change your password just ignore this email':
      'Si vous n\u2019avez pas cherch\u00e9 \u00e0 r\u00e9initialiser votre mot de passe, vous pouvez ignorer cet email.',
    kinds: 'Bien \u00e0 vous',
    'general conditions of use':
      'Conditions  G\u00e9n\u00e9rales d\u2019utilisation',
    'privacy policy': 'Politique de Confidentialit\u00e9',
    'new password': 'Nouveau mot de passe',
    'confirm your new password': 'Confirmez le nouveau mot de passe',
    'that\u2019s it': 'Le tour est jou\u00e9',
    'your password has been reiniatized and you are ready for voxer!':
      'Votre mot de passe a bien \u00e9t\u00e9 r\u00e9iniatilis\u00e9 et vous \u00eates par\u00e9 pour Voxer !',
    'how do you want to participate ?': 'Comment voulez-vous participer?',
    'to know if you have won, receive answers or discover the ranking, you must log in\u2026':
      'Pour savoir si vous avez gagn\u00e9, recevoir des r\u00e9ponses ou d\u00e9couvrir le classement, vous devez vous connecter\u2026',
    'sorry, we can\u2019t find it.': 'D\u00e9sol\u00e9, nous ne trouvons pas.',
    'do you want some help ?': "Voulez-vous de l'aide?",
    'message sent': 'Message envoy\u00e9',
    'you received a message 12/12/2019':
      'Vous avez re\u00e7u un message le 12/12/2019',
    'media name': 'Nom du m\u00e9dia',
    'reserved space': 'Espace R\u00e9serv\u00e9',
    'to find your participations,\nresponses from your media,\naccumulate points\u2026':
      'Pour retrouver vos participations, \nles r\u00e9ponses de votre m\u00e9dia,\ncumuler des points\u2026',
    today: 'Aujourd\u2019hui',
    'no participations': 'Aucune participation',
    'participation sent on': 'Participation envoy\u00e9e le',
    'participation sent': 'Participation envoy\u00e9e',
    'answer received on': 'R\u00e9ponse re\u00e7ue le',
    points: 'Points',
    ranking: 'CLASSEMENT',
    'you have won': 'Vous avez gagn\u00e9',
    'message liked': 'Message aim\u00e9',
    'message broadcasted': 'Message Diffus\u00e9',
    levels: 'Niveaux',
    hero: 'HEROS',
    expert: 'EXPERT',
    pro: 'PRO',
    confirmed: 'CONFIRME',
    regular: 'REGULIER',
    amateur: 'AMATEUR',
    beginner: 'DEBUTANT',
    'modify avatar': 'Modifier l\u2019avatar',
    pseudonym: 'Pseudonyme',
    'city / region': 'Ville / R\u00e9gion',
    name: 'Nom',
    phone: 'T\u00e9l\u00e9phone',
    birthday: 'Date de naissance',
    'change password': 'Changer de mot de passe',
    'terms of use': "conditions d'utilisation",
    users: 'Utilisateurs',
    program: 'Programme',
    'scheduled interaction for today': 'Programmation du jour',
    'without answer': 'Sans r\u00e9ponse',
    'quoted message': 'Messages cit\u00e9s',
    winner: 'Gagnant',
    'latest messages': 'Derniers messages',
    'last message': 'Dernier message',
    'registration required': 'Inscription obligatoire',
    'list of choices': 'Liste des choix',
    'subscription level': "NIVEAU D'ABONNEMENT",
    'with transparency in the background': 'TRANSPARENT EN ARRI\u00c8RE-PLAN',
    disabled: 'D\u00e9sactiv\u00e9',
    'what is your choice?': 'Quel est votre choix ?',
    'confirm your choice': 'Confirmez votre choix',
    'sending...': 'Envoi en cours...',
    'registered on': 'Enregistr\u00e9 le',
    'what type of interaction do you want?':
      "Quel type d'interaction voulez-vous ?",
    'vote / survey': 'Vote / Sondage',
    'multiple choice game': 'Jeu \u00e0 choix multiples',
    'sms game': 'Jeu SMS',
    'please register or login to allow us to respond or contact you':
      'Veuillez vous inscrire ou vous connecter pour nous permettre de vous r\u00e9pondre ou de vous contacter',
    'you are almost there !': 'Vous y \u00eates presque !',
    'you want to react ?': 'Vous voulez r\u00e9agir ?',
    '2. click on "open in ..." your usual browser':
      '2. Cliquez sur \u00ab\u00a0Ouvrir dans \u2026\u00a0\u00bb votre navigateur habituel',
    '1. click on': '1. Cliquez sur',
    'interactions with this radio are not currently available':
      'Les interactions avec cette radio ne sont pas disponibles  actuellement',
    'ready to go!': "C'est parti !",
    'find your media': 'Trouvez votre m\u00e9dia',
    'last information': 'DERNIERE ETAPE',
    'enter your phone number':
      'Entrer votre num\u00e9ro de t\u00e9l\u00e9phone',
    'by validating, you accept the': 'En validant, vous acceptez toutes les',
    'search results': 'R\u00e9sultats de votre recherche',
    'your favourites': 'Vos favoris',
    'it\u2019s up to you to talk to let': 'A vous de parler pour laisser',
    'your message !': 'Votre message !',
    'we prepare the sms\u2026': 'Nous pr\u00e9parons votre SMS...',
    'confirm your participation please': 'Confirmez votre participation SVP',
    'this interaction requires that you are registered and logged in to participate ;-)':
      'Cette interaction n\u00e9cessite que vous soyez inscrit(e) et connect\u00e9(e) pour participer ;-)',
    'you interact regularly and want to find all your messages and many other advantages.':
      'Vous interagissez r\u00e9guli\u00e8rement et vous voulez retrouver tous vos messages et pleins d\u2019autres avantages.',
    'you must log in or register to access your personal space.':
      'Vous devez vous connecter ou vous inscrire pour acc\u00e9der \u00e0 votre espace personnel.',
    'daily start time': 'Heure de d\u00e9but quotidienne',
    'daily end time': 'Heure de fin quotidienne',
    indefinite: 'Ind\u00e9finie',
    participate: 'Participer',
    "start recording when you're ready":
      'D\u00e9marrez l\u2019enregistrement quand vous \u00eates pr\u00eat(e)',
    record: 'Enregistrer',
    stop: 'Arr\u00eatez',
    play: 'Lire',
    restart: 'Recommencer',
    send: 'Envoyer',
    share: 'Partager',
    'thank you for your participation': 'Merci de votre participation',
    'see you soon !': 'A bient\u00f4t !',
    'information emails for all interactions':
      "Emails d'information pour toutes les interactions",
    'information emails for this interaction':
      "Emails d'information pour cette interaction",
    'i have just reacted to': 'Je viens de r\u00e9agir \u00e0',
    'here is my message :': 'Voici mon message :',
    'registration sent': 'Participation envoy\u00e9e',
    'exportable link for social networks':
      'LIEN EXPORTABLE POUR R\u00c9SEAUX SOCIAUX',
    'copied to clipboard': 'Copi\u00e9 dans le presse-papier',
    'information emails': "E-mails d'alerte",
    yes: 'Oui',
    no: 'Non',
    'message posted on': 'MESSAGE DEPOSE SUR',
    'i want to participate too': 'MOI AUSSI JE VEUX PARTICIPER',
    'help center': "CENTRE d'AIDE",
    rubric: 'RUBRIQUE',
    order: 'ORDRE',
    author: 'AUTEUR(E)',
    'short description': 'DESCRIPTIF SUCCINCT',
    'video file': 'FICHIER VIDEO',
    'uploading the video. please do not leave this page...':
      'Veuillez ne pas quitter cette page pendant le t\u00e9l\u00e9chargement de la vid\u00e9o...',
    'punctual interactions': 'Interactions PONCTUELLES',
    'permanent interactions': 'Interactions permanentes',
    'activate broadcast in voxm widgets':
      'ACTIVER LA DIFFUSION DANS LES WIDGETS VOXM',
    'sorry this interaction is no longer available':
      "D\u00e9sol\u00e9 cette interaction n'est plus disponible",
    'break point': 'Taille de rupture',
    'for example \u00ab 1000 \u00bb pixels':
      'Par exemple \u00ab 1000 \u00bb pixels',
    'for example \u00ab 0,6 \u00bb': 'Par exemple \u00ab 0,6 \u00bb',
    'ratio for the size of opened widget below break point':
      'Rapport de reduction',
    'see the schedule of the day for voxm widget':
      'Voir la programmation du jour du widget VoxM',
    'copy the permalink': 'Copier le permalien',
    'of the day for widget': 'Du jour pour le widget',
    permanents: 'Permanentes',
    punctuals: 'Ponctuelles',
    'interaction permanent': 'Interaction permanente',
    'permanent link': 'Lien permanent',
    available: 'Disponible',
    'do you want to save the changes?':
      'Voulez-vous enregistrer les modifications?',
    'create your account': 'Cr\u00e9ez votre compte',
    next: 'Suivant',
    'you already have an account? identify':
      'Vous avez d\u00e9j\u00e0 un compte ? Connectez-vous',
    'consult your mailbox to complete the registration.':
      'Consultez votre bo\u00eete e-mail pour terminer l\u2019inscriription',
    'we have sent you a registration link to the address':
      'Nous vous avons envoy\u00e9 un lien d\u2019inscription \u00e0 l\u2019addresse',
    'resend registration link': 'Renvoyer le lien d\u2019inscription',
    'change email address': 'Changer d\u2019adresse e-mail',
    'please note that this email is already in use, you cannot create a new account with':
      'Attention cet e-mail est d\u00e9j\u00e0 utilis\u00e9, vous ne pouvez pas cr\u00e9er un nouveau compte avec',
    'sorry, safari does not allow the use of the camera. you must change browser or import your video using the small square at the bottom left of the screen':
      "D\u00e9sol\u00e9, SAFARI ne permet pas l'utilisation de la cam\u00e9ra. Vous devez changer de navigateur ou importer votre vid\u00e9o en utilisant le petit carr\u00e9 en bas \u00e0 gauche de l'\u00e9cran",
    'create an interaction': 'Cr\u00e9er une interaction',
    'record again': 'RECOMMENCER',
    'enable sharing': 'ACTIVER LE PARTAGE',
    'enable gamification': 'ACTIVER LA GAMIFICATION',
    'enable \u00ab\u00a0my space\u00a0\u00bb':
      'Activer \u00ab\u00a0MON ESPACE"',
    'authentification mode': "Mode d'authentification",
    none: 'Aucun',
    'name and town': 'Nom et ville',
    'so that your message can be quoted or broadcasted, tell us more about yourself':
      'Pour que votre message puisse \u00eatre cit\u00e9 ou diffus\u00e9, parlez-nous de vous',
    'enter your first name or your nickname':
      'Entrez votre pr\u00e9nom ou votre pseudo',
    'enter the name of your city': 'Entrez le nom de votre ville',
    'required field': 'Champ obligatoire',
    'monetize your audience': 'Mon\u00e9tisez votre audience',
    'as a voxm user, you will be able to generate income through your interactions, have access to dedicated support and more\u2026':
      'En tant qu\u2019utilisateur VoxM, vous pourrez\ng\u00e9n\u00e9rer des revenus gr\u00e2ce \u00e0 vos interactions,\navoir acc\u00e8s \u00e0 l\u2019assistance d\u00e9di\u00e9e et plus encore\u2026',
    'to participate at the voxm monetization program, your account must have minimum accumulated 4,000 messages in the lasts 12 months of 500 different participants. your account will also be subject to verify that it meets voxm\u2019s monetization rules.':
      'Pour participer au programme de mon\u00e9tisation VoxM,\nvotre compte doit avoir accumul\u00e9 au moins 4 000 messages au cours des 12 derniers mois de 500 participants diff\u00e9rents.\nVotre compte sera \u00e9galement soumis \u00e0 la v\u00e9rification de sa conformit\u00e9 aux r\u00e8gles de mon\u00e9tisation de VoxM.',
    'i want to be contacted': 'Je veux \u00eatre contact\u00e9',
    'thank you for your request for information\n on the voxm monetization program.\n\none of our advisers will take care of you\nas soon as possible.\n\nsee you soon':
      "Merci pour votre demande d'information\nsur le programme de mon\u00e9tisation VoxM.\n\nUn de nos conseillers prendra soin de vous\nd\u00e8s que possible.\n\n\u00c0 bient\u00f4t",
    close: 'Fermer',
    from: 'de',
    'maximum recording time (seconds)':
      "Dur\u00e9e d'enregistrement maximale (secondes)",
    'maximum recording time': "Dur\u00e9e d'enregistrement maximale",
    seconds: 'secondes',
    'published quantities': 'Quantit\u00e9s publi\u00e9es',
    news: 'Actualit\u00e9s',
    'the module for generating income with voxm is not activated.':
      'Le module permettant de g\u00e9n\u00e9rer des revenus avec VoxM n\u2019est pas activ\u00e9.',
    discover: 'D\u00e9couvrir',
    'published on the home page': "Publi\u00e9 sur la page d'accueil",
    'global report': 'RAPPORT GLOBAL',
    overview: 'APERCU',
    answered: 'R\u00e9pondus',
    quoted: 'Cit\u00e9s',
    broadcasted: 'Diffus\u00e9',
    'loyalty points': 'Point de fid\u00e9lit\u00e9',
    'enable cloud transfer': 'ACTIVER LE TRANSFERT CLOUD',
    type: 'TYPE',
    'server address': 'ADRESSE DU SERVEUR',
    'name of space': "Nom de l'espace",
    'diffusion spaces': 'Zones de diffusion',
    'an interaction is already diffused in the space':
      'Une interaction est d\u00e9j\u00e0 diffus\u00e9e dans l\u2019espace',
    'confirm to replace it ?':
      'Voulez-vous archiver l\u2019ancienne interaction et mettre celle-ci \u00e0 la place ?',
    'diffusion space': 'Zone de diffusion',
    username: 'Identifiant',
    'for now, the results are :': 'Pour le moment, les r\u00e9sultats sont :',
    'your participation has already been registered for that vote':
      'Votre participation a d\u00e9j\u00e0 \u00e9t\u00e9 prise en compte pour ce vote',
    'limit 1 vote per participant': 'Limiter \u00e0 1 vote par participant',
    'see the results': 'Voir les r\u00e9sultats',
    'specific diffusion': 'Diffusion specifique',
    'text to invite testimonial': 'Texte pour inviter a temoigner',
    'vocal testimonial demanded': 'Demander des temoignages vocaux',
  },
  pt: {
    login: 'Iniciar sess\u00e3o',
    'forgotten password ?': 'Esqueci-me da palavra chave',
    home: 'P\u00e1gina Inicial',
    messages: 'Mensagens',
    participants: 'Participantes',
    interactions: 'Intera\u00e7\u00f5es',
    'hello !': 'Ol\u00e1!',
    'broadcast interactions': 'Programa de intera\u00e7\u00f5es',
    'see the schedule of the day': 'Ver a programa\u00e7\u00e3o do dia',
    'last five messages not read': '\u00daltimas 5 mensagens n\u00e3o lidas',
    live: 'Em directo',
    'at the moment': 'Neste momento',
    statistics: 'Estat\u00edsticas',
    received: 'Recebidos',
    'thanks from the media': 'Agradecimento do media',
    liked: 'Gostos',
    'quoted on the air': 'Citados em antena',
    'broadcast on the air': 'Transmitidos em antena',
    'points of loyalty given':
      'Pontos de fideliza\u00e7\u00e3o atribu\u00eddos',
    'vote & survey': 'Votos e Sondagens',
    game: 'Jogos',
    regsitered: 'Inscritos',
    winners: 'Vencedores',
    don: 'Doa\u00e7\u00e3o',
    collected: 'Coletados',
    all: 'Todos',
    registered: 'Inscritos',
    'not registered': 'N\u00e3o inscritos',
    testimonies: 'Testemunhos',
    'tirage au sort': 'Sorteio',
    mailbox: 'Caixa eletr\u00f3nica',
    'of the day': 'Do dia',
    'not read': 'N\u00e3o lidos',
    'good answers': 'Boas respostas',
    winning: 'Vencedores',
    search: 'Procurar',
    'put in unread': 'Marcar como n\u00e3o lido',
    'set to read': 'Marcar como lido',
    'put back in mailbox': 'Desarquivar',
    's\u00e9lectionn\u00e9 par tirage au sort du 12/02/2020 \u00e0 08h00':
      'Selecionado por sorteio no dia 02/12/2020 \u00e0s 08:00h.',
    'winner by jury': 'Vencedor selecionado pelo j\u00fari',
    'not winning': 'N\u00e3o vencedor',
    'gain of 3,500 euros': 'Ganho de 3.500 euros',
    'enter an internal comment here': 'Deixe aqui um coment\u00e1rio interno',
    'message quoted': 'Mensagem citada',
    'broadcast message': 'Mensagem emitida',
    'voice message': 'Mensagem de voz',
    'written message': 'Mensagem escrita',
    monday: 'Segunda-feira',
    tuesday: 'Ter\u00e7a-feira',
    wednesday: 'Quarta-feira',
    thursday: 'Quinta-feira',
    friday: 'Sexta-feira',
    saturday: 'S\u00e1bado',
    sunday: 'Domingo',
    'last 5 messages for the participant':
      '5 \u00faltimas mensagens do participante',
    trash: 'Lixo',
    cancel: 'Cancelar',
    save: 'Guardar',
    title: 'T\u00edtulo',
    status: 'Estado',
    'type of interaction': 'Tipo de intera\u00e7\u00e3o',
    'choice game': 'Jogo de m\u00faltipla escolha',
    'registration game': 'Inscri\u00e7\u00e3o no jogo',
    'voice game': 'Jogo vocal',
    'text (limited to 160 characters)':
      'Texto (160 caract\u00e9res no m\u00e1ximo)',
    'show associated': 'Programa associado',
    payment: 'Pagamento',
    'payment method': 'Modo de pagamento',
    'paid part': 'Participa\u00e7\u00e3o paga',
    amount: 'Montante',
    'cost of the service': 'Custo do servi\u00e7o',
    currency: 'Moeda',
    'principal widget schedule':
      'Programa\u00e7\u00e3o do dispositivo principal',
    'date of start': 'Data de come\u00e7o',
    'date of end': 'Data de fim',
    'start time': 'Hora de come\u00e7o',
    'end time': 'Hora do t\u00e9rmino',
    days: 'Dias',
    'text (limit to 160 characters)':
      'Texto (160 caract\u00e9res no m\u00e1ximo)',
    'specific background': 'ANTECEDENTES ESPEC\u00cdFICOS',
    'dedicated widget': 'Dispositivo dedicado',
    delete: 'Apagar',
    'copy to clipboard': 'Copiar  para ambiente de trabalho',
    display: 'Exibi\u00e7\u00e3o',
    'see home page': 'Ver p\u00e1gina inicial',
    'account information': 'Informa\u00e7\u00e3o da conta',
    'principal color': 'Cor principal',
    'background color': 'Cor de fundo',
    'secret code': 'C\u00f3digo secreto',
    stream: 'Corrente',
    logo: 'Logotipo',
    background: 'Fundo',
    programs: 'Programas',
    'scheduled for today': 'Grelha do dia',
    published: 'Publicado',
    draft: 'Rascunho',
    archived: 'Arquivado',
    'program name': 'Nome do programa',
    upload: 'Enviar',
    user: 'Utilisador',
    active: 'Activo',
    dissbled: 'Desativado',
    email: 'Email',
    password: 'Palavra-passe',
    pseudo: 'Pseud\u00f3nimo',
    'first name': 'Nome',
    'last name': 'Apelido',
    language: 'L\u00edngua / Idioma',
    picture: 'Imagen',
    'notification emails': 'Notifica\u00e7\u00e3o de email',
    widgets: 'Dispositivos/ferramentas',
    'widget name': 'Nome do dispositivo',
    location: 'Localiza\u00e7\u00e3o',
    'desktop parameters': 'Defini\u00e7\u00f5es da \u00e1rea de trabalho',
    'right offset': 'Deslocar para a direita',
    'bottom offset': 'Deslocar para baixo',
    'z-index': 'Index Z (profundidade)',
    'mobile parameters': 'Par\u00e2metros mov\u00e9is',
    'code to insert': 'C\u00f3digo a inserir',
    link: 'link',
    'reset password': 'Atualizar palavra passe',
    'log out': 'Terminar sess\u00e3o',
    register: 'Inscreva-se',
    'send without registering': 'Enviar sem se inscrever',
    'just choose a pseudonym': 'Escolha apenas um pseud\u00f3nimo',
    'in the event that your message is broadcast on the air':
      'Caso a sua mensagem seja divulgada em antena',
    validate: 'Validar',
    'to register': 'Para inscrever-se',
    'enter your email': 'Coloque o seu email',
    'enter your password': 'Coloque a sua palavra passe',
    'password must be at least 8 characters long':
      'A sua palavra passe deve ter no m\u00ednimo 8 caract\u00e9res',
    'choose a pseudonym': 'Escolha um pseud\u00f3nimo',
    'by validating, you accept\nthe terms of use of the service\n\u00a0 and the privacy policy':
      'Ao validar, aceita\nos termos de uso do servi\u00e7o\n\u00a0 e a Pol\u00edtica de Privacidade',
    'enter your e-mail': 'Coloque o seu email',
    'forgot your password ?': 'Esqueceu-se da palavra-passe?',
    reconnect: 'Para se ligar',
    'enter your new password': 'Introduza uma nova palavra passe',
    'we sent you an email to change your password, just follow the instructions':
      'envi\u00e1mos-lhe um e-mail para alterar a sua password, basta seguir as instru\u00e7\u00f5es',
    'send email again ?': 'Reenviar o email',
    'change voxm password': 'Mudar a palavra passe VoxM',
    'hello pseudo,\n\nyou asked to change your password':
      'Ol\u00e1 pseud\u00f3nimo        Pediu para mudar de palavra passe',
    'click here': 'Clique aqui',
    'if you did not ask to change your password just ignore this email':
      'Se n\u00e3o pediu para alterar a palavra passe, ignore este email',
    kinds: 'Com os melhores cumprimentos',
    'general conditions of use':
      'Condi\u00e7\u00f5es gerais de utiliza\u00e7\u00e3o',
    'privacy policy': 'Pol\u00edtica de privacidade',
    'new password': 'Nova palavra passe',
    'confirm your new password': 'Confirme a sua nova palavra passe',
    'that\u2019s it': 'Est\u00e1 feito',
    'your password has been reiniatized and you are ready for voxer!':
      'A sua palavra passe foi atualizada e est\u00e1 pronto(a) para o Voxer',
    'how do you want to participate ?':
      'Como \u00e9 que gostaria de participar?',
    'to know if you have won, receive answers or discover the ranking, you must log in\u2026':
      'Para saber se ganhou, receber respostas ou\u00a0 ver o ranking, deve fazer login\u2026',
    'sorry, we can\u2019t find it.': 'Desculpe, n\u00e3o encontr\u00e1mos.',
    'do you want some help ?': 'Quer ajuda?',
    'message sent': 'Mensagem enviada',
    'you received a message 12/12/2019': 'Recebeu uma mensagem 12/12/2019',
    'media name': 'Nome do media',
    'reserved space': 'Espa\u00e7o reservado',
    'to find your participations,\nresponses from your media,\naccumulate points\u2026':
      'Para encontrar as suas participa\u00e7\u00f5es,\nAs respostas da sua m\u00eddia,\nacumular pontos...',
    today: 'Hoje',
    'no participations': 'Nenhuma participa\u00e7\u00e3o',
    'participation sent on': 'Participa\u00e7\u00e3o enviada no dia',
    'participation sent': 'Participa\u00e7\u00e3o enviada',
    'answer received on': 'Resposta recebida no dia',
    points: 'Pontos',
    ranking: 'Classifica\u00e7\u00e3o',
    'you have won': 'Voc\u00ea ganhou',
    'message liked': 'Mensagem com gostos',
    'message broadcasted': 'Mensagem transmitida',
    levels: 'N\u00edveis',
    hero: 'Her\u00f3i',
    expert: 'Especialista',
    pro: 'Profissional',
    confirmed: 'Confirmado',
    regular: 'Regulado',
    amateur: 'Amador',
    beginner: 'Iniciante',
    'modify avatar': 'Modificar o avatar',
    pseudonym: 'Pseud\u00f3nimo',
    'city / region': 'Cidade/ Regi\u00e3o',
    name: 'Apelido',
    phone: 'Telem\u00f3vel',
    birthday: 'Data de anivers\u00e1rio',
    'change password': 'Mudar palavra passe',
    'terms of use': 'condi\u00e7\u00f5es de uso',
    users: 'Usu\u00e1rios',
    program: 'Programa',
    'scheduled interaction for today': 'INTERA\u00c7\u00c3O AGENDADA PARA HOJE',
    'without answer': 'SEM RESPOSTA',
    'quoted message': 'MENSAGEM CITADA',
    winner: 'Vencedor(a)',
    'latest messages': '\u00daLTIMAS MENSAGENS',
    'last message': '\u00daLTIMA MENSAGEM',
    'registration required': 'Registro obrigatorio',
    'list of choices': 'LISTA DE ESCOLHAS',
    'subscription level': 'N\u00cdVEL DE ASSINATURA',
    'with transparency in the background': 'COM TRANSPAR\u00caNCIA NO FUNDO',
    disabled: 'DESATIVADO',
    'what is your choice?': 'Qual \u00e9 a sua escolha ?',
    'confirm your choice': 'Confirme sua escolha',
    'sending...': 'Enviando ...',
    'registered on': 'Gravado em',
    'vote / survey': 'Votos e Sondagens',
    'by validating, you accept the': 'Ao validar, voc\u00ea aceita todas as',
    'it\u2019s up to you to talk to let': 'Cabe a voc\u00ea falar para deixar',
    'your message !': 'Sua mensagem !',
    'we prepare the sms\u2026': 'Preparamos o SMS',
    'confirm your participation please':
      'Confirme sua participa\u00e7\u00e3o, por favor',
    'this interaction requires that you are registered and logged in to participate ;-)':
      'Essa intera\u00e7\u00e3o requer que voc\u00ea esteja registrado e logado para participar ;-)',
    'you interact regularly and want to find all your messages and many other advantages.':
      'Voc\u00ea interage regularmente e deseja encontrar todas as suas mensagens e muitas outras vantagens.',
    'you must log in or register to access your personal space.':
      'Voc\u00ea deve fazer login ou se registrar para acessar seu espa\u00e7o pessoal.',
    'daily start time': 'Hora de in\u00edcio di\u00e1ria',
    'daily end time': 'Hora de t\u00e9rmino di\u00e1ria',
    indefinite: 'Indefinido',
    participate: 'Participar',
    "start recording when you're ready":
      'Comece a gravar quando estiver pronto',
    record: 'Salvar',
    stop: 'Parar',
    play: 'Reproduzir',
    restart: 'Come\u00e7ar de novo',
    send: 'Enviar',
    share: 'Compartilhar',
    'thank you for your participation':
      'Obrigado pela sua participa\u00e7\u00e3o',
    'see you soon !': 'At\u00e9 breve!',
    'information emails for all interactions':
      'E-mails informativos para todas as intera\u00e7\u00f5es',
    'information emails for this interaction':
      'E-mails informativos para essa intera\u00e7\u00e3o',
    'i have just reacted to': 'Acabei de reagir a',
    'here is my message :': 'Aqui est\u00e1 a minha mensagem:',
    'registration sent': 'Mensagem enviada',
    'exportable link for social networks':
      'LINK EXPORT\u00c1VEL PARA REDES SOCIAIS',
    'copied to clipboard': 'Copiado para a \u00e1rea de transfer\u00eancia',
    'information emails': 'E-mails de alerta',
    yes: 'Sim',
    no: 'N\u00e3o',
    'message posted on': 'MENSAGEM DEPOSITADA EM',
    'i want to participate too': 'Eu tamb\u00e9m quero participar',
    'help center': 'CENTRO DE AJUD',
    rubric: 'T\u00d3PICO',
    order: 'ORDEM',
    author: 'AUTOR',
    'short description': 'BREVE DESCRI\u00c7\u00c3O',
    'video file': 'ARQUIVO DE V\u00cdDEO',
    'punctual interactions': 'Intera\u00e7\u00f5es pontuais',
    'permanent interactions': 'Intera\u00e7\u00f5es permanentes',
    'activate broadcast in voxm widgets':
      'ATIVAR TRANSMISS\u00c3O NOS WIDGETS VOXM',
    'sorry this interaction is no longer available':
      'Desculpe, essa intera\u00e7\u00e3o n\u00e3o est\u00e1 mais dispon\u00edvel',
    'see the schedule of the day for voxm widget':
      'Veja o calend\u00e1rio do widget VoxM',
    'copy the permalink': 'Copie o link permanente',
    'of the day for widget': 'Hoje para o widget',
    permanents: 'Permanente',
    punctuals: 'Pontual',
    'interaction permanent': 'Intera\u00e7\u00e3o permanente',
    'permanent link': 'Link permanente',
    available: 'Acess\u00edvel',
    'do you want to save the changes?':
      'Deseja salvar as altera\u00e7\u00f5es?',
    next: 'Pr\u00f3ximo',
    'sorry, safari does not allow the use of the camera. you must change browser or import your video using the small square at the bottom left of the screen':
      'Desculpe, o SAFARI n\u00e3o permite o uso da c\u00e2mera. Voc\u00ea deve alterar o navegador ou importar o seu v\u00eddeo usando o pequeno quadrado na parte inferior esquerda da tela',
    'create an interaction': 'Criar intera\u00e7\u00e3o',
    'record again': 'ANTERIOR',
    'enable sharing': 'ATIVAR COMPARTILHAMENTO',
    'enable gamification': 'ATIVAR GAMIFICA\u00c7\u00c3O',
    'enable \u00ab\u00a0my space\u00a0\u00bb':
      'ATIVE \u00ab\u00a0MEU ESPA\u00c7O"',
    'authentification mode': 'Modo de autenticacao',
    none: 'Nao',
    'name and town': 'Nome e cidade',
    'so that your message can be quoted or broadcasted, tell us more about yourself':
      'Para que sua mensagem possa ser citada ou transmitida, conte-nos sobre voc\u00ea',
    'enter your first name or your nickname':
      'Digite seu primeiro nome ou seu apelido',
    'enter the name of your city': 'Digite o nome da sua cidade',
    'required field': 'Os campos obrigat\u00f3rios',
    'monetize your audience': 'Monetize seu p\u00fablico-alvo',
    'as a voxm user, you will be able to generate income through your interactions, have access to dedicated support and more\u2026':
      'Como usu\u00e1rio do VoxM, voc\u00ea poder\u00e1\ngerar renda atrav\u00e9s de suas intera\u00e7\u00f5es,\ntenha acesso a suporte dedicado e muito mais ...',
    'to participate at the voxm monetization program, your account must have minimum accumulated 4,000 messages in the lasts 12 months of 500 different participants. your account will also be subject to verify that it meets voxm\u2019s monetization rules.':
      'Para participar do programa de monetiza\u00e7\u00e3o da VoxM,\nsua conta deve ter acumulado pelo menos 4.000 mensagens nos \u00faltimos 12 meses de 500 participantes diferentes.\nSua conta tamb\u00e9m estar\u00e1 sujeita \u00e0 verifica\u00e7\u00e3o de conformidade com as regras de monetiza\u00e7\u00e3o do VoxM.',
    'i want to be contacted': 'Quero ser contactado',
    'thank you for your request for information\n on the voxm monetization program.\n\none of our advisers will take care of you\nas soon as possible.\n\nsee you soon':
      'Obrigado pelo seu pedido\nno programa de monetiza\u00e7\u00e3o VoxM.\n\nUm de nossos consultores cuidar\u00e1 de voc\u00ea\nO mais breve poss\u00edvel.\n\nTe vejo em breve',
    close: 'Fechar',
    from: 'de',
    'maximum recording time (seconds)':
      'Tempo m\u00e1ximo de grava\u00e7\u00e3o (segundos)',
    'maximum recording time': 'Tempo m\u00e1ximo de grava\u00e7\u00e3o',
    seconds: 'segundos',
    'published quantities': 'Quantidades publicadas',
    news: 'Not\u00edcia',
    'the module for generating income with voxm is not activated.':
      'O m\u00f3dulo para gerar renda com o VoxM n\u00e3o est\u00e1 ativado.',
    discover: 'Descobrir',
    'published on the home page': 'Publicado na p\u00e1gina inicial',
    answered: 'Respondidas',
    quoted: 'Citades',
    broadcasted: 'Transmitido',
    'loyalty points': 'Ponto de fidelidade',
    'enable cloud transfer': 'ATIVAR A TRANSFER\u00caNCIA DE NUVEM',
    type: 'TIPO',
    'server address': 'ENDERECA DO SERVIDOR',
    'name of space': 'Nome espa\u00e7o',
    username: 'Identifiant',
    'for now, the results are :': 'Por agora, os resultados s\u00e3o :',
    'your participation has already been registered for that vote':
      'Voc\u00ea participa\u00e7\u00e3o j\u00e1 foi registada para essa vota\u00e7\u00e3o',
    'limit 1 vote per participant': 'Limitar 1 voto por participante',
  },
};
