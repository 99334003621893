import React from 'react';

export const InfoIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
  >
    <defs>
      <clipPath id="info-icon-clip-path">
        <path d="M0 0H12V12H0z" fill="#707070" />
      </clipPath>
    </defs>
    <g clipPath="url(#info-icon-clip-path)">
      <path
        d="M6 0a6 6 0 1 0 6 6 6 6 0 0 0-6-6m1.248 9.3c-.31.121-.555.213-.739.277a1.92 1.92 0 0 1-.639.1A1.272 1.272 0 0 1 5 9.4a.877.877 0 0 1-.31-.694 2.758 2.758 0 0 1 .022-.335c.016-.114.041-.241.075-.385l.387-1.366c.034-.131.064-.255.086-.369a1.719 1.719 0 0 0 .034-.322.494.494 0 0 0-.109-.364.605.605 0 0 0-.411-.1 1.128 1.128 0 0 0-.308.044c-.1.032-.193.062-.267.09l.1-.419c.252-.1.5-.191.726-.265a2.122 2.122 0 0 1 .654-.115 1.243 1.243 0 0 1 .857.27.891.891 0 0 1 .3.7c0 .06-.008.164-.02.312a2.234 2.234 0 0 1-.077.412l-.381 1.359c-.033.109-.06.236-.088.375a1.954 1.954 0 0 0-.036.317.46.46 0 0 0 .121.369.675.675 0 0 0 .422.1 1.213 1.213 0 0 0 .317-.05 1.809 1.809 0 0 0 .258-.086zm-.066-5.525a.969.969 0 0 1-1.3 0 .8.8 0 0 1-.271-.608.812.812 0 0 1 .271-.608.969.969 0 0 1 1.3 0 .811.811 0 0 1 .268.608.8.8 0 0 1-.268.608"
        fill="#707070"
      />
    </g>
  </svg>
);
