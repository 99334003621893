import React, { useEffect } from 'react';
import Datetime from 'react-datetime';
import { WithPicker } from '../../models/picker/withPicker';
import styled from 'styled-components';
import { Text } from '../text/Text';
import moment from 'moment';
import { Absolute } from '../box/Absolute';
import ClickOutside from '../../features/utils/ClickOutside';
import { TIME_FORMAT } from '../../constants/common';
import { InputWrapper } from '../input/InputWrapper';

const PickerWrapper = styled(Absolute)`
  background: white;
  z-index: 3;
  width: 100%;
  min-width: 110px !important;
  table {
    width: 100%;
  }
  .rdt {
    & > div:first-of-type {
      position: absolute;
      bottom: -125px;
      left: calc(50% - 33px);
      z-index: 100000;
    }
    .rdtTime {
      padding-bottom: 10px;
    }
  }
  .rdtPicker {
    width: 100%;
    box-sizing: border-box;
  }
`;

const focusInput = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.focus();
  }
};

export const TimePicker = ({ name, value, onChange, disabled }) => {
  const { open, setOpen } = WithPicker();
  const displayTime = value ? moment(value) : '';
  const timeString = displayTime ? displayTime.format(TIME_FORMAT) : '';

  useEffect(() => {
    if (open) {
      focusInput(name);
    }
  }, [open]);

  return (
    <InputWrapper
      onClick={() => !disabled && setOpen(true)}
      disabled={disabled}
    >
      <Text width={'100%'}>{timeString || '-'}</Text>
      {open && (
        <PickerWrapper r={'0'} t={'31px'}>
          <ClickOutside callback={() => setOpen(false)}>
            <Datetime
              open
              renderInput={({ ...rest }) => (
                <input
                  {...rest}
                  type="time"
                  id={name}
                  onKeyPress={(e) => e.key === 'Enter' && setOpen(false)}
                />
              )}
              value={displayTime}
              onChange={(changedValue) => {
                changedValue = value.set({
                  hour: changedValue && changedValue.get('hour'),
                  minute: changedValue && changedValue.get('minute'),
                });
                onChange(name, changedValue);
              }}
              dateFormat={false}
              timeFormat={'HH:mm'}
            />
          </ClickOutside>
        </PickerWrapper>
      )}
    </InputWrapper>
  );
};
