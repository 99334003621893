import { useEffect, useRef, useState } from 'react';

export const WithImageUpload = ({
  onChange,
  name,
  currentId,
  video = false,
}) => {
  const uploadRef = useRef();
  const [preview, setPreview] = useState();
  const [processing, setProcessing] = useState(false);
  let file = null;
  const [fileName, setFileName] = useState('');
  const [duration, setDuration] = useState(0);
  const durationRef = useRef(duration);
  durationRef.current = duration;

  const handleFileRead = (e) => {
    const { result } = e.target;
    let previewResult;
    if (video && file.type.split('/')[0]==='video') {
      const blob = new Blob([result], { type: file.type });
      previewResult = URL.createObjectURL(blob);
    } else {
      previewResult = result;
    }
    setPreview(previewResult);
    setProcessing(false);
    if (onChange && file) {
      file.preview = previewResult;
      onChange(name, file);
      if (video && file.type.split('/')[0]==='video') {
        setDurationDelay();
      }
    }
  };

  const setDurationDelay = () => {
    if (!durationRef.current) {
      return setTimeout(setDurationDelay, 500);
    }
    onChange(`${name}_duration`, durationRef.current);
  };

  const reader = new FileReader();
  reader.onload = handleFileRead;

  const handleFileUpload = async (event) => {
    file = event.target.files[0];
    if (!file) {
      return;
    }

    setProcessing(true);
    if (video && file.type.split('/')[0]==='video') {
      setDuration(0);
      reader.readAsArrayBuffer(file);
    } else {
      reader.readAsDataURL(file);
    }
    setFileName(file.name);

    // onChange && onChange(name, file);
  };

  const handleUploadClick = () => uploadRef.current.click();

  const handleDeleteClick = () => {
    setPreview(false);
    setProcessing(false);
    setFileName('');
    onChange && onChange(name, false);
    setDuration(0);
  };

  useEffect(() => {
    if (currentId) {
      setPreview(null);
      setProcessing(false);
      setFileName('');
      setDuration(0);
    }
  }, [currentId]);

  return {
    processing,
    duration,
    uploadRef,
    preview,
    fileName,
    setDuration,
    handleUploadClick,
    handleFileUpload,
    handleDeleteClick,
  };
};
