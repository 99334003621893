import { useState } from 'react';

export const WithPicker = () => {
  const [open, setOpen] = useState(false);

  return {
    open,
    // setters
    setOpen,
  };
};
