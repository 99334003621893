import React from 'react';
import { BaseInput } from './BaseInput';
import { Select } from '../picker/Select';
import { DatePicker } from '../picker/DatePicker';
import { TimePicker } from '../picker/TimePicker';
import { InputLabels } from './InputLabels';

export const Input = ({
  label,
  description,
  select,
  date,
  time,
  mt,
  mr,
  semiBold,
  sm,
  labelRight,
  noLabel,
  ...rest
}) => {
  const Component = select
    ? Select
    : date
    ? DatePicker
    : time
    ? TimePicker
    : BaseInput;

  if (noLabel) {
    return <Component {...rest} />
  }

  return (
    <InputLabels
      label={label}
      description={description}
      select={select}
      mt={mt}
      mr={mr}
      sm={sm}
      semiBold={semiBold}
      labelRight={labelRight}
    >
      <Component {...rest} />
    </InputLabels>
  );
};
