import React from 'react';
import { COLOR } from '../../constants/common';

export const EditIcon = ({ color = COLOR.grey8, ...rest }) => (
  <svg
    height="20"
    viewBox="0 0 17.924 20"
    width="17.924"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <clipPath id="edit-icon-clip-path">
      <path d="m0 0h17.924v20h-17.924z" />
    </clipPath>
    <g clipPath="url(#edit-icon-clip-path)">
      <path
        d="m16.133 12.416a.782.782 0 0 0 -1.1 0l-.151.151 1.1 1.1.151-.151a.782.782 0 0 0 0-1.1m-1.257 2.359-1.105-1.105-2.894 2.888-.464 1.528 1.587-.439zm2.362-.15-4.28 4.275a.781.781 0 0 1 -.343.2l-3.153.873a.781.781 0 0 1 -.956-.98l.934-3.075a.781.781 0 0 1 .2-.326l4.289-4.28a2.344 2.344 0 0 1 3.314 3.315m-13.343-3.69a.781.781 0 0 0 0 1.563h4.888a.781.781 0 0 0 0-1.563zm8.828-2.344a.781.781 0 0 0 -.781-.781h-8.047a.781.781 0 0 0 0 1.563h8.047a.781.781 0 0 0 .781-.781m-.781-3.906h-8.047a.781.781 0 1 0 0 1.562h8.047a.781.781 0 1 0 0-1.562m-5.429 14.531a.781.781 0 0 1 -.781.781h-2.613a3.129 3.129 0 0 1 -3.124-3.125v-13.75a3.129 3.129 0 0 1 3.124-3.125h9.605a3.129 3.129 0 0 1 3.125 3.125v4.8a.781.781 0 0 1 -1.562 0v-4.8a1.564 1.564 0 0 0 -1.562-1.562h-9.606a1.564 1.564 0 0 0 -1.562 1.562v13.75a1.564 1.564 0 0 0 1.563 1.562h2.612a.781.781 0 0 1 .781.781"
        fill={color}
        transform="translate(.001)"
        className="active-change"
      />
    </g>
  </svg>
);
