import React, { useContext, useState } from 'react';
import { useGlobal } from '../../../models/global/withGlobal'; // TODO:NEW Remove one "../"

export const ModalContext = React.createContext(null);

const defaultState = {
  initData: null,
  open: false,
};

export const ModalProvider = (props) => {
  const [state, setState] = useState(defaultState);
  return (
    <ModalContext.Provider value={[state, setState]}>
      {props.children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const [state, setState] = useContext(ModalContext);
  const { refreshData } = useGlobal();

  const openModal = (modalId, initData) => {
    setState({
      initData,
      open: modalId,
    });
  };

  const closeModal = () => setState(defaultState) || refreshData();

  return {
    // global state
    ...state,
    // methods
    openModal,
    closeModal,
  };
};
