import React from 'react';

export const ConfirmedIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    data-name="Groupe 945"
    viewBox="0 0 40 40"
  >
    <g id="Groupe_407" data-name="Groupe 407">
      <circle
        id="Ellipse_57"
        cx="20"
        cy="20"
        r="20"
        fill="#31cc41"
        data-name="Ellipse 57"
      />
    </g>
    <g
      id="Groupe_409"
      data-name="Groupe 409"
      transform="translate(10.28 8.087)"
    >
      <g id="Groupe_408" data-name="Groupe 408" transform="translate(0 19)">
        <path
          id="Tracé_470"
          d="M31.52 28.86a12.6 12.6 0 0 1-19.44 0"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="3px"
          data-name="Tracé 470"
          transform="translate(-12.08 -28.86)"
        />
      </g>
      <path
        id="Tracé_471"
        d="M21.8 9.86l2.55 4.15 4.74 1.14-3.16 3.71.37 4.86-4.5-1.86-4.5 1.86.37-4.86-3.16-3.71 4.74-1.14z"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="3px"
        data-name="Tracé 471"
        transform="translate(-12.08 -9.86)"
      />
    </g>
  </svg>
);
