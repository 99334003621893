import React from 'react';

export const ModerationIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <g transform="translate(-408 -265)">
      <path
        className="active-change"
        fill="#707070"
        d="M422.142 265h-8.284L408 270.858v8.284l5.858 5.858h8.284l5.858-5.858v-8.284z"
      />
      <path
        d="M574.882 332.218a1.308 1.308 0 1 1 2.616 0v6.541a1.308 1.308 0 1 1-2.616 0z"
        transform="translate(-158.19 -62.477)"
        fill="#fff"
      />
      <circle
        id="Ellipse_123"
        cx="1.308"
        cy="1.308"
        r="1.308"
        fill="#fff"
        transform="translate(416.692 278.899)"
      />
    </g>
  </svg>
);
