import React from 'react';
import { Box } from 'reflexbox';
import { Label } from '../input/Label';
import { Text } from '../text/Text';
import { WithPicker } from '../../models/picker/withPicker';
import { Relative } from '../box/Relative';
import { Absolute } from '../box/Absolute';
import ClickOutside from '../../features/utils/ClickOutside';
import styled from 'styled-components';
import { COLOR } from '../../constants/common';
import { ArrowDropDownIcon } from '../icons/ArrowDropDownIcon';

const PickerWrapper = styled(Absolute)`
  background: white;
  z-index: 1;
  width: 100%;
  border: 1px solid ${COLOR.stone2_12};
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
`;

const AbsolutePointer = styled(Absolute)`
  cursor: pointer;
`;

const InnerWrapper = styled(Relative)`
  & > div:first-of-type {
    min-height: 25px;
  }
`;

export const SelectPicker = ({
  options,
  label,
  value,
  onChange,
  name,
  widgetInput,
  widgetInputSmall,
  disabled,
}) => {
  const { open, setOpen } = WithPicker();
  const selected = options.find(option => option.value === value) || {};
  return (
    <Box mb={'11px'}>
      {widgetInput ? (
        <Text color={'#a29e9e'} mb={'16px'} size={'h5'}>
          {label}
        </Text>
      ) : widgetInputSmall ? (
        <Text color={'#a29e9e'} size={'xs'} mb={'14px'}>
          {label}
        </Text>
      ) : (
        <Label>{label || value}</Label>
      )}
      <InnerWrapper>
        <Text
          size={widgetInputSmall ? 'lg' : 'h5'}
          color={COLOR.text}
          bordered
          pointer={!disabled}
          onClick={() => setOpen(true)}
          upper={!widgetInputSmall}
          className={'picker-label'}
        >
          {selected.label || selected.value || ''}
        </Text>
        {open && !disabled && (
          <PickerWrapper t={'24px'} l={'0'}>
            <ClickOutside callback={() => setOpen(false)}>
              {options.map(option => (
                <Text
                  key={`${name}-${option.value}`}
                  upper
                  size={'h5'}
                  color={value === option.value ? COLOR.white : COLOR.text}
                  pointer
                  pl={'23px'}
                  onClick={() => onChange(name, option.value) || setOpen(false)}
                  backgroundColor={
                    value === option.value ? COLOR.link : COLOR.white
                  }
                  py={'5px'}
                >
                  {option.label || option.value}
                </Text>
              ))}
            </ClickOutside>
          </PickerWrapper>
        )}
        {!disabled && (
          <AbsolutePointer r={'0'} t={'0'} onClick={() => setOpen(true)}>
            <ArrowDropDownIcon open={open} />
          </AbsolutePointer>
        )}
      </InnerWrapper>
    </Box>
  );
};
