import React from 'react';

export const ClappingIcon = () => (
  <svg height="17.81" viewBox="0 0 17.821 17.81" width="17.821" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m44.725 164.934-1.735 1a1 1 0 0 0 -1-1.734l-2.631 1.519-1.411.814a1.334 1.334 0 0 0 .466-1.86.66.66 0 0 0 -.888-.207l-1.156.667-1.609.929a5.1 5.1 0 0 0 -2.069 6.692 5 5 0 0 0 6.948 2.046l5.2-3a1.039 1.039 0 0 0 .441-1.348 1 1 0 0 0 -1.392-.413l1.635-.944a1.038 1.038 0 0 0 .442-1.348 1 1 0 0 0 -1.392-.414l1.106-.639a1.038 1.038 0 0 0 .441-1.347 1 1 0 0 0 -1.393-.413zm0 0"
      fill="#fec000" transform="translate(-30.896 -157.663)" />
    <path d="m195.2 208.6-4.05 2.338a.313.313 0 1 1 -.313-.541l4.05-2.338a.313.313 0 0 1 .313.541zm0 0" fill="#d9a600"
          transform="translate(-183.234 -199.895)" />
    <path d="m245.315 313.608-2.946 1.7a.313.313 0 1 1 -.313-.541l2.946-1.7a.313.313 0 0 1 .313.541zm0 0" fill="#d9a600"
          transform="translate(-232.448 -300.801)" />
    <path d="m221.393 244.378-4.63 2.673a.313.313 0 1 1 -.313-.541l4.63-2.673a.313.313 0 0 1 .313.541zm0 0"
          fill="#d9a600"
          transform="translate(-207.843 -234.275)" />
    <path d="m108.825 223.911-2.32 1.339a.313.313 0 0 1 -.313-.541l2.32-1.339a.313.313 0 1 1 .313.541zm0 0"
          fill="#d9a600"
          transform="translate(-101.895 -214.606)" />
    <g fill="#aeadb3">
      <path
        d="m388.363 26.539a.313.313 0 0 1 -.221-.534l2.031-2.031a.313.313 0 1 1 .442.442l-2.031 2.031a.313.313 0 0 1 -.221.092zm0 0"
        transform="translate(-372.89 -22.949)" />
      <path
        d="m400.357 89.468a.313.313 0 0 1 -.161-.58l1.563-.938a.312.312 0 1 1 .322.536l-1.563.938a.31.31 0 0 1 -.161.044zm0 0"
        transform="translate(-384.415 -84.472)" />
      <path
        d="m352.367 26.473a.312.312 0 0 1 -.279-.452l.938-1.875a.313.313 0 1 1 .559.279l-.938 1.875a.313.313 0 0 1 -.28.173zm0 0"
        transform="translate(-338.301 -23.04)" />
      <path
        d="m145.342 2.89a.312.312 0 0 1 -.287-.189l-.938-2.188a.313.313 0 0 1 .574-.246l.938 2.188a.313.313 0 0 1 -.287.436zm0 0"
        transform="translate(-138.463 -.082)" />
      <path
        d="m200.363 10.477a.313.313 0 0 1 -.313-.313v-1.875a.313.313 0 1 1 .625 0v1.875a.313.313 0 0 1 -.312.313zm0 0"
        transform="translate(-192.235 -7.668)" />
      <path
        d="m105.6 34.163a.312.312 0 0 1 -.244-.117l-1.25-1.563a.313.313 0 0 1 .488-.391l1.25 1.563a.312.312 0 0 1 -.244.508zm0 0"
        transform="translate(-99.973 -30.729)" />
    </g>
    <path
      d="m10.864 108.409-1.417 1.417a1 1 0 1 0 -1.413-1.416l-2.148 2.149-1.153 1.151a1.333 1.333 0 0 0 -.031-1.917.659.659 0 0 0 -.912.031l-.943.942-1.314 1.315a5.093 5.093 0 0 0 -.268 7 5 5 0 0 0 7.24.175l4.244-4.244a1.038 1.038 0 0 0 .077-1.415 1 1 0 0 0 -1.452-.039l1.335-1.335a1.038 1.038 0 0 0 .077-1.416 1 1 0 0 0 -1.452-.039l.9-.9a1.039 1.039 0 0 0 .077-1.416 1 1 0 0 0 -1.452-.039zm0 0"
      fill="#ffe670" transform="translate(.001 -103.895)" />
    <path d="m147.279 150.351-3.307 3.307a.312.312 0 0 1 -.442-.442l3.307-3.307a.313.313 0 0 1 .442.442zm0 0"
          fill="#ebb300" transform="translate(-137.838 -143.972)" />
    <path d="m218.6 245.839-2.406 2.406a.313.313 0 1 1 -.442-.442l2.407-2.406a.312.312 0 0 1 .442.442zm0 0"
          fill="#ebb300"
          transform="translate(-207.229 -235.729)" />
    <path d="m183.839 174.342-3.782 3.782a.313.313 0 1 1 -.442-.442l3.785-3.782a.313.313 0 1 1 .442.442zm0 0"
          fill="#ebb300" transform="translate(-172.51 -167.022)" />
    <path d="m61.276 198.393-1.894 1.894a.313.313 0 0 1 -.442-.442l1.893-1.894a.313.313 0 1 1 .442.442zm0 0"
          fill="#ebb300" transform="translate(-56.549 -190.129)" />
  </svg>
);
