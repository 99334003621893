import React from 'react';
import { COLOR } from '../../constants/common';

export const ChevronLeftIcon = ({ color = COLOR.black }) => (
  <svg viewBox="0 0 4.695 8.389" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m10.533 5.667-3.488 3.488 3.487 3.487"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      transform="translate(-6.545 -4.96)"
    />
  </svg>
);
