import React from 'react';
import { Text } from '../../ui/text/Text';
import { Heading } from '../../ui/text/Heading';
import { useTranslation } from 'react-i18next';
import {
  COLOR,
  DATE_FORMAT,
  INTERACTION_API_TYPE_MAP,
  TIME_FORMAT,
} from '../../constants/common';
import styled, { css } from 'styled-components';
import {
  AVAILABLE_INFORMATION,
  CREATE_INTERACTION_STEPS,
  END_TYPES,
  GAME_TYPES,
  START_TYPES,
  SURVEY_TYPES,
  MEDIA_SURVEY_TYPES,
  WithCreateInteraction,
} from '../../models/interactions/withCreateInteraction';
import { INTERACTION_FRONTEND_TYPE_MAP } from '../../constants/interactions/interaction';
import { Button } from '../../ui/button/Button';
import { Flex, Box } from 'reflexbox';
import { FlexCenter } from '../../ui/box/FlexCenter';
import { AvailableOption } from '../../ui/interactions/AvailableOption';
import { Select } from '../../ui/picker/Select';
import { Input } from '../../ui/input/Input';
import { WidgetPreview } from '../../ui/widgetPreview/WidgetPreview';
import { useUser } from '../../../models/users/withUser'; // TODO:NEW Remove one "../"
import { YES_NO_OPTIONS, SUPPORTING_PARTICIPATION_FORMAT } from '../../constants/picker/options';
import { useModal } from '../../models/global/withModal';
import { Description } from '../../ui/input/Description';
import { InputLabels } from '../../ui/input/InputLabels';
import { Checkboxes } from '../../ui/checkboxes/Checkboxes';
import { TextSummary } from '../../../ui/input/TextSummary';
import { UploadButtons } from '../../ui/button/UploadButtons';
import { Footer } from '../../ui/modal/Footer';
import { Link } from 'react-router-dom';
import { Steps } from '../../../features/steps/Steps';
import { Relative } from '../../ui/box/Relative';
import { PlusMinusIconWrapper } from '../../ui/interactions/PlusMinusIconWrapper';
import { MinusIcon } from '../../ui/icons/MinusIcon';
import { PlusIcon } from '../../ui/icons/PlusIcon';
import { ColorPickerIcon } from '../../ui/icons/ColorPickerIcon';
import {
  DEFAULT_COLOR, DEFAULT_TEXT_COLOR,
  getLabel,
} from '../../constants/customisation/customisation';
import { RadioPicker } from '../../ui/picker/RadioPicker';

const Wrapper = styled(Flex)`
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

const Header = styled(Flex)`
  height: 61px;
  border-bottom: 1px solid ${COLOR.veryLightPink};
  padding: 0 54px;
`;

const Container = styled(Flex)`
  flex: 1;
  max-height: calc(100% - 122px);
`;

const Content = styled(Flex)`
  flex: 1;
`;

const TypeSelectWrapper = styled.div`
  overflow-y: scroll;
  width: 28%;
  border-right: 1px solid ${COLOR.veryLightPink};
`;

const ContentWrapper = styled(Box)`
  padding: 21.5px 74px 21.5px 32px;
  flex: 1;
`;

const InteractionType = styled(Flex)`
  align-items: center;
  height: 115px;
  cursor: pointer;
  padding: 20px;
  border-bottom: 1px solid ${COLOR.veryLightPink};
  svg {
    height: 35px;
    width: 35px;
  }
  ${({ active }) =>
    active &&
    css`
      background: ${COLOR.white2};
    `}
`;

const InnerScrollWrapper = styled(Flex)`
  overflow-y: auto;
  flex: 1;
  padding: 10px 45px 10px 0;
  margin-right: 40px !important;
  flex-direction: column;
`;

const InnerMaxWidth = styled(Flex)`
  max-width: 75%;
  height: 100%;
`;

export const CreateInteraction = ({ initData }) => {
  const { t } = useTranslation();
  const { channel } = useUser();
  const { closeModal } = useModal();
  const {
    interaction,
    isStepValid,
    isSaved,
    step,
    setField,
    nextStep,
    previousStep,
    setFrom,
    showColors,
    setShowColors,
    setTo,
    answerCount,
    removeChoiceOnIndex,
    addNewChoice,
    saveInteraction,
    resetCustomisation,
  } = WithCreateInteraction({
    initInteraction: initData,
    channelId: channel.id,
  });
  const availableTypes = [
    INTERACTION_API_TYPE_MAP.vote,
    INTERACTION_API_TYPE_MAP.voice,
    INTERACTION_API_TYPE_MAP.video,
    INTERACTION_API_TYPE_MAP.text,
    INTERACTION_API_TYPE_MAP.game,
  ];

  const { customisation } = interaction;

  const setFieldGDPR = (name, value) => {
    setField(name, value);
    setField('customisation|consent_checkbox', false);
  }

  const setFieldImageRight = (name, value) => {
    setField(name, value);
    setField('customisation|consent_checkbox_image_right', false);
  }
  return (
    <Wrapper>
      <Header>
        <Steps steps={CREATE_INTERACTION_STEPS} step={step} />
      </Header>
      <Container>
        {step === 0 && (
          <TypeSelectWrapper>
            {availableTypes.map((type) => (
              <InteractionType
                active={interaction.type === type}
                onClick={() =>
                  setField('type', type) ||
                  setField('testimonial_demanded', false)
                }
                key={type}
              >
                {INTERACTION_FRONTEND_TYPE_MAP[type].iconNew}
                <Text ml={'15px'} bold size={'h4'} mt={'5px'} capital>
                  {t(INTERACTION_FRONTEND_TYPE_MAP[type].label)}
                </Text>
              </InteractionType>
            ))}
          </TypeSelectWrapper>
        )}
        <Content>
          {step === 0 ? (
            !interaction.type ? (
              <Box p={'88px'} flex={1}>
                <Text mt={'50px'} bold size={'lg'} center>
                  {t(
                    'Let’s go ! Choose the new interaction we’re going to create !'
                  )}
                </Text>
                <Text
                  mt={'30px'}
                  center
                  lh={'25px'}
                  width={'300px'}
                  mx={'auto'}
                >
                  {t(
                    'Select in the left menu the type of interaction that you wish to offer to your public'
                  )}
                </Text>
              </Box>
            ) : (
              <Box px={'22.5px'} py={'20.5px'} pr={'114px'}>
                <Text bold mb={'17px'} size={'h1.8'} capital>
                  {t(INTERACTION_FRONTEND_TYPE_MAP[interaction.type].label)}
                </Text>
                {interaction.type === INTERACTION_API_TYPE_MAP.vote && (
                  <Box>
                    <Text mb={'26px'}>
                      {t(
                        'Ask for your hearing’s opinion on a question, a choice, a societal issue. You can enter 4 possible answers.'
                      )}
                    </Text>

                    <Text bold mb={'15px'}>{t('Possible types of surveys')}</Text>
                    <RadioPicker
                      value={
                        interaction.testimonial_demanded ? 'voice' : 'simple'
                      }
                      name={'testimonial_demanded'}
                      onChange={(name, value) =>
                        setField(name, value === 'voice')
                      }
                      options={SURVEY_TYPES}
                      colorInner={COLOR.brownishGrey}
                      inline={false}
                      px={'15px'}
                    />
                    <Text bold mb={'15px'} mt={'11px'}>{t('Description')}</Text>
                    <Text>
                      {interaction.testimonial_demanded
                        ? t(
                            'You can ask your audience to vote and then encourage them to respond vocally or in writting in order to broadcast their participation on air. '
                          )
                        : t(
                            'With the simple survey, choose ease and speed. You ask a question, you get the first votes right away !'
                          )}
                    </Text>
                    <Text size={'xs'} bold mt={'29px'}>
                      {t('Available options')}
                    </Text>
                    <AvailableOption text={t('4 possible answers ')} active />
                    <AvailableOption
                      text={t('Voting limit of 1 per participant')}
                      active
                    />
                    <AvailableOption
                      text={t(
                        'Possible collection of personal information from your audience'
                      )}
                      active
                    />
                    <AvailableOption
                      text={t('Justification following the survey')}
                      active={interaction.testimonial_demanded}
                    />
                  </Box>
                )}
                {interaction.type === INTERACTION_API_TYPE_MAP.voice && (
                  <Box>
                    <Text mb={'26px'}>
                      {t(
                        'Get your audience to react vocally, at any time, and then broadcast their participation on your air. In this interaction, you can request information from your audience such as first name, surname, city…'
                      )}
                    </Text>
                    <RadioPicker
                      value={'simple'}
                      options={[SURVEY_TYPES[0]]}
                      onChange={() => {}}
                      colorInner={COLOR.brownishGrey}
                      inline={false}
                      px={'15px'}
                    />
                    <Text size={'xs'} bold mt={'29px'}>
                      {t('Available options')}
                    </Text>
                    <AvailableOption text={t('Voice recording')} active />
                    <AvailableOption
                      text={t('Written transcription of the HD sound')}
                      active
                    />
                    <AvailableOption
                      text={t('Limitation of recording time')}
                      active
                    />
                    <AvailableOption
                      text={t(
                        'Possible collection of personal information from your audience'
                      )}
                      active
                    />
                  </Box>
                )}
                {/* Participation vocale / image / media */}
                {interaction.type === INTERACTION_API_TYPE_MAP.video && (
                  <Box>
                    <Text mb={'26px'}>
                      {t(
                        'You can now offer your audience to participate in video or photo/picture.'
                      )}
                    </Text>
                    <RadioPicker
                      name={'media_type'}
                      value={interaction.media_type}
                      options={MEDIA_SURVEY_TYPES}
                      onChange={setField}
                      colorInner={COLOR.brownishGrey}
                      inline={false}
                      px={'15px'}
                    />
                    <Text mt={'26px'}>
                      {t(
                        'Offer your audience easy video participation directly on your digital media'
                      )}
                    </Text>
                    <Text size={'xs'} bold mt={'21px'}>
                      {t('Available options')}
                    </Text>
                    <AvailableOption text={t('Video HD')} active />
                    <AvailableOption
                      text={t('Limited recording time')}
                      active
                    />
                    <AvailableOption text={t('Written transcript')} active />
                    <AvailableOption
                      text={t(
                        'Possible collection of personal information from your audience'
                      )}
                      active
                    />
                  </Box>
                )}
                {interaction.type === INTERACTION_API_TYPE_MAP.text && (
                  <Box>
                    <Text mb={'26px'}>
                      {t(
                        'Participating in writing is now possible with VoxM! Easier for your audience, text interaction allows you to centralise everything on the Interaction Manager'
                      )}
                    </Text>
                    <RadioPicker
                      value={'simple'}
                      options={[SURVEY_TYPES[0]]}
                      onChange={() => {}}
                      colorInner={COLOR.brownishGrey}
                      inline={false}
                      px={'15px'}
                    />
                    <Text mt={'26px'}>
                      {t(
                        'Offer your audience easy text participation directly on your digital media'
                      )}
                    </Text>
                    <Text size={'xs'} bold mt={'21px'}>
                      {t('Available options')}
                    </Text>
                    <AvailableOption
                      text={t(
                        'Possible collection of personal information from your audience'
                      )}
                      active
                    />
                  </Box>
                )}
                {interaction.type === INTERACTION_API_TYPE_MAP.game && (
                  <Box>
                    <Text>
                      {t('Play with your audience and increase their engagement rate !')}
                    </Text>
                    <Text mb={'26px'}>
                      {t('This is now available on VoxM !')}
                    </Text>

                    <RadioPicker
                      value={interaction.game_type}
                      name={'game_type'}
                      onChange={(name, value) => setField(name, value)}
                      options={GAME_TYPES}
                      colorInner={COLOR.brownishGrey}
                      inline={false}
                      px={'15px'}
                    />
                    <Text mt={'26px'}>
                      {interaction.game_type === 'registration' ?
                        t('Game allowing your audience to participate by simply registering')
                        : t('Game allowing you to answer a question (with or without a correct answer) and to make a draw')
                      }
                    </Text>
                    <Text size={'xs'} bold mt={'29px'}>
                      {t('Available options')}
                    </Text>

                    {interaction.game_type === 'registration' ? (
                      <AvailableOption
                        text={t('Participation simple avec juste une inscription')}
                        active
                      />
                    ) : (
                      <>
                        <AvailableOption text={t('Several answers possible')} active />
                        <AvailableOption text={t('One possible correct answer')} active/>
                      </>
                    )}
                    <AvailableOption text={t('Drawing')} active/>
                    <AvailableOption
                      text={t('Possible collection of personal information from your audience')}
                      active
                    />
                  </Box>
                )}
              </Box>
            )
          ) : step === 1 ? (
            <ContentWrapper>
              {showColors ? (
                <>
                  <Heading size={'h4'}>{t('Color parameters')}</Heading>
                  <Flex height={'100%'}>
                    <InnerScrollWrapper>
                      <Input
                        value={customisation["btn_1_color"] || DEFAULT_COLOR}
                        name={"customisation|btn_1_color"}
                        onChange={setField}
                        label={"Main button color"}
                        type={'color'}
                      />
                      <Box height={'15px'} />
                    </InnerScrollWrapper>
                  </Flex>
                  <Flex height={'100%'}>
                    <InnerScrollWrapper>
                      <Input
                        value={customisation["btn_1_text_color"] || DEFAULT_TEXT_COLOR}
                        name={"customisation|btn_1_text_color"}
                        onChange={setField}
                        label={"Main text color"}
                        type={'color'}
                      />
                      <Box height={'15px'} />
                    </InnerScrollWrapper>
                  </Flex>
                </>
              ) : (
                <>
                  <Heading>
                    {interaction.type === INTERACTION_API_TYPE_MAP.voice
                      ? t('Settings for voice participation')
                      : t('Survey parameters')}
                  </Heading>
                  <Flex height={'100%'}>
                    <InnerScrollWrapper>
                     <Input
                        value={interaction.title}
                        name={'title'}
                        onChange={setField}
                        label={t('Title')}
                        placeholder={t('Title')}
                      />
                      <Input
                        value={interaction.text}
                        name={'text'}
                        onChange={setField}
                        label={t('Interaction text')}
                        textArea
                        placeholder={t(
                          'Write here the content of your visible interaction of your audience'
                        )}
                      />
                      {(
                        interaction.type === INTERACTION_API_TYPE_MAP.vote
                        || (interaction.type === INTERACTION_API_TYPE_MAP.game && interaction.game_type === 'answer')
                      ) && (
                        <>
                          {Array.from({ length: answerCount }).map(
                            (values, index) => {
                              const value = interaction.choices[index];
                              const name = String.fromCharCode(65 + index);
                              return (
                                <Relative
                                  mt={index === 1 ? '-12px' : undefined}
                                >
                                  <Input
                                    key={name}
                                    value={value || ''}
                                    onChange={setField}
                                    label={!index && t('Possible answer')}
                                    description={
                                      !index && t('2 answers minimum.')
                                    }
                                    name={`choices|${index}`}
                                    placeholder={`${t('Answer')} ${name}`}
                                  />
                                  <PlusMinusIconWrapper
                                    onClick={() => removeChoiceOnIndex(index)}
                                  >
                                    <MinusIcon />
                                  </PlusMinusIconWrapper>
                                </Relative>
                              );
                            }
                          )}
                          <Relative>
                            <Input
                              placeholder={t('Add a new answer')}
                              dash
                              disabled
                            />
                            <PlusMinusIconWrapper onClick={addNewChoice}>
                              <PlusIcon />
                            </PlusMinusIconWrapper>
                          </Relative>
                        </>
                      )}
                      {interaction.type === INTERACTION_API_TYPE_MAP.game && interaction.game_type === 'answer' && (
                          <Input
                            select
                            value={interaction.correct_answer || null}
                            name={'correct_answer'}
                            onChange={setField}
                            label={t('Correct answer')}
                            options={[
                              {value: null, label: 'No correct answer'},
                              ...interaction.choices.map(val=>({value: val, label: val}))
                            ]}
                          />
                        )
                      }
                      {interaction.type === INTERACTION_API_TYPE_MAP.vote && (
                        <>
                          <Input
                            select
                            value={interaction.limit_1_vote}
                            name={'limit_1_vote'}
                            onChange={setField}
                            options={YES_NO_OPTIONS}
                            description={t(
                              'By limiting to 1 vote per participant, you avoid multiple votes during a poll.'
                            )}
                            label={t('Limit to 1 vote')}
                          />
                          <Input
                            select
                            value={interaction.show_results}
                            name={'show_results'}
                            onChange={setField}
                            options={YES_NO_OPTIONS}
                            description={t(
                              'This option allows the real-time display of the survey result when your audience participates.'
                            )}
                            label={t('Show results')}
                          />
                        </>
                      )}
                      {interaction.testimonial_demanded && (
                        <>
                          <Heading>
                            {t('Settings for voice participation')}
                          </Heading>
                          <Input
                            select
                              value={interaction.testimonial_format}
                              name={'testimonial_format'}
                              onChange={setField}
                              options={SUPPORTING_PARTICIPATION_FORMAT}
                              description={t(
                                'Supporting participation format voice or text'
                              )}
                              label={t('Format')}
                              />
                          <Input
                            value={interaction.testimonial}
                            name={'testimonial'}
                            onChange={setField}
                            label={t('Incentive text')}
                            textArea
                            placeholder={t(
                              'Write here the incentive text following the vote'
                            )}
                            description={t(
                              'This text makes it possible to personalise the incentive to leave a voice participation after the vote.'
                            )}
                          />
                        </>
                      )}
                      {(interaction.testimonial_demanded && interaction.testimonial_format === "voice" ||
                        interaction.type ===
                          INTERACTION_API_TYPE_MAP.voice) && (
                        <Input
                          type={'number'}
                          value={interaction.max_recording_time}
                          name={'max_recording_time'}
                          onChange={setField}
                          label={t('Recording time')}
                          placeholder={t('In seconds')}
                          description={t(
                            'You can set a maximum recording time for a voice participation.'
                          )}
                        />
                      )}
                      {[
                        INTERACTION_API_TYPE_MAP.voice,
                        INTERACTION_API_TYPE_MAP.video,
                      ].includes(interaction.type) && interaction.media_type !== "image" && (
                        <Input
                          select
                          value={interaction.enable_written_answer}
                          name={'enable_written_answer'}
                          onChange={setField}
                          options={YES_NO_OPTIONS}
                          label={t('Enable written answer')}
                        />
                      )}
                      <Input
                        select
                        value={interaction.gather_information}
                        name={'gather_information'}
                        onChange={setField}
                        options={YES_NO_OPTIONS}
                        description={t(
                          'This option allows you to collect information from the audience who sends their voice participation as part of the broadcast of their message on the air.'
                        )}
                        label={t('Gathering information')}
                      />
                      <Checkboxes
                        possibleValues={AVAILABLE_INFORMATION}
                        onChange={(key, value) =>
                          setField(`information_to_gather|${key}`, value)
                        }
                        value={interaction.information_to_gather}
                        doubleCheckbox
                      />
                      <Input
                        value={customisation.text_personal}
                        name={'customisation|text_personal'}
                        onChange={setField}
                        label={t('Personal information')}
                        textArea
                      />
                      <Input
                        value={customisation.text_thanks}
                        name={'customisation|text_thanks'}
                        onChange={setField}
                        label={t('Thank-you text')}
                        textArea
                      />
                      <Input
                        value={interaction.payload}
                        name={'payload'}
                        onChange={setField}
                        label={t('Payload')}
                      />
                      <Heading>{t('Legal informations')}</Heading>
                      <Input
                        select
                        value={customisation.consent_gdpr}
                        name={'customisation|consent_gdpr'}
                        onChange={setFieldGDPR}
                        options={YES_NO_OPTIONS}
                        description={''}
                        label={t('Consent GDPR')}
                      />
                      {customisation.consent_gdpr && (
                        <div>
                          <Input
                            value={customisation.title_consent}
                            name={'customisation|title_consent'}
                            onChange={setField}
                            label={t('Title consent')}
                            placeholder={t('Consent Personal Data')}
                          />
                          <Input
                            select
                            value={customisation.consent_checkbox}
                            name={'customisation|consent_checkbox'}
                            onChange={setField}
                            options={YES_NO_OPTIONS}
                            description={''}
                            label={t('Checkbox')}
                          />
 
                          <Input
                            value={customisation.consent_gdpr_text}
                            name={'customisation|consent_gdpr_text'}
                            onChange={setField}
                            description={''}
                            label={t('Consent GDPR text')}
                            textArea
                          />
                        </div>
                      )}
                      <Input
                        select
                        value={customisation.image_right}
                        name={'customisation|image_right'}
                        onChange={setFieldImageRight}
                        options={YES_NO_OPTIONS}
                        description={''}
                        label={t('Image right')}
                      />
                      {customisation.image_right && (
                        <div>
                          <Input
                            value={customisation.title_consent_image_right}
                            name={'customisation|title_consent_image_right'}
                            onChange={setField}
                            label={t('Title consent')}
                            placeholder={t('Consent Personal Data')}
                          />
                           <Input
                            select
                            value={customisation.consent_checkbox_image_right}
                            name={'customisation|consent_checkbox_image_right'}
                            onChange={setField}
                            options={YES_NO_OPTIONS}
                            description={''}
                            label={t('Checkbox')}
                          />
 
                          <Input
                            value={customisation.image_right_text}
                            name={'customisation|image_right_text'}
                            onChange={setField}
                            description={''}
                            label={t('Image right text')}
                            textArea
                          />
                        </div>
                      )}
                    </InnerScrollWrapper>
                    <Box>
                      <WidgetPreview
                        backgroundMedia={
                          interaction.background?.preview ||
                          interaction.background_url
                        }
                        channel={channel}
                        interactionText={interaction.text}
                        customisation={customisation || {}}
                      />
                      <Box width={'92%'} margin={'auto'}>
                        <UploadButtons
                          acceptVideo
                          onUpload={setField}
                          text={t('Upload a custom background')}
                          name={'background'}
                          hideDelete
                          width={'100%'}
                        />
                        <Flex flexDirection={'column'} alignItems={'center'}>
                          <Button
                            mt={'7px'}
                            width={'100%'}
                            theme={'white'}
                            onClick={() => setShowColors(true)}
                          >
                            <ColorPickerIcon color={COLOR.waterBlue} />
                            <Text
                              size={'xs'}
                              color={COLOR.waterBlue}
                              ml={'7px'}
                            >
                              {t('Color parameters')}
                            </Text>
                          </Button>
                        </Flex>
                      </Box>
                    </Box>
                  </Flex>
                </>
              )}
            </ContentWrapper>
          ) : step === 2 ? (
            <ContentWrapper>
              <InnerMaxWidth>
                <InnerScrollWrapper>
                  <Heading>{t('Availability of interaction')}</Heading>
                  <Input
                    select
                    value={interaction.from}
                    name={'from'}
                    onChange={setFrom}
                    options={START_TYPES}
                    description={t(
                      'You can choose whether your interaction is available now or whether it is scheduled to start at a later date.'
                    )}
                    label={t('From')}
                  />
                  <Input
                    date
                    value={interaction.start_date}
                    name={'start_date'}
                    onChange={setField}
                    label={t('Start date')}
                    disabled={interaction.from === 'now'}
                  />
                  <Input
                    time
                    value={interaction.start_date}
                    name={'start_date'}
                    onChange={setField}
                    label={t('Start time')}
                    disabled={interaction.from === 'now'}
                  />
                  <Input
                    select
                    value={interaction.to}
                    name={'to'}
                    onChange={setTo}
                    options={END_TYPES}
                    description={t(
                      'You can choose whether your interaction ends at a desired date or whether it is permanent.'
                    )}
                    label={t('Up to')}
                  />
                  <Input
                    date
                    value={interaction.end_date}
                    name={'end_date'}
                    onChange={setField}
                    label={t('End date')}
                    disabled={interaction.to === 'permanent'}
                  />
                  <Input
                    time
                    value={interaction.end_date}
                    name={'end_date'}
                    onChange={setField}
                    label={t('End time')}
                    disabled={interaction.to === 'permanent'}
                  />
                </InnerScrollWrapper>
              </InnerMaxWidth>
            </ContentWrapper>
          ) : (
            <ContentWrapper>
              <Description mb={'21px'}>
                {t(
                  'Congratulations, you have arrived at the last stage of setting up your interaction! You can now either: use the direct link of your interaction that you can share on all your media, or configure your interaction directly in the already existing distribution points :'
                )}
              </Description>
              <Flex height={'calc(100% - 61px)'}>
                <InnerScrollWrapper>
                  <InputLabels label={t('Type')}>
                    {interaction.type === INTERACTION_API_TYPE_MAP.voice ? (
                      <TextSummary>
                        {INTERACTION_FRONTEND_TYPE_MAP.recording_question.label}
                      </TextSummary>
                    ) : interaction.type === INTERACTION_API_TYPE_MAP.game ? (
                      <TextSummary>
                        {GAME_TYPES.map(type => type.value === interaction.game_type && type.label)}
                      </TextSummary>
                    ) : (
                      <TextSummary>
                        {
                          INTERACTION_FRONTEND_TYPE_MAP.single_choice_question
                            .label
                        }
                        &nbsp;
                        {interaction.testimonial_demanded
                          ? SURVEY_TYPES[1].label
                          : SURVEY_TYPES[0].label}
                      </TextSummary>
                    )}
                  </InputLabels>
                  <InputLabels label={t('Direct link')}>
                    <TextSummary
                      semiBold
                      as={'a'}
                      theme={'link'}
                      href={interaction.shareUrl}
                      target={'_blank'}
                      rel={'noreferrer'}
                    >
                      {interaction.shareUrl}
                    </TextSummary>
                  </InputLabels>

                  <Heading>
                    {interaction.type === INTERACTION_API_TYPE_MAP.voice
                      ? t('Settings for voice participation')
                      : t('Survey parameters')}
                  </Heading>
                  <InputLabels label={t('Title')}>
                    <TextSummary>{interaction.title}</TextSummary>
                  </InputLabels>
                  <InputLabels label={t('Interaction text')}>
                    <TextSummary>{interaction.text}</TextSummary>
                  </InputLabels>
                  {(
                    interaction.type === INTERACTION_API_TYPE_MAP.vote
                    || (interaction.type === INTERACTION_API_TYPE_MAP.game && interaction.game_type === 'answer')
                  ) && (
                    <>
                      {interaction.choices
                        .filter((choice) => choice)
                        .map((choice, index) => (
                          <InputLabels
                            label={!index && t('Possible answer')}
                            description={!index && interaction.choices.length + t(' possible answers.')}
                            key={`choice-${index}`}
                            mt={index === 1 ? '-12px' : undefined}
                          >
                            <TextSummary>{choice}</TextSummary>
                          </InputLabels>
                        ))}
                      <InputLabels
                        description={t(
                          'By limiting to 1 vote per participant, you avoid multiple votes during a poll.'
                        )}
                        label={t('Limit to 1 vote')}
                      >
                        <TextSummary>
                          {interaction.limit_1_vote ? t('Yes') : t('No')}
                        </TextSummary>
                      </InputLabels>
                      <InputLabels
                        description={t(
                          'This option allows the real-time display of the survey result when your audience participates.'
                        )}
                        label={t('Show results')}
                      >
                        <TextSummary>
                          {interaction.show_results ? t('Yes') : t('No')}
                        </TextSummary>
                      </InputLabels>
                    </>
                  )}
                  {interaction.testimonial_demanded && (
                    <>
                      <Heading>{t('Settings for voice participation')}</Heading>
                      <InputLabels
                        label={t('Incentive text')}
                        description={t(
                          'This text makes it possible to personalise the incentive to leave a voice participation after the vote.'
                        )}
                      >
                        <TextSummary>{interaction.testimonial}</TextSummary>
                      </InputLabels>
                    </>
                  )}
                  {(interaction.testimonial_demanded ||
                    interaction.type === INTERACTION_API_TYPE_MAP.voice) && (
                    <InputLabels
                      label={t('Recording time')}
                      description={t(
                        'You can set a maximum recording time for a voice participation.'
                      )}
                    >
                      <TextSummary>
                        {interaction.max_recording_time} {t('seconds')}
                      </TextSummary>
                    </InputLabels>
                  )}
                  <InputLabels label={t('Personal information')}>
                    <TextSummary>{customisation.text_personal}</TextSummary>
                  </InputLabels>
                  <InputLabels label={t('Enable written answer')}>
                    <TextSummary>
                      {interaction.enable_written_answer ? t('Yes') : t('No')}
                    </TextSummary>
                  </InputLabels>
                  {(interaction.testimonial_demanded ||
                    interaction.type === INTERACTION_API_TYPE_MAP.voice) && (
                    <>
                      <InputLabels
                        description={t(
                          'This option allows you to collect information from the audience who sends their voice participation as part of the broadcast of their message on the air.'
                        )}
                        label={t('Gathering information')}
                      >
                        <TextSummary>
                          {interaction.gather_information ? t('Yes') : t('No')}
                        </TextSummary>
                      </InputLabels>
                      <Checkboxes
                        possibleValues={AVAILABLE_INFORMATION}
                        value={interaction.information_to_gather}
                        disabled
                      />
                    </>
                  )}
                  <InputLabels label={t('Personal information')}>
                    <TextSummary>
                      {customisation.text_personal}
                    </TextSummary>
                  </InputLabels>
                  <InputLabels label={t('Thank-you text')}>
                    <TextSummary>{customisation.text_thanks}</TextSummary>
                  </InputLabels>

                  <Heading>{t('Availability of interaction')}</Heading>
                  <InputLabels
                    description={t(
                      'You can choose whether your interaction is available now or whether it is scheduled to start at a later date.'
                    )}
                    label={t('From')}
                  >
                    <TextSummary>
                      {interaction.from === 'now' ? t('Now') : t('Programmed')}
                    </TextSummary>
                  </InputLabels>
                  <InputLabels label={t('Start date')}>
                    <TextSummary>
                      {interaction.start_date.format(DATE_FORMAT)}
                    </TextSummary>
                  </InputLabels>
                  <InputLabels label={t('Start time')}>
                    <TextSummary>
                      {interaction.start_date.format(TIME_FORMAT)}
                    </TextSummary>
                  </InputLabels>
                  <InputLabels
                    description={t(
                      'You can choose whether your interaction ends at a desired date or whether it is permanent.'
                    )}
                    label={t('Up to')}
                  >
                    <TextSummary>
                      {interaction.to === 'permanent'
                        ? t('Permanent')
                        : t('Scheduled end')}
                    </TextSummary>
                  </InputLabels>
                  <InputLabels label={t('End date')}>
                    <TextSummary>
                      {interaction.end_date?.format(DATE_FORMAT) || '-'}
                    </TextSummary>
                  </InputLabels>
                  <InputLabels label={t('End time')}>
                    <TextSummary>
                      {interaction.end_date?.format(TIME_FORMAT) || '-'}
                    </TextSummary>
                  </InputLabels>
                </InnerScrollWrapper>
                <Box>
                  <WidgetPreview
                    backgroundMedia={interaction.background}
                    channel={channel}
                    interactionText={interaction.text}
                    customisation={customisation || {}}
                  />
                </Box>
              </Flex>
            </ContentWrapper>
          )}
        </Content>
      </Container>
      <Footer>
        {step === 3 ? (
          <>
            <div />
            <FlexCenter>
              <Button onClick={closeModal} mr={'25px'} theme={'light'}>
                {t('Back to the list')}
              </Button>
              <Button
                px={'52px'}
                as={Link}
                to={'/timeline'}
                onClick={closeModal}
              >
                {t('Go to distribution points')}
              </Button>
            </FlexCenter>
          </>
        ) : step === 1 && showColors ? (
          <>
            <Button onClick={resetCustomisation} theme={'light'}>
              {t('Reset default value')}
            </Button>
            <FlexCenter>
              <Button onClick={() => setShowColors(false) || saveInteraction()}>
                {t('Back to interaction settings')}
              </Button>
            </FlexCenter>
          </>
        ) : (
          <>
            {step !== 0 ? (
              <Button onClick={previousStep} theme={'light'}>
                {t('Back')}
              </Button>
            ) : (
              <div />
            )}
            <FlexCenter>
              {isSaved && (
                <Text size={'xxs'} mr={'26px'}>
                  {t('Recorded as a draft')}
                </Text>
              )}
              <Button onClick={nextStep} disabled={!isStepValid}>
                {step === 2 ? t('Activate') : t('Next')}
              </Button>
            </FlexCenter>
          </>
        )}
      </Footer>
    </Wrapper>
  );
};
