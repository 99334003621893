import { useRef, useEffect } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import { PERM_SHARE_URL } from '../constants/common';
import i18n from 'i18next';
import {
  INTERACTION_AVAILABILITY_MAP,
  INTERACTION_FRONTEND_TYPE_MAP,
} from '../constants/interactions/interaction';
import { AVAILABLE_INFORMATION } from '../models/interactions/withCreateInteraction';

export const padTime = (value) => ('00' + value).substr(-2);

export const processInteractiveDates = (data) => {
  data.startDate = moment
    .utc(get(data, 'start_date'))
    .local()
    .format('DD/MM/YYYY');
  data.endDate = moment.utc(get(data, 'end_date')).local();
  data.endDate = data.endDate.isValid()
    ? data.endDate.format('DD/MM/YYYY')
    : '';
  data.interactionType = INTERACTION_FRONTEND_TYPE_MAP[data.type];
  data.shareUrl = `${PERM_SHARE_URL}${data.uuid}`;
  data.availability = INTERACTION_AVAILABILITY_MAP[data.availability];
  return data;
};

export const formatDateInList = (date) => {
  const dateObj = moment(date);
  return dateObj.format('DD MMM YYYY - HH:mm');
};

export const getDateString = (date) => {
  const now = moment();
  const current = moment(date);
  if (now.isSame(current, 'day')) {
    return current.format('HH:mm');
  }
  return current.format('d MMM');
};

export const copyToClipboard = (value, language = 'fr') => {
  const el = document.createElement('textarea');
  el.value = value;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  alert(i18n.t('Copied to clipboard'));
};

export const share = (url, text, language = 'fr') => {
  if (navigator.share) {
    navigator.share({
      title: 'VoxM',
      text,
      url,
    });
  } else {
    copyToClipboard(url, language);
  }
};

export const dateToLocal = (date) => moment.utc(date).local();

export const localDateToStr = (date) => moment.utc(date);

export const todayString = moment().format('DD/MM/YYYY');

export const processStarEndDateRead = (data) => {
  data.start_date = dateToLocal(data.start_date);
  data.end_date = data.end_date ? dateToLocal(data.end_date) : null;
  return data;
};

export const processStarEndDateSave = (data) => {
  data.start_date = localDateToStr(data.start_date);
  data.end_date = data.end_date ? localDateToStr(data.end_date) : null;
  return data;
};

export const processInteraction = (data) => {
  data = processStarEndDateRead(data);
  if (data.choices) {
    data.choicesFull = [...data.choices];
    data.choices = data.choices.map((choice) => choice.label);
  }
  data.shareUrl = `${PERM_SHARE_URL}${data.uuid}`;
  data.information_to_gather = data.information_to_gather || {};
  data.to = !data.end_date ? 'permanent' : 'scheduled';
  data.from = 'programmed';
  data.gather_information = data.gather_information || false;
  data.customisation = data.segment_customisation;
  return data;
};

export const prepareInteractionSave = (data) => {
  data = processStarEndDateSave(data);
  if (data.choices) {
    data.choices = data.choices.map((choice) => ({ label: choice }));
  }
  data.time_offset = moment().utcOffset();
  return data;
};

export const processReply = (interaction) => {
  let duration = interaction.content?.duration;
  if (duration) {
    duration = moment.duration(duration);
    duration = `${padTime(duration.minutes())}:${padTime(duration.seconds())}`;
  } else {
    duration = '';
  }
  interaction.duration = duration;
  interaction.created_at = dateToLocal(interaction.created_at).format(
    'DD/MM/YYYY HH:mm'
  );
  interaction.author = getInteractionAuthor(interaction);
  interaction.transcription = getFullTranscript(interaction);
  if (interaction.transcription && interaction.transcription.length > 150) {
    interaction.transcriptionTooLong = true;
  }
  interaction.recordingUrl = interaction.content?.recording_url;
  return interaction;
};

export const processDiffusionSpace = (diffusionSpace) => {
  let compatibilityValue = '';
  if (diffusionSpace.compatibility) {
    Object.entries(diffusionSpace.compatibility).forEach(([item, value]) => {
      if (value) {
        compatibilityValue += `${INTERACTION_FRONTEND_TYPE_MAP[item].label} `;
      }
    });
  }
  return {
    ...diffusionSpace,
    link: `${PERM_SHARE_URL}${diffusionSpace.uuid}`,
    compatibilityValue,
  };
};

const shortenLanguage = (language) => language.split('-')[0].toLowerCase();

export const getDefaultLanguage = () => {
  const nav = window.navigator;
  const browserLanguagePropertyKeys = [
    'language',
    'browserLanguage',
    'systemLanguage',
    'userLanguage',
  ];
  let i;
  let language;
  let len;
  let shortLanguage = null;

  if (Array.isArray(nav.languages)) {
    for (i = 0; i < nav.languages.length; i++) {
      language = nav.languages[i];
      len = language.length;
      if (!shortLanguage && len) {
        shortLanguage = language;
      }
      if (language && len > 2) {
        shortLanguage = shortenLanguage(language);
      }
    }
  }

  if (!shortLanguage) {
    for (i = 0; i < browserLanguagePropertyKeys.length; i++) {
      language = nav[browserLanguagePropertyKeys[i]];
      len = language.length;
      if (!shortLanguage && len) {
        shortLanguage = language;
      }
      if (language && len > 2) {
        shortLanguage = shortenLanguage(language);
      }
    }
  }

  if (shortLanguage) {
    shortLanguage = shortenLanguage(shortLanguage);
  }
  return shortLanguage in ['fr', 'en', 'pt'] ? shortLanguage : 'fr';
};

export const getFullTranscript = (interaction) => {
  let parsedTranscription = '';
  const transcription = interaction.content?.transcription;
  if (transcription?.data?.length) {
    parsedTranscription = transcription.data
      .map((value) => value.transcript)
      .join(' ');
  }
  if (!parsedTranscription) {
     parsedTranscription = interaction.content?.text || '';
  }
  return parsedTranscription;
};

export const emailRe = /\S+@\S+\.\S+/;
export const phoneRe = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,7}$/;
export const passwordRe = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/;

export const uuid4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

export const enterFullscreen = (elem) => {
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) {
    /* Firefox */
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) {
    /* Chrome, Safari and Opera */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    /* IE/Edge */
    elem.msRequestFullscreen();
  }
};

export const getInteractionAuthor = (interaction) => {
  const { author_app_user, extra_user_information } = interaction;

  const output = {
    name: interaction.nickname || author_app_user?.nickname,
    city: interaction.city || author_app_user?.city,
    phone: author_app_user?.phone,
    udid: author_app_user?.udid,
  };

  if (extra_user_information) {
    AVAILABLE_INFORMATION.forEach(({ key }) => {
      if (extra_user_information[key]) {
        output[key] = extra_user_information[key];
      }
    });
  }
  return output;
};
export const NOT_REGISTERED = i18n.t('Not registered');

export const getCurrentIdLinks = (links, id) =>
  links.map((link) => ({
    ...link,
    path: link.path.replace(':id', id || 'new'),
  }));

export const useChangeEffect = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
};
