import React from 'react';

export const ArrowRightIcon = ({fill}) => (
  <svg id="ic_keyboard_arrow_right_24px"
       xmlns="http://www.w3.org/2000/svg"
       width="7.41"
       height="12"
       viewBox="0 0 7.41 12">
    <path id="ic_keyboard_arrow_right_24px-2"
          data-name="ic_keyboard_arrow_right_24px"
          d="M8.59,16.34l4.58-4.59L8.59,7.16,10,5.75l6,6-6,6Z"
          transform="translate(-8.59 -5.75)"
          fill={fill || "#a4a4a4"}/>
  </svg>
);
