import { Text } from '../text/Text';
import { Flex } from 'reflexbox';
import React from 'react';
import styled from 'styled-components';

const FbBlockContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 9990;
`;

const FbNotSupported = styled.div`
  position: absolute;
  margin-top: 28px;
  width: 87%;
  min-height: 150px;
  border-radius: 5px;
  background: rgb(59, 89, 152);
  z-index: 9920;
  padding: 13px 9px 13px 8px;
  color: white;
  font-size: 15px;
  font-family: 'Ubuntu', sans-serif;
`;

const FbBackdrop = styled.div`
  z-index: 9910;
  opacity: 0.87;
  width: 150%;
  height: 150%;
  background: rgb(100, 100, 100);
`;

const DotsWrapper = styled.div`
  width: 22px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.5px solid rgb(216, 216, 216);
  margin-left: 5px;
`;

const DotsImage = styled.img`
  width: 14px;
  height: 4px;
`;

const ArrowImage = styled.img`
  position: absolute;
  top: 7px;
  right: 3px;
  width: 26px;
  height: 22px;
`;

export const FbBlocked = () => (
  <FbBlockContainer>
    <FbBackdrop />
    <FbNotSupported>
      <Text theme={'white'} size={'h3'} my={'5px'} bold>
        You are almost there !
      </Text>
      <Text theme={'white'} size={'h3'} mb={'16px'} mt={'10px'}>
        You want to react ?
      </Text>
      <Flex alignItems={'center'} mb={'5px'}>
        <Text size={'lg'} theme={'white'}>
          1. Click on
        </Text>
        <DotsWrapper>
          <DotsImage src="/fb-blocked-dots.png" alt="" />
        </DotsWrapper>
      </Flex>
      <Text theme={'white'} size={'lg'}>
        2. Click on "Open in ..." your usual browser
      </Text>
      <ArrowImage src="/fb-blocked-arrow.png" alt="" />
    </FbNotSupported>
  </FbBlockContainer>
);
