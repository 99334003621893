import React from 'react';

export const SettingsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21.046"
    viewBox="0 0 20 21.046"
  >
    <path
      className="active-change"
      fill="#707070"
      d="M19.911 13.554a8.39 8.39 0 0 0 .072-1.031 8.391 8.391 0 0 0-.072-1.031l2.169-1.736a.539.539 0 0 0 .123-.673l-2.055-3.642a.511.511 0 0 0-.627-.232l-2.56 1.052a7.471 7.471 0 0 0-1.737-1.031l-.391-2.789a.505.505 0 0 0-.5-.442h-4.116a.505.505 0 0 0-.5.442l-.395 2.79a7.857 7.857 0 0 0-1.737 1.031l-2.56-1.053a.5.5 0 0 0-.627.232L2.342 9.082a.527.527 0 0 0 .123.673l2.169 1.736a8.539 8.539 0 0 0-.072 1.031 8.539 8.539 0 0 0 .072 1.031L2.465 15.29a.539.539 0 0 0-.123.673L4.4 19.6a.511.511 0 0 0 .627.232l2.56-1.052a7.471 7.471 0 0 0 1.737 1.031l.389 2.789a.505.505 0 0 0 .5.442h4.112a.505.505 0 0 0 .5-.442l.391-2.789a7.856 7.856 0 0 0 1.737-1.031l2.56 1.052a.5.5 0 0 0 .627-.232l2.06-3.636a.539.539 0 0 0-.123-.673zm-7.638 2.652a3.684 3.684 0 1 1 3.6-3.683 3.645 3.645 0 0 1-3.6 3.683z"
      transform="translate(-2.271 -2)"
    />
  </svg>
);
