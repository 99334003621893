import React from 'react';

export const RegularUserIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    data-name="Groupe 946"
    viewBox="0 0 40 40"
  >
    <g data-name="Groupe 418">
      <circle cx="20" cy="20" r="20" fill="#198fe5" data-name="Ellipse 60" />
    </g>
    <g data-name="Groupe 420" transform="translate(10.28 9.965)">
      <g data-name="Groupe 419" transform="translate(0 17.16)">
        <path
          d="M31.52 27.09a12.6 12.6 0 0 1-19.44 0"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="3px"
          data-name="Tracé 477"
          transform="translate(-12.08 -27.09)"
        />
      </g>
      <path
        d="M0 0v12.48"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="3px"
        data-name="Ligne 105"
        transform="translate(9.72)"
      />
      <path
        d="M12.48 0H0"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="3px"
        data-name="Ligne 106"
        transform="translate(3.48 6.24)"
      />
    </g>
  </svg>
);
