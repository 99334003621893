import styled from 'styled-components';
import { Flex } from 'reflexbox';

export const TopNav = styled(Flex)`
  position: absolute;
  top: 0;
  width: 100%;
  height: 38px;
  padding: 18px 19px 0;
  justify-content: center;
  align-items: center;
  z-index: 4000;
`;
