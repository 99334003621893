import React from 'react';
import styled, { css } from 'styled-components';
import { COLOR } from '../../constants/common';
import { Text } from '../text/Text';

const Base = styled.div`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 50%;
`;

const NoImage = styled(Base)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${COLOR.veryLightPink};
  box-sizing: border-box;

  div {
    text-transform: uppercase;
  }
`;

const Image = styled(Base)`
  object-fit: cover;
  overflow: hidden;
`;

export const RoundIcon = ({ src, size, alt }) =>
  src ? (
    <Image src={src} size={size} alt={alt} as={'img'} />
  ) : (
    <NoImage size={size}>
      <Text>{alt || ''}</Text>
    </NoImage>
  );
