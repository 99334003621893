import React from 'react';

export const HappyIcon = () => (
  <svg height="17.791" viewBox="0 0 13.3 17.791" width="13.3" xmlns="http://www.w3.org/2000/svg">
    <g fillRule="evenodd">
      <path
        d="m10.811 27.3-.354 2.384a.983.983 0 0 0 1.116 1.116c.751-.111 1.79-.266 2.381-.346a1.437 1.437 0 0 1 .72.078 6.651 6.651 0 1 0 -4.362-6.245 6.471 6.471 0 0 0 .412 2.289 1.447 1.447 0 0 1 .087.724z"
        fill="#ffc45c" transform="translate(-10.32 -13.149)" />
      <path d="m26.5 41.422-5.462.008a.912.912 0 0 0 -.861 1.206 3.8 3.8 0 0 0 7.194 0 .92.92 0 0 0 -.871-1.217z"
            fill="#455a63" transform="translate(-17.118 -29.636)" />
      <path
        d="m21.51 44.461a3.777 3.777 0 0 1 -.544-1.034.912.912 0 0 1 .861-1.206l5.462-.008a.941.941 0 0 1 .122.008.919.919 0 0 0 -.913-.8l-5.462.008a.912.912 0 0 0 -.861 1.206 3.809 3.809 0 0 0 1.335 1.826z"
        fill="#38454a" transform="translate(-17.118 -29.636)" />
      <path
        d="m20.917 32.261a.583.583 0 0 1 .268-.374 1.053 1.053 0 0 1 .334-.148 1.544 1.544 0 0 1 .718 0 1.158 1.158 0 0 1 .23.086.626.626 0 0 1 .371.439.155.155 0 1 0 .309 0 .988.988 0 0 0 -.391-.853 1.392 1.392 0 0 0 -.879-.326 1.489 1.489 0 0 0 -.47.086 1.341 1.341 0 0 0 -.407.229.988.988 0 0 0 -.389.856.154.154 0 1 0 .309 0z"
        fill="#455a63" transform="translate(-17.448 -22.468)" />
      <path
        d="m35.394 32.261a.581.581 0 0 1 .268-.374 1.056 1.056 0 0 1 .338-.148 1.544 1.544 0 0 1 .718 0 1.151 1.151 0 0 1 .23.086.625.625 0 0 1 .371.439.154.154 0 1 0 .309 0 .987.987 0 0 0 -.391-.853 1.39 1.39 0 0 0 -.879-.326 1.486 1.486 0 0 0 -.469.086 1.339 1.339 0 0 0 -.411.237.99.99 0 0 0 -.389.856.155.155 0 0 0 .309 0z"
        fill="#455a63" transform="translate(-27.484 -22.468)" />
      <path
        d="m24.845 47.509a.307.307 0 0 0 .118.371 3.8 3.8 0 0 0 4.123 0 .307.307 0 0 0 .118-.372 2.432 2.432 0 0 0 -4.359 0z"
        fill="#f45558" transform="translate(-20.374 -32.918)" />
      <g fill="#2294ef">
        <path d="m29.449 3.782v2.454a.782.782 0 1 0 1.565 0v-2.454a.782.782 0 0 0 -1.565 0z"
              transform="translate(-23.581 -3)" />
        <path d="m40.207 8.77-.83 1.438a.782.782 0 1 0 1.355.782l.83-1.438a.783.783 0 0 0 -1.355-.782z"
              transform="translate(-30.391 -6.729)" />
        <path d="m17.017 9.54.832 1.442a.782.782 0 1 0 1.351-.782l-.832-1.442a.782.782 0 1 0 -1.355.782z"
              transform="translate(-14.89 -6.72)" />
      </g>
    </g>
  </svg>
);
