import React from 'react';

export const PlusIcon = () => (
  <svg
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m3270.976 897.271a10 10 0 1 0 10 10 10 10 0 0 0 -10-10zm4.219 11.1h-3.218v3.365a1.022 1.022 0 1 1 -2.044 0v-3.364h-3.133a1.022 1.022 0 1 1 0-2.044h3.132v-2.986a1.022 1.022 0 0 1 2.044 0v2.986h3.218a1.022 1.022 0 1 1 0 2.043zm0 0"
      fill="#f89e14"
      transform="translate(-3260.976 -897.271)"
    />
  </svg>
);
