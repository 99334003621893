import React from 'react';
import { COLOR } from '../../constants/common';

export const VictoryBadgeIcon = ({ color = COLOR.orange, width = '16', height = '22' }) => (
  <svg
    height={height}
    viewBox="0 0 9.219 13.159"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill={color} transform="translate(-1815.868 -1128.862)">
      <path
        d="m1848.212 1161.982a2.249 2.249 0 1 0 2.249 2.249 2.251 2.251 0 0 0 -2.249-2.249z"
        transform="translate(-27.734 -30.522)"
      />
      <path
        d="m1824.915 1249.517-.286-.259-.674-.61-.733.079-.449.048-.106.012-.343.037-1.079 2.292 1.671.022 1.081 1.273 1.229-2.612-.3-.27-.012-.011z"
        transform="translate(-4.955 -110.39)"
      />
      <path
        d="m1879.824 1248.786-.106-.011-.449-.048-.733-.079-.674.61-.287.259-.012.011-.3.27 1.229 2.612 1.081-1.273 1.671-.022-1.079-2.292-.343-.037z"
        transform="translate(-56.581 -110.39)"
      />
      <path d="m1820.478 1136.729a3.02 3.02 0 1 1 3.02-3.02 3.023 3.023 0 0 1 -3.02 3.02zm4.609-4.517-1.423-.817-.337-1.606-1.632.175-1.217-1.1-1.217 1.1-1.632-.175-.337 1.606-1.423.817.671 1.5-.671 1.5 1.423.817.337 1.606.124-.013.449-.048.448-.048.12-.013.489-.053.368.333.388.351.121.109.178.161.162.146.162-.146.178-.161.121-.109.3-.273.454-.411.793.085.081.009.185.02.448.048.125.013.337-1.606 1.423-.817-.671-1.5.671-1.5z" />
    </g>
  </svg>
);
