import React from 'react';

export const GoToIconLeft = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <g transform="translate(-1379.463 -304)">
      <rect
        width="20"
        height="20"
        fill="#1592e6"
        rx="2"
        transform="translate(1379.463 304)"
      />
      <path
        fill="#fff"
        d="M22.95 20.286v-2.681a.533.533 0 0 0-.929-.357l-4.8 5.333a.533.533 0 0 0-.017.693L22 29.14a.533.533 0 0 0 .946-.336v-2.658c4.213.137 6.842 1.938 8.027 5.493a.533.533 0 0 0 .506.364.513.513 0 0 0 .089-.003.533.533 0 0 0 .447-.526c.001-6.284-3.779-10.897-9.065-11.188z"
        transform="translate(1364.387 289.613)"
      />
    </g>
  </svg>
);
