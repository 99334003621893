import { useState } from 'react';

export const WithPlayer = () => {
  const [listening, setListening] = useState();
  const [duration, setDuration] = useState();
  const [audio, setAudio] = useState();
  let [lisetningTime, setListeningTime] = useState(0);
  let [listeningTimeInterval, setListeningTimeInterval] = useState();

  const listen = async url => {
    if (listening !== url && audio) {
      audio.pause();
      clearInterval(listeningTimeInterval);
    }
    const newAudio = new Audio(url);
    newAudio.addEventListener('ended', stop);
    newAudio.currentTime = 0;
    setAudio(newAudio);
    listeningTimeInterval = setInterval(playerTick, 30);
    setListeningTimeInterval(listeningTimeInterval);
    newAudio.play();
    setListening(url);
    newAudio.addEventListener('durationchange', () =>
      setDuration(newAudio.duration)
    );
  };

  const stop = () => {
    if (audio) {
      audio.pause();
    }
    clearInterval(listeningTimeInterval);
    setListeningTime(0);
    setListening(null);
  };

  const playerTick = () => {
    setListeningTime(lisetningTime => lisetningTime + 3);
  };

  return {
    duration,
    lisetningTime,
    listening,
    listen,
    stop,
  };
};
