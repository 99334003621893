import React from 'react';
import { COLOR } from '../../constants/common';

export const TextInteractionIcon = ({ color = COLOR.grey1 }) => (
  <svg enableBackground="new 0 0 24 24" viewBox="0 0 24 24">
    <clipPath id="text-interaction">
      <path d="m0 0h24v24h-24z" />
    </clipPath>
    <g
      clipPath="url(#text-interaction)"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        d="m4.8 6.8c0-1.1.9-2 2-2h10.5c1.1 0 2 .9 2 2v7.5c0 1.1-.9 2-2 2h-2.6l-2.6 3-2.6-3h-2.7c-1.1 0-2-.9-2-2z"
        strokeWidth="1.5"
      />
      <path d="m9.5 11c0 .3-.2.5-.5.5s-.5-.2-.5-.5.2-.5.5-.5.5.2.5.5z" />
      <path d="m12.5 11c0 .3-.2.5-.5.5s-.5-.2-.5-.5.2-.5.5-.5.5.2.5.5z" />
      <path d="m15.5 11c0 .3-.2.5-.5.5s-.5-.2-.5-.5.2-.5.5-.5.5.2.5.5z" />
    </g>
  </svg>
);
