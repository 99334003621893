import React from 'react';
import styled from 'styled-components';
import { COLOR } from '../../constants/common';
import { Flex } from 'reflexbox';
import { SendIcon } from '../icons/SendIcon';
import { Action } from '../actions/Actions';

const Container = styled(Flex)`
  border: 1px solid ${COLOR.stone2_12};
  border-radius: 8px;
  height: 144px;
  width: 100%;
  padding: 11px 16px;
`;

const Text = styled.textarea`
  border: none;
  font-size: 16px;
  line-height: 24px;
  height: 100%;
  flex: 1;
  resize: none;
`;

export const Reply = ({ name, value, placeholder, onChange, onSubmit }) => (
  <Container>
    <Text
      placeholder={placeholder}
      value={value}
      onChange={e => onChange && onChange(e.target.value)}
      name={name}
      id={name}
    />
    <Action ml={'19px'} onClick={onSubmit} mt={'5px'}>
      <SendIcon/>
    </Action>
  </Container>
);
