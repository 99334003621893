import React from 'react';

export const FullScreenIcon = ({size="14"}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 14 14"
  >
    <path
      d="M7 14H5v5h5v-2H7zm-2-4h2V7h3V5H5zm12 7h-3v2h5v-5h-2zM14 5v2h3v3h2V5z"
      transform="translate(-5 -5)"
      fill="#1592e6"
    />
  </svg>
);
