import React from 'react';
import { APP_USER_SCREENS, WithAppUser } from '../../models/widget/withAppUser';
import styled from 'styled-components';
import { Box, Flex } from 'reflexbox';
import { ArrowBackIcon } from '../../ui/icons/ArrowBackIcon';
import { WidgetButton } from '../../ui/widget/WidgetButton';
import { Text } from '../../ui/text/Text';
import { Pointer } from '../../ui/box/Pointer';
import { Input } from '../../ui/input/Input';
import { NextStep } from '../../ui/widget/icons/NextStep';
import { ScreenWrapper } from '../../ui/widget/ScreenWrapper';
import { TopNav } from '../../ui/widget/TopNav';
import { COLOR, getTermsLink } from '../../constants/common';
import { Translate } from '../../features/translate/Translate';
import { phoneRe } from '../../utils/utils';
import { Link } from '../../ui/link/Link';
import { useUser } from '../../models/users/withUser';

const LoginRegisterInner = styled.div`
  margin-top: 92.3px;
  width: 100%;
  input {
    background: transparent;
    color: white;
  }
`;

const TermsLink = styled(Link)`
  font-size: 12px;
  line-height: 11px;
  color: #a29e9e;
  margin-left: 2px;
`;

const SentYouWrapper = styled.div`
  margin-bottom: 17px;
  font-size: 14px;
  line-height: 16px;
  b {
    color: white;
  }
  div {
    display: inline;
    color: #d2d2d2;
  }
`;

export const LoginRegisterScreen = ({
  onAppUser,
  initialScreen,
  channelId,
  onClose,
  hideAnonymous,
}) => {
  const {
    login,
    nickname,
    register,
    password,
    email,
    activeScreen,
    setActiveScreen,
    setEmail,
    setPassword,
    handleLeftArrow,
    topNavText,
    showValidation,
    emailValid,
    nicknameValid,
    forgotPassword,
    setNicknameField,
    setEmailField,
    handleRegisterFirstClick,
    loginError,
    phone,
    setPhone,
  } = WithAppUser({ onAppUser, initialScreen, channelId });
  const { language } = useUser();
  const isMain = activeScreen === APP_USER_SCREENS.main;
  return (
    <ScreenWrapper>
      <TopNav>
        <Pointer onClick={() => (isMain ? onClose() : handleLeftArrow())}>
          <ArrowBackIcon />
        </Pointer>
        <Text bold color={COLOR.white} flex={1} size={'h4'} center upper>
          {topNavText}
        </Text>
        <Box width={'9px'} />
      </TopNav>
      {isMain && (
        <>
          {hideAnonymous ? (
            hideAnonymous === 'home' ? (
              <>
                <Text
                  size={'h5'}
                  color={'#d2d2d2'}
                  center
                  width={'232px'}
                  mt={'105px'}
                >
                  You interact regularly and want to find all your messages and
                  many other advantages.
                </Text>
                <Text
                  size={'h5'}
                  color={'#d2d2d2'}
                  center
                  width={'232px'}
                  mb={'50px'}
                >
                  You must log in or register to access your personal space.
                </Text>
              </>
            ) : (
              <Text
                size={'h5'}
                color={'#d2d2d2'}
                center
                width={'232px'}
                mb={'92px'}
                mt={'105px'}
              >
                This interaction requires that you are registered and logged in
                to participate ;-)
              </Text>
            )
          ) : (
            <Text
              size={'h5'}
              color={'#d2d2d2'}
              center
              width={'238px'}
              mb={'92px'}
              mt={'114px'}
            >
              How do you want to participate ?
            </Text>
          )}
          <WidgetButton
            onClick={() => setActiveScreen(APP_USER_SCREENS.register)}
          >
            Register
          </WidgetButton>
          {!hideAnonymous ? (
            <WidgetButton
              reversed
              mt={'32px'}
              onClick={() =>
                setActiveScreen(APP_USER_SCREENS.sendWithoutRegister)
              }
            >
              Send without registering
            </WidgetButton>
          ) : null}
          <Text
            theme={'white'}
            upper
            size={'md'}
            mt={'27px'}
            bold
            pointer
            onClick={() => setActiveScreen(APP_USER_SCREENS.loginEmail)}
          >
            Login
          </Text>
        </>
      )}
      {activeScreen === APP_USER_SCREENS.loginEmail && (
        <LoginRegisterInner>
          <Input
            widgetInput
            value={email}
            onChange={(field, value) => setEmail(value)}
            label={'Enter your e-mail'}
            type={'email'}
            onEnter={() =>
              email && setActiveScreen(APP_USER_SCREENS.loginPassword)
            }
            autoFocus
          />
          <Flex justifyContent={'flex-end'} mt={'54px'}>
            <Pointer
              onClick={() =>
                email && setActiveScreen(APP_USER_SCREENS.loginPassword)
              }
            >
              <NextStep />
            </Pointer>
          </Flex>
        </LoginRegisterInner>
      )}
      {activeScreen === APP_USER_SCREENS.loginPassword && (
        <LoginRegisterInner>
          <Input
            widgetInput
            value={password}
            onChange={(field, value) => setPassword(value)}
            label={'Enter your password'}
            type={'password'}
            onEnter={login}
            autoFocus
            showValidation={loginError}
            valid={!loginError}
          />
          <Flex justifyContent={'space-between'} mt={'54px'}>
            <Text
              upper
              pointer
              onClick={() =>
                setActiveScreen(APP_USER_SCREENS.forgotPassword) ||
                setPassword('') ||
                forgotPassword()
              }
              color={'#a29e9e'}
              size={'md'}
              bold
              display={'flex'}
              alignItems={'center'}
            >
              Forgot your password ?
            </Text>
            <Pointer onClick={login} height={'40px'}>
              <NextStep />
            </Pointer>
          </Flex>
        </LoginRegisterInner>
      )}
      {activeScreen === APP_USER_SCREENS.forgotPassword && (
        <LoginRegisterInner>
          <SentYouWrapper>
            <b>{email}&nbsp;</b>
            <Translate>
              we sent you an email to change your password, just follow the
              instructions
            </Translate>
          </SentYouWrapper>
          <Input
            widgetInput
            value={password}
            onChange={(field, value) => setPassword(value)}
            label={'Enter your new password'}
            type={'password'}
            onEnter={login}
          />
          <Flex justifyContent={'space-between'} mt={'54px'}>
            <Text
              upper
              pointer
              onClick={forgotPassword}
              color={'#a29e9e'}
              bold
              size={'md'}
              display={'flex'}
              alignItems={'center'}
            >
              Send email again ?
            </Text>
            <Pointer onClick={login} height={'40px'}>
              <NextStep />
            </Pointer>
          </Flex>
        </LoginRegisterInner>
      )}
      {activeScreen === APP_USER_SCREENS.register && (
        <LoginRegisterInner>
          <Input
            widgetInput
            value={email}
            onChange={setEmailField}
            label={'Enter your email'}
            type={'email'}
            showValidation={showValidation}
            valid={emailValid}
            autoFocus={true}
          />
          <Box mt={'38px'} />
          <Input
            widgetInput
            value={password}
            onChange={(field, value) => setPassword(value)}
            label={'Enter your password'}
            type={'password'}
            description={'Password must be at least 8 characters long'}
            showValidation={showValidation}
            valid={password.length > 7}
            onEnter={handleRegisterFirstClick}
            autoComplete="new-password"
          />

          <Flex justifyContent={'flex-end'} mt={'54px'}>
            <Pointer onClick={handleRegisterFirstClick} height={'40px'}>
              <NextStep />
            </Pointer>
          </Flex>
        </LoginRegisterInner>
      )}
      {activeScreen === APP_USER_SCREENS.registerFinish && (
        <LoginRegisterInner>
          <Input
            widgetInput
            value={nickname}
            valid={nicknameValid}
            showValidation={showValidation}
            onChange={setNicknameField}
            label={'Choose a pseudonym'}
            onEnter={() => register()}
            autoFocus={true}
          />
          <Box mt={'38px'} />
          <Input
            type={'tel'}
            widgetInput
            value={phone}
            valid={phoneRe.test(phone)}
            showValidation={showValidation}
            onChange={(field, value) => setPhone(value)}
            label={'Enter your phone number'}
            onEnter={() => register()}
          />

          <Flex justifyContent={'flex-end'} mt={'54px'}>
            <Box display={'inline'} alignItems={'center'}>
              <Text
                color={'#a29e9e'}
                size={'xs'}
                display={'inline'}
                lh={language === 'en' ? '35px' : '15px'}
              >
                By validating, you accept the
              </Text>
              <TermsLink href={getTermsLink(language)} target={'_blank'}>
                Terms of use
              </TermsLink>
            </Box>
            <Pointer onClick={() => register()} height={'40px'} ml={'15px'}>
              <NextStep />
            </Pointer>
          </Flex>
        </LoginRegisterInner>
      )}
      {activeScreen === APP_USER_SCREENS.sendWithoutRegister && (
        <LoginRegisterInner>
          <Input
            widgetInput
            value={nickname}
            onChange={setNicknameField}
            label={'Just choose a pseudonym'}
            description={
              'In the event that your message is broadcast on the air'
            }
            showValidation={showValidation}
            valid={nicknameValid}
            onEnter={() => register({ is_anonymous: true })}
          />
          <Flex justifyContent={'flex-end'} mt={'54px'}>
            <Pointer
              onClick={() => register({ is_anonymous: true })}
              height={'40px'}
            >
              <NextStep />
            </Pointer>
          </Flex>
        </LoginRegisterInner>
      )}
    </ScreenWrapper>
  );
};
