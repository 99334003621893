import React from 'react';

export const NextStep = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <g
      id="Groupe_924"
      data-name="Groupe 924"
      transform="translate(-321.457 -311)"
    >
      <circle
        id="Ellipse_84"
        cx="20"
        cy="20"
        r="20"
        fill="#fff"
        data-name="Ellipse 84"
        transform="translate(321.457 311)"
      />
      <path
        id="Tracé_559"
        d="M4 4l7 6.5L4 17"
        data-name="Tracé 559"
        transform="translate(333.957 320.5)"
        fill="none"
        stroke="#2b2b2b"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="3px"
      />
    </g>
  </svg>
);
