import styled, { css } from 'styled-components';

export const RecordButton = styled.div`
  cursor: pointer;
  position: absolute;
  bottom: -45px;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 40px;
  color: white;

  ${({ reversed, mainColor }) =>
    reversed
      ? css`
          color: ${mainColor || '#a37578'};
          #border {
            fill: ${mainColor || '#a37578'};
          }
          #background {
            fill: white;
          }
          path {
            fill: ${mainColor || '#a37578'};
            stroke: ${mainColor || '#a37578'};
          }
        `
      : css`
          #background {
            fill: ${mainColor || '#a37578'};
          }
          #border {
            fill: white;
          }
        `}

  & > *:nth-child(2) {
    position: absolute;
  }
  & > *:nth-child(3) {
    position: absolute;
    bottom: -18px;
  }
`;
