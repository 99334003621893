import React from 'react';
import { COLOR } from '../../constants/common';

export const SendIcon = ({ color = COLOR.white }) => (
  <svg
    height="26"
    width="26"
  >
    <clipPath id="send">
      <path d="m0 0h26v26h-26z" transform="translate(0 .246)" />
    </clipPath>
    <g
      clipPath={'url(#send)'}
      fill={'none'}
      stroke={color}
      strokeLinecap={'round'}
      strokeLinejoin={'round'}
      strokeWidth={'1.5'}
      transform="translate(0 -.246)"
    >
      <path
        d="m4.75 20.746 8-16 8 16-8-3.861z"
        transform="translate(.49 .26)"
      />
      <path d="m12 15.784v-3.034" transform="translate(1.238 1.085)" />
    </g>
  </svg>
);
