import styled from 'styled-components';
import { Text } from '../../new/ui/text/Text';

export const TextSummary = styled(Text).attrs(() => ({
  capital: true,
}))`
  padding-left: 8px;
  padding-top: 8px;
  height: 33px;
`;
