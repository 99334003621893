import React from 'react';

export const MinusIcon = () => (
  <svg
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m3576.833 971.107a9.956 9.956 0 0 0 -3.64-3.639 10.061 10.061 0 0 0 -10.039 0 9.954 9.954 0 0 0 -3.64 3.639 10.065 10.065 0 0 0 0 10.039 9.958 9.958 0 0 0 3.639 3.639 10.063 10.063 0 0 0 10.04 0 9.954 9.954 0 0 0 3.64-3.639 10.068 10.068 0 0 0 0-10.039zm-2.826 5.853a.845.845 0 0 1 -.833.833h-10a.845.845 0 0 1 -.833-.833v-1.666a.846.846 0 0 1 .833-.833h10a.845.845 0 0 1 .833.833zm0 0"
      fill="#abaaac"
      transform="translate(-3558.173 -966.127)"
    />
    <rect fill="#abaaac" height="5" rx="1" width="13" x="3.584" y="7.531" />
    <rect fill="#fff" height="3" rx="1" width="11" x="4.584" y="8.531" />
  </svg>
);
