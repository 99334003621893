import React from 'react';
import i18n from 'i18next';
import { SettingsIcon } from '../../ui/icons/SettingsIcon';
import { ModerationIcon } from '../../ui/icons/ModerationIcon';
import { CalendarIcon } from '../../ui/icons/CalendarIcon';
import { DistributionIcon } from '../../ui/icons/DistributionIcon';
import { ParticipationsIcon } from '../../ui/icons/ParticipationsIcon';
import { EditIcon } from '../../ui/icons/EditIcon';
import { HomeIcon } from '../../ui/icons/HomeIcon';
import { UsersIcon } from '../../ui/icons/UsersIcon';
import { GoToIconLeft } from '../../ui/icons/GoToIconLeft';
import { CustomisationIcon } from '../../ui/icons/CustomisationIcon';
import { WITH_EVOL_RTL } from '../common';

export const mainLinks = [
  {
    title: i18n.t('Interactions'),
    path: '/interactions',
    icon: <CalendarIcon />,
  },
  {
    title: i18n.t('Points of distribution'),
    path: '/timeline',
    icon: <DistributionIcon />,
  },
  {
    title: i18n.t('Moderation'),
    path: '/moderation',
    icon: <ModerationIcon />,
  },
  { title: i18n.t('Settings'), path: '/settings', icon: <SettingsIcon /> },
  {
    title: i18n.t('Back to BE Admin'),
    BEAdmin: true,
    path: '/channels',
    icon: <GoToIconLeft />,
  },
];

export const interactionLinks = [
  {
    title: i18n.t('Participations'),
    path: '/participations/:id',
    icon: <ParticipationsIcon />,
  },
  {
    title: i18n.t('Settings'),
    path: '/interactions/:id',
    icon: <EditIcon />,
  },

];
if ( WITH_EVOL_RTL) {
  interactionLinks.push(
    {
      title: i18n.t('Webview'),
      path: '/interactions/:id/webview/',
      icon: <EditIcon />,
    }
  );
}

export const settingsLinks = [
  {
    title: i18n.t('Media'),
    path: '/settings',
    icon: <HomeIcon />,
  },
  {
    title: i18n.t('Customisation'),
    path: '/customisation',
    icon: <CustomisationIcon />,
  },
  {
    title: i18n.t('Users'),
    path: '/users',
    icon: <UsersIcon />,
  },
];

export const userLinks = [
  {
    title: i18n.t('Profil'),
    path: '/users/:id',
    icon: <EditIcon />,
  },
];

export const distributionLinks = [
  {
    title: i18n.t('Calendar'),
    path: '/timeline',
    icon: <CalendarIcon />,
  },
  {
    title: i18n.t('Broadcasting channel'),
    path: '/broadcast',
    icon: <DistributionIcon />,
  },
];

export const channelsLinks = [
  {
    title: i18n.t('Medias'),
    path: '/channels',
    icon: <HomeIcon />,
  },
];

export const channelLinks = [
  {
    title: i18n.t('Media'),
    path: '/channels/:id',
    icon: <HomeIcon />,
  },
];
