import React from 'react';
import styled, { css } from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

import { COLOR } from '../../constants/common';
import { useUser } from '../../models/users/withUser';
import { getValueForLanguage } from '../../features/translate/Translate';

const styles = props => css`
  color: ${COLOR.link};
  text-decoration: underline;
  user-select: none;
  cursor: pointer;
  font-size: 14px;

  ${['black', 'light-black'].includes(props.theme) &&
    css`
      color: ${COLOR.stoneDark};
      border-bottom: 1px solid ${COLOR.transparent};
      padding-bottom: 2px;
      padding-top: 2px;
      &:hover {
        color: ${COLOR.accentBlueLight};
        border-bottom: 1px solid ${COLOR.accentBlueLight} !important;
      }
    `} ${(props.theme + '').includes('light') &&
    css`
      font-weight: normal;
    `} &:hover {
    color: ${COLOR.accentBlueDark};
  }
`;
const RouterLinkComponent = styled(RouterLink)`
  ${props => styles(props)};
`;
const SpanComponent = styled.span`
  ${props => styles(props)};
`;
const AnchorComponent = styled.a`
  ${props => styles(props)};
`;

export const Link = props => {
  let { to, href, theme, children } = props;
  const { language } = useUser();
  children = getValueForLanguage(language, children);
  const className = `link-${theme}`;
  if (to) {
    return (
      <RouterLinkComponent className={className} {...props}>
        {children}
      </RouterLinkComponent>
    );
  }
  if (href) {
    return (
      <AnchorComponent className={className} {...props}>
        {children}
      </AnchorComponent>
    );
  }
  return (
    <SpanComponent className={className} {...props}>
      {children}
    </SpanComponent>
  );
};
