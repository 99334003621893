import styled from 'styled-components';
import { COLOR } from '../../constants/common';

export const AppWrapper = styled.div`
  letter-spacing: -0.1px;
  input,
  textarea,
  button {
    letter-spacing: -0.1px;
  }

  ::-webkit-scrollbar {
    width: 6px;
    background-color: rgba(0, 0, 0, 0);
  }

  ::-webkit-scrollbar:hover {
    background-color: ${COLOR.white3};
  }

  ::-webkit-scrollbar-thumb:vertical {
    background: ${COLOR.white3};
  }

  ::-webkit-scrollbar-thumb:vertical:active {
    background: ${COLOR.white3};
  }

  .__react_component_tooltip.show {
    opacity: 1 !important;
  }
`;
