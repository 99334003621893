import React from 'react';
import { Flex } from 'reflexbox';
import styled from 'styled-components';
import { CheckedIcon } from '../icons/CheckedIcon';
import { COLOR } from '../../constants/common';

const Container = styled(Flex)`
  border: ${({ checked, borderColor }) =>
    checked ? 'none' : `1px solid ${borderColor}`};
  cursor: pointer;
`;

export const Checkbox = ({
  checked,
  onChange,
  checkedIcon,
  borderColor = COLOR.grey,
  mt = '6px',
}) => (
  <Container
    checked={checked}
    onClick={() => onChange && onChange()}
    width={'14px'}
    height={'14px'}
    alignItems={'center'}
    justifyContent={'center'}
    borderColor={borderColor}
    mt={mt}
  >
    {checked ? checkedIcon || <CheckedIcon /> : null}
  </Container>
);
