import React from 'react';
import { COLOR } from '../../constants/common';

export const VoiceInteractionIcon = ({ color = COLOR.grey1 }) => (
  <svg viewBox="0 0 24 24">
    <clipPath id="voice">
      <path d="m0 0h24v24h-24z" />
    </clipPath>
    <g
      clipPath={'url(#voice)'}
      fill={'none'}
      stroke={color}
      strokeWidth={'1.5'}
      strokeLinecap={'round'}
      strokeLinejoin={'round'}
    >
      <path d="m8.8 8c0-1.8 1.5-3.2 3.2-3.2s3.2 1.5 3.2 3.2v3c0 1.8-1.5 3.2-3.2 3.2s-3.2-1.4-3.2-3.2z" />
      <path d="m5.8 12.8s.2 4.5 6.2 4.5 6.2-4.5 6.2-4.5" />
      <path d="m12 17.8v1.5" />
    </g>
  </svg>
);
