import React from 'react';
import styled from 'styled-components';
import { Flex } from 'reflexbox';
import { Text } from '../text/Text';
import { Box } from 'reflexbox';
import { Description } from './Description';

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
  ${({ mt }) => mt && `margin-top: ${mt};`}
`;

const WrapperInner = styled(Flex)`
  & > :last-child {
    width: ${({ sm }) => (sm ? 232 : 328)}px;
  }
`;

export const InputLabels = ({
  label,
  description,
  select,
  mt,
  mr,
  children,
  labelRight,
  sm,
  semiBold=true,
}) => {
  const marginBottom = description ? 12 : label ? 20 : 15;

  return (
    <Wrapper marginBottom={marginBottom} mt={mt}>
      <WrapperInner sm={sm}>
        {label ? (
          <Text
            flex={1}
            semiBold={semiBold}
            mt={'7px'}
            capital
            right={labelRight}
            mr={mr || (labelRight ? '30px' : 0)}
          >
            {label} :
          </Text>
        ) : (
          <Box flex={1} />
        )}
        {children}
      </WrapperInner>
      {description && (
        <Description mt={`${select ? 10 : 3}px`} capital>
          {description}
        </Description>
      )}
    </Wrapper>
  );
};
