import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  ${({ open }) =>
    open &&
    css`
      svg {
        transform: rotate(${open ? 180 : 0}deg);
      }
    `}
`;

export const ArrowDropDownIcon = ({ open }) => (
  <Wrapper open={open}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="7.717"
      viewBox="0 0 14 7.717"
    >
      <path
        fill="#abaaac"
        d="M8.59 18.1l4.77-5.355L8.59 7.4l1.469-1.65 6.249 7-6.249 7z"
        transform="rotate(90 14.17 5.58)"
      />
    </svg>
  </Wrapper>
);
