import { useEffect, useRef, useState } from 'react';
import {
  API_ENDPOINTS,
  INTERACTION_API_TYPE_MAP,
} from '../../constants/common';
import {
  handlePartialUpload,
  initPartialUpload,
  request,
} from '../utils/request';
import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import { useParams } from 'react-router-dom';
import { uuid4 } from '../../utils/utils';
import { WithVideoRecorder } from '../../../models/recorder/withVideoRecorder';
import { WithRecorder } from '../../../models/recorder/withRecorder';
import { WithInteraction } from '../interactions/withInteraction';
import { WithMessage } from '../../../models/messages/withMessage';

export const WIDGET_SCREENS = {
  init: 'init',
  recorder: 'recorder',
  videoUploaded: 'videoUploaded',
  voting: 'voting',
  gatherInformation: 'gatherInformation',
  rgpdConsent: 'rgpdConsent',
  sending: 'sending',
  thanks: 'thanks',
  write: 'write',
  results: 'results',
};

export const WIDGET_INFORMATION_MAP = {
  first_name: 'First name',
  name: 'Name',
  city: 'City',
  phone: 'Phone',
  email: 'Email',
  age: 'Age',
};

export const WithWidget = () => {
  const [channel, setChannel] = useState();
  const [gatherInfoErrors, setGatherInfoErrors] = useState({});
  const [background, setBackground] = useState('');
  const [textAnswer, setTextAnswer] = useState('');
  const [initialized, setInitialized] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [backgroundReady, setBackgroundReady] = useState(false);
  const [sentInteraction, setSentInteraction] = useState();
  const sentInteractionRef = useRef(sentInteraction);
  sentInteractionRef.current = sentInteraction;
  const [mainColor, setMainColor] = useState();
  const [selectedChoices, setSelectedChoices] = useState({});
  let [activeSegment, setActiveSegment] = useState();
  const activeSegmentRef = useRef(activeSegment);
  activeSegmentRef.current = activeSegment;
  const [activeScreen, setActiveScreen] = useState(WIDGET_SCREENS.init);
  const [screenFading, setScreenFading] = useState(false);
  const [hideAnonymous, setHideAnonymous] = useState(true);
  const { search } = useLocation();
  const { channelId, segmentId } = useParams();
  const [imageRightsGiven, setImageRight] = useState(null);
  const [consentGDPRGiven, setConsentGDPR] = useState(null);
  const [consentGDPRText, setConsentGDPRText] = useState('');

  const isFacebook =
    navigator.userAgent.match(/FBAV|FBAN|FBIOS|FBDV|FBSN|FBID|FBSS/i)
  const [deviceId, setDeviceId] = useState();
  const [uploadProgress, setUploadProgress] = useState(0);
  const interactionDisabled = false;
  const [alreadyReceived, setAlreadyReceived] = useState(false);
  const alreadyReceivedRef = useRef(alreadyReceived);
  alreadyReceivedRef.current = alreadyReceived;
  const [extraUserInformation, setExtraUserInformation] = useState({});
  const [showValidation, setShowValidation] = useState(false);
  const [shouldFetchResults, setShouldFetchResults] = useState();
  const [testimonialAccepted, setTestimonialAccepted] = useState(false);
  const [audioError, setAudioError] = useState('');
  const { fetchInteraction: fetchSegmentById } = WithInteraction({});
  const { fetchInteraction } = WithMessage({});

  const changeActiveScreen = (screen, transitionDuration=200) => {
    setScreenFading(true)
    setTimeout(() => {
      setActiveScreen(screen)
      setTimeout(() => setScreenFading(false), 50)
    }, transitionDuration)
  }

  const setThanksScreen = () => {
    setActiveScreen(WIDGET_SCREENS.thanks);
    if (
      activeSegment &&
      activeSegment.show_results &&
      (!activeSegment.testimonial_demanded || testimonialAccepted) &&
      (activeSegment.type === INTERACTION_API_TYPE_MAP.choice || activeSegment.type === INTERACTION_API_TYPE_MAP.vote)
    ) setTimeout(() => setShouldFetchResults(true), 1000);
  };

  const setVoteScreen = (segment) => {
    setActiveSegment(segment);
    changeActiveScreen(WIDGET_SCREENS.voting);
  };

  const manageChoice = (choice) => {
    if (!choice || !choice.id) {
      return;
    }
    const { id } = choice;
    let newSelected = { ...selectedChoices };
    if (newSelected[id]) {
      // Already exists
      delete newSelected[id];
    } else {
      // Single and multi choice (multi should support only one)
      newSelected = { [id]: choice };
    }
    setSelectedChoices({ ...newSelected });
  };

  const setSendingScreen = (timeout = 2000) => {
    setActiveScreen(WIDGET_SCREENS.sending);
    // fix upload issue when upload takes more than 2 seconds
    // keep default behavior for compatibility purpose
    if (timeout != 0)
      setTimeout(setThanksScreen, timeout);;
  }

  // For voice / video interaction
  const sendInteraction = async (file) => {
    // No timeout here for the sending screen
    // we want to be sure that the upload is ending before the user leave the screen
    setSendingScreen(0);
    setUploadProgress(1);
    const extension = file.name.split('.').pop();
    const { data_id, url } = await initPartialUpload(extension);

    await handlePartialUpload(file, url, setUploadProgress);

    const queryParams = parse(search);
    const query = {
      channel_id: activeSegment.channel_id,
      segment: activeSegment.id,
      language: 'fr',
      site_ref: queryParams.ref || null,
      device_id: deviceId,
      data_id,
      extension,
    };
    if (sentInteractionRef.current && sentInteractionRef.current.id) {
      query.update_interaction_id = sentInteractionRef.current.id;
    }
    const headers = {
      'Content-Disposition': 'form-data; name="file"; filename="recording.wav"',
      'Content-Type': 'video/wav',
      'Extra-User-Information': JSON.stringify(extraUserInformation),
    };
    const promise = request({
      url: API_ENDPOINTS.interactions,
      method: 'PUT',
      query,
      headers,
    });
    resetAudio();
    resetVideo();
    if (window.ga) setTimeout(() => {
      window.ga
        .getAll()[0]
        .send(
          'event',
          'Interaction ' + activeSegment.type,
          'sent',
          activeSegment.id + '',
          1
        );
    }, 0);
    const { data } = await promise;
    if (window.ga) setTimeout(() => {
      window.ga
        .getAll()[0]
        .send(
          'event',
          'Interaction ' + activeSegment.type,
          'received',
          activeSegment.id + '',
          1
        );
    }, 0);
    setUploadProgress(0);
    if (data && data.id) {
      setSentInteraction(data);
    }
    // THe upload is ended
    setThanksScreen();
  };

  const handleAudioError = (error) => {
    setAudioError(error);
    changeActiveScreen(WIDGET_SCREENS.recorder);
  };

  const remakeAudio = () => {
    setAudioError('');
    remake();
  };

  const {
    recordingState: recordingStateAudio,
    playing,
    uploading,
    playPause,
    send,
    remake,
    stopRecording,
    startRecording: startRecordingRecorder,
    getRecordingTime,
    getAudio,
    getLeftProgress,
    getPercentageProgress,
    resetAudio,
  } = WithRecorder({
    onSend: sendInteraction,
    onError: handleAudioError,
  });

  const {
    recordingState: recordingStateVideo,
    setRecordingState: setRecordingStateVideo,
    uploading: uploadingVideo,
    playPause: playPauseVideo,
    send: sendVideo,
    remake: remakeVideo,
    stopRecording: stopRecordingVideo,
    startRecording: startRecordingRecorderVideo,
    getRecordingTime: getRecordingTimeVideo,
    getLeftProgress: getLeftProgressVideo,
    videoUrl,
    imageUrl,
    resetVideo,
    initFromUrl: initFromUrlVideo,
    uploadFromFile,
    uploadFromFileImage,
    uploadInit: uploadInitVideo,
  } = WithVideoRecorder({
    onSend: sendInteraction,
    onError: () => changeActiveScreen(WIDGET_SCREENS.recorder),
  });

  const handleUploadFileImage = (e) => {
    uploadFromFileImage(e);
    changeActiveScreen(WIDGET_SCREENS.videoUploaded);
  }
  const handleUploadFromFile = (e) => {
    uploadFromFile(e);
    changeActiveScreen(WIDGET_SCREENS.videoUploaded);
  };

  const handleRegisterSms = async () => {
    if (activeSegment) {
      await sendOtherInteraction();
      return setThanksScreen(activeSegment);
    }
  };

  const poolInteraction = async (taskId) => {
    const { data } = await request({
      url: API_ENDPOINTS.task(taskId),
      method: 'GET',
    });
    if (data.status === 'FAILURE') {
      return console.error(data);
    } else if (data.status === 'SUCCESS') {
      const interaction = await fetchInteraction(data.result);
      setSentInteraction(interaction);
    } else {
      setTimeout(() => poolInteraction(taskId), 1000);
    }
  };

  const sendVotes = async () => {
    const choice_ids = Object.keys(selectedChoices);
    if (choice_ids.length === 0 && activeSegment.game_type !== "registration") {
      return;
    }
    if (window.ga) setTimeout(() => {
      window.ga
        .getAll()[0]
        .send(
          'event',
          'Interaction ' + activeSegment.type,
          'sent',
          activeSegment.id + '',
          1
        );
    }, 0);
    setSendingScreen()
    const { data } = await sendOtherInteraction({
      content: { choice_ids },
    });
    if (data.message) {
      setAlreadyReceived(true);
      if (window.ga) setTimeout(() => {
        window.ga
          .getAll()[0]
          .send(
            'event',
            'Interaction ' + activeSegment.type,
            'received',
            activeSegment.id + '',
            1
          );
      }, 0);
    }
    poolInteraction(data.task_id);
  };

  const sendText = async () => {
    if (!textAnswer) {
      return;
    }
    if (window.ga) setTimeout(() => {
      window.ga
        .getAll()[0]
        .send(
          'event',
          'Interaction ' + activeSegment.type,
          'sent',
          activeSegment.id + '',
          1
        );
    }, 0);
    setSendingScreen()
    await sendOtherInteraction({
      content: { text: textAnswer },
    });
  };

  // For text/vote/sms interactions
  const sendOtherInteraction = async (data = {}) => {
    // Can't read activeSegment in setTimeout...
    if (!activeSegment && activeSegmentRef && activeSegmentRef.current) {
      activeSegment = activeSegmentRef.current;
    }
    const queryParams = parse(search);
    const body = {
      channel_id: activeSegment.channel_id,
      segment: activeSegment.id,
      site_ref: queryParams.ref || null,
      device_id: deviceId,
      extra_user_information: extraUserInformation,
      only_user_data: activeSegment.game_type === 'registration',
      consent_gdpr: consentGDPRText,
      ...data,
    };
    return request({
      url: API_ENDPOINTS.interactions,
      method: 'POST',
      body,
    });
  };

  const fetchChannel = async (channelId) => {
    const { data } = await request({
      url: API_ENDPOINTS.channelDetailsWidget(channelId),
      method: 'GET',
    });
    setInitialized(true);
    setChannel(data);
    if (!data) {
      return;
    }
    setMainColor(data.primary_color ? data.primary_color : '#a37578');
  };

  const fetchSegment = async (segmentId) => {
    const segmentData = await fetchSegmentById(segmentId);
    const { gather_information, information_to_gather } = segmentData;
    const shouldAsk = gather_information && information_to_gather;
    if (shouldAsk) {
      const informationToGather = [];
      Object.entries(information_to_gather).forEach(([key, value]) => {
        if (value) {
          informationToGather.push(key);
        }
      });

      if (informationToGather.length) {
        segmentData.informationToGather = informationToGather;

        const savedInformation = readSavedExtraUserInformation();
        if (savedInformation) {
          const initInformation = {};
          informationToGather.forEach(
            (key) => (initInformation[key] = savedInformation[key])
          );
          setRememberMe(true);
          setExtraUserInformation(initInformation);
        }
      }
    }
    setActiveSegment(segmentData);
  };

  const getBackground = () => {
    if (!initialized || !activeSegment) {
      return '';
    }
    let bgImage = '';
    if (activeSegment.background_url) {
      bgImage = activeSegment.background_url;
    }
    if (!bgImage) {
      bgImage = channel.background_url || '';
    }
    setBackgroundReady(true);
    return bgImage;
  };

  const handleTestimonial = (type) => {
    if (type === "voice")
      changeActiveScreen(WIDGET_SCREENS.recorder);
    if (type === "text")
      changeActiveScreen(WIDGET_SCREENS.write);
    setTestimonialAccepted(true);
  }

  const saveExtraUserInformation = async () => {
    const toSave = {
      ...(readSavedExtraUserInformation() || {}),
      ...extraUserInformation,
    };
    try {
      localStorage.setItem('extraUserInformation', JSON.stringify(toSave));
    } catch (e) {}
  };

  const readSavedExtraUserInformation = () => {
    try {
      const saved = localStorage.getItem('extraUserInformation');
      if (saved) {
        return JSON.parse(saved);
      }
    } catch (e) {}
  };

  const validateExtraInformation = (saveInfo=true) => {
    if (!activeSegment.informationToGather || testimonialAccepted) {
      return true;
    }
    const fieldPatterns = {
      email: {
        pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]+$/,
        message: 'The email address is not valid'
      }
    }
    let errors = {}
    Object.entries(activeSegment.information_to_gather).forEach(([field_name, reqs]) => {
      if (!extraUserInformation[field_name]){
        if (reqs.mandatory) errors[field_name] = `Missing required ${field_name} field`
      } else if (fieldPatterns[field_name] && !fieldPatterns[field_name].pattern.test(extraUserInformation[field_name])) {
        errors[field_name] = fieldPatterns[field_name].message || `${field_name} value is not valid`
      }
    })
    if (activeSegment.customisation.consent_checkbox_image_right && activeSegment.customisation.image_right && !imageRightsGiven && activeScreen === WIDGET_SCREENS.rgpdConsent ) {
      errors['image_right'] = "Missing consent for sending image"
    }
    setGatherInfoErrors(errors)
    if (saveInfo && !Object.keys(errors).length && rememberMe) {
      saveExtraUserInformation();
    }
    return !Object.keys(errors).length;
  };

  const startRecording = () => {
    if (interactionDisabled || isFacebook) {
      return;
    }
    setAudioError('');

    if (activeScreen !== WIDGET_SCREENS.recorder) {
      changeActiveScreen(WIDGET_SCREENS.recorder)
      if (window.ga) setTimeout(() => {
        window.ga
          .getAll()[0]
          .send(
            'event',
            'Interaction ' + activeSegment.type,
            'start',
            activeSegment.id + '',
            1
          );
      }, 0);
      return;
    }

    if (activeSegment.type === INTERACTION_API_TYPE_MAP.video && activeScreen !== WIDGET_SCREENS.recorder) {
      const galleryUploads = Array.from(
        document.getElementsByClassName('gallery-upload')
      );
      if (galleryUploads[0]) {
        galleryUploads[0].click();
      }
      return;
    }
    startRecordingRecorder(activeSegment.max_recording_time);
  };

  const setField = (name, value) =>
    setExtraUserInformation((prev) => ({ ...prev, [name]: value }));

  const handleResetCamera = () => {
    resetVideo();
    changeActiveScreen(WIDGET_SCREENS.init);
    startRecordingRecorderVideo(true);
  };

  const handleSend = (skipValidation=false) => {
    if (!skipValidation && !validateExtraInformation()) {
      setShowValidation(showValidation !== undefined);
      return changeActiveScreen(WIDGET_SCREENS.gatherInformation);
    }

    if (textAnswer) return sendText();

    if (activeSegment.type === INTERACTION_API_TYPE_MAP.voice || testimonialAccepted) {
      return send();
    }

    if (activeSegment.type === INTERACTION_API_TYPE_MAP.video && (recordingStateVideo === 'waiting' && recordingStateAudio !== 'waiting'))  {
      return send();
    }

    if (activeSegment.type === INTERACTION_API_TYPE_MAP.video) {
      return sendVideo();
    }

    if ([INTERACTION_API_TYPE_MAP.vote, INTERACTION_API_TYPE_MAP.game].includes(activeSegment.type)) {
      return sendVotes();
    }
  };

  const shouldGatherInfo = () => {
    if (audioError) {
      return;
    }
    if (activeSegment.informationToGather && !testimonialAccepted) {
      changeActiveScreen(WIDGET_SCREENS.gatherInformation)
    } else {
      handleSend()
    }
  }

  const shouldShowRgpdConsent = (
    activeSegment?.customisation.image_right
    || activeSegment?.customisation.consent_gdpr
  ) && activeScreen !== WIDGET_SCREENS.rgpdConsent
  const handleGatherInfo = () => {
    if (shouldShowRgpdConsent) {
      if (!validateExtraInformation()) {
        setShowValidation(showValidation !== undefined);
        return
      }
      changeActiveScreen(WIDGET_SCREENS.rgpdConsent)
    } else handleSend()
  }

  useEffect(() => {
    fetchChannel(channelId);
    fetchSegment(segmentId);

    try {
      const previousDeviceId = localStorage.getItem('device_id');
      if (previousDeviceId) {
        setDeviceId(previousDeviceId);
      } else {
        const newDeviceId = uuid4();
        setDeviceId(newDeviceId);
        localStorage.setItem('device_id', newDeviceId);
      }
    } catch (e) {
      setDeviceId(uuid4());
    }
  }, []);

  useEffect(() => {
    setBackground(getBackground());
  }, [channel, activeSegment]);

  return {
    //Variables
    alreadyReceived,
    uploadProgress,
    recordingState:
      [INTERACTION_API_TYPE_MAP.voice, INTERACTION_API_TYPE_MAP.vote].includes(activeSegment?.type)
        ? recordingStateAudio
        : activeScreen === WIDGET_SCREENS.recorder ? recordingStateAudio : recordingStateVideo,
    videoUrl,
    imageUrl,
    channel,
    shouldFetchResults,
    hideAnonymous,
    playing,
    uploading,
    audioError,
    playPause,
    send,
    remakeAudio,
    stopRecording,
    shouldShowRgpdConsent,
    getRecordingTime,
    getAudio,
    getLeftProgress,
    getPercentageProgress,
    uploadingVideo,
    playPauseVideo,
    sendVideo,
    remakeVideo,
    stopRecordingVideo,
    getRecordingTimeVideo,
    getLeftProgressVideo,
    initFromUrlVideo,
    testimonialAccepted,
    handleTestimonial,
    activeSegment,
    activeScreen,
    screenFading,
    mainColor,
    rememberMe,
    initialized,
    background,
    isFacebook,
    sentInteraction,
    backgroundReady,
    uploadInitVideo,
    showValidation,
    extraUserInformation,
    textAnswer,
    imageRightsGiven,
    consentGDPRGiven,
    consentGDPRText,
    gatherInfoErrors,
    //Setters
    setRecordingStateVideo,
    setTextAnswer,
    setActiveScreen,
    setHideAnonymous,
    setActiveSegment,
    setImageRight,
    setConsentGDPR,
    setConsentGDPRText,
    selectedChoices,
    setRememberMe,
    setGatherInfoErrors,
    //  Methods
    changeActiveScreen,
    shouldGatherInfo,
    handleGatherInfo,
    setField,
    startRecording,
    setThanksScreen,
    sendOtherInteraction,
    setVoteScreen,
    manageChoice,
    handleRegisterSms,
    sendVotes,
    handleUploadFromFile,
    handleUploadFileImage,
    handleResetCamera,
    handleSend,
    validateExtraInformation,
  };
};
