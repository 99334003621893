import React from 'react';

export const DotsIcon = () => (
  <svg
    enableBackground="new 0 0 16 32"
    viewBox="0 0 16 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m8 10c1.7 0 3-1.3 3-3s-1.3-3-3-3-3 1.3-3 3 1.3 3 3 3z" />
    <path d="m8 13c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3z" />
    <path d="m8 22c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3z" />
  </svg>
);
