import { useEffect, useState } from 'react';

import { request } from '../utils/request';
import { API_ENDPOINTS } from '../../constants/common';
import { getDefaultLanguage, phoneRe } from '../../utils/utils';
import { emailRe } from '../../utils/utils';
import { useUser } from '../users/withUser';

const REQUEST_TIMEOUT = 350;
export const APP_USER_SCREENS = {
  main: 'main',
  loginEmail: 'loginEmail',
  loginPassword: 'loginPassword',
  forgotPassword: 'forgotPassword',
  register: 'register',
  registerFinish: 'registerFinish',
  sendWithoutRegister: 'sendWithoutRegister',
};

export const WithAppUser = ({ onAppUser, initialScreen, channelId }) => {
  const [activeScreen, setActiveScreen] = useState(
    initialScreen || APP_USER_SCREENS.main
  );
  const [topNavText, setTopNavText] = useState('');
  let [email, setEmail] = useState('');
  const [appUser, setAppUser] = useState();
  const [password, setPassword] = useState('');
  let [nickname, setNickname] = useState('');
  let [phone, setPhone] = useState('');
  const [loginError, setLoginError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [nicknameValid, setNicknameValid] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const { setLanguage } = useUser();
  let checkEmailTimeout = null;
  let checkNicknameTimeout = null;

  const validate = extraData => {
    setShowValidation(true);
    if (!extraData || !extraData.is_anonymous) {
      return (
        password.length > 7 &&
        emailValid &&
        nicknameValid &&
        phoneRe.test(phone)
      );
    }
    return nicknameValid && !phoneRe.test(phone);
  };

  const handleRegisterFirstClick = () => {
    if (!emailValid || password.length < 8) {
      return setShowValidation(true);
    }
    setShowValidation(false);
    setActiveScreen(APP_USER_SCREENS.registerFinish);
  };

  const register = async extraData => {
    if (isLoading || !validate(extraData)) {
      return;
    }
    let userLanguage = getDefaultLanguage();
    let userData = {
      email: email,
      language: userLanguage,
    };
    if (phone) {
      userData.phone = phone;
    }
    if (nickname) {
      userData.nickname = nickname;
      userData.password = password;
    }
    if (extraData) {
      userData = { ...userData, ...extraData };
    }
    setLoading(true);
    const { data } = await request({
      url: API_ENDPOINTS.registerAppUser,
      method: 'POST',
      body: userData,
    });
    if (data && data.id) {
      if (!data.is_anonymous) {
        try {
          localStorage.setItem('voxm_app_user', JSON.stringify(data));
        } catch (e) {}
      }
      manageAppUser(data);
    }
    setLoading(false);
  };

  const forgotPassword = async () => {
    if (email && emailRe.test(email)) {
      const body = { email, channel_id: channelId };
      await request({
        url: API_ENDPOINTS.forgotEmail,
        method: 'POST',
        body,
      });
    }
  };

  const checkEmailValid = () => {
    if (checkEmailTimeout) {
      clearTimeout(checkEmailTimeout);
    }
    setEmailValid(false);
    checkEmailTimeout = null;
    if (email && emailRe.test(email)) {
      checkEmailTimeout = setTimeout(async () => {
        const { data } = await request({
          url: API_ENDPOINTS.checkEmail(email),
          method: 'GET',
        });
        setEmailValid(data === 'OK');
        checkEmailTimeout = null;
      }, REQUEST_TIMEOUT);
    }
  };

  const setNicknameField = (field, value) => {
    setNickname(value);
    nickname = value;
    checkNicknameValid();
  };

  const setEmailField = (field, value) => {
    setEmail(value);
    email = value;
    checkEmailValid();
  };

  const checkNicknameValid = () => {
    if (checkNicknameTimeout) {
      clearTimeout(checkNicknameTimeout);
    }
    setNicknameValid(false);
    checkNicknameTimeout = null;
    if (nickname) {
      checkNicknameTimeout = setTimeout(async () => {
        const { data } = await request({
          url: API_ENDPOINTS.checkNickname(nickname),
          method: 'GET',
        });
        setNicknameValid(data === 'OK');
        checkNicknameTimeout = null;
      }, REQUEST_TIMEOUT);
    }
  };

  const manageAppUser = appUser => {
    setAppUser(appUser);
    setLanguage(appUser.language);
    onAppUser && onAppUser(appUser);
  };

  const login = async () => {
    if (isLoading || !email || !password) {
      return;
    }
    setLoading(true);
    setLoginError(false);
    const body = { email, password };
    const { data } = await request({
      url: API_ENDPOINTS.loginAppUser,
      method: 'POST',
      body,
    });
    if (data && !data.message) {
      try {
        localStorage.setItem('voxm_app_user', JSON.stringify(data));
      } catch (e) {}
      manageAppUser(data);
      setLoginError(false);
    } else {
      setLoginError(true);
    }
    setLoading(false);
  };

  const handleLeftArrow = () => {
    if (
      activeScreen === APP_USER_SCREENS.loginEmail ||
      activeScreen === APP_USER_SCREENS.register ||
      activeScreen === APP_USER_SCREENS.sendWithoutRegister
    ) {
      setActiveScreen(APP_USER_SCREENS.main);
      setShowValidation(false);
    } else if (activeScreen === APP_USER_SCREENS.loginPassword) {
      setActiveScreen(APP_USER_SCREENS.loginEmail);
    } else if (activeScreen === APP_USER_SCREENS.forgotPassword) {
      setActiveScreen(APP_USER_SCREENS.loginPassword);
    } else if (activeScreen === APP_USER_SCREENS.registerFinish) {
      setActiveScreen(APP_USER_SCREENS.register);
      setShowValidation(true);
    }
  };

  useEffect(() => {
    let previousAppUser;
    try {
      previousAppUser = localStorage.getItem('voxm_app_user');
    } catch (e) {}
    if (previousAppUser) {
      manageAppUser(JSON.parse(previousAppUser));
    }
  }, []);

  useEffect(() => {
    if (
      activeScreen === APP_USER_SCREENS.loginEmail ||
      activeScreen === APP_USER_SCREENS.loginPassword
    ) {
      setTopNavText('Log in');
    } else if (activeScreen === APP_USER_SCREENS.main) {
      setTopNavText('');
    } else if (activeScreen === APP_USER_SCREENS.sendWithoutRegister) {
      setTopNavText('Send without registering');
    } else if (activeScreen === APP_USER_SCREENS.register) {
      setTopNavText('To register');
    } else if (activeScreen === APP_USER_SCREENS.forgotPassword) {
      const newTopNavText = `Reconnect`;
      setTopNavText(newTopNavText);
    }
  }, [activeScreen]);

  return {
    // variables
    phone,
    activeScreen,
    topNavText,
    email,
    nickname,
    emailValid,
    nicknameValid,
    password,
    isLoading,
    loginError,
    //setters
    setEmail,
    setPassword,
    showValidation,
    setActiveScreen,
    setPhone,
    // methods
    login,
    register,
    checkEmailValid,
    checkNicknameValid,
    handleLeftArrow,
    setNicknameField,
    setEmailField,
    forgotPassword,
    handleRegisterFirstClick,
  };
};
