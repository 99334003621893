import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';

import { request } from '../utils/request';
import {
  API_ENDPOINTS,
  INTERACTION_API_TYPE_MAP,
  INTERACTION_TYPES,
} from '../../constants/common';
import moment from 'moment';
import { getDefaultLanguage } from '../../utils/utils';
import i18n from 'i18next';

require('moment/locale/fr');
require('moment/locale/pt');
require('moment/locale/pl');
require('moment/locale/es');

export const UserContext = React.createContext(null);

export const UserProvider = (props) => {
  const [state, setState] = useState({
    user: null,
    unreadMessages: 0,
    channel: null,
    allowedInteractionTypes: [],
    language: getDefaultLanguage(),
    error: null,
    isLoggedIn: false,
    isLoading: false,
    isSuperAdmin: false,
    inSuperAdminMode: false,
  });
  return (
    <UserContext.Provider value={[state, setState]}>
      {props.children}
    </UserContext.Provider>
  );
};

const defaultLanguage = getDefaultLanguage();

const setLanguageGlobal = (language) => {
  i18n.changeLanguage(language);
  moment.locale(language === 'en' ? 'en-gb' : language);
};

setLanguageGlobal(defaultLanguage);

export const useUser = () => {
  const [state, setState] = useContext(UserContext);
  const history = useHistory();

  const setLanguage = (language) => {
    setLanguageGlobal(language);
    setState({ ...state, language });
  };

  const fetchCurrentUser = async () => {
    setState({ ...state, isLoading: true, error: null });
    const { data } = await request({
      url: API_ENDPOINTS.currentUser,
      method: 'GET',
    });
    const alreadySetWelcome = localStorage.getItem('show_welcome_help');
    if (alreadySetWelcome !== 'shown') {
      localStorage.setItem('show_welcome_help', data.login_count);
    }
    data.nickname = data.screenname;
    data.avatar_url = data.image_url;
    if (data.status_code && data.status_code === 401) {
      if (history.location.pathname !== '/login') {
        history.push('/login');
      }
      setState({ ...state, user: null, isLoading: false, error: data.error });
    }
    const channelId = get(data, 'channel_id');
    const channelData = await fetchCurrentUserCompany(channelId);
    const allowedInteractionTypes = getAllowedInteractionTypes(channelData);
    const isSuperAdmin = data.role === 'super_administrator';
    const inSuperAdminMode = isSuperAdmin && channelId === null;
    const { language } = data;
    state.language = language || getDefaultLanguage();
    moment.locale(state.language === 'en' ? 'en-gb' : state.language);
    fetchUnreadMessages();
    await setState({
      ...state,
      user: data,
      channel: channelData,
      isLoading: false,
      inSuperAdminMode,
      isSuperAdmin,
      isLoggedIn: true,
      allowedInteractionTypes,
    });
    return {
      inSuperAdminMode,
      user: data,
      channel: channelData,
    };
  };

  const setChannel = (channel) => setState({ ...state, channel });

  const getAllowedInteractionTypes = (channel) => {
    if (!channel) {
      return [];
    }
    return INTERACTION_TYPES.filter((type) => {
      switch (type.value) {
        case INTERACTION_API_TYPE_MAP.voice:
          return channel.interaction_available_voice;
        case INTERACTION_API_TYPE_MAP.vote:
          return channel.interaction_available_vote;
        case INTERACTION_API_TYPE_MAP.choice:
          return channel.interaction_available_choice;
        case INTERACTION_API_TYPE_MAP.sms:
          return channel.interaction_available_sms;
        case INTERACTION_API_TYPE_MAP.registration:
          return channel.interaction_available_registration;
        case INTERACTION_API_TYPE_MAP.video:
          return channel.interaction_available_video;
        default:
          return false;
      }
    });
  };

  const fetchCurrentUserCompany = async (channelId) => {
    if (!channelId) {
      return;
    }
    const { data } = await request({
      url: API_ENDPOINTS.channelDetails(channelId),
      method: 'GET',
    });
    return data;
  };

  const updateCurrentCompany = async (channelId) => {
    const { data } = await request({
      url: API_ENDPOINTS.user(state.user.id),
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...state.user,
        channel_id: channelId,
      }),
    });
    await fetchCurrentUser();
    if (channelId === null) {
      history.push('/channels');
    } else {
      history.push('/interactions');
    }
    return data;
  };

  const fetchUnreadMessages = async () => {
    const { data } = await request({
      url: API_ENDPOINTS.unreadMessages,
      method: 'GET',
    });
    if (typeof data !== 'number') {
      return;
    }

    setState((prevState) => ({
      ...prevState,
      unreadMessages: data,
    }));

    return data;
  };

  const getWelcomeHelpItem = async () => {
    const shouldShow = localStorage.getItem('show_welcome_help') === '1';
    if (!shouldShow) {
      return;
    }
    const { data } = await request({
      url: API_ENDPOINTS.welcomeHelpItem,
      method: 'GET',
    });
    if (data.id) {
      localStorage.setItem('show_welcome_help', 'shown');
      return data;
    }
  };

  return {
    user: state.user,
    channel: state.channel,
    isLoggedIn: state.isLoggedIn,
    isLoading: state.isLoading,
    language: state.language,
    isSuperAdmin: state.isSuperAdmin,
    inSuperAdminMode: state.inSuperAdminMode,
    unreadMessages: state.unreadMessages,
    allowedInteractionTypes: state.allowedInteractionTypes,
    // setters
    setState,
    // methods
    fetchCurrentUser,
    updateCurrentCompany,
    setChannel,
    setLanguage,
    fetchUnreadMessages,
    getWelcomeHelpItem,
  };
};
