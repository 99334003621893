import React, {useEffect, useState} from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';

import Waveform from 'waveform-react';
import { Box, Flex } from 'reflexbox';
import { WIDGET_SCREENS, WithWidget } from '../../models/widget/withWidget';
import { Text } from '../../ui/text/Text';
import { Button } from '../../ui/button/Button';
import { PoweredBy } from '../../ui/widget/PoweredBy';
import { Relative } from '../../ui/box/Relative';
import { RECORDER_STATES } from '../../../models/recorder/withRecorder';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../ui/input/Input';
import { WIDGET_INFORMATION_MAP } from '../../../models/widget/withWidget';
import { COLOR, MP4_BACKGROUND_STR, STATIC_BUCKET } from '../../constants/common';
import { Toggle } from '../../ui/toggle/Toggle';
import { INTERACTION_API_TYPE_MAP } from '../../constants/common';
import {FlexCenter} from "../../ui/box/FlexCenter";
import {VoiceInteractionIcon} from "../../ui/icons/VoiceInteractionIcon";
import {TextInteractionIcon} from "../../ui/icons/TextInteractionIcon";
import { SendIcon } from '../../ui/icons/SendIcon';
import { GoBackIcon } from '../../ui/icons/GoBackIcon';
import { VoteInteractionIcon } from '../../ui/icons/VoteInteractionIcon';
import { VideoInteractionIcon } from '../../ui/icons/VideoInteractionIcon';
import { CameraInteractionIcon } from '../../ui/icons/CameraInteractionIcon';
import { MainText } from '../../ui/widget/MainText';
import { GameInteractionIcon } from '../../ui/icons/GameInteractionIcon';
import {downloadAudioBuffer} from "../../../screens/messages/MessageScreen";
import { Video } from '../../features/video/Video';
import { WithVoteInteractionResults } from '../../models/interactions/withVoteInteractionResults';
import { useParams } from 'react-router-dom';
import { DotsIcon } from '../../ui/icons/DotsIcon';
import { Pointer } from '../../ui/box/Pointer';
import { ChevronLeftIcon } from '../../ui/icons/ChevronLeftIcon';
import {Checkbox} from "../../../ui/checkbox/Checkbox";
import { ClappingIcon } from '../../ui/icons/ClappingIcon';
import { PaperAirplaneIcon } from '../../ui/icons/PaperAirplaneIcon';
import { ChevronRightIcon } from '../../ui/icons/ChevronRightIcon';
import { HappyIcon } from '../../ui/icons/HappyIcon';
import {DEFAULT_TEXT_COLOR} from "../../constants/customisation/customisation";

const Wrapper = styled(Relative)`
  width: 100vw;
  height: 100vh;
  background: #fff;
  display: flex;
  align-items: baseline;
  padding: 13px !important;
`;

const GlobalStyle = createGlobalStyle`
   body {
    background: #fff !important;
  }
`;

const Content = styled(Relative)`
  background: ${({ background }) => background};
  margin: 2vh auto 2vh !important;
  width: 100%;
  max-height: 844px;
  max-width: 420px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0 0 8px -3px gray;
  border: 1px solid #eee;
  border-radius: 5px;
  * {
    transition: opacity 350ms ease-in-out !important;
    opacity: ${({childsOpacity=1}) => childsOpacity};
  }
  
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  
  @media (min-height: 1020px) and (min-width: 800px), (min-width: 1020px), (min-height: 1200px) {
    max-width: 580px;
    margin: calc(14vh - 3vw) auto 10vh !important;
  }
`

const ContentBody = styled(Relative)`
  padding: 16px 15px 8px;
`
const isSendMyParticipationDisable = (customisation, consentGDPRGiven, imageRightsGiven) => {
  if ( customisation.consent_checkbox )
    return !consentGDPRGiven;
  if (customisation.consent_checkbox_image_right)
    return !imageRightsGiven;
  return false;
}

export const WidgetButton = styled(Button)`
  position: relative;
  background: ${({ background }) => background};
  ${({capitalize=false}) => capitalize && css`text-transform: capitalize;`}
  font-size: ${({ fontSize }) => fontSize || '12px'};
  font-weight: ${({ fontWeight }) => fontWeight || 600};
  letter-spacing: -.2px;
  ${({ height }) => css`height: ${height || '58px'};`};
  ${({ width }) => css`width: ${width || '100%'};`};
  border: ${({borderWidth}) => borderWidth || '1px'} ${({dottedBorder}) => dottedBorder ? "dotted" : "solid"} ${({borderColor}) => borderColor};
  ${({ disabled }) => disabled && css`pointer-events: none;`};
  ${({ opacity, disabled }) => css`opacity: ${opacity ? opacity : disabled ? '.5' : '1'}`};
  
  ${({ fading }) => fading ? css`
    animation: answer-fade-out .8s ease-in-out;
    animation-fill-mode: forwards;
    
    @keyframes answer-fade-out {
      30% {
        opacity: 0;
      }
      100% {
        height: 0;
        opacity: 0;
        margin: 0;
        padding: 0;
      }
    }
  ` : css`
    margin: ${({margin}) => margin || '16px 0 0'} !important;
  `}
  
  svg {
    height: ${({ iconSize }) => iconSize || '25px'};
    width: ${({ iconSize }) => iconSize};
    margin: ${({iconMargin}) => iconMargin || '0 4px 0 0'};
  } 
`

export const ActionButtonsWrapper = styled(Box)`
  padding: 15px 20px;
  border-radius: 5px;
  background-color: ${COLOR.grey7};
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px #e5e9ec;
  z-index: 10;
  position: relative;
`

const ChoiceWrapper = styled(Flex)`
  justify-content: space-between;
  width: 100%;
  font-weight: 600;
`

export const TextButton = styled(Button)`
  padding: 3px 14px;
  background: none;
  margin: ${({margin}) => margin || '0'} !important;
  width: fit-content;
  font-size: 10px;
  font-weight: ${({ fontWeight }) => fontWeight || 600};
  height: 25px;
  
  svg {
    height: ${({ iconSize }) => iconSize || '15px'};
    width: ${({ iconSize }) => iconSize || '15px'};
    margin-right: 4px;
  }
`

const InteractionImage = styled.div`
  background-image: url(${({image}) => image});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: 320px;
  height: 320px;
`;

const ImageBackground = styled.div`
  filter: blur(12px);
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background-image: url(${({image}) => image});
  background-position: center;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-size: 98% 98%;
  background-repeat: no-repeat;
`

const MediaContainer = styled.div`
  position: relative;  
  height: 320px;
  width: 100%;
  margin: 15px auto;
`

export const TopBar = styled(Flex)`
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
   svg {
    height: 23px;
   }
`

export const TopBadge = styled.div`
  font-size: 12px;
  padding: 7px 15px;
  border-bottom-left-radius: 5px;
  color: ${({ color }) => color || DEFAULT_TEXT_COLOR};
  background: ${({ background }) => background};
  text-transform: uppercase;
`

export const ChevronWrapper = styled(Pointer)`
  height: 16px;
  width: 16px;
  margin-left: 8px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  
  svg {
    height: 8px;
    width: 8px;
  }
`

const InteractionVideo = styled(Video)`
  object-fit: cover;
  border-radius: 0;
  
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: 320px;
  height: 320px;
`;

const VideoBackground = styled(Video)`
  filter: blur(12px);
  position: absolute;
  inset: 0;
  height: 98%;
  width: 98%;
`

const BlurredBackgroundContainer = styled.div`
  filter: blur(5px);
  overflow: hidden;
  border-radius: 10px;
  position: absolute;
  opacity: 0.4;
  inset: 0;
  height: 100%;
  width: 100%;
`


const UploadInsideButton = styled.input`
  position: absolute;
  inset: 0;
  width: 100%;
  z-index: 3;
  opacity: 0;
`;

const WaveContainer = styled(Box)`
  width: 100%;
  overflow: hidden;
  padding: 7px 0;
  max-height: 87.5px;
`;

const DotsWrapper = styled.div`
  border: 1px solid black;
  border-radius: 5px;
  height: fit-content;
  display: inline-flex;
  margin: 0 17px;
  svg {
    height: 35px;
  }
  ${({ rotate }) =>
    rotate && css`
      transform: rotate(${rotate}deg);
      margin-left: 22px;
    `
  }
`

const BottomSection = styled.div`
  z-index: 1;
  ${({disable}) => disable || css`
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 32px);
    left: 0;
    background-color: #f3f3f3;
    padding: 0 16px 48px;
    ${({height}) => height && css`height: ${height};`}
  `}
`

const RecordButtonWrapper = styled.div`
  margin: 16px 0 20px;
  width: 68px;
  height: 68px;
  border-radius: 50%;
  border: solid 2px #242424;
  cursor: pointer;
  position: relative;
`

const RecordButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 90%;
  
  transition: all 100ms ease-in-out !important;
  ${({state}) =>
    state === RECORDER_STATES.recording ? css`
      background-color: #f82d2d;
      border-radius: 10%;
      width: 50%;
      height: 50%;
    ` : state === RECORDER_STATES.finished ? css`
      border-radius: 0;
      width: 36px;
      height: 36px;
      box-sizing: border-box;
      border-style: solid;
      border-width: 18px 10px 18px 29px;
      border-color: transparent transparent transparent #f82d2d;
      transform: translate(-30%, -50%);
      ${({playing}) => playing && css`
        border-style: double;
        border-width: 0 0 0 25px;
        height: 30px;
        transform: translate(-34%, -50%);
      `};
    ` : css`
      border-radius: 50%;
      background-color: #f82d2d;
    `};
`

const WrittenParticipationInput = styled(Input)`
  background-color: #d8d8d8;
  font-size: 12px;
  padding: 6px 12px !important;
  font-weight: 500;
  margin-top: 0;
  height: 50vh;
  max-height: 320px;
  color: #000000;
`

const RGPDConsentWrapper = styled.div`
  height: 50vh;
  max-height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 30px;
`

export const WidgetScreen = () => {
  const {
    activeSegment,
    channel,
    background,
    activeScreen,
    screenFading,
    recordingState,
    imageRightsGiven,
    consentGDPRGiven,
    consentGDPRText,
    extraUserInformation,
    showValidation,
    audioError,
    rememberMe,
    setRememberMe,
    setTextAnswer,
    textAnswer,
    shouldFetchResults,
    selectedChoices,
    changeActiveScreen,
    handleTestimonial,
    testimonialAccepted,
    startRecording,
    stopRecording,
    handleGatherInfo,
    handleSend,
    remakeAudio,
    getPercentageProgress,
    getRecordingTime,
    getAudio,
    videoUrl,
    imageUrl,
    playing,
    playPause,
    setField,
    handleUploadFromFile,
    handleUploadFileImage,
    manageChoice,
    shouldGatherInfo,
    shouldShowRgpdConsent,
    setImageRight,
    setConsentGDPR,
    setConsentGDPRText,
    isFacebook,
    gatherInfoErrors,
    validateExtraInformation,
    setRecordingStateVideo,
  } = WithWidget();
  const { segmentId: interactionId } = useParams();
    const { results: choicesWithStats } = WithVoteInteractionResults({
    interactionId: shouldFetchResults ? interactionId : undefined,
  });
  const { t } = useTranslation();
  const audio = getAudio();
  const [audioBuffer, setAudioBuffer] = useState(null);
  const [choicesPage, setChoicesPage] = useState(0);
  const choicesPerPage = 5;

  useEffect(() => {
    if (audio) {
      downloadAudioBuffer(audio, setAudioBuffer);
    }
  }, [audio]);

  if (!channel || !activeSegment) {
    return null;
  }

  const { customisation } = activeSegment;

  const thankYouMessageScreens = [
    WIDGET_SCREENS.thanks,
    WIDGET_SCREENS.sending,
    WIDGET_SCREENS.results,
  ]

  const textExcludeScreens = [
    WIDGET_SCREENS.videoUploaded,
    WIDGET_SCREENS.rgpdConsent,
  ];

  const logoExcludeScreens = [
    WIDGET_SCREENS.gatherInformation,
    WIDGET_SCREENS.voting,
    WIDGET_SCREENS.videoUploaded,
    WIDGET_SCREENS.write,
    WIDGET_SCREENS.results,
    WIDGET_SCREENS.rgpdConsent,
    WIDGET_SCREENS.thanks,
    WIDGET_SCREENS.sending,
  ];

  const showVoiceRecorder = activeScreen === WIDGET_SCREENS.recorder || (
    thankYouMessageScreens.includes(activeScreen)
    && (
      audio
    )
  )

  const showVideo = activeScreen === WIDGET_SCREENS.videoUploaded || (
    thankYouMessageScreens.includes(activeScreen)
    && (
      videoUrl
    )
  )

  const ifGatherInfo = activeSegment.informationToGather && !testimonialAccepted

  const handleRecordButtonClick = () => {
    if(recordingState === RECORDER_STATES.waiting) startRecording()
    else if (recordingState === RECORDER_STATES.recording) stopRecording()
    else if (recordingState === RECORDER_STATES.finished) playPause()
  }

  const getRecorderButtonText = () => {
    if (recordingState === RECORDER_STATES.finished) {
      return t('Play recording');
    }
    if (recordingState === RECORDER_STATES.recording) {
      return t('Stop recording');
    }
    return t('Start recording');
  }

  const showGatherInfoForm = (
    activeSegment.gather_information
    && activeScreen === WIDGET_SCREENS.gatherInformation
  )

  const showThanksInBottomSection =
    activeSegment.type === INTERACTION_API_TYPE_MAP.voice && textAnswer;

  const enableGoBackButton = [
    WIDGET_SCREENS.gatherInformation,
    WIDGET_SCREENS.thanks,
    WIDGET_SCREENS.sending
  ].includes(activeScreen)

  if (!customisation.btn_1_text_color) {
    customisation.btn_1_text_color = DEFAULT_TEXT_COLOR;
  }

  return (
    <Wrapper>
      <GlobalStyle />
      {isFacebook ? (
        <Content background={'#edecec'}>
          <FlexCenter fullHeight>
            <Text size={'h5'} center theme={'dark'}>
              Unfortunately, <b>Facebook</b> doesn't allow<br/>
              you to live the full experience!
              <br/><br/>
              To participate you must click on
              <br/><br/>
              <FlexCenter>
                <DotsWrapper>
                  <DotsIcon/>
                </DotsWrapper>
                ou
                <DotsWrapper rotate={'90'}>
                  <DotsIcon/>
                </DotsWrapper>
              </FlexCenter>
              <br/>
              And click on:
              <br/><br/>
              "Open in a browser"
              <br/><br/>
              <b>Thank you</b>
            </Text>
          </FlexCenter>
        </Content>
      ) : (
        <Content background={"#fff"} childsOpacity={screenFading ? 0 : 1}>
          <TopBar>
            <TopBadge
              background={customisation.btn_1_color}
              color={customisation.btn_1_text_color}
            >
              {t('Participate!')}
            </TopBadge>
            {activeScreen !== WIDGET_SCREENS.init && (
              <Pointer onClick={() => {
                if (activeScreen === WIDGET_SCREENS.thanks) {
                  window.location.reload();
                }
                changeActiveScreen(WIDGET_SCREENS.init)
              }}>
                <FlexCenter>
                  <ChevronWrapper inactive={enableGoBackButton}>
                    <ChevronLeftIcon color={enableGoBackButton ? COLOR.grey : COLOR.blackish}/>
                  </ChevronWrapper>
                  <Text
                    size="xs"
                    color={enableGoBackButton ? COLOR.grey : COLOR.blackish}
                  >
                    {t('Go back')}
                  </Text>
                </FlexCenter>
              </Pointer>
            )}
          </TopBar>
          <ContentBody>
            {!textExcludeScreens.includes(activeScreen) && (
              <MainText
                textAlign={'center'}
                color={COLOR.blackish}
              >
                { showGatherInfoForm ? customisation.text_personal : activeSegment.text}
              </MainText>
            )}

            {!logoExcludeScreens.includes(activeScreen) && (activeScreen === WIDGET_SCREENS.init) ? (
              <>
                {background && background.includes(MP4_BACKGROUND_STR) && !background.includes(STATIC_BUCKET) ? (
                  <MediaContainer>
                    <BlurredBackgroundContainer>
                      <VideoBackground src={background} autoPlay loop muted playsInline/>
                    </BlurredBackgroundContainer>
                    <InteractionVideo src={background} autoPlay loop controls muted playsInline id={'InteractionVideo'} onPlay={() => {
                      window.document.addEventListener('click', () => {
                        const video = document.getElementById('InteractionVideo');
                        if (video) {
                          video.muted = false;
                        }
                      })
                    }} />
                  </MediaContainer>
                ) : (
                  <MediaContainer>
                    <BlurredBackgroundContainer>
                      <ImageBackground image={background}/>
                    </BlurredBackgroundContainer>
                    <InteractionImage image={background}/>
                  </MediaContainer>
                )}
              </>
            ) : (
              <Box marginTop={'12px'}/>
            )}

            {activeScreen === WIDGET_SCREENS.init && (
              <ActionButtonsWrapper>
                {activeSegment.type === INTERACTION_API_TYPE_MAP.voice && (
                  <>
                    <WidgetButton
                      onClick={startRecording}
                      background={customisation.btn_1_color}
                      color={customisation.btn_1_text_color}
                      margin={'0'}
                    >
                      <VoiceInteractionIcon color={customisation.btn_1_text_color}/>
                      {t('Participate vocally')}
                    </WidgetButton>
                    {activeSegment.enable_written_answer && (
                      <FlexCenter>
                        <TextButton color={COLOR.blackish} onClick={() => changeActiveScreen(WIDGET_SCREENS.write)}>
                          <TextInteractionIcon color={COLOR.blackish}/>
                          {t('Participate in writing')}
                        </TextButton>
                      </FlexCenter>
                    )}
                  </>
                )}
                {/* Historiquement segment.type === video est pour les médias video et image la distinction se fait sur segment.type_media */}
                {activeSegment.type === INTERACTION_API_TYPE_MAP.video && (
                  <>
                    {/* Le bouton est soit pour le chargement d'une vidéo */}
                    {activeSegment.media_type !== "image" && (
                      <WidgetButton
                        margin={'0 0 12px'}
                        background={customisation.btn_1_color}
                        color={customisation.btn_1_text_color}
                      >
                        <UploadInsideButton
                          id={'gallery-upload'}
                          type={'file'}
                          accept={'video/*'}
                          onChange={handleUploadFromFile}
                        />
                        <VideoInteractionIcon color={customisation.btn_1_text_color}/>
                        {t('Participate in video')}
                      </WidgetButton>
                    )}
                    {/* Le bouton est soit pour le chargement d'une image */}
                    {activeSegment.media_type === "image" && (
                      <WidgetButton
                        margin={'0 0 12px'}
                        background={customisation.btn_1_color}
                        color={customisation.btn_1_text_color}
                      >
                        <UploadInsideButton
                          id={'gallery-upload'}
                          type={'file'}
                          accept={'image/*'}
                          onChange={handleUploadFileImage}
                        />
                        <CameraInteractionIcon color={customisation.btn_1_text_color}/>
                        {t('Participate')}
                      </WidgetButton>
                    )}
 
                    <FlexCenter>
                      {activeSegment.enable_audio_answer && (
                        <TextButton color={COLOR.blackish} onClick={() => changeActiveScreen(WIDGET_SCREENS.recorder)}>
                          <VoiceInteractionIcon color={COLOR.blackish}/>
                          {t('In voice')}
                        </TextButton>
                      )}
                      {activeSegment.enable_written_answer && activeSegment.enable_audio_answer && (<Text bold>|</Text>)}
                      {activeSegment.enable_written_answer && (
                        <TextButton color={COLOR.blackish} onClick={() => changeActiveScreen(WIDGET_SCREENS.write)}>
                          <TextInteractionIcon color={COLOR.blackish}/>
                          {t('Writing')}
                        </TextButton>
                      )}
                    </FlexCenter>
                  </>
                )}
                {activeSegment.type === INTERACTION_API_TYPE_MAP.vote && (
                  <WidgetButton
                    background={customisation.btn_1_color}
                    color={customisation.btn_1_text_color}
                    onClick={() => changeActiveScreen(WIDGET_SCREENS.voting)}
                    margin={'0'}
                  >
                    <VoteInteractionIcon color={customisation.btn_1_text_color}/>
                    {t('Participate in the survey')}
                  </WidgetButton>
                )}
                {activeSegment.type === INTERACTION_API_TYPE_MAP.text && (
                  <WidgetButton
                    background={customisation.btn_1_color}
                    color={customisation.btn_1_text_color}
                    onClick={() => changeActiveScreen(WIDGET_SCREENS.write)}
                    margin={'0'}
                  >
                    <TextInteractionIcon color={customisation.btn_1_text_color}/>
                    {t('Participate in writing')}
                  </WidgetButton>
                )}
                {activeSegment.type === INTERACTION_API_TYPE_MAP.game && (
                  <WidgetButton
                    background={customisation.btn_1_color}
                    color={customisation.btn_1_text_color}
                    onClick={() => changeActiveScreen(
                      activeSegment.game_type === 'registration' ? WIDGET_SCREENS.gatherInformation : WIDGET_SCREENS.voting
                    )}
                    margin={'0'}
                  >
                    <GameInteractionIcon color={customisation.btn_1_text_color}/>
                    {t('Participate in game')}
                  </WidgetButton>
                )}
                {!activeSegment.enable_audio_answer && !activeSegment.enable_written_answer && (<Box height={"25px"}/>)}
              </ActionButtonsWrapper>
            )}

            {showVoiceRecorder && (
              <>
                <Box height={"90px"} margin={"40px 0"}>
                  {audioBuffer && (recordingState === RECORDER_STATES.finished || recordingState === RECORDER_STATES.recording) && (
                    <WaveContainer>
                      <Waveform
                        key={audio?.src}
                        // Audio buffer
                        buffer={audioBuffer}
                        // waveform height
                        height={60}
                        markerStyle={{
                          // Position marker color
                          color: COLOR.link,
                          // Position marker width (in pixels)
                          width: 1,
                        }}
                        // Wave plot type (line or bar)
                        plot="line"
                        // Marker position on waveform (0 - 1)
                        position={getPercentageProgress()}
                        // redraw waveform on window size change (default: true)
                        responsive
                        // Show position marker
                        showPosition={recordingState === RECORDER_STATES.finished}
                        waveStyle={{
                          // animate waveform on draw (default: true)
                          animate: true,
                          // waveform color
                          color: customisation.btn_1_color,
                          // width of each rendered point (min: 1, max: 10)
                          pointWidth: 1,
                        }}
                        // waveform width
                        width={400}
                      />
                    </WaveContainer>
                  )}
                </Box>

                <FlexCenter flexDirection={"column"}>
                  <Text center size={'h5'} color={COLOR.blackish} capital>
                    {getRecordingTime()}
                  </Text>
                  <Text color={COLOR.blackish} semiBold mt={"7px"}>
                    {getRecorderButtonText()}
                  </Text>
                  <RecordButtonWrapper onClick={handleRecordButtonClick}>
                    <RecordButton state={recordingState} playing={playing}/>
                  </RecordButtonWrapper>
                  <Flex height={"32px"} mb={"10px"}>
                    {activeScreen === WIDGET_SCREENS.recorder && (
                      <>
                        {recordingState === RECORDER_STATES.finished && (
                          <WidgetButton
                            fontWeight={500}
                            margin={"0"}
                            onClick={remakeAudio}
                            color={COLOR.blackish}
                            fontSize={"12px"}
                            dottedBorder
                            borderColor={COLOR.blackish}
                            height={"30px"}
                            background={"none"}
                          >
                            <GoBackIcon color={COLOR.blackish}/>
                            {t('Start recording again')}
                          </WidgetButton>
                        )}
                        {recordingState === RECORDER_STATES.waiting && (
                          <>
                            <HappyIcon/>
                            <Text ml={"8px"}>{t('You can start again before sending')}</Text>
                          </>
                        )}
                      </>
                    )}
                  </Flex>
                </FlexCenter>

                {activeScreen === WIDGET_SCREENS.recorder && (
                  <>
                  <ActionButtonsWrapper>
                    <WidgetButton
                      margin={'15px 0'}
                      flex={1}
                      onClick={() => shouldGatherInfo()}
                      background={customisation.btn_1_color}
                      color={customisation.btn_1_text_color}
                      disabled={recordingState !== RECORDER_STATES.finished}
                    >
                      {!ifGatherInfo && (
                        <SendIcon color={customisation.btn_1_text_color}/>
                      )}
                      {t(ifGatherInfo ? 'Continue' : 'Send my participation')}
                    </WidgetButton>
                  </ActionButtonsWrapper>
                  </>
                )}

                {audioError && (
                  <Text center mt={'20px'} bold color={COLOR.blackish} capital>
                    {t(audioError)}
                  </Text>
                )}
              </>
            )}

            {showVideo && (
              <>
                <MediaContainer>
                  { activeSegment.media_type !== "image" && (
                    <>
                      <InteractionVideo autoPlay loop controls muted playsInline id={'video_recording'} onPlay={() => {
                        window.document.addEventListener('click', () => {
                          const video = document.getElementById('video_recording');
                          if (video) {
                            video.muted = false;
                            video.play();
                          }
                        })
                      }} />
                      <BlurredBackgroundContainer>
                        <VideoBackground autoPlay loop muted playsInline id={'video_recording_bg'}/>
                      </BlurredBackgroundContainer>
                    </>
                  )}
                  { activeSegment.media_type === "image" && (
                    <>
                    <InteractionImage image={imageUrl} />
                    </>
                  )}
                </MediaContainer>

                {activeScreen === WIDGET_SCREENS.videoUploaded ? (
                  <>
                    <FlexCenter>
                      <WidgetButton
                        width={"fit-content"}
                        fontWeight={500}
                        margin={"0 0 5px"}
                        onClick={() => {
                          changeActiveScreen(WIDGET_SCREENS.init)
                          setRecordingStateVideo(RECORDER_STATES.waiting);
                        }}
                        color={COLOR.blackish}
                        fontSize={"12px"}
                        dottedBorder
                        borderColor={COLOR.blackish}
                        height={"30px"}
                        background={"none"}
                      >
                        <GoBackIcon color={COLOR.blackish}/>
                        {t('Start recording again')}
                      </WidgetButton>
                    </FlexCenter>
                    <ActionButtonsWrapper>
                      <WidgetButton
                        margin={"15px 0"}
                        onClick={() => shouldGatherInfo()}
                        background={customisation.btn_1_color}
                        color={customisation.btn_1_text_color}
                      >
                        {!ifGatherInfo && (
                          <SendIcon color={customisation.btn_1_text_color}/>
                        )}
                        {t(ifGatherInfo ? 'Continue' : 'Send my participation')}
                      </WidgetButton>
                    </ActionButtonsWrapper>
                  </>
                ) : (<Box height={"30px"}/>)}
              </>
            )}

            {activeScreen === WIDGET_SCREENS.write && (
              <Box mt={'10px'}>
                <WrittenParticipationInput
                  color={COLOR.white}
                  widgetInputSmall
                  value={textAnswer}
                  textArea
                  onChange={(name, value) => setTextAnswer(value)}
                  placeholder={"Écrivez ici votre message texte…"}
                />
                <ActionButtonsWrapper>
                  <WidgetButton
                    margin={'15px 0'}
                    flex={1}
                    onClick={() => shouldGatherInfo()}
                    background={customisation.btn_1_color}
                    color={customisation.btn_1_text_color}
                  >
                    {!ifGatherInfo && (
                      <SendIcon color={customisation.btn_1_text_color}/>
                    )}
                    {t(ifGatherInfo ? 'Continue' : 'Send my participation')}
                  </WidgetButton>
                </ActionButtonsWrapper>
              </Box>
            )}

            {[WIDGET_SCREENS.voting, WIDGET_SCREENS.results].includes(activeScreen) && (
              <Box>
                <Box height={"300px"}>
                  {activeScreen === WIDGET_SCREENS.voting ? activeSegment.choicesFull.slice(
                    choicesPerPage*choicesPage,
                    choicesPerPage*(choicesPage+1),
                  ).map((choice) => (
                    <WidgetButton
                      margin={'16px 0 0'}
                      key={`choice-${choice.id}`}
                      color={selectedChoices[choice.id] ? customisation.btn_1_text_color : COLOR.blackish}
                      borderColor={selectedChoices[choice.id] ? 'none' : COLOR.grey1}
                      background={selectedChoices[choice.id] ? customisation.btn_1_color + "CC" : 'none'}
                      onClick={() => manageChoice(choice)}
                      height={'47px'}
                    >
                      {choice.label || ''}
                    </WidgetButton>
                  )) : choicesWithStats.slice(
                    choicesPerPage*choicesPage,
                    choicesPerPage*(choicesPage+1),
                  ).map((choice) => (choice.percentage !== 0 &&
                  <WidgetButton
                    margin={'16px 0 0'}
                    key={`result-${choice.id}`}
                    color={selectedChoices[choice.id] ? customisation.btn_1_text_color : COLOR.blackish}
                    borderColor={selectedChoices[choice.id] ? 'none' : COLOR.grey1}
                    background={selectedChoices[choice.id] ? customisation.btn_1_color + "CC" : 'none'}
                    height={'47px'}
                    borderWidth={'2px'}
                    width={choice.percentage + "%"}
                  >
                    <ChoiceWrapper>
                      <span>{choice.label || ''}</span>
                      <span>{choice.percentage}%</span>
                    </ChoiceWrapper>
                  </WidgetButton>
                ))}
                </Box>
                {(
                  activeScreen === WIDGET_SCREENS.voting
                    ? activeSegment.choicesFull.length
                    : choicesWithStats.filter(c => c.percentage !== 0).length
                ) > choicesPerPage && (
                  <Flex gap={"9px"} mb={"16px"}>
                    {choicesPage === 0 ? (
                      <WidgetButton
                        margin={'16px 0 0'}
                        color={COLOR.blackish}
                        borderColor={COLOR.grey1}
                        background={'none'}
                        onClick={() => setChoicesPage(choicesPage + 1)}
                        height={'47px'}
                        dottedBorder
                        iconSize={"9px"}
                        iconMargin={"0 0 0 9px"}
                      >
                        {t('See other choices')}
                        <ChevronRightIcon color={COLOR.blackish}/>
                      </WidgetButton>
                    ) : (
                      <>
                        <WidgetButton
                          margin={'16px 0 0'}
                          color={COLOR.blackish}
                          borderColor={COLOR.grey1}
                          background={'none'}
                          onClick={() => setChoicesPage(choicesPage - 1)}
                          height={'47px'}
                          width={"50%"}
                          dottedBorder
                          iconSize={"9px"}
                          iconMargin={"0 9px 0 0"}
                        >
                          <ChevronLeftIcon color={COLOR.blackish}/>
                          {t('Previous choices')}
                        </WidgetButton>
                        <Box width={"9px"}/>
                        {(choicesPage+1) * choicesPerPage < (
                          activeScreen === WIDGET_SCREENS.voting
                            ? activeSegment.choicesFull.length
                            : choicesWithStats.filter(c => c.percentage !== 0).length
                        ) && (
                          <WidgetButton
                            margin={'16px 0 0'}
                            color={COLOR.blackish}
                            borderColor={COLOR.grey1}
                            background={'none'}
                            onClick={() => setChoicesPage(choicesPage + 1)}
                            height={'47px'}
                            width={"50%"}
                            dottedBorder
                            iconSize={"9px"}
                            iconMargin={"0 0 0 9px"}
                          >
                            {t('Next choices')}
                            <ChevronRightIcon color={COLOR.blackish}/>
                          </WidgetButton>
                        )}
                      </>
                    )}
                  </Flex>
                )}

                {activeScreen === WIDGET_SCREENS.voting && (
                  <>
                  <ActionButtonsWrapper>
                    <WidgetButton
                      margin={'15px 0'}
                      flex={1}
                      onClick={() => shouldGatherInfo()}
                      background={customisation.btn_1_color + 'CC'}
                      color={customisation.btn_1_text_color}
                      disabled={!Object.keys(selectedChoices).length} 
                    >
                    {!ifGatherInfo && (
                      <SendIcon color={customisation.btn_1_text_color}/>
                    )}
                    {t(ifGatherInfo ? 'Continue' : 'Send my participation')}
                    </WidgetButton>
                  </ActionButtonsWrapper>
                  </>
                )}
              </Box>
            )}

            {showGatherInfoForm && (
              <Box>
                <Box margin={'18px 0 25px'}>
                  {Object.entries(activeSegment.information_to_gather).map(
                    ([fieldName, fieldProps]) => fieldProps.active && (
                      <Box key={fieldName} margin={'15px 0 8px'}>
                        <Input
                          rounded
                          borderless
                          padding={'13px 34px 13px 12px'}
                          background={"#D5D5D5"}
                          color={COLOR.blackish}
                          labelColor={COLOR.blackish}
                          labelSize={"xxs"}
                          labelBottomMargin={"8px"}
                          placeholderColor={COLOR.blackish + 'CC'}
                          fontWeight={400}
                          fontFamily={'Montserrat'}
                          placeholder={''}
                          widgetInputSmall
                          value={extraUserInformation[fieldName]}
                          onChange={(name, val) => {
                            if(WIDGET_SCREENS.gatherInformation === activeScreen) {
                              setField(name, val)
                              validateExtraInformation(false)
                            }
                          }}
                          name={fieldName}
                          label={t(WIDGET_INFORMATION_MAP[fieldName]) + ' (' + (fieldProps.mandatory ? t('required') : t('optional')) + ')'}
                          valid={!gatherInfoErrors[fieldName]}
                          showValidation={showValidation}
                          required={fieldProps.mandatory}
                        />
                      </Box>
                    )
                  )}
                  <Flex justifyContent={'space-between'} alignItems={'center'}>
                    <MainText color={COLOR.blackish}>{t('Memorise my information')}</MainText>
                    <Toggle
                      color={customisation.btn_1_color}
                      active={rememberMe}
                      onChange={(val) => {
                        if(WIDGET_SCREENS.gatherInformation === activeScreen) setRememberMe(val)
                      }}
                    />
                  </Flex>
                </Box>
                {activeScreen === WIDGET_SCREENS.gatherInformation && (
                  <>
                    <ActionButtonsWrapper>
                      <WidgetButton
                        margin={'15px 0'}
                        background={customisation.btn_1_color}
                        color={customisation.btn_1_text_color}
                        onClick={handleGatherInfo}
                      >
                        {!shouldShowRgpdConsent && <SendIcon color={customisation.btn_1_text_color}/>}
                        {t(shouldShowRgpdConsent ? 'Continue' : 'Send my participation')}
                      </WidgetButton>
                    </ActionButtonsWrapper>
                    {!!Object.keys(gatherInfoErrors).length && showValidation && (
                      <WidgetButton
                        capitalize={false}
                        height={'fit-content'}
                        background={'#DB3838'}
                        color={'#FFFFFF'}
                        borderColor={'#DB3838'}
                        fontWeight={500}
                        fontSize={'14px'}
                        disabled
                      >
                        <Flex flexDirection={'column'}>
                          <Text mb={'8px'} semiBold theme={'white'}>
                            {t('Error(s) in the form')}
                          </Text>
                          {Object.values(gatherInfoErrors).map(val => (
                            <Text theme={'white'}>
                              {t(val)}
                            </Text>
                          ))}
                        </Flex>
                      </WidgetButton>
                    )}
                  </>
                )}
              </Box>
            )}

            {activeScreen === WIDGET_SCREENS.rgpdConsent && (
              <Box>
                <RGPDConsentWrapper>
                  {customisation.image_right && (
                    <Box mb={"18px"}>
                      <Text upper semiBold>
                        {customisation.title_consent_image_right || t('Consent right of ownership')}
                      </Text>
                      <Flex>
                        {customisation.consent_checkbox_image_right && (
                        <Flex justifyContent="justify-content-start" padding="10px">
                          <Box width={'25px'}>
                            <Checkbox
                              checked={imageRightsGiven}
                              onChange={() => {
                                setImageRight(!imageRightsGiven)
                              }}
                              pointer
                            />
                          </Box>
                        </Flex>
                        )}
                        <Flex justifyContent="justify-content-end" padding="10px">
                          <Text
                            ml={"10px"}
                            size={'xs'}
                            lh={'14px'}
                            color={COLOR.blackish}
                            dangerouslySetInnerHTML={{__html: customisation.image_right_text}}
                          />
                        </Flex>
                      </Flex>
                    </Box>
                  )}
                  {customisation.consent_gdpr && (
                    <Box>
                      <Text upper semiBold>
                        {customisation.title_consent || t('Consent RGPD')}
                      </Text>
                      <Flex>
                        {customisation.consent_checkbox && (
                      <Flex justifyContent="justify-content-start" padding="10px">
                          <Box width={'25px'}>
                            <Checkbox
                            width={'25px'}
                            checked={consentGDPRGiven}
                            onChange={() => {
                              setConsentGDPR(!consentGDPRGiven)
                              setConsentGDPRText(customisation.consent_gdpr_text)
                            }}
                            pointer

                            />
                          </Box>
                          </Flex>
                        )}
                      <Flex justifyContent="justify-content-end" padding="10px">
                        <Text
                          lh={'14px'}
                          size={'xs'}
                          color={COLOR.blackish}
                          dangerouslySetInnerHTML={{__html: customisation.consent_gdpr_text}}
                        />
                      </Flex>
                      </Flex>
                    </Box>
                  )}
                </RGPDConsentWrapper>
                <ActionButtonsWrapper>
                  <WidgetButton
                    margin={'15px 0'}
                    background={customisation.btn_1_color}
                    color={customisation.btn_1_text_color}
                    disabled={isSendMyParticipationDisable(customisation, consentGDPRGiven, imageRightsGiven)}
                    onClick={handleGatherInfo}
                  >
                    <SendIcon color={customisation.btn_1_text_color}/>
                    {t('Send my participation')}
                  </WidgetButton>
                </ActionButtonsWrapper>
              </Box>
            )}

            {[WIDGET_SCREENS.thanks, WIDGET_SCREENS.sending].includes(activeScreen) && (
              <BottomSection
                disable={!showThanksInBottomSection}
                height={'358px'}
              >
                <Flex
                  flexDirection={'column'}
                  justifyContent={showThanksInBottomSection && 'center'}
                  height={showThanksInBottomSection && '100%'}
                  width={showThanksInBottomSection && '100%'}
                >
                  <Box height={Object.keys(selectedChoices).length !== 0 ? "300px" : ""}>
                    {activeSegment.type === INTERACTION_API_TYPE_MAP.choice && Object.values(selectedChoices).map((choice) => (
                      <WidgetButton
                        margin={'0 0 16px'}
                        key={`choice-${choice.id}`}
                        color={customisation.btn_1_text_color}
                        background={customisation.btn_1_color + "CC"}
                        height={'47px'}
                        disabled
                        opacity={'1'}
                      >
                        {choice.label || ''}
                      </WidgetButton>
                    ))}

                    {textAnswer && !activeSegment.testimonial_demanded && (
                      <WrittenParticipationInput
                        color={COLOR.white}
                        widgetInputSmall
                        value={textAnswer}
                        textArea
                        disabled
                      />
                    )}

                    {WIDGET_SCREENS.thanks === activeScreen && (
                      <>
                        {activeSegment.testimonial_demanded && !testimonialAccepted ? (
                          <>
                            <Text
                              color={COLOR.blackish}
                              center
                              marginTop={'25px'}
                              marginBottom={'25px'}
                              upper
                            >
                              {activeSegment.testimonial}
                            </Text>
                            { activeSegment.testimonial_format === "voice" && (
                              <WidgetButton
                                margin={"0 0 16px"}
                                width={'100%'}
                                onClick={none => handleTestimonial("voice")}
                                background={customisation.btn_1_color}
                                color={customisation.btn_1_text_color}
                              >
                                <VoiceInteractionIcon color={customisation.btn_1_text_color} />
                                {t('Record voice message')}
                              </WidgetButton>
                            )}
                            { activeSegment.testimonial_format === "text" && (
                              <>
                              <WidgetButton
                                margin={"0 0 16px"}
                                width={'100%'}
                                onClick={none => handleTestimonial("text")}
                                background={customisation.btn_1_color}
                                color={customisation.btn_1_text_color}
                              >
                                <TextInteractionIcon color={customisation.btn_1_text_color} />
                                {t('Participate')}
                              </WidgetButton>
                              </>
                            )}
                          </>
                        ) : (activeSegment.type === INTERACTION_API_TYPE_MAP.vote && activeSegment.show_results) && (
                          <WidgetButton
                            margin={'0 0 16px'}
                            color={COLOR.blackish}
                            borderColor={COLOR.grey1}
                            background={'none'}
                            onClick={() => changeActiveScreen(WIDGET_SCREENS.results)}
                            height={'47px'}
                            dottedBorder
                            iconSize={"9px"}
                            iconMargin={"0 0 0 9px"}
                          >
                            {t('See the results')}
                            <ChevronRightIcon color={COLOR.blackish}/>
                          </WidgetButton>
                        )}
                      </>
                    )}
                  </Box>
                </Flex>
              </BottomSection>
            )}
            {thankYouMessageScreens.includes(activeScreen) && (
              <ActionButtonsWrapper>
                <FlexCenter flexDirection={"column"}>
                  {WIDGET_SCREENS.sending === activeScreen ? (
                    <FlexCenter margin={"25px 0"}>
                      <Text mr={"7px"} semiBold color={COLOR.blackish}>
                        {t('Sending in progress')}
                      </Text>
                      <PaperAirplaneIcon/>
                    </FlexCenter>
                  ) : (
                    <FlexCenter margin={"6px 0 16px"}>
                      <Text mr={"7px"} semiBold color={COLOR.blackish}>
                        {t('Participation sent')}
                      </Text>
                      <ClappingIcon/>
                    </FlexCenter>
                  )}
                  {WIDGET_SCREENS.thanks === activeScreen && (
                    <Text center color={COLOR.blackish}>{customisation.text_thanks}</Text>
                  )}
                </FlexCenter>
              </ActionButtonsWrapper>
            )}
            {channel.show_voxm_logo && (<PoweredBy margin={"7px 0 0"}/>)}
          </ContentBody>
        </Content>
      )}
    </Wrapper>
  );
}
