import styled from 'styled-components';

export const Video = styled.video`
  width: 100%;
  border-radius: 5px;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  background: rgba(28, 28, 28, 0.7);
  z-index: 1;
  object-fit: cover;
  object-position: center;
  aspect-ratio: 1;
`;