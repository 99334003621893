import React from 'react';
import { COLOR } from '../../constants/common';

export const GameInteractionIcon = ({ color = COLOR.grey1 }) => (
  <svg
    height="35"
    viewBox="0 0 35 35"
    width="35"
    xmlns="http://www.w3.org/2000/svg"
  >
    <clipPath id="game-interaction">
      <path d="m0 0h35v35h-35z" />
    </clipPath>
    <g clipPath="url(#game-interaction)" fill={color}>
      <path
        d="m-112.735 269.085a17.5 17.5 0 0 1 -17.482-17.511 17.5 17.5 0 0 1 17.5-17.489 17.5 17.5 0 0 1 17.485 17.515 17.5 17.5 0 0 1 -1.444 6.951l1.387 4.212a1.426 1.426 0 0 1 -.906 1.8l-11.05 3.638a17.534 17.534 0 0 1 -5.486.886m.032-32.143a14.653 14.653 0 0 0 -14.64 14.666 14.665 14.665 0 0 0 .728 4.554 14.629 14.629 0 0 0 18.47 9.324l9.7-3.191-1.105-3.359a1.361 1.361 0 0 1 -.072-.317 1.429 1.429 0 0 1 .142-.794 14.652 14.652 0 0 0 -7.021-19.49 14.64 14.64 0 0 0 -6.2-1.392z"
        transform="translate(130.217 -234.085)"
      />
      <path
        d="m-100.263 267.189a1.426 1.426 0 0 1 -1.427-1.424v-2.383h-2.381a1.427 1.427 0 0 1 -1.426-1.427 1.427 1.427 0 0 1 1.426-1.427h2.381v-2.381a1.427 1.427 0 0 1 1.427-1.428 1.427 1.427 0 0 1 1.427 1.428v2.381h2.382a1.427 1.427 0 0 1 1.426 1.427 1.427 1.427 0 0 1 -1.426 1.427h-2.382v2.381a1.425 1.425 0 0 1 -1.425 1.426z"
        transform="translate(115.018 -247.998)"
      />
      <path
        d="m-83.363 289.366a2.36 2.36 0 0 1 -2.359 2.361 2.36 2.36 0 0 1 -2.36-2.36 2.36 2.36 0 0 1 2.359-2.361 2.359 2.359 0 0 1 2.36 2.358"
        transform="translate(104.309 -266.616)"
      />
      <path
        d="m-70.629 276.527a2.36 2.36 0 0 1 -2.358 2.362 2.36 2.36 0 0 1 -2.361-2.359 2.36 2.36 0 0 1 2.358-2.362 2.359 2.359 0 0 1 2.36 2.359"
        transform="translate(96.479 -258.725)"
      />
    </g>
  </svg>
);
