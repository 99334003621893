import React from 'react';

export const AcceptIcon = () => (
  <svg
    height="15"
    viewBox="0 0 17.442 15"
    width="17.442"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m350.888 253-5.888-5.888 3.446-3.446 2.441 2.442 8.113-8.108 3.446 3.446zm0 0"
      fill="#addb31"
      transform="translate(-345 -238)"
    />
  </svg>
);
