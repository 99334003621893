import React from 'react';

export const Thanks = () => (
  <svg
    id="ic_thumb_down_24px"
    xmlns="http://www.w3.org/2000/svg"
    width="38.5"
    height="35"
    viewBox="0 0 38.5 35"
  >
    <path
      id="ic_thumb_down_24px-2"
      data-name="ic_thumb_down_24px"
      d="M25.5,3H9.75A3.476,3.476,0,0,0,6.53,5.135L1.245,17.473A3.457,3.457,0,0,0,1,18.75v3.343l.017.017L1,22.25a3.51,3.51,0,0,0,3.5,3.5H15.543l-1.662,8-.053.56a2.634,2.634,0,0,0,.77,1.855L16.453,38,27.985,26.468A3.478,3.478,0,0,0,29,24V6.5A3.51,3.51,0,0,0,25.5,3Zm7,0V24h7V3Z"
      transform="translate(39.5 38) rotate(180)"
      fill="#7f0e7e"
    />
  </svg>
);
