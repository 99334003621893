import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { request } from '../utils/request';
import { API_ENDPOINTS } from '../../constants/common';
import { useUser } from './withUser';
import { parse } from 'query-string';

export const WithLogin = () => {
  const [email, setEmail] = useState('');
  const [emailFromReset, setEmailFromReset] = useState(false);
  const [password, setPassword] = useState('');
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const { fetchCurrentUser, setState: setUserState, userState } = useUser();

  const finishLogin = async token => {
    window.localStorage.setItem('access_token', token);
    window.localStorage.removeItem('show_welcome_help');
    const { inSuperAdminMode } = await fetchCurrentUser();
    setLoading(false);

    if (inSuperAdminMode) {
      history.push('/channels');
    } else {
      history.push('/home');
    }
  };

  const login = async () => {
    setLoading(true);

    const loginData = {
      email,
      password,
    };

    const { data } = await request({
      url: API_ENDPOINTS.auth,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(loginData),
    });

    const { access_token } = data;
    await finishLogin(access_token);
  };

  const logout = (extraPath = '') => {
    window.localStorage.removeItem('access_token');
    window.localStorage.removeItem('show_welcome_help');
    setLoading(false);
    history.push(`/login${typeof extraPath === 'string' ? extraPath : ''}`);
    setUserState({ ...userState, user: null, isLoading: false });
  };

  const resetPassword = async e => {
    if (!email) {
      return;
    }
    if (e) {
      e.preventDefault();
    }
    const body = {
      email,
    };
    await request({
      url: API_ENDPOINTS.usersReset,
      method: 'POST',
      body,
    });
    history.push(`/login?email=${email}`);
  };

  useEffect(() => {
    const queryParams = parse(history.location.search);
    if (queryParams.email) {
      setEmail(queryParams.email);
      setEmailFromReset(true);
    }
    if (queryParams.token) {
      finishLogin(queryParams.token);
    }
  }, []);

  return {
    email,
    emailFromReset,
    password,
    isLoading,
    // setters
    setEmail,
    setPassword,
    // methods
    login,
    resetPassword,
    logout,
  };
};
