import React from 'react';
import Datetime from 'react-datetime';
import { WithPicker } from '../../models/picker/withPicker';
import styled from 'styled-components';
import { Text } from '../text/Text';
import { DATE_FORMAT } from '../../constants/common';
import moment from 'moment';
import { Absolute } from '../box/Absolute';
import ClickOutside from '../../features/utils/ClickOutside';
import { CalendarPickerIcon } from '../icons/CalendarPickerIcon';
import { InputWrapper } from '../input/InputWrapper';

const PickerWrapper = styled(Absolute)`
  background: transparent;
  z-index: 3;
  width: 100%;
  table {
    width: 100%;
  }
  .rdtPicker {
    width: 100%;
    box-sizing: border-box;
    min-width: 250px;
  }
`;

export const DatePicker = ({
  name,
  value,
  onChange,
  disabled,
  hideIcon,
  format,
}) => {
  const { open, setOpen } = WithPicker();
  const displayDate = value ? moment(value) : '';
  const dateString = displayDate
    ? displayDate.format(format || DATE_FORMAT)
    : '';

  return (
    <InputWrapper
      onClick={() => !disabled && setOpen(true)}
      disabled={disabled}
    >
      <Text width={'100%'} capital>
        {dateString || '-'}
      </Text>
      {open && (
        <PickerWrapper r={'0'} t={'31px'}>
          <ClickOutside callback={() => setOpen(false)}>
            <Datetime
              open
              input={false}
              value={displayDate}
              onChange={(value) => onChange(name, value)}
              timeFormat={false}
            />
          </ClickOutside>
        </PickerWrapper>
      )}
      {!hideIcon && (
        <Absolute r={'8px'} t={'6px'}>
          <CalendarPickerIcon />
        </Absolute>
      )}
    </InputWrapper>
  );
};
