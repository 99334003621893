import React from 'react';

export const ArrowBackIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10.84"
    height="18.716"
    viewBox="0 0 10.84 18.716"
  >
    <path
      d="M4 4l7.5 7.5L4 19"
      transform="rotate(-180 6.443 10.439)"
      fill={'none'}
      stroke={'#ffffff'}
      strokeLinecap={'round'}
      strokeLinejoin={'round'}
      strokeMiterlimit={10}
      strokeWidth={'2.5px'}
    />
  </svg>
);
