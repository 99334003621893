import React from 'react';
import { Box, Flex } from 'reflexbox';
import styled from 'styled-components';

import { TopNav } from '../../ui/widget/TopNav';
import { ProIcon } from '../../ui/icons/ProIcon';
import { HeroUserIcon } from '../../ui/icons/HeroIcon';
import { ExpertIcon } from '../../ui/icons/ExpertIcon';
import { AmateurIcon } from '../../ui/icons/AmateurIcon';
import { BeginnerIcon } from '../../ui/icons/BeginnerIcon';
import { RegularUserIcon } from '../../ui/icons/RegularIcon';
import { ConfirmedIcon } from '../../ui/icons/ConfirmedIcon';
import { ArrowBackIcon } from '../../ui/icons/ArrowBackIcon';
import { ScreenWrapper } from '../../ui/widget/ScreenWrapper';
import { Absolute } from '../../ui/box/Absolute';
import { ScrollY } from '../../ui/box/ScrollY';
import { Translate } from '../../features/translate/Translate';

export const SectionHeader = styled(Translate)`
  font-size: 28px;
  font-weight: bold;
  line-height: 32px;
  text-align: center;
  color: #d2d2d2;
  margin-top: 56px !important;
  text-transform: uppercase;
  margin-bottom: 15px !important;
`;

const UserLevelWrapper = styled(Flex)`
  border-bottom: solid 1px #a29e9e;
  width: calc(100% - 58px);
  padding-top: 11px;
  padding-bottom: 13px;
  color: white;
  z-index: 3000;
  margin: auto !important;
  ${({ isLast }) => (isLast ? 'border-bottom: none;' : '')};
`;

const UserLevelName = styled(Translate)`
  font-size: 18px;
  font-weight: bold;
  line-height: 1.17;
  text-align: left;
  color: #fefffe;
  margin-bottom: 3px;
`;

const UserLevelPoints = styled.div`
  font-size: 13px;
  line-height: 1.08;
  text-align: left;
  color: #d2d2d2;
  div {
    display: inline;
  }
`;

const UserLevel = ({ name, points, icon, color, isLast }) => (
  <UserLevelWrapper key={name} color={color} isLast={isLast}>
    <Box>
      <UserLevelName>{name}</UserLevelName>
      <UserLevelPoints>
        {points} <Translate>points</Translate>
      </UserLevelPoints>
    </Box>
    <Box ml={'auto'} h={'40px'}>
      {icon}
    </Box>
  </UserLevelWrapper>
);

const userLevels = [
  {
    name: 'HERO',
    points: 2000,
    icon: <HeroUserIcon />,
  },
  {
    name: 'EXPERT',
    points: 1500,
    icon: <ExpertIcon />,
  },
  {
    name: 'PRO',
    points: 1000,
    icon: <ProIcon />,
  },
  {
    name: 'CONFIRMED',
    points: 750,
    icon: <ConfirmedIcon />,
  },
  {
    name: 'REGULAR',
    points: 500,
    icon: <RegularUserIcon />,
  },
  {
    name: 'AMATEUR',
    points: 250,
    icon: <AmateurIcon />,
  },
  {
    name: 'BEGINNER',
    points: 0,
    icon: <BeginnerIcon />,
  },
];

const AbsolutePointer = styled(Absolute)`
  cursor: pointer;
`;

export const LevelsScreen = ({ onClose }) => {
  return (
    <ScreenWrapper>
      <TopNav>
        <AbsolutePointer onClick={onClose} left={'19px'} top={'15px'}>
          <ArrowBackIcon />
        </AbsolutePointer>
      </TopNav>

      <SectionHeader>LEVELS</SectionHeader>

      <ScrollY py={'18px'} width={'100%'} flex={1}>
        {userLevels.map((userLevel, index) => (
          <UserLevel
            key={`level-${userLevel.name}`}
            {...userLevel}
            isLast={index === userLevels.length - 1}
          />
        ))}
      </ScrollY>
    </ScreenWrapper>
  );
};
