import React from 'react';

export const CustomisationIcon = () => (
  <svg
    height="19.992"
    viewBox="0 0 19.993 19.992"
    width="19.993"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="#707070" className="active-change">
      <path
        d="m558.1 413.566a2.343 2.343 0 1 0 -4.686 0v1.171a1.241 1.241 0 0 1 -1.211 1.21v1.171h2.382a3.552 3.552 0 0 0 3.514-3.553zm0 0"
        transform="translate(-552.199 -397.127)"
      />
      <path
        d="m732.584 234.308a.586.586 0 1 0 .717.414.585.585 0 0 0 -.717-.414zm0 0"
        transform="translate(-722.78 -229.403)"
      />
      <path
        d="m676.388 299.06a.586.586 0 0 0 .3-1.131.43.43 0 0 0 -.328.027q.01.53.014 1.1s.007 0 .012.006zm0 0"
        transform="translate(-669.898 -289.711)"
      />
      <path
        d="m779.887 408.585a.586.586 0 1 0 -.414.718.585.585 0 0 0 .414-.718zm0 0"
        transform="translate(-766.941 -394.215)"
      />
      <path
        d="m820.089 258.58a.586.586 0 1 0 -.828 0 .587.587 0 0 0 .828 0zm0 0"
        transform="translate(-805.194 -251.483)"
      />
      <path
        d="m674.531 163.04a9.762 9.762 0 0 0 -5.1 1.8 37.892 37.892 0 0 1 .329 4.065 1.757 1.757 0 1 1 .212 3.5c-.056 0-.112-.017-.169-.022-.007.937-.028 1.864-.069 2.749a3.088 3.088 0 0 1 1.375 1.011 2.829 2.829 0 0 1 .389 2.185 1.764 1.764 0 0 0 1.339 2.153 8.8 8.8 0 1 0 1.692-17.438zm2.036 4.261a1.758 1.758 0 0 1 2.486 2.485 1.758 1.758 0 0 1 -2.486-2.486zm-4.98.484a1.757 1.757 0 1 1 2.152 1.243 1.756 1.756 0 0 1 -2.152-1.243zm4.577 10.3a1.757 1.757 0 1 1 1.242-2.151 1.759 1.759 0 0 1 -1.242 2.151zm4.556-4.556a1.757 1.757 0 1 1 -1.242-2.152 1.756 1.756 0 0 1 1.242 2.152zm0 0"
        transform="translate(-663.329 -161.86)"
      />
      <path
        d="m843.113 344.532a.586.586 0 1 0 .414.717.585.585 0 0 0 -.414-.717zm0 0"
        transform="translate(-827.268 -333.889)"
      />
      <path
        d="m590.206 151.059c0-.2.007-.389.007-.586 0-4.542-.3-10-1.757-10s-1.757 5.454-1.757 10c0 .2.005.39.007.586zm0 0"
        transform="translate(-584.903 -140.477)"
      />
      <path
        d="m588.86 367.394a3.471 3.471 0 0 1 1.677.445c.025-.526.045-1.067.058-1.616h-3.47c.014.549.033 1.091.058 1.616a3.471 3.471 0 0 1 1.677-.445zm0 0"
        transform="translate(-585.307 -354.47)"
      />
    </g>
  </svg>
);
