import React from 'react';
import { FlexCenter } from '../../new/ui/box/FlexCenter';
import { Text } from '../../new/ui/text/Text';
import styled, { css } from 'styled-components';
import { COLOR } from '../../new/constants/common';
import { useTranslation } from 'react-i18next';

const StepWrapper = styled(FlexCenter)`
  position: relative;
`;

const StepLine = styled.div`
  width: 100%;
  height: 1px;
  background: ${COLOR.brownishGrey};
  right: -50%;
  position: absolute;
  top: 19px;
`;

const StepIndicatorWrapper = styled.div`
  padding: 0 12.5px;
  z-index: 2;
  background: ${COLOR.white};
`;

const StepIndicator = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 0.5px solid ${COLOR.grey6};
  box-sizing: border-box;

  ${({ active }) =>
    active &&
    css`
      border: none;
      background: ${COLOR.yellowishOrange};
    `}
`;

export const Steps = ({ steps, step }) => {
  const { t } = useTranslation();
  return (
    <>
      {steps.map((name, index) => (
        <StepWrapper flex={1} key={`step-${index}`}>
          <FlexCenter flexDirection={'column'} flex={1}>
            <StepIndicatorWrapper>
              <StepIndicator active={index <= step} />
            </StepIndicatorWrapper>
            <Text mt={'5px'} semiBold={index <= step}>
              {t(name)}
            </Text>
          </FlexCenter>
          {index !== steps.length - 1 && <StepLine />}
        </StepWrapper>
      ))}
    </>
  );
};
