import React from 'react';
import { Text } from '../text/Text';
import { WithPicker } from '../../models/picker/withPicker';
import { Absolute } from '../box/Absolute';
import ClickOutside from '../../features/utils/ClickOutside';
import styled, { css } from 'styled-components';
import { COLOR } from '../../constants/common';
import { ArrowDropDownIcon } from '../icons/ArrowDropDownIcon';
import { InputWrapper } from '../input/InputWrapper';
import { useTranslation } from 'react-i18next';

const PickerWrapper = styled(Absolute)`
  background: ${COLOR.white};
  z-index: 10;
  width: 100%;
  border: 1px solid ${COLOR.veryLightPink};
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  max-height: 350px;
  overflow-y: auto;
`;

const AbsolutePointer = styled(Absolute)`
  cursor: pointer;
`;

const Icon = styled.img`
  width: 30px;
  height: 21px;
  margin-right: 6px;

  ${({ isLoginPage }) =>
    isLoginPage &&
    css`
      width: 44px;
      height: 29px;
      margin-left: 16px;
      margin-right: 0;
    `}
`;

export const Select = ({
  options,
  value,
  onChange,
  name,
  disabled,
  isLoginPage,
}) => {
  const { open, setOpen } = WithPicker();
  const { t } = useTranslation();
  const selected = options.find((option) => option.value === value) || {};
  return (
    <InputWrapper
      onClick={() => setOpen(true)}
      disabled={disabled}
      isLoginPage={isLoginPage}
    >
      {selected.icon && (
        <Icon src={selected.icon} alt={''} isLoginPage={isLoginPage} />
      )}
      <Text
        color={isLoginPage ? COLOR.white : COLOR.brownishGrey}
        capital
        size={isLoginPage ? 'lg' : undefined}
      >
        {t(selected.label || selected.value || '')}
      </Text>
      {open && !disabled && (
        <PickerWrapper t={'30px'} l={'0'}>
          <ClickOutside callback={() => setOpen(false)}>
            {options.filter((option) => !option.hidden).map((option) => (
              <Text
                key={`${name}-${option.value}`}
                color={
                  value === option.value ? COLOR.white : COLOR.brownishGrey
                }
                pointer
                pl={'20px'}
                onClick={(e) =>
                  e.stopPropagation() ||
                  option.disabled ||
                  onChange(name, option.value) ||
                  setOpen(false)
                }
                backgroundColor={
                  option.disabled ? COLOR.grey4 : value === option.value ? COLOR.link : COLOR.white
                }
                py={'5px'}
                capital
              >
                {t(option.label || option.value)}
              </Text>
            ))}
          </ClickOutside>
        </PickerWrapper>
      )}
      {!disabled && !isLoginPage && (
        <AbsolutePointer r={'10px'} t={'6px'}>
          <ArrowDropDownIcon open={open} />
        </AbsolutePointer>
      )}
    </InputWrapper>
  );
};
