import styled, { css } from 'styled-components';
import { Box } from 'reflexbox';

export const Pointer = styled(Box)`
  ${({ inactive }) => !inactive ? css`
    cursor: pointer;
  ` : css`
    pointer-events: none;
  `}
  ${({ hoverUnderline }) =>
    hoverUnderline && css`&:hover{
      text-decoration: underline
    }`
  }
`;
