import styled, { css } from 'styled-components';
import { COLOR } from '../../constants/common';
import { Flex } from 'reflexbox';

export const InputWrapper = styled(Flex)`
  height: 33px;
  position: relative;
  align-items: center;
  border: 1px solid ${COLOR.veryLightPink};
  border-radius: 5px;
  width: 100%;
  padding: 0 8px;
  color: ${COLOR.brownishGrey};
  ${({ disabled }) =>
    disabled
      ? css`
          border-color: transparent !important;
        `
      : css`
          cursor: pointer;
        `};

  border-color: ${({ valid }) =>
    valid !== undefined ? (valid ? '#3a7c2a' : 'red') : COLOR.veryLightPink};

  ${({ dash }) =>
    dash && css`
      border-style: dashed;
      border-color: ${COLOR.veryLightPink} !important;
    `}

  ${({ isLoginPage }) =>
    isLoginPage &&
    css`
      border: none;
      flex-direction: row-reverse;
    `}
`;
